import axios from "axios";
import { startLogout, tokenSubject } from "../actions/auth.js";
import { conf } from "../config/config.js";
import { eraseCookie, getCookie } from "./cookies.js";
const baseURL = conf.apiURL;


export const makeGET = (endpoint = '', query = '') => {
    let headers = {
        'Content-Type': 'application/json'
    }

    if (getCookie("token")) {
        headers.Authorization = getCookie("token")
    }

    return sec(axios.get(baseURL + endpoint + query, { headers, withCredentials: true }))
}

export const makePOST = (endpoint = '', body = '', isFormData = false) => {
    let headers = {
        'Content-Type': isFormData ? 'multipart/form-date' : 'application/json'
    }

    const formatBody = isFormData ? body : JSON.stringify(body)

    if (getCookie("token")) {
        headers.Authorization = getCookie("token")
    }

    return sec(axios.post(baseURL + endpoint, formatBody, { headers, withCredentials: true }))
}

export const makeLogin = (endpoint = '', body = '', isFormData = false) => {
    let headers = {
        'Content-Type': isFormData ? 'multipart/form-date' : 'application/json',
    }

    const formatBody = isFormData ? body : JSON.stringify(body)

    if (getCookie("token")) {
        headers.Authorization = getCookie("token")
    }

    return sec(axios.post(baseURL + endpoint, formatBody, { headers, withCredentials: true }))
}

export const makePATCH = (endpoint = '', body = '', isFormData = false) => {
    let headers = {
        'Content-Type': isFormData ? 'multipart/form-date' : 'application/json',
    }

    const formatBody = isFormData ? body : JSON.stringify(body)

    if (getCookie("token")) {
        headers.Authorization = getCookie("token")
    }
    return sec(axios.patch(baseURL + endpoint, formatBody, { headers, withCredentials: true }))
}

export const makeDELETE = (endpoint = '', body = '') => {
    var data = JSON.stringify(body)
    var config = {
        method: 'delete',
        url: baseURL + endpoint,
        headers: {
            'Authorization': (getCookie("token")),
            'Content-Type': 'application/json'
        },
        data: data, 
        withCredentials: true
    };

    return sec(axios(config))
}

const sec = async (request) => {
    try {
        let response = await request
        return response
    } catch (e) {
        console.log(e)
        const err = e.response || JSON.parse(JSON.stringify(e))
        if (err?.status == 422) {
            eraseCookie('email')
            eraseCookie('token')
            eraseCookie('user')
            tokenSubject.next(null)
            window.location.reload(false);
        }
        throw e
    }
}