
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { makeGET, makePOST } from "../../helpers/makeRequest";
import { conf } from '../../config/config'
import { MDBDataTableV5 } from 'mdbreact';
import Select from "react-select";
import { TextField, Input, MenuItem, Switch, unstable_ClassNameGenerator } from "@mui/material";
import InputMask from 'react-input-mask'
import { FileUpload } from 'primereact/fileupload';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { FaPlus, FaSave, FaTimes } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import MapPicker from 'react-google-map-picker'
import { categorias } from "../../config/gmb_categories";
import { createFilter } from "react-select";
import zIndex from "@mui/material/styles/zIndex";
import { setCookie } from "../../helpers/cookies";
import { Picker } from 'react-gmap-picker';
import { AdvancedMarker, APIProvider, Map, useMap } from '@vis.gl/react-google-maps';
export const InicioScreen = () => {
  const user = useSelector((state) => state.auth);


  const [datos, setDatos] = useState([]);
  const baseURL = conf.apiURL;
  const [estatusFilled, setEstatusFilled] = useState(false);
  const initialFormState = {
    advertiserId: undefined,
    business_id: undefined,
    nombre: undefined,
    slogan: undefined,
    nosotros: undefined,
    logo: undefined,
    portada: undefined,
    imgServicios: [],
    ciudad: undefined,
    estado: undefined,
    colonia: undefined,
    colonia: undefined,
    calle: undefined,
    numero: undefined,
    codigoPostal: undefined,
    lat: undefined,
    lng: undefined,
    horarios: [],
    categoria: undefined,
    productos: [],
    tags: [],
    urlReservacion: undefined,
    menu: undefined,
    tiposPago: [],
    telefono: undefined,
    whatsapp: undefined,
    telefonosAdicionales: [],
    correo: undefined,
    sitioWeb: undefined,
    twitter: undefined,
    facebook: undefined,
    youtube: undefined,
    instagram: undefined,
  }
  const [form, setForm] = useState({ ...initialFormState })

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const undReturn = (val) => {
    if (val == undefined) {
      return ""
    }
    return val
  }

  const show = (severity, summary) => {
    try {
      toast[severity](summary, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      })
    } catch (e) {
      console.log(e)
    }
  };

  const [flag, setFlag] = useState(false)

  const estatuses = [
    { value: 1, label: "INICIADO" },
    { value: 2, label: "COMPLETADO" },
  ]

  const updateStatus = (val, idx, prop) => {
    let temp = { ...datos }
    temp.rows[idx][prop] = val.label
    temp.rows[idx].estatus_button =
      <div className="text-center">
        <Select
          options={estatuses}
          value={{ value: statutes[val.value], label: val.label }}
          autoFocus={false}
          isSearchable={false}
          onChange={(e) => {
            updateStatus(e, idx, "estatus")
          }}
        />
      </div>
    setDatos({ ...temp })
    makePOST("updateStatus", { id: temp.rows[idx].id, estatus: statutes[temp.rows[idx][prop]] })
  }

  const statutes = {
    "INICIADO": 1,
    "COMPLETADO": 2
  }

  useEffect(() => {
    if (datos.length != 0 && !estatusFilled) {
      let temp = { ...datos }
      temp.rows.map((element, idx) => {
        element.estatus_button =
          <div className="text-center">
            <Select
              options={estatuses}
              value={{ value: statutes[element.estatus], label: element.estatus }}
              autoFocus={false}
              isSearchable={false}
              onChange={(e) => {
                updateStatus(e, idx, "estatus")
              }}
            />
          </div>
      })
      setDatos({ ...temp })
      setEstatusFilled(true)
    }
  }, [datos])


  const logoFile = useRef(null)
  const portadaFile = useRef(null)
  const servicioFile = useRef(null)
  const menuFile = useRef(null)

  const resetForm = () => {
    setForm({ ...initialFormState })
    setAddingServicio(false)
    setTmpService({
      seccion: undefined,
      archivos: undefined
    })

    logoFile.current.value = ""
    portadaFile.current.value = ""

    if (servicioFile.current) {
      servicioFile.current.value = ""
    }

    if (menuFile.current) {
      menuFile.current.value = ""
    }

    setTmpSub(undefined)
    setAddingSub(false)
    setTmpSub(undefined)
    setTmpProd(undefined)
    setTmpAdTels(undefined)
    setLocation(defaultLocation)
    setSrcPortada(undefined)
    setSrcMenu(undefined)
    setSrcLogo(undefined)
    setColonias([])
    setDias([...initialDiasState])
  }

  const initialized = useRef(false)
  const [initiated, setInitiated] = useState(false)

  const initializeToken = () => {
    let url = new URL(window.location)
    let params = new URLSearchParams(url.search)
    let token = params.get('token')
    setCookie("token", token)

    makeGET("getTokenInfo").then(v => {
      const { data } = v
      if (data?.advertiser_id && data?.business_id) {
        setUserId(data?.user_id)
        setForm({
          ...form,
          business_id: data?.business_id,
          advertiserId: data?.advertiser_id
        })
      }
    })
  }

  const map = useMap();

  useEffect(() => {
    if (!map) return;
    console.log(map)
  }, [map]);

  useEffect(() => {
    if (!initiated) {
      setInitiated(true)
    } else {
      setTimeout(() => {
        initializeToken()
      }, 1000);
    }
  }, [initiated]);

  const [userId, setUserId] = useState(undefined)

  const [tmpService, setTmpService] = useState(
    {
      seccion: undefined,
      archivos: undefined
    }
  )

  const [addingServicio, setAddingServicio] = useState(false)
  const [addingSub, setAddingSub] = useState(false)
  const [tmpSub, setTmpSub] = useState(undefined)
  const [tmpProd, setTmpProd] = useState(undefined)
  const [tmpAdTels, setTmpAdTels] = useState(undefined)

  const checkPhone = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return true
    }
    return false
  }

  const removeImg = (i) => {
    const tmpArr = [...form.imgServicios]

    tmpArr.splice(i, 1)
    setForm({ ...form, imgServicios: [...tmpArr] })
  }

  const tiposPago = [
    { value: "EFECTIVO", label: "EFECTIVO" },
    { value: "DEPOSITO BANCARIO", label: "DEPÓSITO BANCARIO" },
    { value: "VALES", label: "VALES" },
    { value: "CHEQUES", label: "CHEQUES" },
    { value: "VISA", label: "VISA" },
    { value: "MASTERCARD", label: "MASTERCARD" },
    { value: "AMERICAN EXPRESS", label: "AMERICAN EXPRESS" },
    { value: "PAYPAL", label: "PAYPAL" },
    { value: "TRANSFERENCIA BANCARIA", label: "TRANSFERENCIA BANCARIA" },
  ]

  const readURL = (file) => {
    if (file) {
      var reader = new FileReader()

      reader.readAsDataURL(file)
      return reader.onload = function (e) {
        return e.target.result
      }
    }
  }

  const returnHorarios = () => {
    let arr = []
    for (let i = 0; i < 24; i++) {
      arr = [...arr, String(i).padStart(2, "0") + ":" + "00"]
      arr = [...arr, String(i).padStart(2, "0") + ":" + "30"]
    }
    return arr
  }
  const horarios = returnHorarios()
  const initialDiasState = [
    {
      dia: "lunes",
      active: false,
      hrs: [{
        open: "",
        close: "",
      }]
    },
    {
      dia: "martes",
      active: false,
      hrs: [{
        open: "",
        close: "",
      }]
    },
    {
      dia: "miercoles",
      active: false,
      hrs: [{
        open: "",
        close: "",
      }]
    },
    {
      dia: "jueves",
      active: false,
      hrs: [{
        open: "",
        close: "",
      }]
    },
    {
      dia: "viernes",
      active: false,
      hrs: [{
        open: "",
        close: "",
      }]
    },
    {
      dia: "sabado",
      active: false,
      hrs: [{
        open: "",
        close: "",
      }]
    },
    {
      dia: "domingo",
      active: false,
      hrs: [{
        open: "",
        close: "",
      }]
    }
  ]
  const [dias, setDias] = useState([...initialDiasState])

  /*useEffect(() => {
    setForm({
      ...form, horarios: dias.map(v => {
        let obj = { ...v }
        delete obj.active
        return { ...obj }
      })
    })
  }, [dias])*/

  const [srcPortada, setSrcPortada] = useState(undefined)
  const [srcMenu, setSrcMenu] = useState(undefined)
  const [srcLogo, setSrcLogo] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [colonias, setColonias] = useState([])
  const DefaultLocation = { lat: 23.634501, lng: -102.552784 };
  const DefaultZoom = 5;
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const handleChangeLocation = (lat, lng) => {
    setLocation({ lat: lat, lng: lng });
  }

  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom);
  }

  const handleResetLocation = () => {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }

  const validateForm = () => {
    return !(!((form.nombre?.length || 0) == 0) && !((form.slogan?.length || 0) == 0)
      && !((form.nosotros?.length || 0) == 0) && !(form.logo?.length == 0 && form.logo != undefined)
      && !(form.portada?.length == 0 && form.portada != undefined) && !(form.imgServicios?.length == 0 && form.imgServicios != undefined)
      && !(form.codigoPostal?.length == 0 && form.codigoPostal != undefined) && !(form.estado?.length == 0 && form.estado != undefined)
      && !(form.ciudad?.length == 0 && form.ciudad != undefined) && !(form.colonia?.length == 0 && form.colonia != undefined)
      && !(form.calle?.length == 0 && form.calle != undefined) && !(form.numero?.length == 0 && form.numero != undefined)
      && !(form.lat?.length == 0 && form.lat != undefined) && !(form.lng?.length == 0 && form.lng != undefined)
      && !(dias.filter(v => v.active)?.length == 0 && dias != undefined)
      && !(form.categoria?.length == 0 && form.categoria != undefined) && !(form.productos?.length > 10 && form.productos != undefined)
      && (checkPhone(form.telefono) && form.telefono != undefined) && (validateEmail(form.correo) && form.correo != undefined))
  }

  const checkImgServicios = () => {
    return (tmpService?.seccion?.length == 0) || (servicioFile.current?.files?.length == 0)
      || (tmpService == undefined || servicioFile.current?.files == undefined)
  }

  const submit = async () => {
    setLoading(true)
    if (!validateForm()) {
      const formData = new FormData()
      let data = {
        ...form,
        lat: location?.lat, lng: location?.lng,
        horarios: [...dias].filter(v => v.active).map(v => {
          return {
            dia: v.dia,
            hrs: [...v.hrs]
          }
        }),
        categoria: form?.categoria?.value
      }

      delete data.logo
      delete data.portada
      delete data.menu
      data.imgServicios = data.imgServicios.map(v => {
        return v.seccion
      })

      formData.append("data", JSON.stringify({ ...data }))
      formData.append("logo", logoFile?.current?.files[0])
      formData.append("portada", portadaFile?.current?.files[0])
      if (menuFile?.current?.files?.length > 0) {
        formData.append("menu", menuFile?.current?.files[0])
      }

      form.imgServicios.map(v => {
        v.archivos.map((a, i) => {
          formData.append("seccion-" + v.seccion + "-" + i, a)
        })
      })
      try {
        await makePOST("upload", formData, true)
        console.log("listo")
        show("success", "Éxito! Formulario guardado exitosamente.")
        resetForm()
      } catch (e) {
        console.log(e)
        show("error", "Ocurrió un error. Por favor, intenta de nuevo.")
      }
    } else {
      show("warning", "Por favor, revisa los campos del formulario y vuelve a intentarlo.")
    }
    setLoading(false)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 10000
    }),
  }

  const checkCP = async (cp) => {
    const { data } = await makeGET("getCpInfo?codigo_postal=" + cp)
    if (data.mensaje == "exito") {
      const { estado, municipio, colonias } = data?.info?.codigo_postal
      const { geocode } = data
      setColonias([...colonias])
      setForm({
        ...form,
        codigoPostal: cp,
        estado,
        ciudad: municipio
      })
      setLocation({ ...geocode?.location })
      setDefaultLocation({ ...geocode?.location })
      setZoom(16)

      map.setCenter({ ...geocode?.location });
      map.setZoom(16);
    }
  }

  function readFile(file) {
    return new Promise((resolve, reject) => {
      var fr = new FileReader()
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.onerror = reject
      fr.readAsDataURL(file)
    })
  }

  const [categoriasVal, setCategoriasVal] = useState(categorias.map(v => {
    return {
      value: v.name,
      label: v.displayName
    }
  }))

  const [showOptions, setShowOptions] = useState(false);
  const handleInputChange = useCallback((typedOption) => {
    if (typedOption.length > 2) {
      setShowOptions(true);
    }
    else {
      setShowOptions(false);
    }
  }, []);


  return (
    <>
      <div className="card m-3">
        <ToastContainer />
        <h5 className="card-header d-flex justify-content-between align-items-center">
          Formulario de recopilación de contenido
        </h5>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-12">
              <p className="font-weight-bold border-bottom">Cliente/empresa</p>
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.advertiserId?.length == 0 && form.advertiserId != undefined) && ' mb-3')}>
              <TextField
                type="number"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  setForm({ ...form, advertiserId: e.target.value })
                }}
                value={undReturn(form.advertiserId)}
                label="Advertiser ID"
                autoComplete="off"
                placeholder=""
                disabled={true}
                //required
                variant="outlined"
                //error={(form.advertiserId?.length == 0 && form.advertiserId != undefined)}
                //helperText={(form.advertiserId?.length == 0 && form.advertiserId != undefined) && "Este campo es obligatorio"}
                style={{ marginBottom: (form.advertiserId?.length == 0 && form.advertiserId != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.business_id?.length == 0 && form.business_id != undefined) && ' mb-3')}>
              <TextField
                type="number"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  setForm({ ...form, business_id: e.target.value })
                }}
                value={undReturn(form.business_id)}
                label="Business Id"
                autoComplete="off"
                placeholder=""
                disabled={true}
                //required
                variant="outlined"
                style={{ marginBottom: (form.business_id?.length == 0 && form.business_id != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.nombre?.length == 0 && form.nombre != undefined) && ' mb-3')}>
              <TextField
                type="text"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  setForm({ ...form, nombre: e.target.value })
                }}
                value={undReturn(form.nombre)}
                label="Nombre del negocio"
                autoComplete="off"
                placeholder=""
                //disabled={flag}
                required
                variant="outlined"
                error={(form.nombre?.length == 0 && form.nombre != undefined)}
                helperText={(form.nombre?.length == 0 && form.nombre != undefined) ? "Este campo es obligatorio"
                  : "Ingresa el nombre oficial de tu empresa."}
                style={{ marginBottom: (form.nombre?.length == 0 && form.nombre != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.slogan?.length == 0 && form.slogan != undefined) && ' mb-3')}>
              <TextField
                type="text"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  setForm({ ...form, slogan: e.target.value })
                }}
                value={undReturn(form.slogan)}
                label="Slogan"
                autoComplete="off"
                placeholder=""
                //disabled={flag}
                required
                variant="outlined"
                error={(form.slogan?.length == 0 && form.slogan != undefined)}
                helperText={(form.slogan?.length == 0 && form.slogan != undefined) ? "Este campo es obligatorio"
                  : "Proporciona una breve descripción o lema que represente a tu empresa."}
                style={{ marginBottom: (form.slogan?.length == 0 && form.slogan != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.nosotros?.length == 0 && form.nosotros != undefined) && ' mb-3')}>
              <TextField
                type="text"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  setForm({ ...form, nosotros: e.target.value })
                }}
                value={undReturn(form.nosotros)}
                label="Sobre Nosotros"
                autoComplete="off"
                placeholder=""
                //disabled={flag}
                required
                variant="outlined"
                error={(form.nosotros?.length == 0 && form.nosotros != undefined)}
                helperText={(form.nosotros?.length == 0 && form.nosotros != undefined) ? "Este campo es obligatorio"
                  : "Detalla las actividades y la historia de tu empresa."}
                style={{ marginBottom: (form.nosotros?.length == 0 && form.nosotros != undefined) && '5px' }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12"><p className="font-weight-bold border-bottom">Multimedia</p></div>
            <div className={"col-12 col-lg-6 mb-3"}>
              <div className="border rounded pt-3 pb-3 h-100">
                <div className="d-flex align-content-center justify-content-center">
                  <p className="m-0 p-0 mr-3">Logo</p>
                  <button className="btn btn-primary" style={{ height: "fit-content" }} onClick={() => {
                    logoFile.current.click()
                  }}>
                    {form?.logo ? form?.logo : "Elegir archivo"}
                  </button>
                  {srcLogo &&
                    <img src={srcLogo} className="ml-2"
                      height={"40px"} role="button" style={{ aspectRatio: "1/1" }} onClick={async (e) => {
                        var image = new Image()
                        image.src = srcLogo

                        var w = window.open("");
                        w.document.write(image.outerHTML)
                      }} />
                  }
                  <input type='file' id='logo' accept="image/svg+xml,image/png" ref={logoFile}
                    onChange={async (e) => {
                      let flag = false
                      if (logoFile.current.files.length == 0) {
                        flag = true
                        show("warn", "Advertencia. Debes seleccionar al menos un archivo")
                      }

                      if (logoFile.current.files.length > 1) {
                        flag = true
                        show("warn", "Advertencia. Sólo puedes subir un archivo a la vez.")
                      }

                      const file = logoFile.current?.files[0]
                      /*if (Math.floor(file?.size / 1000000) > 2) {
                        flag = true
                        show("warn", "Advertencia. El archivo no puede pesar más de 5 MB.")
                      }*/

                      let _size
                      if (!flag) {
                        if (file?.size < 1000000) {
                          _size = Math.floor(file?.size / 1000) + 'KB'
                        } else {
                          _size = Math.floor(file?.size / 1000000) + 'MB'
                        }
                        setForm({ ...form, logo: file?.name + " " + _size })
                        setSrcLogo(await readFile(logoFile.current?.files[0]))
                      }
                    }} style={{ display: 'none' }} />
                </div>
                <div className="d-flex align-content-center justify-content-center">
                  <p className="paragraph">
                    Sube el logo de tu empresa
                  </p>
                </div>
              </div>
            </div>
            <div className={"col-12 col-lg-6 mb-3"}>
              <div className="border rounded pt-3 pb-3 h-100">
                <div className="d-flex align-content-center justify-content-center">
                  <p className="m-0 p-0 mr-3">Portada</p>
                  <button className="btn btn-primary" style={{ height: "fit-content" }} onClick={() => {
                    portadaFile.current.click()
                  }}>
                    {form?.portada ? form?.portada : "Elegir archivo"}
                  </button>
                  {srcPortada &&
                    <img src={srcPortada} className="ml-2"
                      height={"40px"} role="button" style={{ aspectRatio: "1/1" }} onClick={async (e) => {
                        var image = new Image()
                        image.src = srcPortada

                        var w = window.open("");
                        w.document.write(image.outerHTML)
                      }} />
                  }
                  <input type='file' id='portada' accept="image/*" ref={portadaFile}
                    onChange={async (e) => {
                      let flag = false

                      if (portadaFile.current.files.length == 0) {
                        flag = true
                        show("warn", "Advertencia. Debes seleccionar al menos un archivo")
                      }
                      if (portadaFile.current.files.length > 1) {
                        flag = true
                        show("warn", "Advertencia. Sólo puedes subir un archivo a la vez.")
                      }

                      const file = portadaFile.current?.files[0]
                      if (Math.floor(file?.size / 1000000) > 2) {
                        flag = true
                        show("warn", "Advertencia. El archivo no puede pesar más de 5 MB.")
                      }

                      let _size
                      if (!flag) {
                        if (file?.size < 1000000) {
                          _size = Math.floor(file?.size / 1000) + 'KB'
                        } else {
                          _size = Math.floor(file?.size / 1000000) + 'MB'
                        }
                        setForm({ ...form, portada: file?.name + " " + _size })
                        setSrcPortada(await readFile(portadaFile.current?.files[0]))
                      }
                    }} style={{ display: 'none' }} />
                </div>
                <div className="d-flex align-content-center justify-content-center">
                  <p className="paragraph">
                    Sube la imagen que se mostrará en la ventana de información de tu negocio.
                  </p>
                </div>
              </div>
            </div>
            <div className={"col-12 col-lg-6" + (!(form.nosotros?.length == 0 && form.nosotros != undefined) && ' mb-3 text-center')}>
              <div className="border rounded p-3">
                <div className="d-flex justify-content-between">
                  <p className="m-0 p-0 mr-3">Galería</p>
                  <div className="mb-2">
                    {addingServicio ?
                      <>
                        <button className="btn btn-danger"
                          style={{ height: "fit-content" }} onClick={() => { setAddingServicio(false) }}>
                          <FaTimes />
                        </button>
                        <button className="btn btn-primary ml-1"
                          disabled={checkImgServicios()}
                          style={{ height: "fit-content" }} onClick={async () => {
                            const files = [...Array.from(servicioFile.current?.files)]
                            const srcs = (await Promise.all(files.map(async v => {
                              return await readFile(v)
                            })))
                            setForm({
                              ...form, imgServicios: [
                                ...form.imgServicios,
                                {
                                  seccion: tmpService.seccion,
                                  archivos: files,
                                  srcs
                                }
                              ]
                            })
                            setAddingServicio(false)
                            setTmpService({
                              seccion: undefined,
                              archivos: undefined
                            })
                            servicioFile.current.value = ""
                          }}>
                          <FaSave />
                        </button>
                      </>
                      :
                      <button className="btn btn-success" disabled={form?.imgServicios.length >= 5}
                        style={{ height: "fit-content" }} onClick={() => { setAddingServicio(true) }}>
                        <FaPlus />
                      </button>
                    }
                  </div>
                </div>
                {addingServicio &&
                  <div className="row mt-3">
                    <div className="col-12 col-lg-8">
                      <TextField
                        type="text"
                        fullWidth
                        size="small"
                        sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                        onChange={(e) => {
                          setTmpService({ ...tmpService, seccion: e.target.value })
                        }}
                        value={undReturn(tmpService.seccion)}
                        label="Sección"
                        autoComplete="off"
                        placeholder=""
                        //disabled={flag}
                        required
                        variant="outlined"
                        error={(tmpService.seccion?.length == 0 && tmpService.seccion != undefined)}
                        helperText={(tmpService.seccion?.length == 0 && tmpService.seccion != undefined) ? "Este campo es obligatorio"
                          : "Nombra la sección de estas imágenes. Sube máximo 5 archivos por sección."}
                        style={{ marginBottom: (tmpService.seccion?.length == 0 && tmpService.seccion != undefined) && '5px', zIndex: 0 }}
                      />
                    </div>
                    <div className="col-12 col-lg-4 text-center">
                      <button className="btn btn-primary" style={{ height: "fit-content" }} onClick={() => {
                        servicioFile.current.click()
                      }}>
                        {tmpService?.archivos ? tmpService?.archivos : "Elegir archivos"}
                      </button>
                      <input type='file' id='logo' accept="image/*" multiple ref={servicioFile}
                        onChange={(e) => {
                          let flag = false

                          if (servicioFile.current.files.length == 0) {
                            flag = true
                            show("warn", "Advertencia. Debes seleccionar al menos un archivo")
                          }
                          if (servicioFile.current.files.length > 5) {
                            flag = true
                            show("warn", "Advertencia. Sólo puedes subir 5 archivos a la vez.")
                          }

                          const file = servicioFile.current?.files[0]
                          let totalSize = 0
                          Array.from(servicioFile.current?.files).map(v => {
                            totalSize = totalSize + v.size
                          })

                          if (Math.floor(totalSize / 1000000) > 25) {
                            flag = true
                            show("warn", "Advertencia. En total, los archivos no pueden pesar más de 25MB")
                          }

                          let _size
                          if (!flag) {
                            if (totalSize < 1000000) {
                              _size = Math.floor(totalSize / 1000) + 'KB en total'
                            } else {
                              _size = Math.floor(totalSize / 1000000) + 'MB en total'
                            }
                            setTmpService({ ...tmpService, archivos: "Varios arch. " + _size })
                          }
                        }} style={{ display: 'none' }} />
                    </div>
                  </div>
                }
                {form?.imgServicios.map((v, i) => (
                  <div className="card">
                    <div className="card-body d-flex justify-content-between p-2">
                      <p>
                        <span className="font-weight-bold">{v?.seccion}</span> ({v?.archivos?.length} archivos)
                      </p>
                      {v?.srcs?.map(v => (
                        <img src={v} height={"40px"} role="button" style={{ aspectRatio: "1/1" }} onClick={(e) => {
                          var image = new Image()
                          image.src = v

                          var w = window.open("");
                          w.document.write(image.outerHTML)
                        }} />
                      ))}
                      <button className="btn btn-danger" onClick={() => {
                        removeImg(i)
                      }}>
                        <FaTimes />
                      </button>
                    </div>
                  </div>
                ))
                }
              </div>

            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12"><p className="font-weight-bold border-bottom">Ubicación</p></div>

            <div className={"col-12 col-lg-6 text-center" + (!(form.codigoPostal?.length == 0 && form.codigoPostal != undefined) && ' mb-3')}>
              <TextField
                type="number"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "").substring(0, 5)
                  setForm({ ...form, codigoPostal: value })
                  if (e.target.value.length == 5) {
                    checkCP(e.target.value)
                  }
                }}
                value={undReturn(form.codigoPostal)}
                label="Código postal"
                autoComplete="off"
                inputProps={{ maxLength: 5 }}
                placeholder=""
                //disabled={flag}
                onInput={(e) => {
                  //e.target.value = (parseInt(e.target.value)).toString().slice(0, 5)
                }}
                required
                variant="outlined"
                error={(form.codigoPostal?.length < 5 && form.codigoPostal != undefined)}
                helperText={(form.codigoPostal?.length == 0 && form.codigoPostal != undefined) ? "Este campo es obligatorio" :
                  ("Especifica el código postal en donde está ubicada la empresa. Los campos de ciudad, estado y colonia se llenarán usando este valor.")
                }
                style={{ marginBottom: (form.codigoPostal?.length == 0 && form.codigoPostal != undefined) && '5px', zIndex: 0 }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.estado?.length == 0 && form.estado != undefined) && ' mb-3')}>
              <TextField
                type="text"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  //const value = e.target.value.replace(/\D/g, "").substring(0, 5)
                  setForm({ ...form, estado: e.target.value })
                  if (e.target.value.length == 5) {
                    checkCP(e.target.value)
                  }
                }}
                value={undReturn(form.estado)}
                label="Estado"
                autoComplete="off"
                placeholder=""
                disabled={true}
                required
                variant="outlined"
                //error={(form.estado?.length == 0 && form.estado != undefined)}
                //helperText={(form.estado?.length == 0 && form.estado != undefined) && "Este campo es obligatorio"}
                style={{ zIndex: 0 }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.ciudad?.length == 0 && form.ciudad != undefined) && ' mb-3')}>
              <TextField
                type="text"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  //const value = e.target.value.replace(/\D/g, "").substring(0, 5)
                  if (e.target.value.length == 5) {
                    checkCP(e.target.value)
                  }
                  setForm({ ...form, ciudad: e.target.value })
                }}
                value={undReturn(form.ciudad)}
                label="Ciudad"
                autoComplete="off"
                placeholder=""
                disabled={true}
                required
                variant="outlined"
                //error={(form.estado?.length == 0 && form.estado != undefined)}
                //helperText={(form.estado?.length == 0 && form.estado != undefined) && "Este campo es obligatorio"}
                style={{ zIndex: 0 }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.colonia?.length == 0 && form.colonia != undefined) && ' mb-3')}>
              <TextField
                id="outlined-select-currency"
                select
                label="Colonia"
                autoComplete="off"
                placeholder=""
                fullWidth
                required
                sx={{ boxShadow: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                value={undReturn(form.colonia)}
                onChange={(e) => {
                  setForm({ ...form, colonia: e.target.value })
                }}
                error={(form.colonia?.length == 0 && form.colonia != undefined)}
                helperText={(form.colonia?.length == 0 && form.colonia != undefined) && "La colonia es obligatoria"}
                size="small"
                variant="outlined"
                style={{ marginBottom: (form.colonia?.length == 0 && form.colonia != undefined) && '5px', zIndex: 0 }}
              >
                {colonias.map((r) => (
                  <MenuItem key={r} value={r}>
                    {r}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.calle?.length == 0 && form.calle != undefined) && ' mb-3')}>
              <TextField
                type="text"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  setForm({ ...form, calle: e.target.value })
                }}
                value={undReturn(form.calle)}
                label="Calle"
                autoComplete="off"
                placeholder=""
                required
                variant="outlined"
                error={(form.calle?.length == 0 && form.calle != undefined)}
                helperText={(form.calle?.length == 0 && form.calle != undefined) && "Este campo es obligatorio"}
                style={{ marginBottom: (form.calle?.length == 0 && form.calle != undefined) && '5px', zIndex: 0 }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.numero?.length == 0 && form.numero != undefined) && ' mb-3')}>
              <TextField
                type="text"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  setForm({ ...form, numero: e.target.value })
                }}
                value={undReturn(form.numero)}
                label="Número"
                autoComplete="off"
                placeholder=""
                required
                variant="outlined"
                error={(form.numero?.length == 0 && form.numero != undefined)}
                helperText={(form.numero?.length == 0 && form.numero != undefined) && "Este campo es obligatorio"}
                style={{ marginBottom: (form.numero?.length == 0 && form.numero != undefined) && '5px', zIndex: 0 }}
              />
            </div>
            <div className="col-12 mb-3" style={{ height: "400px" }}>
              <Map defaultCenter={DefaultLocation} defaultZoom={zoom}
                onClick={(e) => {
                  setDefaultLocation(e.detail.latLng)
                }}
                mapId='mapPicker'>
                <AdvancedMarker position={defaultLocation?.lat ? defaultLocation : DefaultLocation} />
              </Map>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 mb-3">
              <p className="m-0 p-0 font-weight-bold border-bottom">
                Horarios
              </p>
              <p className="paragraph" style={{ margin: "0" }}>
                Especifica los horarios de apertura y cierre de tu empresa para cada día de la semana.
              </p>
              {dias.map((v, i) => (
                <div className="row mt-3" key={i}>
                  <div className={`col-6 col-md-3 d-flex`}>
                    <p style={{ textTransform: "capitalize" }}>{v.dia}</p>
                  </div>
                  <div className="col-6 col-md-2 d-flex">
                    <Switch checked={v.active} onChange={(e) => {
                      let tmp = [...dias]
                      tmp[i].active = e.target.checked
                      setDias([...tmp])
                    }} value={v.active} />
                  </div>
                  {dias[i]?.active ?
                    <>
                      <div className="col-12 col-md-7">
                        <div className="d-flex justify-content-between align-items-baseline">
                          <TextField
                            select
                            label="Abierto"
                            autoComplete="off"
                            placeholder=""
                            fullWidth
                            required
                            sx={{ boxShadow: { borderRadius: '2px' } }}
                            value={undReturn(dias[i].hrs[0].open)}
                            onChange={(e) => {
                              let tmp = [...dias]
                              tmp[i].hrs[0].open = e.target.value
                              setDias([...tmp])
                            }}
                            error={(dias[i].hrs[0].open?.length == 0 && dias[i].hrs[0].open != undefined)}
                            helperText={(dias[i].hrs[0].open?.length == 0 && dias[i].hrs[0].open != undefined) && "Este campo es obligatorio"}
                            size="small"
                            variant="outlined"
                            style={{
                              marginBottom: (dias[i].hrs[0].open?.length == 0 && dias[i].hrs[0].open != undefined) && '5px',
                              backgroundColor: "whitesmoke", zIndex: 0
                            }}
                          >
                            {horarios.filter(l => (parseInt(l.replace(/\D/g, "")) < parseInt(dias[i].hrs[0].close.replace(/\D/g, "")))
                              || !dias[i].hrs[0].close)
                              .map((r) => (
                                <MenuItem key={r} value={r}>
                                  {r}
                                </MenuItem>
                              ))}
                          </TextField>
                          <p className="m-3">a</p>
                          <TextField
                            select
                            label="Cerrado"
                            autoComplete="off"
                            placeholder=""
                            fullWidth
                            required
                            sx={{ boxShadow: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
                            value={undReturn(dias[i].hrs[0].close)}
                            onChange={(e) => {
                              let tmp = [...dias]
                              tmp[i].hrs[0].close = e.target.value
                              setDias([...tmp])
                            }}
                            error={(dias[i].hrs[0].close?.length == 0 && dias[i].hrs[0].close != undefined)}
                            helperText={(dias[i].hrs[0].close?.length == 0 && dias[i].hrs[0].close != undefined) && "Este campo es obligatorio"}
                            size="small"
                            variant="outlined"
                            style={{ marginBottom: (dias[i].hrs[0].close?.length == 0 && dias[i].hrs[0].close != undefined) && '5px' }}
                          >
                            {horarios.filter(l => (parseInt(l.replace(/\D/g, "")) > parseInt(dias[i].hrs[0].open.replace(/\D/g, "")))
                              || !dias[i].hrs[0].open).map((r) => (
                                <MenuItem key={r} value={r}>
                                  {r}
                                </MenuItem>
                              ))}
                          </TextField>
                          <button className="btn btn-success ml-1" onClick={() => {
                            let tmp = [...dias]
                            tmp[i].hrs = [...tmp[i].hrs, {
                              open: "",
                              close: "",
                            }]
                            setDias([...tmp])
                          }}>
                            <FaPlus />
                          </button>
                        </div>
                        {v?.hrs.slice(1).map((l, il) => (
                          <div className="d-flex justify-content-between align-items-baseline">
                            <TextField
                              select
                              label="Abierto"
                              autoComplete="off"
                              placeholder=""
                              fullWidth
                              required
                              sx={{ boxShadow: { borderRadius: '2px' } }}
                              value={undReturn(dias[i].hrs[il + 1].open)}
                              onChange={(e) => {
                                let tmp = [...dias]
                                tmp[i].hrs[il + 1].open = e.target.value
                                setDias([...tmp])
                              }}
                              error={(dias[i].hrs[il + 1].open?.length == 0 && dias[i].hrs[il + 1].open != undefined)}
                              helperText={(dias[i].hrs[il + 1].open?.length == 0 && dias[i].hrs[il + 1].open != undefined)
                                && "Este campo es obligatorio"}
                              size="small"
                              variant="outlined"
                              style={{
                                marginBottom: (dias[i].hrs[il + 1].open?.length == 0 && dias[i].hrs[il + 1].open != undefined) && '5px',
                                backgroundColor: "whitesmoke", zIndex: 0
                              }}
                            >
                              {horarios.filter(l => (parseInt(l.replace(/\D/g, "")) > parseInt(dias[i].hrs[0].close.replace(/\D/g, ""))))
                                .map((r) => (
                                  <MenuItem key={r} value={r}>
                                    {r}
                                  </MenuItem>
                                ))}
                            </TextField>
                            <p className="m-3">a</p>
                            <TextField
                              select
                              label="Cerrado"
                              autoComplete="off"
                              placeholder=""
                              fullWidth
                              required
                              sx={{ boxShadow: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
                              value={undReturn(dias[i].hrs[il + 1].close)}
                              onChange={(e) => {
                                let tmp = [...dias]
                                tmp[i].hrs[il + 1].close = e.target.value
                                setDias([...tmp])
                              }}
                              error={(dias[i].hrs[il + 1].close?.length == 0 && dias[i].hrs[il + 1].close != undefined)}
                              helperText={(dias[i].hrs[il + 1].close?.length == 0 && dias[i].hrs[il + 1].close != undefined) && "Este campo es obligatorio"}
                              size="small"
                              variant="outlined"
                              style={{ marginBottom: (dias[i].hrs[il + 1].close?.length == 0 && dias[i].hrs[il + 1].close != undefined) && '5px' }}
                            >
                              {horarios.filter(l => (parseInt(l.replace(/\D/g, "")) > parseInt(dias[i].hrs[0].close.replace(/\D/g, "")))).map((r) => (
                                <MenuItem key={r} value={r}>
                                  {r}
                                </MenuItem>
                              ))}
                            </TextField>
                            <button className="btn btn-danger ml-1" onClick={() => {
                              let tmp = [...dias]
                              tmp[i].hrs.splice(il + 1, 1)
                              setDias([...tmp])
                            }}>
                              <FaTimes />
                            </button>
                          </div>
                        ))}
                      </div>

                    </> :
                    <div className="col-12 col-md-7 d-flex justify-content-between align-items-baseline"></div>
                  }
                </div>
              ))}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12"><p className="font-weight-bold border-bottom">Qué ofrece</p></div>
            <div className={"col-12 col-lg-6" + (!(form.codigoPostal?.length == 0 && form.codigoPostal != undefined) && ' mb-3')}>
              <Select
                options={(showOptions) ? categoriasVal : []}
                autoFocus={false}
                className=""
                id="categorias"
                style={{ width: "100%", paddingTop: "1rem!important", maxHeight: "15px", zIndex: "1000" }}
                isSearchable={true}
                placeholder={"Categoria"}
                defaultValue={undefined}
                value={form.categoria}
                styles={customStyles}
                onInputChange={handleInputChange}
                onChange={async (e) => {
                  setForm({ ...form, categoria: e })
                }}
                noOptionsMessage={({ inputValue }) => "Escribe 2 o más letras para filtrar"}
                filterOption={createFilter({ ignoreAccents: false })}
              />
              <p className="paragraph">
                Seleciona la categoría/giro del negocio
              </p>
            </div>
            <div className={"col-12 col-lg-6"}>
              <div className="d-flex w-100">
                <TextField
                  type="text"
                  fullWidth
                  size="small"
                  sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, width: "90%", zIndex: 0 }}
                  onChange={(e) => {
                    setTmpSub(e.target.value)
                  }}
                  value={undReturn(tmpSub)}
                  label="Tags"
                  autoComplete="off"
                  placeholder=""
                  disabled={false}
                  required
                  variant="outlined"
                />
                <button className="btn btn-success ml-1"
                  disabled={form?.tags?.length >= 5 || tmpSub?.length == 0 || !tmpSub}
                  style={{ height: "fit-content" }} onClick={async () => {
                    setTmpSub(undefined)
                    const arr = tmpSub.split(",").map(v => v.trim()).slice(0, 5)
                    setForm({ ...form, tags: [...form.tags, ...arr].slice(0, 5) })
                  }}>
                  <FaSave />
                </button>
              </div>
              <p className="paragraph">
                Aspectos que diferencien a tu negocio. Máximo 5 elementos. Puedes agregarlos uno por uno, o todos separados por coma.
              </p>
              <div className="row mb-2 text-center">
                {form.tags.map((v, i) => (
                  <div className="col-4 mb-3">
                    <button className="btn btn-primary" onClick={() => {
                      let tmp = [...form.tags]
                      tmp.splice(i, 1)
                      setForm({ ...form, tags: [...tmp] })
                    }}>
                      {v} <FaTimes />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className={"col-12 col-lg-6"}>
              <div className="d-flex w-100">
                <TextField
                  type="text"
                  fullWidth
                  size="small"
                  sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, width: "90%", zIndex: 0 }}
                  onChange={(e) => {
                    setTmpProd(e.target.value)
                  }}
                  value={undReturn(tmpProd)}
                  label="Productos o servicios"
                  autoComplete="off"
                  placeholder=""
                  disabled={false}
                  required
                  variant="outlined"
                />
                <button className="btn btn-success ml-1"
                  disabled={form?.productos?.length >= 10 || tmpProd?.length == 0 || !tmpProd}
                  style={{ height: "fit-content" }} onClick={async () => {
                    const arr = tmpProd.split(",").map(v => v.trim()).slice(0, 10)
                    setTmpProd(undefined)
                    setForm({ ...form, productos: [...form.productos, ...arr].slice(0, 10) })
                  }}>
                  <FaSave />
                </button>
              </div>
              <p className="paragraph">
                Productos o servicios estrella de tu negocio. Puedes subir máximo 10. Puedes agregarlos uno por uno, o todos separados por coma.
              </p>
              <div className="row mb-2 text-center">
                {form.productos?.map((v, i) => (
                  <div className="col-4 mb-3">
                    <button className="btn btn-primary" onClick={() => {
                      let tmp = [...form.productos]
                      tmp.splice(i, 1)
                      setForm({ ...form, productos: [...tmp] })
                    }}>
                      {v} <FaTimes />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {String(form?.categoria?.label).toLowerCase().includes("hotel") &&
              <div className={"col-12 col-lg-6"}>
                <TextField
                  type="text"
                  fullWidth
                  size="small"
                  sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
                  onChange={(e) => {
                    setForm({ ...form, urlReservacion: e.target.value })
                  }}
                  value={undReturn(form.urlReservacion)}
                  label="URL de Reservación"
                  autoComplete="off"
                  placeholder=""
                  //disabled={flag}
                  required
                  variant="outlined"
                  error={(form.urlReservacion?.length == 0 && form.urlReservacion != undefined)}
                  helperText={(form.urlReservacion?.length == 0 && form.urlReservacion != undefined) ? "Este campo es obligatorio" :
                    "URL del sitio de reservación preferido."
                  }
                  style={{ marginBottom: (form.urlReservacion?.length == 0 && form.urlReservacion != undefined) && '5px', zIndex: 0 }}
                />
              </div>
            }
            {String(form?.categoria?.label).toLowerCase().includes("restaurant") &&
              <div className={"col-12 col-lg-6"}>
                <div className="d-flex align-content-center justify-content-center">
                  <p className="m-0 p-0 mr-3">Menú</p>
                  <button className="btn btn-primary" style={{ height: "fit-content" }} onClick={() => {
                    menuFile.current.click()
                  }}>
                    {form?.menu ? form?.menu : "Elegir archivo"}
                  </button>
                  {srcMenu &&
                    <img src={srcMenu} className="ml-2"
                      height={"40px"} role="button" style={{ aspectRatio: "1/1" }} onClick={async (e) => {
                        var image = new Image()
                        image.src = srcMenu

                        var w = window.open("");
                        w.document.write(image.outerHTML)
                      }} />
                  }
                  <input type='file' id='menu' accept="image/*" ref={menuFile}
                    onChange={async (e) => {
                      let flag = false

                      if (menuFile.current.files.length == 0) {
                        flag = true
                        show("warn", "Advertencia. Debes seleccionar al menos un archivo")
                      }
                      if (menuFile.current.files.length > 1) {
                        flag = true
                        show("warn", "Advertencia. Sólo puedes subir un archivo a la vez.")
                      }

                      const file = menuFile.current?.files[0]
                      if (Math.floor(file?.size / 1000000) > 2) {
                        flag = true
                        show("warn", "Advertencia. El archivo no puede pesar más de 5 MB.")
                      }

                      let _size
                      if (!flag) {
                        if (file?.size < 1000000) {
                          _size = Math.floor(file?.size / 1000) + 'KB'
                        } else {
                          _size = Math.floor(file?.size / 1000000) + 'MB'
                        }
                        setForm({ ...form, menu: file?.name + " " + _size })
                        setSrcMenu(await readFile(menuFile.current?.files[0]))
                      }
                    }} style={{ display: 'none' }} />
                </div>
                <div className="d-flex align-content-center justify-content-center">
                  <p className="paragraph">
                    Sube una foto del menú con lo que ofrece tu negocio.
                  </p>
                </div>
              </div>
            }
            <div className={"col-12 col-lg-6"}>
              <Select
                options={tiposPago}
                autoFocus={false}
                className=""
                id="tipos_pago"
                isMulti={true}
                value={form.tiposPago.map(v => {
                  return {
                    label: v, value: v
                  }
                })}
                style={{ width: "100%", paddingTop: "1rem!important", maxHeight: "15px", zIndex: "1000" }}
                isSearchable={true}
                placeholder={"Tipos de pago"}
                defaultValue={undefined}
                onChange={async (e) => {
                  setForm({ ...form, tiposPago: [...e.map(v => v.value)] })
                }}
              />
              <p className="paragraph">
                Seleciona los tipos de pago que aceptan en tu negocio
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12"><p className="font-weight-bold border-bottom">Contacto</p></div>
            <div className={"col-12 col-lg-6 text-center mb-3"
              //+ (!(!checkPhone(form.phone2) && form.phone2 != undefined) && ' mb-3')
            }>
              <InputMask
                mask="999 999 9999"
                value={undReturn(form.telefono)}
                disabled={false}
                maskChar=" "
                onChange={(e) => {
                  setForm({ ...form, telefono: (e.target.value) })
                }}
              >
                {() => <TextField
                  type="text"
                  autoComplete="off"
                  placeholder="444 444 4444"
                  sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
                  fullWidth
                  error={(!checkPhone(form.telefono) && form.telefono != undefined)}
                  helperText={(!checkPhone(form.telefono) && form.telefono != undefined) ? "Debe tener 10 números"
                    : "Proporciona el número de teléfono principal de contacto de tu empresa."}
                  size="small"
                  variant="outlined"
                  label="Teléfono principal"
                //style={{ marginBottom: (!checkPhone(form.phone2) && form.phone2 != undefined) && '5px' }} 
                />}
              </InputMask>
            </div>
            <div className={"col-12 col-lg-6 text-center mb-3"
              //+ (!(!checkPhone(form.phone2) && form.phone2 != undefined) && ' mb-3')
            }>
              <InputMask
                mask="999 999 9999"
                value={undReturn(form.whatsapp)}
                disabled={false}
                maskChar=" "
                onChange={(e) => {
                  setForm({ ...form, whatsapp: (e.target.value) })
                }}
              >
                {() => <TextField
                  type="text"
                  autoComplete="off"
                  placeholder="444 444 4444"
                  sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
                  fullWidth
                  error={(!checkPhone(form.whatsapp) && form.whatsapp != undefined)}
                  helperText={(!checkPhone(form.whatsapp) && form.whatsapp != undefined) ? "Debe tener 10 números"
                    : "Proporciona el número de whatsapp de contacto de tu empresa. Puede ser el mismo que tu teléfono principal."}
                  size="small"
                  variant="outlined"
                  label="Whatsapp"
                //style={{ marginBottom: (!checkPhone(form.phone2) && form.phone2 != undefined) && '5px' }} 
                />}
              </InputMask>
            </div>
            <div className={"col-12 col-lg-6" + (!(tmpSub?.length == 0 && tmpSub != undefined) && ' mb-3')}>
              <div className="mb-2 d-flex w-100">
                <InputMask
                  mask="999 999 9999"
                  value={undReturn(tmpAdTels)}
                  disabled={false}
                  maskChar=" "
                  onChange={(e) => {
                    setTmpAdTels(e.target.value)
                  }}
                >
                  {() => <TextField
                    type="text"
                    autoComplete="off"
                    placeholder="555 555 5555"
                    sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
                    fullWidth
                    error={(!checkPhone(tmpAdTels) && tmpAdTels != undefined)}
                    helperText={(!checkPhone(tmpAdTels) && tmpAdTels != undefined) ? "Debe tener 10 números"
                      : "Proporciona hasta 5 números adicionales."}
                    size="small"
                    variant="outlined"
                    label="Teléfono adicional"
                  //style={{ marginBottom: (!checkPhone(form.phone2) && form.phone2 != undefined) && '5px' }} 
                  />}
                </InputMask>
                <button className="btn btn-success ml-1"
                  disabled={form?.telefonosAdicionales.length >= 5 || (tmpAdTels?.replace(/\D/g, "")?.length != 10)}
                  style={{ height: "fit-content" }} onClick={async () => {
                    setTmpAdTels(undefined)
                    setForm({ ...form, telefonosAdicionales: [...form.telefonosAdicionales, tmpAdTels] })
                  }}>
                  <FaSave />
                </button>
              </div>
              <div className="row mb-3">
                {form.telefonosAdicionales.map((v, i) => (
                  <div className="col-6 text-center mb-2">
                    <button className="btn btn-primary" onClick={() => {
                      let tmp = [...form.telefonosAdicionales]
                      tmp.splice(i, 1)
                      setForm({ ...form, telefonosAdicionales: [...tmp] })
                    }}>
                      {v} <FaTimes />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(!validateEmail(form.correo) && form.correo != undefined) && ' mb-3')}>
              <TextField
                type="email"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, correo: e.target.value })
                }}
                value={undReturn(form.correo)}
                autoComplete="off"
                placeholder={""}
                required
                error={(!validateEmail(form.correo) && form.correo != undefined)}
                helperText={(!validateEmail(form.correo) && form.correo != undefined) ? "Formato de email no válido" :
                  "Proporciona una dirección de correo electrónico para que tus clientes contacten a tu empresa."
                }
                size="small"
                variant="outlined"
                label="Email"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
              //style={{ marginBottom: (!validateEmail(form.email) && form.email != undefined) && '5px' }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12"><p className="font-weight-bold border-bottom">Redes sociales</p></div>
            <div className={"col-12 col-lg-6 text-center mb-3"}>
              <TextField
                type="text"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, sitioWeb: e.target.value })
                }}
                value={undReturn(form.sitioWeb)}
                autoComplete="off"
                placeholder={""}
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
                size="small"
                helperText={"Ingresa la URL del sitio web oficial de tu empresa."}
                variant="outlined"
                label="Sitio"
                style={{ marginBottom: (form.sitioWeb?.length == 0 && form.sitioWeb != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center mb-3"}>
              <TextField
                type="text"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, twitter: e.target.value })
                }}
                value={undReturn(form.twitter)}
                autoComplete="off"
                placeholder={""}
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
                size="small"
                helperText={"Proporciona la URL de Twitter de tu empresa."}
                variant="outlined"
                label="Twitter"
                style={{ marginBottom: (form.twitter?.length == 0 && form.twitter != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center mb-3"}>
              <TextField
                type="text"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, facebook: e.target.value })
                }}
                value={undReturn(form.facebook)}
                autoComplete="off"
                placeholder={""}
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
                size="small"
                helperText={"Proporciona la URL de Facebook de tu empresa"}
                variant="outlined"
                label="Facebook"
                style={{ marginBottom: (form.facebook?.length == 0 && form.facebook != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center mb-3"}>
              <TextField
                type="text"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, youtube: e.target.value })
                }}
                value={undReturn(form.youtube)}
                autoComplete="off"
                placeholder={""}
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' }, zIndex: 0 }}
                size="small"
                helperText={"Proporciona la URL de Youtube de tu empresa"}
                variant="outlined"
                label="Youtube"
                style={{ marginBottom: (form.youtube?.length == 0 && form.youtube != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center mb-3"}>
              <TextField
                type="text"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, instagram: e.target.value })
                }}
                value={undReturn(form.instagram)}
                autoComplete="off"
                placeholder={""}
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                size="small"
                helperText={"Proporciona la URL de Instagram de tu empresa"}
                variant="outlined"
                label="Instagram"
                style={{ marginBottom: (form.instagram?.length == 0 && form.instagram != undefined) && '5px' }}
              />
            </div>
          </div>
          <div className="col-12 text-center mt-2">
            <button
              type="button"
              className={"btn btn-large " + (flag ? "btn-success" : "btn-primary")}
              onClick={() => {
                submit()
              }}
              disabled={validateForm() || loading}
              style={{
                paddingLeft: "2.5rem",
                paddingRight: "2.5rem",
                fontSize: "18px",
              }}
            >
              {loading ? "Cargando..." : (flag ? "Modificar" : "Añadir")}
            </button>
          </div>
        </div>
      </div >
    </>
  );
};
