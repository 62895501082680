import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect, HashRouter
} from 'react-router-dom';

import { useDispatch } from 'react-redux';

//import { firebase } from '../firebase/firebase-config'
import { AuthRouter } from './AuthRouter';
import { PrivateRoute } from './PrivateRoute';
import { token, login } from '../actions/auth';
import { PublicRoute } from './PublicRoute';
import { PrincipalScreen } from '../components/main/PrincipalScreen';
import { InicioScreen } from '../components/main/InicioScreen';

export const AppRouter = () => {

    const dispatch = useDispatch();

    const [checking, setChecking] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [networkStatus, setNetworkStatus] = useState(window.navigator.onLine)
    //const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userProps")))
    const [userData, setUserData] = useState({})

    useEffect(() => {
        token.subscribe(x => {
            if (x) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
            setChecking(false);
        });
    }, [dispatch, setChecking, setIsLoggedIn])

    /*useEffect(() => {
        console.log(JSON.parse(localStorage.getItem("userProps")))
        console.log(userData)
    }, [isLoggedIn])*/

    /*if (checking) {
        return (
            <h1>Espere...</h1>
        )
    }*/


    return (
        <Router>
            <div>
                <Switch>
                    {/*<PublicRoute
                        path="/auth"
                        component={AuthRouter}
                        isAuthenticated={isLoggedIn}
                        initPage="/inicio"
                    />

                    <PrivateRoute
                        exact
                        isAuthenticated={isLoggedIn}
                        path="/:actualPage"
                        component={PrincipalScreen}
                    />

                    <Redirect to="/auth/login" />*/}

                    <PrivateRoute
                        isAuthenticated={true}
                        path="/"
                        component={InicioScreen}
                    />

                </Switch>
            </div>
        </Router>
    )
}