export const categorias = [
    {
        "displayName": "Centro de servicio de Aadhaar",
        "name": "categories/gcid:aadhar_center"
    },
    {
        "displayName": "Concesionario Abarth",
        "name": "categories/gcid:abarth_dealer"
    },
    {
        "displayName": "Abadía",
        "name": "categories/gcid:abbey"
    },
    {
        "displayName": "Organización de isleños del estrecho de Torres y aborígenes",
        "name": "categories/gcid:aboriginal_and_torres_strait_islander_organisation"
    },
    {
        "displayName": "Galería de arte aborigen",
        "name": "categories/gcid:aboriginal_art_gallery"
    },
    {
        "displayName": "Clínica de abortos",
        "name": "categories/gcid:abortion_clinic"
    },
    {
        "displayName": "Proveedor de abrasivos",
        "name": "categories/gcid:abrasives_supplier"
    },
    {
        "displayName": "Iglesia de Dios Vida Abundante",
        "name": "categories/gcid:abundant_life_church"
    },
    {
        "displayName": "Tienda de açaí",
        "name": "categories/gcid:acai_shop"
    },
    {
        "displayName": "Restaurante de acarajé",
        "name": "categories/gcid:acaraje_restaurant"
    },
    {
        "displayName": "Contador",
        "name": "categories/gcid:accountant"
    },
    {
        "displayName": "Estudio contable",
        "name": "categories/gcid:accounting_firm"
    },
    {
        "displayName": "Escuela de Contabilidad",
        "name": "categories/gcid:accounting_school"
    },
    {
        "displayName": "Accounting software company",
        "name": "categories/gcid:accounting_software_company"
    },
    {
        "displayName": "Asesor acústico",
        "name": "categories/gcid:acoustical_consultant"
    },
    {
        "displayName": "Piscina de buceo acrobático",
        "name": "categories/gcid:acrobatic_diving_pool"
    },
    {
        "displayName": "Tienda de productos acrílicos",
        "name": "categories/gcid:acrylic_store"
    },
    {
        "displayName": "Clínica de acupuntura",
        "name": "categories/gcid:acupuncture_clinic"
    },
    {
        "displayName": "Escuela de acupuntura",
        "name": "categories/gcid:acupuncture_school"
    },
    {
        "displayName": "Acupunturista",
        "name": "categories/gcid:acupuncturist"
    },
    {
        "displayName": "Concesionario Acura",
        "name": "categories/gcid:acura_dealer"
    },
    {
        "displayName": "Centro de tratamiento de adicciones",
        "name": "categories/gcid:addiction_treatment_center"
    },
    {
        "displayName": "Abogado administrativo",
        "name": "categories/gcid:administrative_attorney"
    },
    {
        "displayName": "Agencia de adopción",
        "name": "categories/gcid:adoption_agency"
    },
    {
        "displayName": "Centro de día para adultos mayores",
        "name": "categories/gcid:adult_day_care_center"
    },
    {
        "displayName": "Tienda de DVD para adultos",
        "name": "categories/gcid:adult_dvd_store"
    },
    {
        "displayName": "Escuela para adultos",
        "name": "categories/gcid:adult_education_school"
    },
    {
        "displayName": "Club de entretenimiento para adultos",
        "name": "categories/gcid:adult_entertainment_club"
    },
    {
        "displayName": "Tienda de entretenimiento para adultos",
        "name": "categories/gcid:adult_entertainment_store"
    },
    {
        "displayName": "Servicios de cuidado para adultos",
        "name": "categories/gcid:adult_foster_care_service"
    },
    {
        "displayName": "Deportes de aventura",
        "name": "categories/gcid:adventure_sports"
    },
    {
        "displayName": "Centro de deportes de aventura",
        "name": "categories/gcid:adventure_sports_center"
    },
    {
        "displayName": "Agencia de publicidad",
        "name": "categories/gcid:advertising_agency"
    },
    {
        "displayName": "Fotógrafo comercial",
        "name": "categories/gcid:advertising_photographer"
    },
    {
        "displayName": "Distribuidor de bebidas gasificadas",
        "name": "categories/gcid:aerated_drinks_supplier"
    },
    {
        "displayName": "Servicio de antenas",
        "name": "categories/gcid:aerial_installation_service"
    },
    {
        "displayName": "Fotógrafo aéreo",
        "name": "categories/gcid:aerial_photographer"
    },
    {
        "displayName": "Centro de deportes aéreos",
        "name": "categories/gcid:aerial_sports_center"
    },
    {
        "displayName": "Clase de aerodanza",
        "name": "categories/gcid:aero_dance_class"
    },
    {
        "displayName": "Profesora de aeróbic",
        "name": "categories/gcid:aerobics_instructor"
    },
    {
        "displayName": "Aeroclub",
        "name": "categories/gcid:aeroclub"
    },
    {
        "displayName": "Tienda de aeromodelos",
        "name": "categories/gcid:aeromodel_shop"
    },
    {
        "displayName": "Ingeniero aeronáutico",
        "name": "categories/gcid:aeronautical_engineer"
    },
    {
        "displayName": "Compañía aeroespacial",
        "name": "categories/gcid:aerospace_company"
    },
    {
        "displayName": "Restaurante de cocina afgana",
        "name": "categories/gcid:afghani_restaurant"
    },
    {
        "displayName": "Tienda de productos africanos",
        "name": "categories/gcid:african_goods_store"
    },
    {
        "displayName": "Restaurante de cocina africana",
        "name": "categories/gcid:african_restaurant"
    },
    {
        "displayName": "Programa de actividades extracurriculares",
        "name": "categories/gcid:after_school_program"
    },
    {
        "displayName": "Agencia tributaria",
        "name": "categories/gcid:agenzia_entrate"
    },
    {
        "displayName": "Proveedor de agregados",
        "name": "categories/gcid:aggregate_supplier"
    },
    {
        "displayName": "Servicio de pasturaje",
        "name": "categories/gcid:agistment_service"
    },
    {
        "displayName": "Asociación agrícola",
        "name": "categories/gcid:agricultural_association"
    },
    {
        "displayName": "Cooperativa agrícola",
        "name": "categories/gcid:agricultural_cooperative"
    },
    {
        "displayName": "Servicio de ingeniería agronómica",
        "name": "categories/gcid:agricultural_engineer"
    },
    {
        "displayName": "Escuela secundaria agropecuaria",
        "name": "categories/gcid:agricultural_high_school"
    },
    {
        "displayName": "Fabricante de maquinaria agrícola",
        "name": "categories/gcid:agricultural_machinery_manufacturer"
    },
    {
        "displayName": "Organización agrícola",
        "name": "categories/gcid:agricultural_organization"
    },
    {
        "displayName": "Mayorista de productos agrícolas",
        "name": "categories/gcid:agricultural_product_wholesaler"
    },
    {
        "displayName": "Agricultural production",
        "name": "categories/gcid:agricultural_production"
    },
    {
        "displayName": "Servicio agrícola",
        "name": "categories/gcid:agricultural_service"
    },
    {
        "displayName": "Proveedor de agroquímicos",
        "name": "categories/gcid:agrochemicals_supplier"
    },
    {
        "displayName": "Club de aikido",
        "name": "categories/gcid:aikido_club"
    },
    {
        "displayName": "Escuela de Aikido",
        "name": "categories/gcid:aikido_school"
    },
    {
        "displayName": "Servicio de ambulancia aérea",
        "name": "categories/gcid:air_ambulance_service"
    },
    {
        "displayName": "Servicio de reparación de compresores neumáticos",
        "name": "categories/gcid:air_compressor_repair_service"
    },
    {
        "displayName": "Proveedor de compresores neumáticos",
        "name": "categories/gcid:air_compressor_supplier"
    },
    {
        "displayName": "Contratista de aire acondicionado",
        "name": "categories/gcid:air_conditioning_contractor"
    },
    {
        "displayName": "Servicio de reparación de aire acondicionado",
        "name": "categories/gcid:air_conditioning_repair_service"
    },
    {
        "displayName": "Tienda especializada en aire acondicionado",
        "name": "categories/gcid:air_conditioning_store"
    },
    {
        "displayName": "Proveedor de sistemas de aire acondicionado",
        "name": "categories/gcid:air_conditioning_system_supplier"
    },
    {
        "displayName": "Servicio de limpieza de tubos de aire",
        "name": "categories/gcid:air_duct_cleaning_service"
    },
    {
        "displayName": "Proveedor de filtros de aire",
        "name": "categories/gcid:air_filter_supplier"
    },
    {
        "displayName": "Base de la Fuerza Aérea",
        "name": "categories/gcid:air_force_base"
    },
    {
        "displayName": "Taxi aéreo",
        "name": "categories/gcid:air_taxi"
    },
    {
        "displayName": "Servicio de aerografía",
        "name": "categories/gcid:airbrushing_service"
    },
    {
        "displayName": "Tienda de insumos aerográficos",
        "name": "categories/gcid:airbrushing_supply_store"
    },
    {
        "displayName": "Concesionario de aeronaves",
        "name": "categories/gcid:aircraft_dealer"
    },
    {
        "displayName": "Compañía de mantenimiento aeronáutico",
        "name": "categories/gcid:aircraft_maintenance_company"
    },
    {
        "displayName": "Fabricante de aeronaves",
        "name": "categories/gcid:aircraft_manufacturer"
    },
    {
        "displayName": "Servicio de alquiler de aeronaves",
        "name": "categories/gcid:aircraft_rental_service"
    },
    {
        "displayName": "Tienda de insumos para aeronaves",
        "name": "categories/gcid:aircraft_supply_store"
    },
    {
        "displayName": "Aerolínea",
        "name": "categories/gcid:airline"
    },
    {
        "displayName": "Agencia de boletos de aerolínea",
        "name": "categories/gcid:airline_ticket_agency"
    },
    {
        "displayName": "Avión",
        "name": "categories/gcid:airplane_exhibit"
    },
    {
        "displayName": "Aeropuerto",
        "name": "categories/gcid:airport"
    },
    {
        "displayName": "Servicio de traslados desde el aeropuerto y hacia el aeropuerto",
        "name": "categories/gcid:airport_shuttle_service"
    },
    {
        "displayName": "Tienda de insumos para armas de aire comprimido",
        "name": "categories/gcid:airsoft_gun_shop"
    },
    {
        "displayName": "Pista de aterrizaje",
        "name": "categories/gcid:airstrip"
    },
    {
        "displayName": "Fabricante de alcohol",
        "name": "categories/gcid:alcohol_manufacturer"
    },
    {
        "displayName": "Monopolio de venta minorista de bebidas alcohólicas",
        "name": "categories/gcid:alcohol_retail_monopoly"
    },
    {
        "displayName": "Mayorista de bebidas alcohólicas",
        "name": "categories/gcid:alcoholic_beverage_wholesaler"
    },
    {
        "displayName": "Programa de tratamiento para el alcoholismo",
        "name": "categories/gcid:alcoholism_treatment_program"
    },
    {
        "displayName": "Concesionario Alfa Romeo",
        "name": "categories/gcid:alfa_romeo_dealer"
    },
    {
        "displayName": "Alergista",
        "name": "categories/gcid:allergist"
    },
    {
        "displayName": "Iglesia de la Alianza Cristiana",
        "name": "categories/gcid:alliance_church"
    },
    {
        "displayName": "Restaurante de cocina alsaciana",
        "name": "categories/gcid:alsace_restaurant"
    },
    {
        "displayName": "Estación de combustible alternativa",
        "name": "categories/gcid:alternative_fuel_station"
    },
    {
        "displayName": "Practicante de medicina alternativa",
        "name": "categories/gcid:alternative_medicine_practitioner"
    },
    {
        "displayName": "Distribuidor de alternadores",
        "name": "categories/gcid:alternator_supplier"
    },
    {
        "displayName": "Proveedor de aluminio",
        "name": "categories/gcid:aluminium_supplier"
    },
    {
        "displayName": "Proveedor de marcos de aluminio",
        "name": "categories/gcid:aluminum_frames_supplier"
    },
    {
        "displayName": "Servicio de soldadura en aluminio",
        "name": "categories/gcid:aluminum_welder"
    },
    {
        "displayName": "Ventana de aluminio",
        "name": "categories/gcid:aluminum_window"
    },
    {
        "displayName": "Teatro de amateurs",
        "name": "categories/gcid:amateur_theatre"
    },
    {
        "displayName": "Servicio de ambulancia",
        "name": "categories/gcid:ambulance_service"
    },
    {
        "displayName": "Tienda de productos americanos",
        "name": "categories/gcid:american_grocery_store"
    },
    {
        "displayName": "Restaurante americano",
        "name": "categories/gcid:american_restaurant"
    },
    {
        "displayName": "Tienda de mobiliario amish",
        "name": "categories/gcid:amish_furniture_store"
    },
    {
        "displayName": "Proveedor de munición",
        "name": "categories/gcid:ammunition_supplier"
    },
    {
        "displayName": "Anfiteatro",
        "name": "categories/gcid:amphitheatre"
    },
    {
        "displayName": "Centro de entretenimiento",
        "name": "categories/gcid:amusement_center"
    },
    {
        "displayName": "Proveedor de máquinas de diversión",
        "name": "categories/gcid:amusement_machine_supplier"
    },
    {
        "displayName": "Parque de atracciones",
        "name": "categories/gcid:amusement_park"
    },
    {
        "displayName": "Parque de atracciones",
        "name": "categories/gcid:amusement_park_ride"
    },
    {
        "displayName": "Proveedor de paseos de atracciones",
        "name": "categories/gcid:amusement_ride_supplier"
    },
    {
        "displayName": "Restaurante de cocina de Anhui",
        "name": "categories/gcid:an_hui_restaurant"
    },
    {
        "displayName": "Restaurante de anago",
        "name": "categories/gcid:anago_restaurant"
    },
    {
        "displayName": "Restaurante de cocina andaluza",
        "name": "categories/gcid:andalusian_restaurant"
    },
    {
        "displayName": "Restaurante andhra",
        "name": "categories/gcid:andhra_restaurant"
    },
    {
        "displayName": "Anestesiólogo",
        "name": "categories/gcid:anesthesiologist"
    },
    {
        "displayName": "Centro Anganwadi",
        "name": "categories/gcid:anganwadi_center"
    },
    {
        "displayName": "Restaurante de rape",
        "name": "categories/gcid:angler_fish_restaurant"
    },
    {
        "displayName": "Iglesia anglicana",
        "name": "categories/gcid:anglican_church"
    },
    {
        "displayName": "Café de animales",
        "name": "categories/gcid:animal_cafe"
    },
    {
        "displayName": "Servicio de control de animales",
        "name": "categories/gcid:animal_control_service"
    },
    {
        "displayName": "Hospital veterinario",
        "name": "categories/gcid:animal_hospital"
    },
    {
        "displayName": "Parque zoológico",
        "name": "categories/gcid:animal_park"
    },
    {
        "displayName": "Organización de protección de animales",
        "name": "categories/gcid:animal_protection_organization"
    },
    {
        "displayName": "Servicio de rescate animal",
        "name": "categories/gcid:animal_rescue_service"
    },
    {
        "displayName": "Refugio de animales",
        "name": "categories/gcid:animal_shelter"
    },
    {
        "displayName": "Abrevadero de animales",
        "name": "categories/gcid:animal_watering_hole"
    },
    {
        "displayName": "Estudio de animación",
        "name": "categories/gcid:animation_studio"
    },
    {
        "displayName": "Club de animé",
        "name": "categories/gcid:anime_club"
    },
    {
        "displayName": "Servicio de restauración de mobiliario antiguo",
        "name": "categories/gcid:antique_furniture_restoration_service"
    },
    {
        "displayName": "Tienda de mobiliario antiguo",
        "name": "categories/gcid:antique_furniture_store"
    },
    {
        "displayName": "Tienda de antigüedades",
        "name": "categories/gcid:antique_store"
    },
    {
        "displayName": "Edificio de apartamentos",
        "name": "categories/gcid:apartment_building"
    },
    {
        "displayName": "Complejo de departamentos",
        "name": "categories/gcid:apartment_complex"
    },
    {
        "displayName": "Agencia de alquiler de departamentos",
        "name": "categories/gcid:apartment_rental_agency"
    },
    {
        "displayName": "Iglesia apostólica",
        "name": "categories/gcid:apostolic_church"
    },
    {
        "displayName": "Proveedor de repuestos de electrodomésticos",
        "name": "categories/gcid:appliance_parts_supplier"
    },
    {
        "displayName": "Servicio de alquiler de electrodomésticos",
        "name": "categories/gcid:appliance_rental_service"
    },
    {
        "displayName": "Servicio de reparación de electrodomésticos",
        "name": "categories/gcid:appliance_repair_service"
    },
    {
        "displayName": "Tienda de electrodomésticos",
        "name": "categories/gcid:appliance_store"
    },
    {
        "displayName": "Atención al cliente de electrodomésticos",
        "name": "categories/gcid:appliances_customer_service"
    },
    {
        "displayName": "Terapeuta de análisis conductual aplicado",
        "name": "categories/gcid:applied_behavior_analysis_therapist"
    },
    {
        "displayName": "Tasador",
        "name": "categories/gcid:appraiser"
    },
    {
        "displayName": "Centro formativo profesional",
        "name": "categories/gcid:apprenticeship_center"
    },
    {
        "displayName": "Granja de acuicultura",
        "name": "categories/gcid:aquaculture_farm"
    },
    {
        "displayName": "Acuario",
        "name": "categories/gcid:aquarium"
    },
    {
        "displayName": "Tienda especializada en acuarios",
        "name": "categories/gcid:aquarium_shop"
    },
    {
        "displayName": "Parque acuático",
        "name": "categories/gcid:aquatic_center"
    },
    {
        "displayName": "Restaurante árabe",
        "name": "categories/gcid:arab_restaurant"
    },
    {
        "displayName": "Arboreto",
        "name": "categories/gcid:arboretum"
    },
    {
        "displayName": "Arborist and tree surgeon",
        "name": "categories/gcid:arborist_and_tree_surgeon"
    },
    {
        "displayName": "Museo arqueológico",
        "name": "categories/gcid:archaeological_museum"
    },
    {
        "displayName": "Club de arquería",
        "name": "categories/gcid:archery_club"
    },
    {
        "displayName": "Centro de tiro al blanco",
        "name": "categories/gcid:archery_range"
    },
    {
        "displayName": "Tienda de arquería",
        "name": "categories/gcid:archery_store"
    },
    {
        "displayName": "Arquitecto",
        "name": "categories/gcid:architect"
    },
    {
        "displayName": "Asociación de arquitectos",
        "name": "categories/gcid:architects_association"
    },
    {
        "displayName": "Constructor de modelos de arquitectura e ingeniería",
        "name": "categories/gcid:architectural_and_engineering_model_maker"
    },
    {
        "displayName": "Diseñador arquitectónico",
        "name": "categories/gcid:architectural_designer"
    },
    {
        "displayName": "Tienda de rescate arquitectónico",
        "name": "categories/gcid:architectural_salvage_store"
    },
    {
        "displayName": "Estudio de arquitectura",
        "name": "categories/gcid:architecture_firm"
    },
    {
        "displayName": "Escuela de arquitectura",
        "name": "categories/gcid:architecture_school"
    },
    {
        "displayName": "Archivo",
        "name": "categories/gcid:archive"
    },
    {
        "displayName": "Estadio",
        "name": "categories/gcid:arena"
    },
    {
        "displayName": "Restaurante argentino",
        "name": "categories/gcid:argentinian_restaurant"
    },
    {
        "displayName": "Asociación de las Fuerzas Armadas",
        "name": "categories/gcid:armed_forces_association"
    },
    {
        "displayName": "Iglesia armenia",
        "name": "categories/gcid:armenian_church"
    },
    {
        "displayName": "Restaurante armenio",
        "name": "categories/gcid:armenian_restaurant"
    },
    {
        "displayName": "Tienda de excedentes del ejército y la armada",
        "name": "categories/gcid:army_and_navy_store"
    },
    {
        "displayName": "Cuarteles del ejército",
        "name": "categories/gcid:army_base"
    },
    {
        "displayName": "Destacamento militar",
        "name": "categories/gcid:army_facility"
    },
    {
        "displayName": "Museo del ejército",
        "name": "categories/gcid:army_museum"
    },
    {
        "displayName": "Clases de aromaterapia",
        "name": "categories/gcid:aromatherapy_class"
    },
    {
        "displayName": "Servicio de aromaterapia",
        "name": "categories/gcid:aromatherapy_service"
    },
    {
        "displayName": "Tienda de insumos de aromaterapia",
        "name": "categories/gcid:aromatherapy_supply_store"
    },
    {
        "displayName": "Café de arte",
        "name": "categories/gcid:art_cafe"
    },
    {
        "displayName": "Centro de arte",
        "name": "categories/gcid:art_center"
    },
    {
        "displayName": "Marchante",
        "name": "categories/gcid:art_dealer"
    },
    {
        "displayName": "Galería de arte",
        "name": "categories/gcid:art_gallery"
    },
    {
        "displayName": "Museo de arte",
        "name": "categories/gcid:art_museum"
    },
    {
        "displayName": "Servicio de restauración de arte",
        "name": "categories/gcid:art_restoration_service"
    },
    {
        "displayName": "Escuela de arte",
        "name": "categories/gcid:art_school"
    },
    {
        "displayName": "Estudio de arte",
        "name": "categories/gcid:art_studio"
    },
    {
        "displayName": "Tienda de suministros artísticos",
        "name": "categories/gcid:art_supply_store"
    },
    {
        "displayName": "Proveedor de plantas artificiales",
        "name": "categories/gcid:artificial_plant_supplier"
    },
    {
        "displayName": "Artista",
        "name": "categories/gcid:artist"
    },
    {
        "displayName": "Organización de arte",
        "name": "categories/gcid:arts_organization"
    },
    {
        "displayName": "Parrilla",
        "name": "categories/gcid:asador"
    },
    {
        "displayName": "Servicio de detección de asbesto",
        "name": "categories/gcid:asbestos_testing_service"
    },
    {
        "displayName": "Ashram",
        "name": "categories/gcid:ashram"
    },
    {
        "displayName": "Restaurante de cocina fusión asiática",
        "name": "categories/gcid:asian_fusion_restaurant"
    },
    {
        "displayName": "Tienda de alimentos asiáticos",
        "name": "categories/gcid:asian_grocery_store"
    },
    {
        "displayName": "Tienda de artículos asiáticos para el hogar",
        "name": "categories/gcid:asian_household_goods_store"
    },
    {
        "displayName": "Restaurante asiático",
        "name": "categories/gcid:asian_restaurant"
    },
    {
        "displayName": "Contratista de asfalto",
        "name": "categories/gcid:asphalt_contractor"
    },
    {
        "displayName": "Planta mezcladora de asfalto",
        "name": "categories/gcid:asphalt_mixing_plant"
    },
    {
        "displayName": "Restaurante asamés",
        "name": "categories/gcid:assamese_restaurant"
    },
    {
        "displayName": "Iglesia de las Asambleas de Dios",
        "name": "categories/gcid:assemblies_of_god_church"
    },
    {
        "displayName": "Asistente de jardín infantil",
        "name": "categories/gcid:assistante_maternelle"
    },
    {
        "displayName": "Asilo de ancianos",
        "name": "categories/gcid:assisted_living_facility"
    },
    {
        "displayName": "Asociación u organización",
        "name": "categories/gcid:association_or_organization"
    },
    {
        "displayName": "Concesionario Aston Martin",
        "name": "categories/gcid:aston_martin_dealer"
    },
    {
        "displayName": "Astrólogo",
        "name": "categories/gcid:astrologer"
    },
    {
        "displayName": "Restaurante asturiano",
        "name": "categories/gcid:asturian_restaurant"
    },
    {
        "displayName": "Club de atletismo",
        "name": "categories/gcid:athletic_club"
    },
    {
        "displayName": "Campo de atletismo",
        "name": "categories/gcid:athletic_field"
    },
    {
        "displayName": "Parque de atletismo",
        "name": "categories/gcid:athletic_park"
    },
    {
        "displayName": "Pista de atletismo",
        "name": "categories/gcid:athletic_track"
    },
    {
        "displayName": "Cajero automático",
        "name": "categories/gcid:atm"
    },
    {
        "displayName": "Servicio de referencia de abogados",
        "name": "categories/gcid:attorney_referral_service"
    },
    {
        "displayName": "Concesionario de vehículos todo terreno",
        "name": "categories/gcid:atv_dealer"
    },
    {
        "displayName": "Servicio de alquiler de vehículos todo terreno",
        "name": "categories/gcid:atv_rental_service"
    },
    {
        "displayName": "Taller de reparación de vehículos todo terreno",
        "name": "categories/gcid:atv_repair_shop"
    },
    {
        "displayName": "Casa de remates",
        "name": "categories/gcid:auction_house"
    },
    {
        "displayName": "Concesionario Audi",
        "name": "categories/gcid:audi_dealer"
    },
    {
        "displayName": "Consultor audiovisual",
        "name": "categories/gcid:audio_visual_consultant"
    },
    {
        "displayName": "Alquiler de equipos audiovisuales",
        "name": "categories/gcid:audio_visual_equipment_rental_service"
    },
    {
        "displayName": "Servicio de reparación de equipos audiovisuales",
        "name": "categories/gcid:audio_visual_equipment_repair_service"
    },
    {
        "displayName": "Proveedor de equipos audiovisuales",
        "name": "categories/gcid:audio_visual_equipment_supplier"
    },
    {
        "displayName": "Audiólogo",
        "name": "categories/gcid:audiologist"
    },
    {
        "displayName": "Auditor",
        "name": "categories/gcid:auditor"
    },
    {
        "displayName": "Auditorio",
        "name": "categories/gcid:auditorium"
    },
    {
        "displayName": "Tienda de productos australianos",
        "name": "categories/gcid:australian_goods_store"
    },
    {
        "displayName": "Restaurante australiano",
        "name": "categories/gcid:australian_restaurant"
    },
    {
        "displayName": "Restaurante austríaco",
        "name": "categories/gcid:austrian_restaurant"
    },
    {
        "displayName": "Mayorista de accesorios automotrices",
        "name": "categories/gcid:auto_accessories_wholesaler"
    },
    {
        "displayName": "Taller de aire acondicionado automotriz",
        "name": "categories/gcid:auto_air_conditioning_service"
    },
    {
        "displayName": "Subasta de autos",
        "name": "categories/gcid:auto_auction"
    },
    {
        "displayName": "Proveedor de repuestos de carrocería de automóviles",
        "name": "categories/gcid:auto_body_parts_supplier"
    },
    {
        "displayName": "Taller de chapa y pintura",
        "name": "categories/gcid:auto_body_shop"
    },
    {
        "displayName": "Mecánico especializado en chapa y pintura de automóviles",
        "name": "categories/gcid:auto_bodywork_mechanic"
    },
    {
        "displayName": "Agente de automóviles",
        "name": "categories/gcid:auto_broker"
    },
    {
        "displayName": "Auto chemistry shop",
        "name": "categories/gcid:auto_chemistry_shop"
    },
    {
        "displayName": "Servicio de chapa y pintura",
        "name": "categories/gcid:auto_dent_removal_service"
    },
    {
        "displayName": "Servicio eléctrico para autos",
        "name": "categories/gcid:auto_electrical_service"
    },
    {
        "displayName": "Servicio de reparación para vidrios de automóviles",
        "name": "categories/gcid:auto_glass_repair_service"
    },
    {
        "displayName": "Tienda de cristales para autos",
        "name": "categories/gcid:auto_glass_shop"
    },
    {
        "displayName": "Agencia de seguros de automóviles",
        "name": "categories/gcid:auto_insurance_agency"
    },
    {
        "displayName": "Servicio técnico automotriz",
        "name": "categories/gcid:auto_machine_shop"
    },
    {
        "displayName": "Mercado de automóviles",
        "name": "categories/gcid:auto_market"
    },
    {
        "displayName": "Servicio de pintura para automóviles",
        "name": "categories/gcid:auto_painting"
    },
    {
        "displayName": "Fabricante de autopartes",
        "name": "categories/gcid:auto_parts_manufacturer"
    },
    {
        "displayName": "Mercado de autopartes",
        "name": "categories/gcid:auto_parts_market"
    },
    {
        "displayName": "Tienda de repuestos para carro",
        "name": "categories/gcid:auto_parts_store"
    },
    {
        "displayName": "Servicio de reparación de radiadores",
        "name": "categories/gcid:auto_radiator_repair_service"
    },
    {
        "displayName": "Taller de reparación de autos",
        "name": "categories/gcid:auto_repair_shop"
    },
    {
        "displayName": "Servicio de restauración de automóviles",
        "name": "categories/gcid:auto_restoration_service"
    },
    {
        "displayName": "Parada de mototaxis",
        "name": "categories/gcid:auto_rickshaw_stand"
    },
    {
        "displayName": "Tienda de muelles de automóviles",
        "name": "categories/gcid:auto_spring_shop"
    },
    {
        "displayName": "Tienda de techos corredizos de automóviles",
        "name": "categories/gcid:auto_sunroof_shop"
    },
    {
        "displayName": "Agencia de registro de automotores",
        "name": "categories/gcid:auto_tag_agency"
    },
    {
        "displayName": "Taller de balanceo y alineación",
        "name": "categories/gcid:auto_tune_up_service"
    },
    {
        "displayName": "Tapicería del automóvil",
        "name": "categories/gcid:auto_upholsterer"
    },
    {
        "displayName": "Servicio de polarizado de autos",
        "name": "categories/gcid:auto_window_tinting_service"
    },
    {
        "displayName": "Grúa de remolque de automóviles",
        "name": "categories/gcid:auto_wrecker"
    },
    {
        "displayName": "Compañía de automatización",
        "name": "categories/gcid:automation_company"
    },
    {
        "displayName": "Planta de almacenamiento de automóviles",
        "name": "categories/gcid:automobile_storage_facility"
    },
    {
        "displayName": "Consultor aeronáutico",
        "name": "categories/gcid:aviation_consultant"
    },
    {
        "displayName": "Instituto de entrenamiento aeronáutico",
        "name": "categories/gcid:aviation_training_institute"
    },
    {
        "displayName": "Restaurante awadhi",
        "name": "categories/gcid:awadhi_restaurant"
    },
    {
        "displayName": "Proveedor de toldos y carpas",
        "name": "categories/gcid:awning_supplier"
    },
    {
        "displayName": "Restaurante de ayam penyet",
        "name": "categories/gcid:ayam_penyet_restaurant"
    },
    {
        "displayName": "Clínica ayurvédica",
        "name": "categories/gcid:ayurvedic_clinic"
    },
    {
        "displayName": "Azerbaijani restaurant",
        "name": "categories/gcid:azerbaijani_restaurant"
    },
    {
        "displayName": "Tienda de ropa para bebés",
        "name": "categories/gcid:baby_clothing_store"
    },
    {
        "displayName": "Tienda para bebés",
        "name": "categories/gcid:baby_store"
    },
    {
        "displayName": "Escuela de natación para bebés",
        "name": "categories/gcid:baby_swimming_school"
    },
    {
        "displayName": "Niñero",
        "name": "categories/gcid:babysitter"
    },
    {
        "displayName": "Restaurante badense",
        "name": "categories/gcid:baden_restaurant"
    },
    {
        "displayName": "Club de bádminton",
        "name": "categories/gcid:badminton_club"
    },
    {
        "displayName": "Complejo de bádminton",
        "name": "categories/gcid:badminton_complex"
    },
    {
        "displayName": "Cancha de bádminton",
        "name": "categories/gcid:badminton_court"
    },
    {
        "displayName": "Tienda de bolsos y equipaje",
        "name": "categories/gcid:bag_shop"
    },
    {
        "displayName": "Tienda de roscas",
        "name": "categories/gcid:bagel_shop"
    },
    {
        "displayName": "Templo bahá’í",
        "name": "categories/gcid:bahai_house_of_worship"
    },
    {
        "displayName": "Servicio de garantía en casos de libertad bajo fianza",
        "name": "categories/gcid:bail_bonds_service"
    },
    {
        "displayName": "Tienda de carnada",
        "name": "categories/gcid:bait_shop"
    },
    {
        "displayName": "Panadería",
        "name": "categories/gcid:bakery"
    },
    {
        "displayName": "Equipamiento para panadería",
        "name": "categories/gcid:bakery_equipment"
    },
    {
        "displayName": "Tienda de insumos para hornear",
        "name": "categories/gcid:baking_supply_store"
    },
    {
        "displayName": "Restaurante especializado en bakso",
        "name": "categories/gcid:bakso_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Bali",
        "name": "categories/gcid:balinese_restaurant"
    },
    {
        "displayName": "Escuela de ballet",
        "name": "categories/gcid:ballet_school"
    },
    {
        "displayName": "Teatro de ballet",
        "name": "categories/gcid:ballet_theater"
    },
    {
        "displayName": "Artista de globos",
        "name": "categories/gcid:balloon_artist"
    },
    {
        "displayName": "Agencia turística de paseos en globo",
        "name": "categories/gcid:balloon_ride_tour_agency"
    },
    {
        "displayName": "Tienda de globos",
        "name": "categories/gcid:balloon_store"
    },
    {
        "displayName": "Instructor de baile de salón",
        "name": "categories/gcid:ballroom_dance_instructor"
    },
    {
        "displayName": "Banda de música",
        "name": "categories/gcid:band"
    },
    {
        "displayName": "Restaurante de cocina de Bangladesh",
        "name": "categories/gcid:bangladeshi_restaurant"
    },
    {
        "displayName": "Tienda de brazaletes",
        "name": "categories/gcid:bangle_shop"
    },
    {
        "displayName": "Banco",
        "name": "categories/gcid:bank"
    },
    {
        "displayName": "Abogado especializado en concursos y quiebras",
        "name": "categories/gcid:bankruptcy_attorney"
    },
    {
        "displayName": "Servicio de concurso y quiebra",
        "name": "categories/gcid:bankruptcy_service"
    },
    {
        "displayName": "Tienda de pancartas publicitarias",
        "name": "categories/gcid:banner_store"
    },
    {
        "displayName": "Salón de banquetes",
        "name": "categories/gcid:banquet_hall"
    },
    {
        "displayName": "Iglesia bautista",
        "name": "categories/gcid:baptist_church"
    },
    {
        "displayName": "Bar",
        "name": "categories/gcid:bar"
    },
    {
        "displayName": "Bar y restaurante",
        "name": "categories/gcid:bar_and_grill"
    },
    {
        "displayName": "Bar PMU",
        "name": "categories/gcid:bar_pmu"
    },
    {
        "displayName": "Tienda de mobiliario para bares y restaurantes",
        "name": "categories/gcid:bar_restaurant_furniture_store"
    },
    {
        "displayName": "Bar stool supplier",
        "name": "categories/gcid:bar_stool_supplier"
    },
    {
        "displayName": "Bar Tabac",
        "name": "categories/gcid:bar_tabac"
    },
    {
        "displayName": "Restaurante de barbacoa",
        "name": "categories/gcid:barbecue_restaurant"
    },
    {
        "displayName": "Área de barbacoa",
        "name": "categories/gcid:barbecue_spots"
    },
    {
        "displayName": "Escuela de peluqueros",
        "name": "categories/gcid:barber_school"
    },
    {
        "displayName": "Barbería",
        "name": "categories/gcid:barber_shop"
    },
    {
        "displayName": "Tienda de insumos para peluquería",
        "name": "categories/gcid:barber_supply_store"
    },
    {
        "displayName": "Cirujano bariátrico",
        "name": "categories/gcid:bariatric_surgeon"
    },
    {
        "displayName": "Proveedor de corteza",
        "name": "categories/gcid:bark_supplier"
    },
    {
        "displayName": "Vendedor de barriles",
        "name": "categories/gcid:barrel_supplier"
    },
    {
        "displayName": "Abogado litigante",
        "name": "categories/gcid:barrister"
    },
    {
        "displayName": "Escuela de bartenders",
        "name": "categories/gcid:bartending_school"
    },
    {
        "displayName": "Club de béisbol",
        "name": "categories/gcid:baseball_club"
    },
    {
        "displayName": "Campo de béisbol",
        "name": "categories/gcid:baseball_field"
    },
    {
        "displayName": "Tienda de artículos para béisbol",
        "name": "categories/gcid:baseball_goods_store"
    },
    {
        "displayName": "Basílica",
        "name": "categories/gcid:basilica"
    },
    {
        "displayName": "Proveedor de canastas",
        "name": "categories/gcid:basket_supplier"
    },
    {
        "displayName": "Club de basquetbol",
        "name": "categories/gcid:basketball_club"
    },
    {
        "displayName": "Cancha de básquetbol",
        "name": "categories/gcid:basketball_court"
    },
    {
        "displayName": "Contratista de canchas de basquetbol",
        "name": "categories/gcid:basketball_court_contractor"
    },
    {
        "displayName": "Restaurante vasco",
        "name": "categories/gcid:basque_restaurant"
    },
    {
        "displayName": "Restaurante de cocina batak",
        "name": "categories/gcid:batak_restaurant"
    },
    {
        "displayName": "Remodelador de baños",
        "name": "categories/gcid:bathroom_remodeler"
    },
    {
        "displayName": "Tienda de insumos para baños",
        "name": "categories/gcid:bathroom_supply_store"
    },
    {
        "displayName": "Tienda de ropa batik",
        "name": "categories/gcid:batik_clothing_store"
    },
    {
        "displayName": "Fabricante de baterías",
        "name": "categories/gcid:battery_manufacturer"
    },
    {
        "displayName": "Tienda de baterías",
        "name": "categories/gcid:battery_store"
    },
    {
        "displayName": "Distribuidor de baterías",
        "name": "categories/gcid:battery_wholesaler"
    },
    {
        "displayName": "Centro de bateo",
        "name": "categories/gcid:batting_cage_center"
    },
    {
        "displayName": "Restaurante bávaro",
        "name": "categories/gcid:bavarian_restaurant"
    },
    {
        "displayName": "Bazar",
        "name": "categories/gcid:bazar"
    },
    {
        "displayName": "Área para barbacoas",
        "name": "categories/gcid:bbq_area"
    },
    {
        "displayName": "Servicio de limpieza de playas",
        "name": "categories/gcid:beach_cleaning_service"
    },
    {
        "displayName": "Tienda de ropa para playa",
        "name": "categories/gcid:beach_clothing_store"
    },
    {
        "displayName": "Club de playa",
        "name": "categories/gcid:beach_club"
    },
    {
        "displayName": "Tienda de entretenimiento en la playa",
        "name": "categories/gcid:beach_entertainment_shop"
    },
    {
        "displayName": "Salón de playa",
        "name": "categories/gcid:beach_pavillion"
    },
    {
        "displayName": "Club de voleibol de playa",
        "name": "categories/gcid:beach_volleyball_club"
    },
    {
        "displayName": "Cancha de voleibol playero",
        "name": "categories/gcid:beach_volleyball_court"
    },
    {
        "displayName": "Tienda de perlas para collares",
        "name": "categories/gcid:bead_store"
    },
    {
        "displayName": "Mayorista de perlas",
        "name": "categories/gcid:bead_wholesaler"
    },
    {
        "displayName": "Proveedor de rodamientos",
        "name": "categories/gcid:bearing_supplier"
    },
    {
        "displayName": "Esteticista",
        "name": "categories/gcid:beautician"
    },
    {
        "displayName": "Proveedor de productos de belleza",
        "name": "categories/gcid:beauty_product_supplier"
    },
    {
        "displayName": "Máquina expendedora de cosméticos",
        "name": "categories/gcid:beauty_products_vending_machine"
    },
    {
        "displayName": "Distribuidor de productos de belleza al por mayor",
        "name": "categories/gcid:beauty_products_wholesaler"
    },
    {
        "displayName": "Salón de belleza",
        "name": "categories/gcid:beauty_salon"
    },
    {
        "displayName": "Escuela de belleza",
        "name": "categories/gcid:beauty_school"
    },
    {
        "displayName": "Tienda de artículos de belleza",
        "name": "categories/gcid:beauty_supply_store"
    },
    {
        "displayName": "Bed & Breakfast",
        "name": "categories/gcid:bed_and_breakfast"
    },
    {
        "displayName": "Tienda de colchones",
        "name": "categories/gcid:bed_shop"
    },
    {
        "displayName": "Tienda de ropa para cama",
        "name": "categories/gcid:bedding_store"
    },
    {
        "displayName": "Tienda de muebles para dormitorio",
        "name": "categories/gcid:bedroom_furniture_store"
    },
    {
        "displayName": "Servicio de reubicación de abejas",
        "name": "categories/gcid:bee_relocation_service"
    },
    {
        "displayName": "Restaurante especializado en gyūdon",
        "name": "categories/gcid:beef_rice_bowl_restaurant"
    },
    {
        "displayName": "Distribuidor de cerveza",
        "name": "categories/gcid:beer_distributor"
    },
    {
        "displayName": "Patio cervecero",
        "name": "categories/gcid:beer_garden"
    },
    {
        "displayName": "Cervecería",
        "name": "categories/gcid:beer_hall"
    },
    {
        "displayName": "Cervecería",
        "name": "categories/gcid:beer_store"
    },
    {
        "displayName": "Restaurante belga",
        "name": "categories/gcid:belgian_restaurant"
    },
    {
        "displayName": "Tienda de cintos",
        "name": "categories/gcid:belt_shop"
    },
    {
        "displayName": "Restaurante bengalí",
        "name": "categories/gcid:bengali_restaurant"
    },
    {
        "displayName": "Concesionario Bentley",
        "name": "categories/gcid:bentley_dealer"
    },
    {
        "displayName": "Restaurante de cocina de Berry",
        "name": "categories/gcid:berry_restaurant"
    },
    {
        "displayName": "Escuela de formación profesional con pasantías",
        "name": "categories/gcid:berufsfachschule"
    },
    {
        "displayName": "Restaurante de cocina betawi",
        "name": "categories/gcid:betawi_restaurant"
    },
    {
        "displayName": "Agencia de apuestas",
        "name": "categories/gcid:betting_agency"
    },
    {
        "displayName": "Proveedor de bebidas",
        "name": "categories/gcid:beverage_distributor"
    },
    {
        "displayName": "Club de ciclismo",
        "name": "categories/gcid:bicycle_club"
    },
    {
        "displayName": "Portabicicletas",
        "name": "categories/gcid:bicycle_rack"
    },
    {
        "displayName": "Servicio de alquiler de bicicletas",
        "name": "categories/gcid:bicycle_rental_service"
    },
    {
        "displayName": "Bicicletería",
        "name": "categories/gcid:bicycle_repair_shop"
    },
    {
        "displayName": "Tienda de bicicletas",
        "name": "categories/gcid:bicycle_store"
    },
    {
        "displayName": "Mayorista de bicicletas",
        "name": "categories/gcid:bicycle_wholesale"
    },
    {
        "displayName": "Lavadero de motocicletas",
        "name": "categories/gcid:bike_wash"
    },
    {
        "displayName": "Estudio de Bikram Yoga",
        "name": "categories/gcid:bikram_yoga_studio"
    },
    {
        "displayName": "Colegio bilingüe",
        "name": "categories/gcid:bilingual_school"
    },
    {
        "displayName": "Tienda de insumos para billar",
        "name": "categories/gcid:billiards_supply_store"
    },
    {
        "displayName": "Salón para bingo",
        "name": "categories/gcid:bingo_hall"
    },
    {
        "displayName": "Biochemical supplier",
        "name": "categories/gcid:biochemical_supplier"
    },
    {
        "displayName": "Laboratorio de bioquímica",
        "name": "categories/gcid:biochemistry_lab"
    },
    {
        "displayName": "Terapeuta de biorretroalimentación",
        "name": "categories/gcid:biofeedback_therapist"
    },
    {
        "displayName": "Compañía de biotecnología",
        "name": "categories/gcid:biotechnology_company"
    },
    {
        "displayName": "Biotechnology engineer",
        "name": "categories/gcid:biotechnology_engineer"
    },
    {
        "displayName": "Servicio de control de aves",
        "name": "categories/gcid:bird_control_service"
    },
    {
        "displayName": "Tienda de aves",
        "name": "categories/gcid:bird_shop"
    },
    {
        "displayName": "Área de observación de aves",
        "name": "categories/gcid:bird_watching_area"
    },
    {
        "displayName": "Birth center",
        "name": "categories/gcid:birth_center"
    },
    {
        "displayName": "Oficina de registro civil de nacimiento",
        "name": "categories/gcid:birth_certificate_service"
    },
    {
        "displayName": "Birth control center",
        "name": "categories/gcid:birth_control_center"
    },
    {
        "displayName": "Restaurante de biryani",
        "name": "categories/gcid:biryani_restaurant"
    },
    {
        "displayName": "Bistró",
        "name": "categories/gcid:bistro"
    },
    {
        "displayName": "Herrero",
        "name": "categories/gcid:blacksmith"
    },
    {
        "displayName": "Servicio de limpieza a presión",
        "name": "categories/gcid:blast_cleaning_service"
    },
    {
        "displayName": "Escuela para ciegos",
        "name": "categories/gcid:blind_school"
    },
    {
        "displayName": "Tienda de persianas",
        "name": "categories/gcid:blinds_shop"
    },
    {
        "displayName": "Banco de sangre",
        "name": "categories/gcid:blood_bank"
    },
    {
        "displayName": "Centro de donación de sangre",
        "name": "categories/gcid:blood_donation_center"
    },
    {
        "displayName": "Servicio de exámenes de sangre",
        "name": "categories/gcid:blood_testing_service"
    },
    {
        "displayName": "Servicio de cianocopia",
        "name": "categories/gcid:blueprint_service"
    },
    {
        "displayName": "Club de blues",
        "name": "categories/gcid:blues_club"
    },
    {
        "displayName": "Concesionario BMW",
        "name": "categories/gcid:bmw_dealer"
    },
    {
        "displayName": "Concesionario de motocicletas BMW",
        "name": "categories/gcid:bmw_motorcycle_dealer"
    },
    {
        "displayName": "Club de BMX",
        "name": "categories/gcid:bmx_club"
    },
    {
        "displayName": "Parque de BMX",
        "name": "categories/gcid:bmx_park"
    },
    {
        "displayName": "Circuito de BMX",
        "name": "categories/gcid:bmx_track"
    },
    {
        "displayName": "Grupo de juegos de mesa",
        "name": "categories/gcid:board_game_club"
    },
    {
        "displayName": "Departamento de Educación",
        "name": "categories/gcid:board_of_education"
    },
    {
        "displayName": "Pensión",
        "name": "categories/gcid:boarding_house"
    },
    {
        "displayName": "Internado",
        "name": "categories/gcid:boarding_school"
    },
    {
        "displayName": "Proveedor de accesorios para barcos",
        "name": "categories/gcid:boat_accessories_supplier"
    },
    {
        "displayName": "Construcción de embarcaciones",
        "name": "categories/gcid:boat_builder"
    },
    {
        "displayName": "Servicio de limpieza de embarcaciones",
        "name": "categories/gcid:boat_cleaning_service"
    },
    {
        "displayName": "Club de navegación",
        "name": "categories/gcid:boat_club"
    },
    {
        "displayName": "Proveedor de fundas para embarcaciones",
        "name": "categories/gcid:boat_cover_supplier"
    },
    {
        "displayName": "Venta de embarcaciones",
        "name": "categories/gcid:boat_dealer"
    },
    {
        "displayName": "Servicio de detallado de embarcaciones",
        "name": "categories/gcid:boat_detailing_service"
    },
    {
        "displayName": "Rampa para embarcaciones",
        "name": "categories/gcid:boat_ramp"
    },
    {
        "displayName": "Servicio de alquiler de barcos",
        "name": "categories/gcid:boat_rental_service"
    },
    {
        "displayName": "Taller de reparación de embarcaciones",
        "name": "categories/gcid:boat_repair_shop"
    },
    {
        "displayName": "Instalación de almacenamiento para barcos",
        "name": "categories/gcid:boat_storage_facility"
    },
    {
        "displayName": "Agencia de turismo náutico",
        "name": "categories/gcid:boat_tour_agency"
    },
    {
        "displayName": "Concesionario de remolques para embarcaciones",
        "name": "categories/gcid:boat_trailer_dealer"
    },
    {
        "displayName": "Instructor náutico",
        "name": "categories/gcid:boating_instructor"
    },
    {
        "displayName": "Pista de bochas",
        "name": "categories/gcid:bocce_ball_court"
    },
    {
        "displayName": "Tienda de piercings para el cuerpo",
        "name": "categories/gcid:body_piercing_shop"
    },
    {
        "displayName": "Instituto de body shaping",
        "name": "categories/gcid:body_shaping_class"
    },
    {
        "displayName": "Fabricante de calderas",
        "name": "categories/gcid:boiler_manufacturer"
    },
    {
        "displayName": "Distribuidor de calderas",
        "name": "categories/gcid:boiler_supplier"
    },
    {
        "displayName": "Centro de manipulación ósea",
        "name": "categories/gcid:bonesetting_house"
    },
    {
        "displayName": "Proveedor de plantas de bonsái",
        "name": "categories/gcid:bonsai_plant_supplier"
    },
    {
        "displayName": "Editor de libros",
        "name": "categories/gcid:book_publisher"
    },
    {
        "displayName": "Librería",
        "name": "categories/gcid:book_store"
    },
    {
        "displayName": "Encuadernador",
        "name": "categories/gcid:bookbinder"
    },
    {
        "displayName": "Servicio de contabilidad",
        "name": "categories/gcid:bookkeeping_service"
    },
    {
        "displayName": "Bookmaker",
        "name": "categories/gcid:bookmaker"
    },
    {
        "displayName": "Mayorista de libros",
        "name": "categories/gcid:books_wholesaler"
    },
    {
        "displayName": "Campo de entrenamiento físico",
        "name": "categories/gcid:boot_camp"
    },
    {
        "displayName": "Taller de reparación de calzados",
        "name": "categories/gcid:boot_repair_shop"
    },
    {
        "displayName": "Tienda de calzados",
        "name": "categories/gcid:boot_store"
    },
    {
        "displayName": "Puesto fronterizo",
        "name": "categories/gcid:border_crossing_station"
    },
    {
        "displayName": "Guarda de frontera",
        "name": "categories/gcid:border_guard"
    },
    {
        "displayName": "Botánica",
        "name": "categories/gcid:botanica"
    },
    {
        "displayName": "Jardín botánico",
        "name": "categories/gcid:botanical_garden"
    },
    {
        "displayName": "Centro de reciclaje de latas y botellas",
        "name": "categories/gcid:bottle_and_can_redemption_center"
    },
    {
        "displayName": "Proveedor de agua embotellada",
        "name": "categories/gcid:bottled_water_supplier"
    },
    {
        "displayName": "Alquiler de castillos inflables",
        "name": "categories/gcid:bouncy_castle_hire"
    },
    {
        "displayName": "Boutique",
        "name": "categories/gcid:boutique"
    },
    {
        "displayName": "Bolera",
        "name": "categories/gcid:bowling_alley"
    },
    {
        "displayName": "Club de bolos",
        "name": "categories/gcid:bowling_club"
    },
    {
        "displayName": "Tienda de insumos para bolos",
        "name": "categories/gcid:bowling_supply_shop"
    },
    {
        "displayName": "Proveedor de loncheras",
        "name": "categories/gcid:box_lunch_supplier"
    },
    {
        "displayName": "Club de boxeo",
        "name": "categories/gcid:boxing_club"
    },
    {
        "displayName": "Gimnasio de boxeo",
        "name": "categories/gcid:boxing_gym"
    },
    {
        "displayName": "Ring de boxeo",
        "name": "categories/gcid:boxing_ring"
    },
    {
        "displayName": "Escuela secundaria para varones",
        "name": "categories/gcid:boys_high_school"
    },
    {
        "displayName": "Empresa de BPO",
        "name": "categories/gcid:bpo_company"
    },
    {
        "displayName": "Agencia de colocación especializada en externalización de procesos de negocio",
        "name": "categories/gcid:bpo_placement_agency"
    },
    {
        "displayName": "Tienda de frenos",
        "name": "categories/gcid:brake_shop"
    },
    {
        "displayName": "Agencia de branding",
        "name": "categories/gcid:branding_agency"
    },
    {
        "displayName": "Pastelaria brasileira",
        "name": "categories/gcid:brazilian_pastelaria"
    },
    {
        "displayName": "Restaurante brasileño",
        "name": "categories/gcid:brazilian_restaurant"
    },
    {
        "displayName": "Restaurante de desayunos",
        "name": "categories/gcid:breakfast_restaurant"
    },
    {
        "displayName": "Cervecería",
        "name": "categories/gcid:brewery"
    },
    {
        "displayName": "Tienda de insumos para cerveza artesanal",
        "name": "categories/gcid:brewing_supply_store"
    },
    {
        "displayName": "Cervecería",
        "name": "categories/gcid:brewpub"
    },
    {
        "displayName": "Fabricante de ladrillos",
        "name": "categories/gcid:brick_manufacturer"
    },
    {
        "displayName": "Albañil",
        "name": "categories/gcid:bricklayer"
    },
    {
        "displayName": "Tienda para novias",
        "name": "categories/gcid:bridal_shop"
    },
    {
        "displayName": "Puente",
        "name": "categories/gcid:bridge"
    },
    {
        "displayName": "Club de bridge",
        "name": "categories/gcid:bridge_club"
    },
    {
        "displayName": "Restaurante de cocina británica",
        "name": "categories/gcid:british_restaurant"
    },
    {
        "displayName": "Restaurante de desayunos y almuerzos",
        "name": "categories/gcid:brunch_restaurant"
    },
    {
        "displayName": "Tienda de té de burbujas",
        "name": "categories/gcid:bubble_tea_store"
    },
    {
        "displayName": "Tienda de artículos budistas",
        "name": "categories/gcid:buddhist_supplies_store"
    },
    {
        "displayName": "Templo budista",
        "name": "categories/gcid:buddhist_temple"
    },
    {
        "displayName": "Restaurante de autoservicio",
        "name": "categories/gcid:buffet_restaurant"
    },
    {
        "displayName": "Concesionario Bugatti",
        "name": "categories/gcid:bugatti_dealer"
    },
    {
        "displayName": "Concesionario Buick",
        "name": "categories/gcid:buick_dealer"
    },
    {
        "displayName": "Consultor de construcción",
        "name": "categories/gcid:building_consultant"
    },
    {
        "displayName": "Building design company",
        "name": "categories/gcid:building_design_company"
    },
    {
        "displayName": "Diseñador de edificios",
        "name": "categories/gcid:building_designer"
    },
    {
        "displayName": "Servicio de alquiler de equipos para edificios",
        "name": "categories/gcid:building_equipment_hire_service"
    },
    {
        "displayName": "Constructora",
        "name": "categories/gcid:building_firm"
    },
    {
        "displayName": "Inspector de construcción",
        "name": "categories/gcid:building_inspector"
    },
    {
        "displayName": "Mercado de materiales de construcción",
        "name": "categories/gcid:building_materials_market"
    },
    {
        "displayName": "Tienda de materiales para la construcción",
        "name": "categories/gcid:building_materials_store"
    },
    {
        "displayName": "Proveedor de materiales para la construcción",
        "name": "categories/gcid:building_materials_supplier"
    },
    {
        "displayName": "Servicio de restauración de edificios",
        "name": "categories/gcid:building_restoration_service"
    },
    {
        "displayName": "Sociedad de préstamos inmobiliarios",
        "name": "categories/gcid:building_society"
    },
    {
        "displayName": "Chartered surveyor",
        "name": "categories/gcid:building_surveyor"
    },
    {
        "displayName": "Restaurante búlgaro",
        "name": "categories/gcid:bulgarian_restaurant"
    },
    {
        "displayName": "Plaza de toros",
        "name": "categories/gcid:bullring"
    },
    {
        "displayName": "Centro de salto en bungee",
        "name": "categories/gcid:bungee_jumping_center"
    },
    {
        "displayName": "Tienda de alarmas contra robo",
        "name": "categories/gcid:burglar_alarm_store"
    },
    {
        "displayName": "Restaurante birmano",
        "name": "categories/gcid:burmese_restaurant"
    },
    {
        "displayName": "Restaurante de burritos",
        "name": "categories/gcid:burrito_restaurant"
    },
    {
        "displayName": "Bus and coach company",
        "name": "categories/gcid:bus_and_coach_company"
    },
    {
        "displayName": "Fletador de autobuses",
        "name": "categories/gcid:bus_charter"
    },
    {
        "displayName": "Empresa de autobuses",
        "name": "categories/gcid:bus_company"
    },
    {
        "displayName": "Almacén de autobuses",
        "name": "categories/gcid:bus_depot"
    },
    {
        "displayName": "Agencia expendedora de boletos de autobús",
        "name": "categories/gcid:bus_ticket_agency"
    },
    {
        "displayName": "Agencia de turismo en autobús",
        "name": "categories/gcid:bus_tour_agency"
    },
    {
        "displayName": "Servicio de administración de empresas",
        "name": "categories/gcid:business_administration_service"
    },
    {
        "displayName": "Abogado empresarial",
        "name": "categories/gcid:business_attorney"
    },
    {
        "displayName": "Servicio de banca empresarial",
        "name": "categories/gcid:business_banking_service"
    },
    {
        "displayName": "Agente de negocios",
        "name": "categories/gcid:business_broker"
    },
    {
        "displayName": "Centro empresarial",
        "name": "categories/gcid:business_center"
    },
    {
        "displayName": "Servicio de desarrollo de negocios",
        "name": "categories/gcid:business_development_service"
    },
    {
        "displayName": "Asesor en administración de empresas",
        "name": "categories/gcid:business_management_consultant"
    },
    {
        "displayName": "Empresa de networking empresarial",
        "name": "categories/gcid:business_networking_company"
    },
    {
        "displayName": "Parque empresarial",
        "name": "categories/gcid:business_park"
    },
    {
        "displayName": "Escuela de negocios",
        "name": "categories/gcid:business_school"
    },
    {
        "displayName": "Servicio de empresa a empresa",
        "name": "categories/gcid:business_to_business_service"
    },
    {
        "displayName": "Distribuidora de gas butano",
        "name": "categories/gcid:butane_gas_supplier"
    },
    {
        "displayName": "Carnicería",
        "name": "categories/gcid:butcher_shop"
    },
    {
        "displayName": "Charcutería",
        "name": "categories/gcid:butcher_shop_deli"
    },
    {
        "displayName": "Tienda de butsudanes",
        "name": "categories/gcid:butsudan_store"
    },
    {
        "displayName": "Cabaré",
        "name": "categories/gcid:cabaret_club"
    },
    {
        "displayName": "Agencia de alquiler de cabañas",
        "name": "categories/gcid:cabin_rental_agency"
    },
    {
        "displayName": "Ebanista",
        "name": "categories/gcid:cabinet_maker"
    },
    {
        "displayName": "Tienda de armarios",
        "name": "categories/gcid:cabinet_store"
    },
    {
        "displayName": "Compañía de cable",
        "name": "categories/gcid:cable_company"
    },
    {
        "displayName": "Concesionario Cadillac",
        "name": "categories/gcid:cadillac_dealer"
    },
    {
        "displayName": "Cafetería",
        "name": "categories/gcid:cafe"
    },
    {
        "displayName": "Cafetería",
        "name": "categories/gcid:cafeteria"
    },
    {
        "displayName": "Restaurante de cocina de Cajún",
        "name": "categories/gcid:cajun_restaurant"
    },
    {
        "displayName": "Tienda de artículos para decoración de pasteles",
        "name": "categories/gcid:cake_decorating_equipment_shop"
    },
    {
        "displayName": "Pastelería",
        "name": "categories/gcid:cake_shop"
    },
    {
        "displayName": "Restaurante de cocina californiana",
        "name": "categories/gcid:californian_restaurant"
    },
    {
        "displayName": "Centro de atención telefónica",
        "name": "categories/gcid:call_center"
    },
    {
        "displayName": "Locutorio",
        "name": "categories/gcid:call_shop"
    },
    {
        "displayName": "Clases de caligrafía",
        "name": "categories/gcid:calligraphy_lesson"
    },
    {
        "displayName": "Iglesia Cavalry Chapel",
        "name": "categories/gcid:calvary_chapel_church"
    },
    {
        "displayName": "Restaurante camboyano",
        "name": "categories/gcid:cambodian_restaurant"
    },
    {
        "displayName": "Taller de reparación de cámaras",
        "name": "categories/gcid:camera_repair_shop"
    },
    {
        "displayName": "Tienda de cámaras",
        "name": "categories/gcid:camera_store"
    },
    {
        "displayName": "Campamento de verano para niños",
        "name": "categories/gcid:camp"
    },
    {
        "displayName": "Proveedor de camionetas con camper",
        "name": "categories/gcid:camper_shell_supplier"
    },
    {
        "displayName": "Terreno para acampar",
        "name": "categories/gcid:campground"
    },
    {
        "displayName": "Cabaña para acampar",
        "name": "categories/gcid:camping_cabin"
    },
    {
        "displayName": "Camping farm",
        "name": "categories/gcid:camping_farm"
    },
    {
        "displayName": "Tienda de artículos para campamento",
        "name": "categories/gcid:camping_store"
    },
    {
        "displayName": "Restaurante de cocina del Noroeste Pacífico (Canadá)",
        "name": "categories/gcid:canadian_pacific_northwest_restaurant"
    },
    {
        "displayName": "Restaurante de cocina canadiense",
        "name": "categories/gcid:canadian_restaurant"
    },
    {
        "displayName": "Centro de tratamiento del cáncer",
        "name": "categories/gcid:cancer_treatment_center"
    },
    {
        "displayName": "Tienda de velas",
        "name": "categories/gcid:candle_store"
    },
    {
        "displayName": "Dulcería",
        "name": "categories/gcid:candy_store"
    },
    {
        "displayName": "Tienda de muebles de mimbre",
        "name": "categories/gcid:cane_furniture_store"
    },
    {
        "displayName": "Club de cannabis",
        "name": "categories/gcid:cannabis_club"
    },
    {
        "displayName": "Tienda de Cannabis",
        "name": "categories/gcid:cannabis_store"
    },
    {
        "displayName": "Fábrica de conservas en lata",
        "name": "categories/gcid:cannery"
    },
    {
        "displayName": "Club de canotaje y kayakismo",
        "name": "categories/gcid:canoe_and_kayak_club"
    },
    {
        "displayName": "Servicio de alquiler de canoas y kayaks",
        "name": "categories/gcid:canoe_and_kayak_rental_service"
    },
    {
        "displayName": "Tienda de canoas y kayak",
        "name": "categories/gcid:canoe_and_kayak_store"
    },
    {
        "displayName": "Agencia de turismo en canoas y kayak",
        "name": "categories/gcid:canoe_and_kayak_tour_agency"
    },
    {
        "displayName": "Área de canotaje",
        "name": "categories/gcid:canoeing_area"
    },
    {
        "displayName": "Restaurante de cocina cantábrica",
        "name": "categories/gcid:cantabrian_restaurant"
    },
    {
        "displayName": "Restaurante cantonés",
        "name": "categories/gcid:cantonese_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Cabo Verde",
        "name": "categories/gcid:cape_verdean_restaurant"
    },
    {
        "displayName": "Academia de capoeira",
        "name": "categories/gcid:capoeira_school"
    },
    {
        "displayName": "Hotel cápsula",
        "name": "categories/gcid:capsule_hotel"
    },
    {
        "displayName": "Tienda de accesorios para autos",
        "name": "categories/gcid:car_accessories_store"
    },
    {
        "displayName": "Proveedor de alarmas para automóvil",
        "name": "categories/gcid:car_alarm_supplier"
    },
    {
        "displayName": "Tienda de baterías para automóviles",
        "name": "categories/gcid:car_battery_store"
    },
    {
        "displayName": "Concesionario de autos",
        "name": "categories/gcid:car_dealer"
    },
    {
        "displayName": "Servicio de limpieza completa de automóviles",
        "name": "categories/gcid:car_detailing_service"
    },
    {
        "displayName": "Fábrica de automóviles",
        "name": "categories/gcid:car_factory"
    },
    {
        "displayName": "Car finance and loan company",
        "name": "categories/gcid:car_finance_and_loan_company"
    },
    {
        "displayName": "Estación de inspección de automóviles",
        "name": "categories/gcid:car_inspection_station"
    },
    {
        "displayName": "Servicio de alquiler de automóviles",
        "name": "categories/gcid:car_leasing_service"
    },
    {
        "displayName": "Fabricante de automóviles",
        "name": "categories/gcid:car_manufacturer"
    },
    {
        "displayName": "Pista de carreras de automóviles",
        "name": "categories/gcid:car_race_track"
    },
    {
        "displayName": "Agencia de alquiler de automóviles",
        "name": "categories/gcid:car_rental_agency"
    },
    {
        "displayName": "Mantenimiento y reparación de vehículos",
        "name": "categories/gcid:car_repair"
    },
    {
        "displayName": "Instalador de sistemas de seguridad para automóviles",
        "name": "categories/gcid:car_security_system_installer"
    },
    {
        "displayName": "Servicio de automóvil",
        "name": "categories/gcid:car_service"
    },
    {
        "displayName": "Ubicación para compartir automóviles",
        "name": "categories/gcid:car_sharing_location"
    },
    {
        "displayName": "Tienda de estéreos para automóviles",
        "name": "categories/gcid:car_stereo_store"
    },
    {
        "displayName": "Lavado de autos",
        "name": "categories/gcid:car_wash"
    },
    {
        "displayName": "Carabinieri police",
        "name": "categories/gcid:carabinieri_police"
    },
    {
        "displayName": "Cardiólogo",
        "name": "categories/gcid:cardiologist"
    },
    {
        "displayName": "Cirujano cardiotorácico",
        "name": "categories/gcid:cardiovascular_and_thoracic_surgeon"
    },
    {
        "displayName": "Servicio de orientación vocacional",
        "name": "categories/gcid:career_guidance_service"
    },
    {
        "displayName": "Restaurante de cocina caribeña",
        "name": "categories/gcid:caribbean_restaurant"
    },
    {
        "displayName": "Club de festejos de carnaval",
        "name": "categories/gcid:carnival_club"
    },
    {
        "displayName": "Carpintero",
        "name": "categories/gcid:carpenter"
    },
    {
        "displayName": "Servicio de limpieza de alfombras",
        "name": "categories/gcid:carpet_cleaning_service"
    },
    {
        "displayName": "Instalador de alfombras",
        "name": "categories/gcid:carpet_installer"
    },
    {
        "displayName": "Fabricante de alfombras",
        "name": "categories/gcid:carpet_manufacturer"
    },
    {
        "displayName": "Tienda de alfombras",
        "name": "categories/gcid:carpet_store"
    },
    {
        "displayName": "Mayorista de alfombras",
        "name": "categories/gcid:carpet_wholesaler"
    },
    {
        "displayName": "Servicio de autos compartidos",
        "name": "categories/gcid:carpool"
    },
    {
        "displayName": "Constructor de porches y pérgolas",
        "name": "categories/gcid:carport_and_pergola_builder"
    },
    {
        "displayName": "Servicio de paseo en carruaje",
        "name": "categories/gcid:carriage_ride_service"
    },
    {
        "displayName": "Restaurante de carnes asadas",
        "name": "categories/gcid:carvery_restaurant"
    },
    {
        "displayName": "Mayorista cash and carry",
        "name": "categories/gcid:cash_and_carry_wholesaler"
    },
    {
        "displayName": "Casino",
        "name": "categories/gcid:casino"
    },
    {
        "displayName": "Servicio de ataúdes",
        "name": "categories/gcid:casket_service"
    },
    {
        "displayName": "Restaurante de cocina de Castilla",
        "name": "categories/gcid:castilian_restaurant"
    },
    {
        "displayName": "Castillo",
        "name": "categories/gcid:castle"
    },
    {
        "displayName": "Restaurante especializado en syokudo y teishoku",
        "name": "categories/gcid:casual_japanese_style_restaurant"
    },
    {
        "displayName": "Restaurante de sushi con cinta transportadora",
        "name": "categories/gcid:casual_sushi_restaurant"
    },
    {
        "displayName": "Servicio de guardería para gatos",
        "name": "categories/gcid:cat_boarding_service"
    },
    {
        "displayName": "Criador de gatos",
        "name": "categories/gcid:cat_breeder"
    },
    {
        "displayName": "Café de gatos",
        "name": "categories/gcid:cat_cafe"
    },
    {
        "displayName": "Entrenador de gatos",
        "name": "categories/gcid:cat_trainer"
    },
    {
        "displayName": "Restaurante catalán",
        "name": "categories/gcid:catalonian_restaurant"
    },
    {
        "displayName": "Proveedor mayorista de alimentos y bebidas",
        "name": "categories/gcid:catering_food_and_drink_supplies"
    },
    {
        "displayName": "Servicio de catering",
        "name": "categories/gcid:catering_service"
    },
    {
        "displayName": "Catedral",
        "name": "categories/gcid:cathedral"
    },
    {
        "displayName": "Catedral católica",
        "name": "categories/gcid:catholic_cathedral"
    },
    {
        "displayName": "Iglesia católica",
        "name": "categories/gcid:catholic_church"
    },
    {
        "displayName": "Escuela católica",
        "name": "categories/gcid:catholic_school"
    },
    {
        "displayName": "Hotel para gatos",
        "name": "categories/gcid:cattery"
    },
    {
        "displayName": "Explotación ganadera",
        "name": "categories/gcid:cattle_farm"
    },
    {
        "displayName": "Mercado de ganado",
        "name": "categories/gcid:cattle_market"
    },
    {
        "displayName": "Restaurante caucásico",
        "name": "categories/gcid:caucasian_restaurant"
    },
    {
        "displayName": "Escuela CBSE",
        "name": "categories/gcid:cbse_school"
    },
    {
        "displayName": "Tienda de CD",
        "name": "categories/gcid:cd_store"
    },
    {
        "displayName": "Distribuidor de cielorrasos",
        "name": "categories/gcid:ceiling_supplier"
    },
    {
        "displayName": "Tienda de accesorios para teléfonos celulares",
        "name": "categories/gcid:cell_phone_accessory_store"
    },
    {
        "displayName": "Estación de carga de teléfonos celulares",
        "name": "categories/gcid:cell_phone_charging_station"
    },
    {
        "displayName": "Tienda de celulares",
        "name": "categories/gcid:cell_phone_store"
    },
    {
        "displayName": "Fabricante de cemento",
        "name": "categories/gcid:cement_manufacturer"
    },
    {
        "displayName": "Proveedor de cemento",
        "name": "categories/gcid:cement_supplier"
    },
    {
        "displayName": "Cementerio",
        "name": "categories/gcid:cemetery"
    },
    {
        "displayName": "Restaurante de cendol",
        "name": "categories/gcid:cendol_restaurant"
    },
    {
        "displayName": "Restaurante de comida centroamericana",
        "name": "categories/gcid:central_american_restaurant"
    },
    {
        "displayName": "Central authority",
        "name": "categories/gcid:central_authority"
    },
    {
        "displayName": "Banco central",
        "name": "categories/gcid:central_bank"
    },
    {
        "displayName": "Restaurante centroeuropeo",
        "name": "categories/gcid:central_european_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Java\\xa0Central",
        "name": "categories/gcid:central_javanese_restaurant"
    },
    {
        "displayName": "Fabricante de productos de cerámica",
        "name": "categories/gcid:ceramic_manufacturer"
    },
    {
        "displayName": "Mayorista de cerámicas",
        "name": "categories/gcid:ceramics_wholesaler"
    },
    {
        "displayName": "Agencia de certificación",
        "name": "categories/gcid:certification_agency"
    },
    {
        "displayName": "Contador público certificado",
        "name": "categories/gcid:certified_public_accountant"
    },
    {
        "displayName": "Cabaña",
        "name": "categories/gcid:chalet"
    },
    {
        "displayName": "Cámara agraria",
        "name": "categories/gcid:chamber_of_agriculture"
    },
    {
        "displayName": "Cámara de Comercio",
        "name": "categories/gcid:chamber_of_commerce"
    },
    {
        "displayName": "Cámara de artesanos",
        "name": "categories/gcid:chamber_of_handicrafts"
    },
    {
        "displayName": "Restaurante especializado en sopa de fideos champon",
        "name": "categories/gcid:champon_noodle_restaurant"
    },
    {
        "displayName": "Restaurante de chanko",
        "name": "categories/gcid:chankonabe_restaurant"
    },
    {
        "displayName": "Capilla",
        "name": "categories/gcid:chapel"
    },
    {
        "displayName": "Charcutería",
        "name": "categories/gcid:charcuterie"
    },
    {
        "displayName": "Caridad",
        "name": "categories/gcid:charity"
    },
    {
        "displayName": "Escuela autónoma",
        "name": "categories/gcid:charter_school"
    },
    {
        "displayName": "Contador público",
        "name": "categories/gcid:chartered_accountant"
    },
    {
        "displayName": "Servicio de cambio de cheques",
        "name": "categories/gcid:check_cashing_service"
    },
    {
        "displayName": "Fabricante de queso",
        "name": "categories/gcid:cheese_manufacturer"
    },
    {
        "displayName": "Tienda de quesos",
        "name": "categories/gcid:cheese_shop"
    },
    {
        "displayName": "Restaurante de sándwiches de churrasco con queso",
        "name": "categories/gcid:cheesesteak_restaurant"
    },
    {
        "displayName": "Servicio de ingeniería química",
        "name": "categories/gcid:chemical_engineer"
    },
    {
        "displayName": "Exportador de productos químicos",
        "name": "categories/gcid:chemical_exporter"
    },
    {
        "displayName": "Fabricante de productos químicos",
        "name": "categories/gcid:chemical_manufacturer"
    },
    {
        "displayName": "Planta química",
        "name": "categories/gcid:chemical_plant"
    },
    {
        "displayName": "Mayorista de productos químicos",
        "name": "categories/gcid:chemical_wholesaler"
    },
    {
        "displayName": "Facultad de Química",
        "name": "categories/gcid:chemistry_faculty"
    },
    {
        "displayName": "Laboratorio químico",
        "name": "categories/gcid:chemistry_lab"
    },
    {
        "displayName": "Restaurante de cocina de Chesapeake",
        "name": "categories/gcid:chesapeake_restaurant"
    },
    {
        "displayName": "Club de ajedrez y cartas",
        "name": "categories/gcid:chess_and_card_club"
    },
    {
        "displayName": "Club de ajedrez",
        "name": "categories/gcid:chess_club"
    },
    {
        "displayName": "Instructor de ajedrez",
        "name": "categories/gcid:chess_instructor"
    },
    {
        "displayName": "Restaurante de cocina de Chettinad",
        "name": "categories/gcid:chettinad_restaurtant"
    },
    {
        "displayName": "Concesionario Chevrolet",
        "name": "categories/gcid:chevrolet_dealer"
    },
    {
        "displayName": "Chicken hatchery",
        "name": "categories/gcid:chicken_hatchery"
    },
    {
        "displayName": "Restaurante de pollo",
        "name": "categories/gcid:chicken_restaurant"
    },
    {
        "displayName": "Tienda de comidas con pollo",
        "name": "categories/gcid:chicken_shop"
    },
    {
        "displayName": "Restaurante Chicken Wings",
        "name": "categories/gcid:chicken_wings_restaurant"
    },
    {
        "displayName": "Guardería",
        "name": "categories/gcid:child_care_agency"
    },
    {
        "displayName": "Centro de atención médica infantil",
        "name": "categories/gcid:child_health_care_centre"
    },
    {
        "displayName": "Psiquiatra infantil",
        "name": "categories/gcid:child_psychiatrist"
    },
    {
        "displayName": "Psicólogo infantil",
        "name": "categories/gcid:child_psychologist"
    },
    {
        "displayName": "Clase de preparación para el parto",
        "name": "categories/gcid:childbirth_class"
    },
    {
        "displayName": "Centro de entretenimiento infantil",
        "name": "categories/gcid:children_amusement_center"
    },
    {
        "displayName": "Sala para niños",
        "name": "categories/gcid:children_hall"
    },
    {
        "displayName": "Children Policlinic",
        "name": "categories/gcid:children_policlinic"
    },
    {
        "displayName": "Librería infantil",
        "name": "categories/gcid:childrens_book_store"
    },
    {
        "displayName": "Café para niños",
        "name": "categories/gcid:childrens_cafe"
    },
    {
        "displayName": "Tienda de ropa infantil",
        "name": "categories/gcid:childrens_clothing_store"
    },
    {
        "displayName": "Club infantil",
        "name": "categories/gcid:childrens_club"
    },
    {
        "displayName": "Granja infantil",
        "name": "categories/gcid:childrens_farm"
    },
    {
        "displayName": "Tienda de mobiliario infantil",
        "name": "categories/gcid:childrens_furniture_store"
    },
    {
        "displayName": "Hogar de niños",
        "name": "categories/gcid:childrens_home"
    },
    {
        "displayName": "Hospital de niños",
        "name": "categories/gcid:childrens_hospital"
    },
    {
        "displayName": "Biblioteca infantil",
        "name": "categories/gcid:childrens_library"
    },
    {
        "displayName": "Museo para niños",
        "name": "categories/gcid:childrens_museum"
    },
    {
        "displayName": "Comida para fiestas infantiles",
        "name": "categories/gcid:childrens_party_buffet"
    },
    {
        "displayName": "Servicio de fiestas infantiles",
        "name": "categories/gcid:childrens_party_service"
    },
    {
        "displayName": "Tienda infantil",
        "name": "categories/gcid:childrens_store"
    },
    {
        "displayName": "Teatro infantil",
        "name": "categories/gcid:childrens_theater"
    },
    {
        "displayName": "Restaurante chileno",
        "name": "categories/gcid:chilean_restaurant"
    },
    {
        "displayName": "Servicios para chimeneas",
        "name": "categories/gcid:chimney_services"
    },
    {
        "displayName": "Limpieza de chimeneas",
        "name": "categories/gcid:chimney_sweep"
    },
    {
        "displayName": "Tienda de artículos de porcelana",
        "name": "categories/gcid:chinaware_store"
    },
    {
        "displayName": "Profesor de chino",
        "name": "categories/gcid:chinese_language_instructor"
    },
    {
        "displayName": "Escuela de chino",
        "name": "categories/gcid:chinese_language_school"
    },
    {
        "displayName": "Clínica de medicina china",
        "name": "categories/gcid:chinese_medicine_clinic"
    },
    {
        "displayName": "Tienda de medicina china",
        "name": "categories/gcid:chinese_medicine_store"
    },
    {
        "displayName": "Restaurante especializado en fideos chinos",
        "name": "categories/gcid:chinese_noodle_restaurant"
    },
    {
        "displayName": "Pastelería china",
        "name": "categories/gcid:chinese_pastry"
    },
    {
        "displayName": "Restaurante chino",
        "name": "categories/gcid:chinese_restaurant"
    },
    {
        "displayName": "Supermercado chino",
        "name": "categories/gcid:chinese_supermarket"
    },
    {
        "displayName": "Restaurante de comida china para llevar",
        "name": "categories/gcid:chinese_takeaway"
    },
    {
        "displayName": "Chinese tea house",
        "name": "categories/gcid:chinese_tea_house"
    },
    {
        "displayName": "Quiropráctico",
        "name": "categories/gcid:chiropractor"
    },
    {
        "displayName": "Chocolate artesanal",
        "name": "categories/gcid:chocolate_artisan"
    },
    {
        "displayName": "Chocolatería",
        "name": "categories/gcid:chocolate_cafe"
    },
    {
        "displayName": "Fábrica de chocolate",
        "name": "categories/gcid:chocolate_factory"
    },
    {
        "displayName": "Tienda de chocolate",
        "name": "categories/gcid:chocolate_shop"
    },
    {
        "displayName": "Coro",
        "name": "categories/gcid:choir"
    },
    {
        "displayName": "Chop bar",
        "name": "categories/gcid:chop_bar"
    },
    {
        "displayName": "Asador de carnes",
        "name": "categories/gcid:chophouse_restaurant"
    },
    {
        "displayName": "Librería cristiana",
        "name": "categories/gcid:christian_book_store"
    },
    {
        "displayName": "Iglesia cristiana",
        "name": "categories/gcid:christian_church"
    },
    {
        "displayName": "Institución educativa cristiana",
        "name": "categories/gcid:christian_college"
    },
    {
        "displayName": "Mercado de Navidad",
        "name": "categories/gcid:christmas_market"
    },
    {
        "displayName": "Tienda de artículos navideños",
        "name": "categories/gcid:christmas_store"
    },
    {
        "displayName": "Granja de árboles navideños",
        "name": "categories/gcid:christmas_tree_farm"
    },
    {
        "displayName": "Concesionario Chrysler",
        "name": "categories/gcid:chrysler_dealer"
    },
    {
        "displayName": "Iglesia",
        "name": "categories/gcid:church"
    },
    {
        "displayName": "Iglesia de Cristo",
        "name": "categories/gcid:church_of_christ"
    },
    {
        "displayName": "La Iglesia de Jesucristo de los Santos de los Últimos Días",
        "name": "categories/gcid:church_of_jesus_christ_of_latter_day_saints"
    },
    {
        "displayName": "Iglesia del Nazareno",
        "name": "categories/gcid:church_of_the_nazarene"
    },
    {
        "displayName": "Tienda de insumos para iglesias",
        "name": "categories/gcid:church_supply_store"
    },
    {
        "displayName": "Churrería",
        "name": "categories/gcid:churreria"
    },
    {
        "displayName": "Bar de sidra",
        "name": "categories/gcid:cider_bar"
    },
    {
        "displayName": "Fábrica de sidra",
        "name": "categories/gcid:cider_mill"
    },
    {
        "displayName": "Restaurante de çiğ köfte",
        "name": "categories/gcid:cig_kofte_restaurant"
    },
    {
        "displayName": "Tienda de cigarros",
        "name": "categories/gcid:cigar_shop"
    },
    {
        "displayName": "Proveedor de equipos audiovisuales de cine",
        "name": "categories/gcid:cinema_equipment_supplier"
    },
    {
        "displayName": "Circo",
        "name": "categories/gcid:circus"
    },
    {
        "displayName": "Oficina de información ciudadana",
        "name": "categories/gcid:citizen_information_bureau"
    },
    {
        "displayName": "Citizens advice bureau",
        "name": "categories/gcid:citizens_advice_bureau"
    },
    {
        "displayName": "Concesionario de Citroen",
        "name": "categories/gcid:citroen_dealer"
    },
    {
        "displayName": "City administration",
        "name": "categories/gcid:city_administration"
    },
    {
        "displayName": "Secretaría municipal",
        "name": "categories/gcid:city_clerks_office"
    },
    {
        "displayName": "Tribunales de la ciudad",
        "name": "categories/gcid:city_courthouse"
    },
    {
        "displayName": "City Department of Environment",
        "name": "categories/gcid:city_department_of_environment"
    },
    {
        "displayName": "City Department of Public Safety",
        "name": "categories/gcid:city_department_of_public_safety"
    },
    {
        "displayName": "Departamento de transporte de la ciudad",
        "name": "categories/gcid:city_department_of_transportation"
    },
    {
        "displayName": "Oficina del distrito municipal",
        "name": "categories/gcid:city_district_office"
    },
    {
        "displayName": "Departamento de empleo de la ciudad",
        "name": "categories/gcid:city_employment_department"
    },
    {
        "displayName": "Oficina administrativa de la ciudad",
        "name": "categories/gcid:city_government_office"
    },
    {
        "displayName": "Municipalidad",
        "name": "categories/gcid:city_hall"
    },
    {
        "displayName": "Parque de la ciudad",
        "name": "categories/gcid:city_park"
    },
    {
        "displayName": "Santuario de pilares de la ciudad",
        "name": "categories/gcid:city_pillar_shine"
    },
    {
        "displayName": "Oficina de impuestos",
        "name": "categories/gcid:city_tax_office"
    },
    {
        "displayName": "Centro cívico",
        "name": "categories/gcid:civic_center"
    },
    {
        "displayName": "Defensa Civil",
        "name": "categories/gcid:civil_defence"
    },
    {
        "displayName": "Ingeniero civil",
        "name": "categories/gcid:civil_engineer"
    },
    {
        "displayName": "Empresa de ingeniería civil",
        "name": "categories/gcid:civil_engineering_company"
    },
    {
        "displayName": "Academia de evaluación para servicios civiles",
        "name": "categories/gcid:civil_examinations_academy"
    },
    {
        "displayName": "Abogado civil",
        "name": "categories/gcid:civil_law_attorney"
    },
    {
        "displayName": "Policía civil",
        "name": "categories/gcid:civil_police"
    },
    {
        "displayName": "Registro civil",
        "name": "categories/gcid:civil_registry"
    },
    {
        "displayName": "Editorial de anuncios clasificados",
        "name": "categories/gcid:classified_ads_newspaper_publisher"
    },
    {
        "displayName": "Personal de limpieza",
        "name": "categories/gcid:cleaners"
    },
    {
        "displayName": "Proveedor de productos de limpieza",
        "name": "categories/gcid:cleaning_products_supplier"
    },
    {
        "displayName": "Clérigo",
        "name": "categories/gcid:clergyman"
    },
    {
        "displayName": "Servicio de reparación de relojes",
        "name": "categories/gcid:clock_repair_service"
    },
    {
        "displayName": "Fabricante de relojes",
        "name": "categories/gcid:clock_watch_maker"
    },
    {
        "displayName": "Televisión por circuito cerrado",
        "name": "categories/gcid:closed_circuit_television"
    },
    {
        "displayName": "Fábrica de ropa y textiles",
        "name": "categories/gcid:clothes_and_fabric_manufacturer"
    },
    {
        "displayName": "Mayorista de ropa y telas",
        "name": "categories/gcid:clothes_and_fabric_wholesaler"
    },
    {
        "displayName": "Mercado de ropas",
        "name": "categories/gcid:clothes_market"
    },
    {
        "displayName": "Servicio de sastrería",
        "name": "categories/gcid:clothing_alteration_service"
    },
    {
        "displayName": "Tienda de ropas",
        "name": "categories/gcid:clothing_store"
    },
    {
        "displayName": "Proveedor de ropa",
        "name": "categories/gcid:clothing_supplier"
    },
    {
        "displayName": "Tienda mayorista de ropas",
        "name": "categories/gcid:clothing_wholesale_market_place"
    },
    {
        "displayName": "Tienda de ropas mayorista",
        "name": "categories/gcid:clothing_wholesaler"
    },
    {
        "displayName": "Club",
        "name": "categories/gcid:club"
    },
    {
        "displayName": "Centro de montaje CNG",
        "name": "categories/gcid:cng_fittment_center"
    },
    {
        "displayName": "Estación de gas natural comprimido",
        "name": "categories/gcid:cng_station"
    },
    {
        "displayName": "Centro de coaching",
        "name": "categories/gcid:coaching_center"
    },
    {
        "displayName": "Exportador de carbón",
        "name": "categories/gcid:coal_exporter"
    },
    {
        "displayName": "Proveedor de carbón",
        "name": "categories/gcid:coal_supplier"
    },
    {
        "displayName": "Yacimiento de carbón",
        "name": "categories/gcid:coalfield"
    },
    {
        "displayName": "Estación de prefectura",
        "name": "categories/gcid:coast_guard_station"
    },
    {
        "displayName": "Coat wholesaler",
        "name": "categories/gcid:coat_wholesaler"
    },
    {
        "displayName": "Bar de cócteles",
        "name": "categories/gcid:cocktail_bar"
    },
    {
        "displayName": "Escuela mixta",
        "name": "categories/gcid:coed_school"
    },
    {
        "displayName": "Proveedor de cafeteras",
        "name": "categories/gcid:coffee_machine_supplier"
    },
    {
        "displayName": "Tostadores de café",
        "name": "categories/gcid:coffee_roasters"
    },
    {
        "displayName": "Cafetería",
        "name": "categories/gcid:coffee_shop"
    },
    {
        "displayName": "Puesto de venta de café",
        "name": "categories/gcid:coffee_stand"
    },
    {
        "displayName": "Cafetería",
        "name": "categories/gcid:coffee_store"
    },
    {
        "displayName": "Máquina expendedora de café",
        "name": "categories/gcid:coffee_vending_machine"
    },
    {
        "displayName": "Proveedor mayorista de café",
        "name": "categories/gcid:coffee_wholesaler"
    },
    {
        "displayName": "Proveedor de ataudes",
        "name": "categories/gcid:coffin_supplier"
    },
    {
        "displayName": "Vendedor de monedas",
        "name": "categories/gcid:coin_dealer"
    },
    {
        "displayName": "Proveedor de equipos de lavandería a monedas",
        "name": "categories/gcid:coin_operated_laundry_equipment_supplier"
    },
    {
        "displayName": "Casilleros de almacenamiento a monedas",
        "name": "categories/gcid:coin_operated_locker"
    },
    {
        "displayName": "Fiambrería",
        "name": "categories/gcid:cold_cut_store"
    },
    {
        "displayName": "Restaurante especializado en fideos fríos",
        "name": "categories/gcid:cold_noodle_restaurant"
    },
    {
        "displayName": "Almacén frigorífico",
        "name": "categories/gcid:cold_storage_facility"
    },
    {
        "displayName": "Tienda de artículos de colección",
        "name": "categories/gcid:collectibles_store"
    },
    {
        "displayName": "Universidad",
        "name": "categories/gcid:college"
    },
    {
        "displayName": "Escuela de agricultura",
        "name": "categories/gcid:college_of_agriculture"
    },
    {
        "displayName": "Restaurante colombiano",
        "name": "categories/gcid:colombian_restaurant"
    },
    {
        "displayName": "Escuela primaria y secundaria",
        "name": "categories/gcid:combined_primary_and_secondary_school"
    },
    {
        "displayName": "Club de cómicos",
        "name": "categories/gcid:comedy_club"
    },
    {
        "displayName": "Tienda de cómics",
        "name": "categories/gcid:comic_book_store"
    },
    {
        "displayName": "Cafetería especializada en cómics",
        "name": "categories/gcid:comic_cafe"
    },
    {
        "displayName": "Representante comercial",
        "name": "categories/gcid:commercial_agent"
    },
    {
        "displayName": "Commercial cleaning service",
        "name": "categories/gcid:commercial_cleaning_service"
    },
    {
        "displayName": "Impresora comercial",
        "name": "categories/gcid:commercial_printer"
    },
    {
        "displayName": "Agencia de bienes raíces comerciales",
        "name": "categories/gcid:commercial_real_estate_agency"
    },
    {
        "displayName": "Inspector de bienes raíces comerciales",
        "name": "categories/gcid:commercial_real_estate_inspector"
    },
    {
        "displayName": "Commercial refrigeration",
        "name": "categories/gcid:commercial_refrigeration"
    },
    {
        "displayName": "Proveedor de refrigeradores comerciales",
        "name": "categories/gcid:commercial_refrigerator_supplier"
    },
    {
        "displayName": "Commissioner for Oaths",
        "name": "categories/gcid:commissioner_for_oaths"
    },
    {
        "displayName": "Centro comunitario",
        "name": "categories/gcid:community_center"
    },
    {
        "displayName": "Institución de educación superior",
        "name": "categories/gcid:community_college"
    },
    {
        "displayName": "Jardín comunitario",
        "name": "categories/gcid:community_garden"
    },
    {
        "displayName": "Centro de salud comunitario",
        "name": "categories/gcid:community_health_center"
    },
    {
        "displayName": "Escuela comunitaria",
        "name": "categories/gcid:community_school"
    },
    {
        "displayName": "Registro mercantil",
        "name": "categories/gcid:company_registry"
    },
    {
        "displayName": "Escuela comprensiva",
        "name": "categories/gcid:comprehensive_secondary_school"
    },
    {
        "displayName": "Tienda de accesorios para computadoras",
        "name": "categories/gcid:computer_accessories_store"
    },
    {
        "displayName": "Club de informática",
        "name": "categories/gcid:computer_club"
    },
    {
        "displayName": "Consultor informático",
        "name": "categories/gcid:computer_consultant"
    },
    {
        "displayName": "Computer desk store",
        "name": "categories/gcid:computer_desk_store"
    },
    {
        "displayName": "Fabricante de hardware de computadoras",
        "name": "categories/gcid:computer_hardware_manufacturer"
    },
    {
        "displayName": "Centro de redes informáticas",
        "name": "categories/gcid:computer_networking_center"
    },
    {
        "displayName": "Servicio de reparación de computadoras",
        "name": "categories/gcid:computer_repair_service"
    },
    {
        "displayName": "Servicio de seguridad para computadoras",
        "name": "categories/gcid:computer_security_service"
    },
    {
        "displayName": "Servicio de reparación de computadoras",
        "name": "categories/gcid:computer_service"
    },
    {
        "displayName": "Tienda de software",
        "name": "categories/gcid:computer_software_store"
    },
    {
        "displayName": "Tienda de informática",
        "name": "categories/gcid:computer_store"
    },
    {
        "displayName": "Asistencia y servicios informáticos",
        "name": "categories/gcid:computer_support_and_services"
    },
    {
        "displayName": "Escuela de informática",
        "name": "categories/gcid:computer_training_school"
    },
    {
        "displayName": "Mayorista de computadoras",
        "name": "categories/gcid:computer_wholesaler"
    },
    {
        "displayName": "Sala de conciertos",
        "name": "categories/gcid:concert_hall"
    },
    {
        "displayName": "Contratista de hormigón",
        "name": "categories/gcid:concrete_contractor"
    },
    {
        "displayName": "Fábrica de hormigón",
        "name": "categories/gcid:concrete_factory"
    },
    {
        "displayName": "Proveedor de armaduras metálicas para hormigón",
        "name": "categories/gcid:concrete_metal_framework_supplier"
    },
    {
        "displayName": "Distribuidor de concreto",
        "name": "categories/gcid:concrete_product_supplier"
    },
    {
        "displayName": "Distribuidor de condimentos",
        "name": "categories/gcid:condiments_supplier"
    },
    {
        "displayName": "Complejo de condominio",
        "name": "categories/gcid:condominium_complex"
    },
    {
        "displayName": "Agencia de alquiler de condominios",
        "name": "categories/gcid:condominium_rental_agency"
    },
    {
        "displayName": "Confitería",
        "name": "categories/gcid:confectionery"
    },
    {
        "displayName": "Mayorista de golosinas",
        "name": "categories/gcid:confectionery_wholesaler"
    },
    {
        "displayName": "Centro de conferencias",
        "name": "categories/gcid:conference_center"
    },
    {
        "displayName": "Congregación",
        "name": "categories/gcid:congregation"
    },
    {
        "displayName": "Conservation department",
        "name": "categories/gcid:conservation_department"
    },
    {
        "displayName": "Club conservador",
        "name": "categories/gcid:conservative_club"
    },
    {
        "displayName": "Sinagoga conservadora",
        "name": "categories/gcid:conservative_synagogue"
    },
    {
        "displayName": "Conservatory construction contractor",
        "name": "categories/gcid:conservatory_construction_contractor"
    },
    {
        "displayName": "Conservatorio de música",
        "name": "categories/gcid:conservatory_of_music"
    },
    {
        "displayName": "Conservatory supply & installation",
        "name": "categories/gcid:conservatory_specialist"
    },
    {
        "displayName": "Tienda de segunda mano",
        "name": "categories/gcid:consignment_shop"
    },
    {
        "displayName": "Construction and maintenance office",
        "name": "categories/gcid:construction_and_maintenance_office"
    },
    {
        "displayName": "Empresa de construcción",
        "name": "categories/gcid:construction_company"
    },
    {
        "displayName": "Proveedor de equipos para la construcción",
        "name": "categories/gcid:construction_equipment_supplier"
    },
    {
        "displayName": "Concesionario de maquinaria de construcción",
        "name": "categories/gcid:construction_machine_dealer"
    },
    {
        "displayName": "Servicio de alquiler de maquinaria para construcción",
        "name": "categories/gcid:construction_machine_rental_service"
    },
    {
        "displayName": "Mayorista de materiales de construcción",
        "name": "categories/gcid:construction_material_wholesaler"
    },
    {
        "displayName": "Consultor",
        "name": "categories/gcid:consultant"
    },
    {
        "displayName": "Centro de asesoramiento al consumidor",
        "name": "categories/gcid:consumer_advice_center"
    },
    {
        "displayName": "Proveedor de lentes de contacto",
        "name": "categories/gcid:contact_lenses_supplier"
    },
    {
        "displayName": "Servicio de contenedores",
        "name": "categories/gcid:container_service"
    },
    {
        "displayName": "Proveedor de contenedores",
        "name": "categories/gcid:container_supplier"
    },
    {
        "displayName": "Terminal de contenedores",
        "name": "categories/gcid:container_terminal"
    },
    {
        "displayName": "Proveedor de contenedores",
        "name": "categories/gcid:containers_supplier"
    },
    {
        "displayName": "Restaurante de cocina contemporánea de Louisiana",
        "name": "categories/gcid:contemporary_louisiana_restaurant"
    },
    {
        "displayName": "Restaurante de cocina continental",
        "name": "categories/gcid:continental_restaurant"
    },
    {
        "displayName": "Contratista",
        "name": "categories/gcid:contractor"
    },
    {
        "displayName": "Tienda de conveniencia",
        "name": "categories/gcid:convenience_store"
    },
    {
        "displayName": "Centro de convenciones",
        "name": "categories/gcid:convention_center"
    },
    {
        "displayName": "Convention information bureau",
        "name": "categories/gcid:convention_information_bureau"
    },
    {
        "displayName": "Abogado especializado en transacciones inmobiliarias",
        "name": "categories/gcid:conveyancer"
    },
    {
        "displayName": "Tienda de galletas",
        "name": "categories/gcid:cookie_shop"
    },
    {
        "displayName": "Curso de cocina",
        "name": "categories/gcid:cooking_class"
    },
    {
        "displayName": "Escuela de cocina",
        "name": "categories/gcid:cooking_school"
    },
    {
        "displayName": "Planta de enfriamiento",
        "name": "categories/gcid:cooling_plant"
    },
    {
        "displayName": "Banco cooperativo",
        "name": "categories/gcid:cooperative_bank"
    },
    {
        "displayName": "Servicio de reparación de fotocopiadoras",
        "name": "categories/gcid:copier_repair_service"
    },
    {
        "displayName": "Proveedor de cobre",
        "name": "categories/gcid:copper_supplier"
    },
    {
        "displayName": "Orfebre de cobre",
        "name": "categories/gcid:coppersmith"
    },
    {
        "displayName": "Fotocopiadora",
        "name": "categories/gcid:copy_shop"
    },
    {
        "displayName": "Tienda de artículos para copiado",
        "name": "categories/gcid:copying_supply_store"
    },
    {
        "displayName": "Servicio de copywriting",
        "name": "categories/gcid:copywriting_service"
    },
    {
        "displayName": "Campus corporativo",
        "name": "categories/gcid:corporate_campus"
    },
    {
        "displayName": "Corporate entertainment service",
        "name": "categories/gcid:corporate_entertainment_service"
    },
    {
        "displayName": "Corporate gift supplier",
        "name": "categories/gcid:corporate_gift_supplier"
    },
    {
        "displayName": "Oficina corporativa",
        "name": "categories/gcid:corporate_office"
    },
    {
        "displayName": "Correctional services department",
        "name": "categories/gcid:correctional_services_department"
    },
    {
        "displayName": "Dentista cosmético",
        "name": "categories/gcid:cosmetic_dentist"
    },
    {
        "displayName": "Fabricante de cosméticos",
        "name": "categories/gcid:cosmetic_products_manufacturer"
    },
    {
        "displayName": "Cirujano cosmético",
        "name": "categories/gcid:cosmetic_surgeon"
    },
    {
        "displayName": "Cosmetics and perfumes supplier",
        "name": "categories/gcid:cosmetics_and_parfumes_supplier"
    },
    {
        "displayName": "Industria cosmética",
        "name": "categories/gcid:cosmetics_industry"
    },
    {
        "displayName": "Tienda de cosméticos",
        "name": "categories/gcid:cosmetics_store"
    },
    {
        "displayName": "Mayorista de cosméticos",
        "name": "categories/gcid:cosmetics_wholesaler"
    },
    {
        "displayName": "Cafetería cosplay",
        "name": "categories/gcid:cosplay_cafe"
    },
    {
        "displayName": "Servicio de contabilidad de costos",
        "name": "categories/gcid:cost_accounting_service"
    },
    {
        "displayName": "Restaurante de cocina de Costa Rica",
        "name": "categories/gcid:costa_rican_restaurant"
    },
    {
        "displayName": "Joyería personalizada",
        "name": "categories/gcid:costume_jewelry_shop"
    },
    {
        "displayName": "Servicio de alquiler de disfraces",
        "name": "categories/gcid:costume_rental_service"
    },
    {
        "displayName": "Tienda de disfraces",
        "name": "categories/gcid:costume_store"
    },
    {
        "displayName": "Cabaña",
        "name": "categories/gcid:cottage"
    },
    {
        "displayName": "Agencia de alquiler de cabañas",
        "name": "categories/gcid:cottage_rental"
    },
    {
        "displayName": "Exportador de algodón",
        "name": "categories/gcid:cotton_exporter"
    },
    {
        "displayName": "Hilandería de algodón",
        "name": "categories/gcid:cotton_mill"
    },
    {
        "displayName": "Proveedor de algodón",
        "name": "categories/gcid:cotton_supplier"
    },
    {
        "displayName": "Municipalidad",
        "name": "categories/gcid:council"
    },
    {
        "displayName": "Consejero",
        "name": "categories/gcid:counselor"
    },
    {
        "displayName": "Countertop contractor",
        "name": "categories/gcid:countertop_contractor"
    },
    {
        "displayName": "Tienda de encimeras",
        "name": "categories/gcid:countertop_store"
    },
    {
        "displayName": "Club de campo",
        "name": "categories/gcid:country_club"
    },
    {
        "displayName": "Restaurante de comida rural",
        "name": "categories/gcid:country_food_restaurant"
    },
    {
        "displayName": "Casa de campo",
        "name": "categories/gcid:country_house"
    },
    {
        "displayName": "Parque rural",
        "name": "categories/gcid:country_park"
    },
    {
        "displayName": "Oficina administrativa del condado",
        "name": "categories/gcid:county_government_office"
    },
    {
        "displayName": "Servicio de courier",
        "name": "categories/gcid:courier_service"
    },
    {
        "displayName": "Funcionario ejecutivo de la corte",
        "name": "categories/gcid:court_executive_officer"
    },
    {
        "displayName": "Taquígrafo judicial",
        "name": "categories/gcid:court_reporter"
    },
    {
        "displayName": "Restaurante especializado en cuscús",
        "name": "categories/gcid:couscous_restaurant"
    },
    {
        "displayName": "Tienda de alta costura",
        "name": "categories/gcid:couture_store"
    },
    {
        "displayName": "Espacio de trabajo compartido",
        "name": "categories/gcid:coworking_space"
    },
    {
        "displayName": "Restaurante de cangrejo",
        "name": "categories/gcid:crab_dish_restaurant"
    },
    {
        "displayName": "Tienda de manualidades",
        "name": "categories/gcid:craft_store"
    },
    {
        "displayName": "Escuela de preparación intensiva para exámenes",
        "name": "categories/gcid:cramming_school"
    },
    {
        "displayName": "Concesionario de grúas",
        "name": "categories/gcid:crane_dealer"
    },
    {
        "displayName": "Agencia de alquiler de grúas",
        "name": "categories/gcid:crane_rental_agency"
    },
    {
        "displayName": "Servicio de grúa",
        "name": "categories/gcid:crane_service"
    },
    {
        "displayName": "Terapia craneosacral",
        "name": "categories/gcid:craniosacral_therapy"
    },
    {
        "displayName": "Creche",
        "name": "categories/gcid:creche"
    },
    {
        "displayName": "Asesoria crediticia",
        "name": "categories/gcid:credit_counseling_service"
    },
    {
        "displayName": "Agencia de informes crediticios",
        "name": "categories/gcid:credit_reporting_agency"
    },
    {
        "displayName": "Unión crediticia",
        "name": "categories/gcid:credit_union"
    },
    {
        "displayName": "Servicio de cremación",
        "name": "categories/gcid:cremation_service"
    },
    {
        "displayName": "Restaurante criollo",
        "name": "categories/gcid:creole_restaurant"
    },
    {
        "displayName": "Crepería",
        "name": "categories/gcid:creperie"
    },
    {
        "displayName": "Club de críquet",
        "name": "categories/gcid:cricket_club"
    },
    {
        "displayName": "Campo de críquet",
        "name": "categories/gcid:cricket_ground"
    },
    {
        "displayName": "Tienda especializada en críquet",
        "name": "categories/gcid:cricket_shop"
    },
    {
        "displayName": "Servicio para víctimas de delitos",
        "name": "categories/gcid:crime_victim_service"
    },
    {
        "displayName": "Abogado penal",
        "name": "categories/gcid:criminal_law_attorney"
    },
    {
        "displayName": "Restaurante croata",
        "name": "categories/gcid:croatian_restaurant"
    },
    {
        "displayName": "Crop grower",
        "name": "categories/gcid:crop_grower"
    },
    {
        "displayName": "Club de cróquet",
        "name": "categories/gcid:croquet_club"
    },
    {
        "displayName": "Agencia de cruceros",
        "name": "categories/gcid:cruise_agency"
    },
    {
        "displayName": "Empresa de cruceros",
        "name": "categories/gcid:cruise_line"
    },
    {
        "displayName": "Terminal de cruceros",
        "name": "categories/gcid:cruise_terminal"
    },
    {
        "displayName": "Crushed stone supplier",
        "name": "categories/gcid:crushed_stone_supplier"
    },
    {
        "displayName": "Cajero automático de criptomonedas",
        "name": "categories/gcid:crypto_atm"
    },
    {
        "displayName": "Restaurante cubano",
        "name": "categories/gcid:cuban_restaurant"
    },
    {
        "displayName": "Escuela culinaria",
        "name": "categories/gcid:culinary_school"
    },
    {
        "displayName": "Asociación cultural",
        "name": "categories/gcid:cultural_association"
    },
    {
        "displayName": "Centro cultural",
        "name": "categories/gcid:cultural_center"
    },
    {
        "displayName": "Referente cultural",
        "name": "categories/gcid:cultural_landmark"
    },
    {
        "displayName": "Tienda de cupcakes",
        "name": "categories/gcid:cupcake_shop"
    },
    {
        "displayName": "Concesionario Cupra",
        "name": "categories/gcid:cupra_dealer"
    },
    {
        "displayName": "Bar de jamón curado",
        "name": "categories/gcid:cured_ham_bar"
    },
    {
        "displayName": "Tienda de jamón curado",
        "name": "categories/gcid:cured_ham_store"
    },
    {
        "displayName": "Almacén de jamón curado",
        "name": "categories/gcid:cured_ham_warehouse"
    },
    {
        "displayName": "Club de curling",
        "name": "categories/gcid:curling_club"
    },
    {
        "displayName": "Salón de curling",
        "name": "categories/gcid:curling_hall"
    },
    {
        "displayName": "Servicio de cambio de divisas",
        "name": "categories/gcid:currency_exchange_service"
    },
    {
        "displayName": "Curtain and upholstery cleaning service",
        "name": "categories/gcid:curtain_and_upholstery_cleaner"
    },
    {
        "displayName": "Tienda de cortinas",
        "name": "categories/gcid:curtain_store"
    },
    {
        "displayName": "Fabricante y proveedor de cortinas",
        "name": "categories/gcid:curtain_supplier_and_maker"
    },
    {
        "displayName": "Tienda de bienes confiscados por la aduana",
        "name": "categories/gcid:custom_confiscated_goods_store"
    },
    {
        "displayName": "Constructor de casas personalizadas",
        "name": "categories/gcid:custom_home_builder"
    },
    {
        "displayName": "Impresora de etiquetas personalizadas",
        "name": "categories/gcid:custom_label_printer"
    },
    {
        "displayName": "Tienda de camisetas personalizadas",
        "name": "categories/gcid:custom_t_shirt_store"
    },
    {
        "displayName": "Sastre",
        "name": "categories/gcid:custom_tailor"
    },
    {
        "displayName": "Despachante de aduana",
        "name": "categories/gcid:customs_broker"
    },
    {
        "displayName": "Customs consultant",
        "name": "categories/gcid:customs_consultant"
    },
    {
        "displayName": "Customs department",
        "name": "categories/gcid:customs_department"
    },
    {
        "displayName": "Oficina de aduana",
        "name": "categories/gcid:customs_office"
    },
    {
        "displayName": "Depósito de aduanas",
        "name": "categories/gcid:customs_warehouse"
    },
    {
        "displayName": "Bazar",
        "name": "categories/gcid:cutlery_store"
    },
    {
        "displayName": "Parada de bicitaxis",
        "name": "categories/gcid:cycle_rickshaw_stand"
    },
    {
        "displayName": "Parque de ciclismo",
        "name": "categories/gcid:cycling_park"
    },
    {
        "displayName": "Restaurante checo",
        "name": "categories/gcid:czech_restaurant"
    },
    {
        "displayName": "Concesionario Dacia",
        "name": "categories/gcid:dacia_dealer"
    },
    {
        "displayName": "Concesionario Daihatsu",
        "name": "categories/gcid:daihatsu_dealer"
    },
    {
        "displayName": "Tambo",
        "name": "categories/gcid:dairy"
    },
    {
        "displayName": "Granja de productos lácteos",
        "name": "categories/gcid:dairy_farm"
    },
    {
        "displayName": "Proveedor de equipos para tambos",
        "name": "categories/gcid:dairy_farm_equipment_supplier"
    },
    {
        "displayName": "Tienda de productos lácteos",
        "name": "categories/gcid:dairy_store"
    },
    {
        "displayName": "Proveedor de productos lácteos",
        "name": "categories/gcid:dairy_supplier"
    },
    {
        "displayName": "Restaurante especializado en fideos dan dan",
        "name": "categories/gcid:dan_dan_noodle_restaurant"
    },
    {
        "displayName": "Discoteca",
        "name": "categories/gcid:dance_club"
    },
    {
        "displayName": "Compañía de baile",
        "name": "categories/gcid:dance_company"
    },
    {
        "displayName": "Salón de baile",
        "name": "categories/gcid:dance_hall"
    },
    {
        "displayName": "Salón de baile",
        "name": "categories/gcid:dance_pavillion"
    },
    {
        "displayName": "Restaurante con pista de baile",
        "name": "categories/gcid:dance_restaurant"
    },
    {
        "displayName": "Escuela de baile",
        "name": "categories/gcid:dance_school"
    },
    {
        "displayName": "Tienda de artículos de baile",
        "name": "categories/gcid:dance_store"
    },
    {
        "displayName": "Restaurante de comida danesa",
        "name": "categories/gcid:danish_restaurant"
    },
    {
        "displayName": "Bar con dardos",
        "name": "categories/gcid:dart_bar"
    },
    {
        "displayName": "Tienda de insumos de dardos",
        "name": "categories/gcid:dart_supply_store"
    },
    {
        "displayName": "Servicio de entrada de datos",
        "name": "categories/gcid:data_entry_service"
    },
    {
        "displayName": "Servicio de recuperación de datos",
        "name": "categories/gcid:data_recovery_service"
    },
    {
        "displayName": "Empresa de gestión de bases de datos",
        "name": "categories/gcid:database_management_company"
    },
    {
        "displayName": "Servicio de contactos",
        "name": "categories/gcid:dating_service"
    },
    {
        "displayName": "Guardería infantil",
        "name": "categories/gcid:day_care_center"
    },
    {
        "displayName": "Spa de día",
        "name": "categories/gcid:day_spa"
    },
    {
        "displayName": "Iglesia para sordos",
        "name": "categories/gcid:deaf_church"
    },
    {
        "displayName": "Servicios para personas sordas",
        "name": "categories/gcid:deaf_service"
    },
    {
        "displayName": "Concesionario de Fiat Professional",
        "name": "categories/gcid:dealer_of_fiat_professional"
    },
    {
        "displayName": "Empresa de remoción de escombros",
        "name": "categories/gcid:debris_removal_service"
    },
    {
        "displayName": "Cobranza",
        "name": "categories/gcid:debt_collecting"
    },
    {
        "displayName": "Agencia de cobranza",
        "name": "categories/gcid:debt_collection_agency"
    },
    {
        "displayName": "Proveedor de calcomanías",
        "name": "categories/gcid:decal_supplier"
    },
    {
        "displayName": "Constructor de terrazas",
        "name": "categories/gcid:deck_builder"
    },
    {
        "displayName": "Tiendas Delicatessen",
        "name": "categories/gcid:deli"
    },
    {
        "displayName": "Restaurante de comida china con entrega a domicilio",
        "name": "categories/gcid:delivery_chinese_restaurant"
    },
    {
        "displayName": "Delivery service",
        "name": "categories/gcid:delivery_service"
    },
    {
        "displayName": "Contratista de demolición",
        "name": "categories/gcid:demolition_contractor"
    },
    {
        "displayName": "Tienda de jeans",
        "name": "categories/gcid:denim_wear_store"
    },
    {
        "displayName": "Clínica odontológica",
        "name": "categories/gcid:dental_clinic"
    },
    {
        "displayName": "Higienista dental",
        "name": "categories/gcid:dental_hygienist"
    },
    {
        "displayName": "Periodoncista de implantes dentales",
        "name": "categories/gcid:dental_implants_periodontist"
    },
    {
        "displayName": "Proveedor de implantes dentales",
        "name": "categories/gcid:dental_implants_provider"
    },
    {
        "displayName": "Agencia de seguros dentales",
        "name": "categories/gcid:dental_insurance_agency"
    },
    {
        "displayName": "Laboratorio odontológico",
        "name": "categories/gcid:dental_lab"
    },
    {
        "displayName": "Radiología dental",
        "name": "categories/gcid:dental_radiology"
    },
    {
        "displayName": "Facultad de Odontología",
        "name": "categories/gcid:dental_school"
    },
    {
        "displayName": "Tienda de insumos para odontología",
        "name": "categories/gcid:dental_supply_store"
    },
    {
        "displayName": "Dentista",
        "name": "categories/gcid:dentist"
    },
    {
        "displayName": "Centro de cuidado odontológico",
        "name": "categories/gcid:denture_care_center"
    },
    {
        "displayName": "Department for Regional Development",
        "name": "categories/gcid:department_for_regional_development"
    },
    {
        "displayName": "Department of education",
        "name": "categories/gcid:department_of_education"
    },
    {
        "displayName": "Department of finance",
        "name": "categories/gcid:department_of_finance"
    },
    {
        "displayName": "Department of housing",
        "name": "categories/gcid:department_of_housing"
    },
    {
        "displayName": "Departamento de vehículos",
        "name": "categories/gcid:department_of_motor_vehicles"
    },
    {
        "displayName": "Departamento de seguridad pública",
        "name": "categories/gcid:department_of_public_safety"
    },
    {
        "displayName": "Departamento de Servicios Sociales",
        "name": "categories/gcid:department_of_social_services"
    },
    {
        "displayName": "Departamento de transportes",
        "name": "categories/gcid:department_of_transportation"
    },
    {
        "displayName": "Tienda departamental",
        "name": "categories/gcid:department_store"
    },
    {
        "displayName": "Dept of City Treasure",
        "name": "categories/gcid:dept_of_city_treasure"
    },
    {
        "displayName": "Department of State Treasure",
        "name": "categories/gcid:dept_of_state_treasure"
    },
    {
        "displayName": "Dermatólogo",
        "name": "categories/gcid:dermatologist"
    },
    {
        "displayName": "Planta desalinizadora",
        "name": "categories/gcid:desalination_plant"
    },
    {
        "displayName": "Agencia de diseño",
        "name": "categories/gcid:design_agency"
    },
    {
        "displayName": "Ingeniero de diseño",
        "name": "categories/gcid:design_engineer"
    },
    {
        "displayName": "Instituto de diseño",
        "name": "categories/gcid:design_institute"
    },
    {
        "displayName": "Servicios de autoedición",
        "name": "categories/gcid:desktop_publishing_service"
    },
    {
        "displayName": "Bufé de dulces y postres",
        "name": "categories/gcid:dessert_buffet"
    },
    {
        "displayName": "Restaurante de postres",
        "name": "categories/gcid:dessert_restaurant"
    },
    {
        "displayName": "Tienda de postres",
        "name": "categories/gcid:dessert_shop"
    },
    {
        "displayName": "Centro de detención",
        "name": "categories/gcid:detention_center"
    },
    {
        "displayName": "Dhaba",
        "name": "categories/gcid:dhaba"
    },
    {
        "displayName": "Centro especializado en diabetes",
        "name": "categories/gcid:diabetes_center"
    },
    {
        "displayName": "Proveedor de equipos de control de la diabetes",
        "name": "categories/gcid:diabetes_equipment_supplier"
    },
    {
        "displayName": "Diabetólogo",
        "name": "categories/gcid:diabetologist"
    },
    {
        "displayName": "Centro de diagnóstico",
        "name": "categories/gcid:diagnostic_center"
    },
    {
        "displayName": "Centro de diálisis",
        "name": "categories/gcid:dialysis_center"
    },
    {
        "displayName": "Comprador de diamantes",
        "name": "categories/gcid:diamond_buyer"
    },
    {
        "displayName": "Distribuidor de diamantes",
        "name": "categories/gcid:diamond_dealer"
    },
    {
        "displayName": "Servicio de pañales reutilizables",
        "name": "categories/gcid:diaper_service"
    },
    {
        "displayName": "Distribuidor de motores diésel",
        "name": "categories/gcid:diesel_engine_dealer"
    },
    {
        "displayName": "Servicio de reparación de motores diesel",
        "name": "categories/gcid:diesel_engine_repair_service"
    },
    {
        "displayName": "Proveedor de diesel",
        "name": "categories/gcid:diesel_fuel_supplier"
    },
    {
        "displayName": "Dietista",
        "name": "categories/gcid:dietitian"
    },
    {
        "displayName": "Impresora digital",
        "name": "categories/gcid:digital_printer"
    },
    {
        "displayName": "Servicio de impresión digital",
        "name": "categories/gcid:digital_printing_service"
    },
    {
        "displayName": "Restaurante de dim sum",
        "name": "categories/gcid:dim_sum_restaurant"
    },
    {
        "displayName": "Diner",
        "name": "categories/gcid:diner"
    },
    {
        "displayName": "Cena con teatro",
        "name": "categories/gcid:dinner_theater"
    },
    {
        "displayName": "Publicidad en correo directo",
        "name": "categories/gcid:direct_mail_advertising"
    },
    {
        "displayName": "Dirt supplier",
        "name": "categories/gcid:dirt_supplier"
    },
    {
        "displayName": "Proveedor de equipos para discapacitados",
        "name": "categories/gcid:disability_equipment_supplier"
    },
    {
        "displayName": "Organización de asistencia y servicios para personas con discapacidad",
        "name": "categories/gcid:disability_services_and_support_organization"
    },
    {
        "displayName": "Centro de deportes adaptados",
        "name": "categories/gcid:disabled_sports_center"
    },
    {
        "displayName": "Campo de golf de disco",
        "name": "categories/gcid:disc_golf_course"
    },
    {
        "displayName": "Iglesia de los Discípulos de Cristo",
        "name": "categories/gcid:disciples_of_christ_church"
    },
    {
        "displayName": "Discoteca",
        "name": "categories/gcid:disco"
    },
    {
        "displayName": "Tienda de promociones",
        "name": "categories/gcid:discount_store"
    },
    {
        "displayName": "Supermercado de descuentos",
        "name": "categories/gcid:discount_supermarket"
    },
    {
        "displayName": "Centro de modelos de casas",
        "name": "categories/gcid:display_home_center"
    },
    {
        "displayName": "Fabricante de estands para exhibición",
        "name": "categories/gcid:display_stand_manufacturer"
    },
    {
        "displayName": "Proveedor de vajilla desechable",
        "name": "categories/gcid:disposable_tableware_supplier"
    },
    {
        "displayName": "Centro de educación a distancia",
        "name": "categories/gcid:distance_learning_center"
    },
    {
        "displayName": "Destilería",
        "name": "categories/gcid:distillery"
    },
    {
        "displayName": "Servicio de distribución",
        "name": "categories/gcid:distribution_service"
    },
    {
        "displayName": "Fiscal general",
        "name": "categories/gcid:district_attorney"
    },
    {
        "displayName": "District council",
        "name": "categories/gcid:district_council"
    },
    {
        "displayName": "Oficina del Gobierno del Distrito",
        "name": "categories/gcid:district_government_office"
    },
    {
        "displayName": "Juez de distrito",
        "name": "categories/gcid:district_justice"
    },
    {
        "displayName": "Oficina de Distrito",
        "name": "categories/gcid:district_office"
    },
    {
        "displayName": "Club de buceo",
        "name": "categories/gcid:dive_club"
    },
    {
        "displayName": "Tienda de buceo",
        "name": "categories/gcid:dive_shop"
    },
    {
        "displayName": "Centro de buceo",
        "name": "categories/gcid:diving_center"
    },
    {
        "displayName": "Contratista de buceo",
        "name": "categories/gcid:diving_service"
    },
    {
        "displayName": "Abogado especializado en divorcios",
        "name": "categories/gcid:divorce_attorney"
    },
    {
        "displayName": "Asistencia de divorcio",
        "name": "categories/gcid:divorce_service"
    },
    {
        "displayName": "Servicio de DJ",
        "name": "categories/gcid:dj"
    },
    {
        "displayName": "Tienda de artículos para DJ",
        "name": "categories/gcid:dj_supply_store"
    },
    {
        "displayName": "Tienda de hágalo usted mismo",
        "name": "categories/gcid:do_it_yourself_store"
    },
    {
        "displayName": "Constructor de muelles",
        "name": "categories/gcid:dock_builder"
    },
    {
        "displayName": "Doctor",
        "name": "categories/gcid:doctor"
    },
    {
        "displayName": "Concesionario Dodge",
        "name": "categories/gcid:dodge_dealer"
    },
    {
        "displayName": "Criador de perros",
        "name": "categories/gcid:dog_breeder"
    },
    {
        "displayName": "Cafetería que admite perros",
        "name": "categories/gcid:dog_cafe"
    },
    {
        "displayName": "Guardería para perros",
        "name": "categories/gcid:dog_day_care_center"
    },
    {
        "displayName": "Parque para perros",
        "name": "categories/gcid:dog_park"
    },
    {
        "displayName": "Entrenador de perros",
        "name": "categories/gcid:dog_trainer"
    },
    {
        "displayName": "Paseador de perros",
        "name": "categories/gcid:dog_walker"
    },
    {
        "displayName": "Servicio de paseos en trineo de perros",
        "name": "categories/gcid:dogsled_ride_service"
    },
    {
        "displayName": "Restaurante de locha",
        "name": "categories/gcid:dojo_restaurant"
    },
    {
        "displayName": "Restauración de muñecas",
        "name": "categories/gcid:doll_restoration_service"
    },
    {
        "displayName": "Tienda de muñecas",
        "name": "categories/gcid:doll_store"
    },
    {
        "displayName": "Tienda de \"todo por un dólar\"",
        "name": "categories/gcid:dollar_store"
    },
    {
        "displayName": "Centro de tratamiento por abuso doméstico",
        "name": "categories/gcid:domestic_abuse_treatment_center"
    },
    {
        "displayName": "Aeropuerto de cabotaje",
        "name": "categories/gcid:domestic_airport"
    },
    {
        "displayName": "Restaurante dominicano",
        "name": "categories/gcid:dominican_restaurant"
    },
    {
        "displayName": "Centro de donaciones",
        "name": "categories/gcid:donations_center"
    },
    {
        "displayName": "Restaurante especializado en döner kebab",
        "name": "categories/gcid:doner_kebab_restaurant"
    },
    {
        "displayName": "Tienda de rosquillas",
        "name": "categories/gcid:donut_shop"
    },
    {
        "displayName": "Fabricante de puertas",
        "name": "categories/gcid:door_manufacturer"
    },
    {
        "displayName": "Tienda de puertas",
        "name": "categories/gcid:door_shop"
    },
    {
        "displayName": "Proveedor de puertas",
        "name": "categories/gcid:door_supplier"
    },
    {
        "displayName": "Almacén de puertas",
        "name": "categories/gcid:door_warehouse"
    },
    {
        "displayName": "Instalación de ventanas de doble acristalamiento",
        "name": "categories/gcid:double_glazing_supplier"
    },
    {
        "displayName": "Doula",
        "name": "categories/gcid:doula"
    },
    {
        "displayName": "Drafting equipment supplier",
        "name": "categories/gcid:drafting_equipment_supplier"
    },
    {
        "displayName": "Servicios de diseño",
        "name": "categories/gcid:drafting_service"
    },
    {
        "displayName": "Servicio de drenaje",
        "name": "categories/gcid:drainage_service"
    },
    {
        "displayName": "Escuela de arte dramático",
        "name": "categories/gcid:drama_school"
    },
    {
        "displayName": "Drama theater",
        "name": "categories/gcid:drama_theater"
    },
    {
        "displayName": "Curso de dibujo",
        "name": "categories/gcid:drawing_lessons"
    },
    {
        "displayName": "Servicio de alquiler de trajes de etiqueta",
        "name": "categories/gcid:dress_and_tuxedo_rental_service"
    },
    {
        "displayName": "Tienda de vestidos",
        "name": "categories/gcid:dress_shop"
    },
    {
        "displayName": "Modista",
        "name": "categories/gcid:dressmaker"
    },
    {
        "displayName": "Tienda de flores secas",
        "name": "categories/gcid:dried_flower_shop"
    },
    {
        "displayName": "Tienda de mariscos disecados",
        "name": "categories/gcid:dried_seafood_store"
    },
    {
        "displayName": "Contratista de perforación",
        "name": "categories/gcid:drilling_contractor"
    },
    {
        "displayName": "Proveedor de equipos para perforación",
        "name": "categories/gcid:drilling_equipment_supplier"
    },
    {
        "displayName": "Fuente de agua potable",
        "name": "categories/gcid:drinking_water_fountain"
    },
    {
        "displayName": "Autocine",
        "name": "categories/gcid:drive_in_movie_theater"
    },
    {
        "displayName": "Driver and vehicle licensing agency",
        "name": "categories/gcid:driver_vehicle_licensing_agency"
    },
    {
        "displayName": "Oficina de licencias de conducir",
        "name": "categories/gcid:drivers_license_office"
    },
    {
        "displayName": "Escuela de conducción",
        "name": "categories/gcid:drivers_license_training_school"
    },
    {
        "displayName": "Tienda de ejes motrices",
        "name": "categories/gcid:driveshaft_shop"
    },
    {
        "displayName": "Escuela de conducción",
        "name": "categories/gcid:driving_school"
    },
    {
        "displayName": "Centro para examen de conducción",
        "name": "categories/gcid:driving_test_centre"
    },
    {
        "displayName": "Tienda de drones",
        "name": "categories/gcid:drone_shop"
    },
    {
        "displayName": "Servicio de pruebas de detección de drogas",
        "name": "categories/gcid:drug_testing_service"
    },
    {
        "displayName": "Farmacia y artículos varios",
        "name": "categories/gcid:drugstore"
    },
    {
        "displayName": "Escuela de batería",
        "name": "categories/gcid:drum_school"
    },
    {
        "displayName": "Tienda de tambores",
        "name": "categories/gcid:drum_store"
    },
    {
        "displayName": "Tintorería",
        "name": "categories/gcid:dry_cleaner"
    },
    {
        "displayName": "Tienda de frutas secas",
        "name": "categories/gcid:dry_fruit_store"
    },
    {
        "displayName": "Proveedor de hielo seco",
        "name": "categories/gcid:dry_ice_supplier"
    },
    {
        "displayName": "Contratista de yesería",
        "name": "categories/gcid:dry_wall_contractor"
    },
    {
        "displayName": "Tienda de insumos para yesería",
        "name": "categories/gcid:dry_wall_supply_store"
    },
    {
        "displayName": "Servicio de limpieza de conductos de ventilación de secadoras",
        "name": "categories/gcid:dryer_vent_cleaning_service"
    },
    {
        "displayName": "Concesionario DS Automobiles",
        "name": "categories/gcid:ds_automobiles_dealer"
    },
    {
        "displayName": "Concesionario de camiones volquete",
        "name": "categories/gcid:dump_truck_dealer"
    },
    {
        "displayName": "Restaurante de bollitos asiáticos",
        "name": "categories/gcid:dumpling_restaurant"
    },
    {
        "displayName": "Alquiler de contenedores de basura",
        "name": "categories/gcid:dumpster_rental_service"
    },
    {
        "displayName": "Restaurante de dürüm",
        "name": "categories/gcid:durum_restaurant"
    },
    {
        "displayName": "Restaurante holandés",
        "name": "categories/gcid:dutch_restaurant"
    },
    {
        "displayName": "Tienda libre de impuestos",
        "name": "categories/gcid:duty_free_store"
    },
    {
        "displayName": "Tienda de DVD",
        "name": "categories/gcid:dvd_store"
    },
    {
        "displayName": "Tienda de tintes",
        "name": "categories/gcid:dye_store"
    },
    {
        "displayName": "Fábrica de tintes",
        "name": "categories/gcid:dyeworks"
    },
    {
        "displayName": "Proveedor de dinamómetros",
        "name": "categories/gcid:dynamometer_supplier"
    },
    {
        "displayName": "Agencia de eCommerce",
        "name": "categories/gcid:e_commerce_agency"
    },
    {
        "displayName": "Servicio de comercio electrónico",
        "name": "categories/gcid:e_commerce_service"
    },
    {
        "displayName": "Servicio de perforación de orejas",
        "name": "categories/gcid:ear_piercing_service"
    },
    {
        "displayName": "Empresa de movimiento de tierras",
        "name": "categories/gcid:earth_works_company"
    },
    {
        "displayName": "Restaurante africano oriental",
        "name": "categories/gcid:east_african_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Java\\xa0Oriental",
        "name": "categories/gcid:east_javanese_restaurant"
    },
    {
        "displayName": "Tienda de productos del este de Europa",
        "name": "categories/gcid:eastern_european_grocery_store"
    },
    {
        "displayName": "Restaurante de cocina de Europa del Este",
        "name": "categories/gcid:eastern_european_restaurant"
    },
    {
        "displayName": "Iglesia Ortodoxa Oriental",
        "name": "categories/gcid:eastern_orthodox_church"
    },
    {
        "displayName": "Centro para el tratamiento de trastornos alimenticios",
        "name": "categories/gcid:eating_disorder_treatment_center"
    },
    {
        "displayName": "Restaurante ecléctico",
        "name": "categories/gcid:eclectic_restaurant"
    },
    {
        "displayName": "Parque ecológico",
        "name": "categories/gcid:ecological_park"
    },
    {
        "displayName": "Asociación de ecologistas",
        "name": "categories/gcid:ecologists_association"
    },
    {
        "displayName": "Consultor económico",
        "name": "categories/gcid:economic_consultant"
    },
    {
        "displayName": "Economic development agency",
        "name": "categories/gcid:economic_development_agency"
    },
    {
        "displayName": "Restaurante ecuatoriano",
        "name": "categories/gcid:ecuadorian_restaurant"
    },
    {
        "displayName": "Centro educativo",
        "name": "categories/gcid:education_center"
    },
    {
        "displayName": "Asesor educativo",
        "name": "categories/gcid:educational_consultant"
    },
    {
        "displayName": "Institución educativa",
        "name": "categories/gcid:educational_institution"
    },
    {
        "displayName": "Tienda de materiales educativos",
        "name": "categories/gcid:educational_supply_store"
    },
    {
        "displayName": "Servicio de exámenes educativos",
        "name": "categories/gcid:educational_testing_service"
    },
    {
        "displayName": "EFTPOS equipment supplier",
        "name": "categories/gcid:eftpos_equipment_supplier"
    },
    {
        "displayName": "Proveedor de huevos",
        "name": "categories/gcid:egg_supplier"
    },
    {
        "displayName": "Restaurante egipcio",
        "name": "categories/gcid:egyptian_restaurant"
    },
    {
        "displayName": "Abogado de la tercera edad",
        "name": "categories/gcid:elder_law_attorney"
    },
    {
        "displayName": "Tienda de bicicletas eléctricas",
        "name": "categories/gcid:electric_bicycle_store"
    },
    {
        "displayName": "Servicio de reparación de motores eléctricos",
        "name": "categories/gcid:electric_motor_repair_shop"
    },
    {
        "displayName": "Concesionario de scooters elèctricos",
        "name": "categories/gcid:electric_motor_scooter_dealer"
    },
    {
        "displayName": "Tienda de motores eléctricos",
        "name": "categories/gcid:electric_motor_store"
    },
    {
        "displayName": "Concesionario de vehículos eléctricos",
        "name": "categories/gcid:electric_motor_vehicle_dealer"
    },
    {
        "displayName": "Concesionario de motos eléctricas",
        "name": "categories/gcid:electric_motorcycle_dealer"
    },
    {
        "displayName": "Compañía de servicios públicos de electricidad",
        "name": "categories/gcid:electric_utility_company"
    },
    {
        "displayName": "Fábrica de material eléctrico",
        "name": "categories/gcid:electric_utility_manufacturer"
    },
    {
        "displayName": "Estación de carga de vehículos eléctricos",
        "name": "categories/gcid:electric_vehicle_charging_station"
    },
    {
        "displayName": "Contratista de estaciones de carga de vehículos eléctricos",
        "name": "categories/gcid:electric_vehicle_charging_station_contractor"
    },
    {
        "displayName": "Mayorista de electrodomésticos",
        "name": "categories/gcid:electrical_appliance_wholesaler"
    },
    {
        "displayName": "Ingeniero electrotécnico",
        "name": "categories/gcid:electrical_engineer"
    },
    {
        "displayName": "Proveedor de equipos eléctricos",
        "name": "categories/gcid:electrical_equipment_supplier"
    },
    {
        "displayName": "Servicio de instalaciones eléctricas",
        "name": "categories/gcid:electrical_installation_service"
    },
    {
        "displayName": "Taller de reparación eléctrica",
        "name": "categories/gcid:electrical_repair_shop"
    },
    {
        "displayName": "Subestación eléctrica",
        "name": "categories/gcid:electrical_substation"
    },
    {
        "displayName": "Tienda de suministros eléctricos",
        "name": "categories/gcid:electrical_supply_store"
    },
    {
        "displayName": "Mayorista de productos electrónicos",
        "name": "categories/gcid:electrical_wholesaler"
    },
    {
        "displayName": "Electricista",
        "name": "categories/gcid:electrician"
    },
    {
        "displayName": "Servicio de depilación por electrólisis",
        "name": "categories/gcid:electrolysis_hair_removal_service"
    },
    {
        "displayName": "Servicios de ingeniería eléctrica",
        "name": "categories/gcid:electronic_engineer"
    },
    {
        "displayName": "Proveedor de piezas electrónicas",
        "name": "categories/gcid:electronic_parts_supplier"
    },
    {
        "displayName": "Mayorista de accesorios electrónicos",
        "name": "categories/gcid:electronics_accessories_wholesaler"
    },
    {
        "displayName": "Empresa de electrónica",
        "name": "categories/gcid:electronics_company"
    },
    {
        "displayName": "Ingeniero electrónico",
        "name": "categories/gcid:electronics_engineer"
    },
    {
        "displayName": "Electronics exporter",
        "name": "categories/gcid:electronics_exporter"
    },
    {
        "displayName": "Tienda de alquiler de electrónica",
        "name": "categories/gcid:electronics_hire_shop"
    },
    {
        "displayName": "Fabricante de aparatos electrónicos",
        "name": "categories/gcid:electronics_manufacturer"
    },
    {
        "displayName": "Servicio de reparación electrónica",
        "name": "categories/gcid:electronics_repair_shop"
    },
    {
        "displayName": "Tienda de electrónica",
        "name": "categories/gcid:electronics_store"
    },
    {
        "displayName": "Máquina expendedora de productos electrónicos",
        "name": "categories/gcid:electronics_vending_machine"
    },
    {
        "displayName": "Mayorista de productos electrónicos",
        "name": "categories/gcid:electronics_wholesaler"
    },
    {
        "displayName": "Escuela primaria",
        "name": "categories/gcid:elementary_school"
    },
    {
        "displayName": "Fabricante de elevadores",
        "name": "categories/gcid:elevator_manufacturer"
    },
    {
        "displayName": "Servicio de ascensores",
        "name": "categories/gcid:elevator_service"
    },
    {
        "displayName": "Embajada",
        "name": "categories/gcid:embassy"
    },
    {
        "displayName": "Servicio de repujado",
        "name": "categories/gcid:embossing_service"
    },
    {
        "displayName": "Servicio de bordado",
        "name": "categories/gcid:embroidery_service"
    },
    {
        "displayName": "Tienda de bordados",
        "name": "categories/gcid:embroidery_shop"
    },
    {
        "displayName": "Psicoterapeuta EMDR",
        "name": "categories/gcid:emdr_psychotherapist"
    },
    {
        "displayName": "Cabina para llamadas de emergencia",
        "name": "categories/gcid:emergency_call_station"
    },
    {
        "displayName": "Médico de atención de urgencias",
        "name": "categories/gcid:emergency_care_physician"
    },
    {
        "displayName": "Servicio de urgencias médicas",
        "name": "categories/gcid:emergency_care_service"
    },
    {
        "displayName": "Servicio odontológico de emergencia",
        "name": "categories/gcid:emergency_dental_service"
    },
    {
        "displayName": "Cerrajero de emergencia",
        "name": "categories/gcid:emergency_locksmith_service"
    },
    {
        "displayName": "Emergency management ministry",
        "name": "categories/gcid:emergency_management_ministry"
    },
    {
        "displayName": "Sala de emergencias",
        "name": "categories/gcid:emergency_room"
    },
    {
        "displayName": "Capacitación en emergencias",
        "name": "categories/gcid:emergency_training"
    },
    {
        "displayName": "Escuela de capacitación para emergencias",
        "name": "categories/gcid:emergency_training_school"
    },
    {
        "displayName": "Servicio veterinario de emergencia",
        "name": "categories/gcid:emergency_veterinarian_service"
    },
    {
        "displayName": "Agencia de empleos",
        "name": "categories/gcid:employment_agency"
    },
    {
        "displayName": "Abogado laboral",
        "name": "categories/gcid:employment_attorney"
    },
    {
        "displayName": "Centro de empleo",
        "name": "categories/gcid:employment_center"
    },
    {
        "displayName": "Asesoría laboral",
        "name": "categories/gcid:employment_consultant"
    },
    {
        "displayName": "Endocrinólogo",
        "name": "categories/gcid:endocrinologist"
    },
    {
        "displayName": "Endodoncista",
        "name": "categories/gcid:endodontist"
    },
    {
        "displayName": "Centro de endoscopia",
        "name": "categories/gcid:endoscopist"
    },
    {
        "displayName": "Equipos y soluciones de energía",
        "name": "categories/gcid:energy_equipment_and_solutions"
    },
    {
        "displayName": "Proveedor de energía",
        "name": "categories/gcid:energy_supplier"
    },
    {
        "displayName": "Servicio de reconstrucción de motores",
        "name": "categories/gcid:engine_rebuilding_service"
    },
    {
        "displayName": "Ingeniero",
        "name": "categories/gcid:engineer"
    },
    {
        "displayName": "Consultor de ingeniería",
        "name": "categories/gcid:engineering_consultant"
    },
    {
        "displayName": "Facultad de Ingeniería",
        "name": "categories/gcid:engineering_school"
    },
    {
        "displayName": "Campamento con enseñanza de inglés",
        "name": "categories/gcid:english_language_camp"
    },
    {
        "displayName": "Escuela de inglés",
        "name": "categories/gcid:english_language_school"
    },
    {
        "displayName": "Restaurante inglés",
        "name": "categories/gcid:english_restaurant"
    },
    {
        "displayName": "Grabador",
        "name": "categories/gcid:engraver"
    },
    {
        "displayName": "Artista",
        "name": "categories/gcid:entertainer"
    },
    {
        "displayName": "Agencia de entretenimientos",
        "name": "categories/gcid:entertainment_agency"
    },
    {
        "displayName": "Proveedor de sobres",
        "name": "categories/gcid:envelope_supplier"
    },
    {
        "displayName": "Oficina de medioambiente",
        "name": "categories/gcid:environment_office"
    },
    {
        "displayName": "Recursos naturales renovables y medioambiente",
        "name": "categories/gcid:environment_renewable_natural_resources"
    },
    {
        "displayName": "Abogado medioambiental",
        "name": "categories/gcid:environmental_attorney"
    },
    {
        "displayName": "Asesor medioambiental",
        "name": "categories/gcid:environmental_consultant"
    },
    {
        "displayName": "Ingeniero en medioambiente",
        "name": "categories/gcid:environmental_engineer"
    },
    {
        "displayName": "Servicio de Salud Ambiental",
        "name": "categories/gcid:environmental_health_service"
    },
    {
        "displayName": "Environmental organization",
        "name": "categories/gcid:environmental_organization"
    },
    {
        "displayName": "Organización de protección ambiental",
        "name": "categories/gcid:environmental_protection_organization"
    },
    {
        "displayName": "Iglesia episcopal",
        "name": "categories/gcid:episcopal_church"
    },
    {
        "displayName": "Club ecuestre",
        "name": "categories/gcid:equestrian_club"
    },
    {
        "displayName": "Instalación ecuestre",
        "name": "categories/gcid:equestrian_facility"
    },
    {
        "displayName": "Tienda ecuestre",
        "name": "categories/gcid:equestrian_store"
    },
    {
        "displayName": "Exportador de equipos",
        "name": "categories/gcid:equipment_exporter"
    },
    {
        "displayName": "Importador de equipos",
        "name": "categories/gcid:equipment_importer"
    },
    {
        "displayName": "Agencia de alquiler de equipos",
        "name": "categories/gcid:equipment_rental_agency"
    },
    {
        "displayName": "Proveedor de equipos",
        "name": "categories/gcid:equipment_supplier"
    },
    {
        "displayName": "Restaurante de cocina de Eritrea",
        "name": "categories/gcid:eritrean_restaurant"
    },
    {
        "displayName": "Casa de masajes eróticos",
        "name": "categories/gcid:erotic_massage"
    },
    {
        "displayName": "Sala de escape",
        "name": "categories/gcid:escape_room_center"
    },
    {
        "displayName": "Servicios de custodia",
        "name": "categories/gcid:escrow_service"
    },
    {
        "displayName": "Cafetería",
        "name": "categories/gcid:espresso_bar"
    },
    {
        "displayName": "Tasador de bienes inmuebles",
        "name": "categories/gcid:estate_appraiser"
    },
    {
        "displayName": "Liquidador inmobiliario",
        "name": "categories/gcid:estate_liquidator"
    },
    {
        "displayName": "Abogado de sucesiones",
        "name": "categories/gcid:estate_litigation_attorney"
    },
    {
        "displayName": "Abogado especializado en planificación de la situación patrimonial",
        "name": "categories/gcid:estate_planning_attorney"
    },
    {
        "displayName": "Restaurante de cocina etíope",
        "name": "categories/gcid:ethiopian_restaurant"
    },
    {
        "displayName": "Museo etnográfico",
        "name": "categories/gcid:ethnographic_museum"
    },
    {
        "displayName": "Tienda de comestibles europea",
        "name": "categories/gcid:european_grocery_store"
    },
    {
        "displayName": "Restaurante europeo",
        "name": "categories/gcid:european_restaurant"
    },
    {
        "displayName": "Iglesia evangélica",
        "name": "categories/gcid:evangelical_church"
    },
    {
        "displayName": "Evening dress rental service",
        "name": "categories/gcid:evening_dress_rental_service"
    },
    {
        "displayName": "Escuela nocturna",
        "name": "categories/gcid:evening_school"
    },
    {
        "displayName": "Empresa de administración de eventos",
        "name": "categories/gcid:event_management_company"
    },
    {
        "displayName": "Planificador de eventos",
        "name": "categories/gcid:event_planner"
    },
    {
        "displayName": "Servicio de tecnología para eventos",
        "name": "categories/gcid:event_technology_service"
    },
    {
        "displayName": "Vendedor de entradas para eventos",
        "name": "categories/gcid:event_ticket_seller"
    },
    {
        "displayName": "Lugar del evento",
        "name": "categories/gcid:event_venue"
    },
    {
        "displayName": "Contratista de excavación",
        "name": "categories/gcid:excavating_contractor"
    },
    {
        "displayName": "Empresa de búsqueda de ejecutivos",
        "name": "categories/gcid:executive_search_firm"
    },
    {
        "displayName": "Agencia de alquiler de oficinas ejecutivas",
        "name": "categories/gcid:executive_suite_rental_agency"
    },
    {
        "displayName": "Albacea",
        "name": "categories/gcid:executor"
    },
    {
        "displayName": "Muestra",
        "name": "categories/gcid:exhibit"
    },
    {
        "displayName": "Centro de negocios y exposiciones",
        "name": "categories/gcid:exhibition_and_trade_center"
    },
    {
        "displayName": "Organizador de exposiciones",
        "name": "categories/gcid:exhibition_planner"
    },
    {
        "displayName": "Exportador",
        "name": "categories/gcid:exporter"
    },
    {
        "displayName": "Hotel de estadía extendida",
        "name": "categories/gcid:extended_stay_hotel"
    },
    {
        "displayName": "Restaurante de cocina extremeña",
        "name": "categories/gcid:extremadurian_restaurant"
    },
    {
        "displayName": "Centro oftalmológico",
        "name": "categories/gcid:eye_care_center"
    },
    {
        "displayName": "Bar de cejas",
        "name": "categories/gcid:eyebrow_bar"
    },
    {
        "displayName": "Salón de pestañas",
        "name": "categories/gcid:eyelash_salon"
    },
    {
        "displayName": "Fabricante de productos textiles",
        "name": "categories/gcid:fabric_product_manufacturer"
    },
    {
        "displayName": "Tienda de telas",
        "name": "categories/gcid:fabric_store"
    },
    {
        "displayName": "Mayorista textil",
        "name": "categories/gcid:fabric_wholesaler"
    },
    {
        "displayName": "Ingeniero de fabricación",
        "name": "categories/gcid:fabrication_engineer"
    },
    {
        "displayName": "Spa facial",
        "name": "categories/gcid:facial_spa"
    },
    {
        "displayName": "Proveedor de maquinaria industrial",
        "name": "categories/gcid:factory_equipment_supplier"
    },
    {
        "displayName": "Facultad de Derecho",
        "name": "categories/gcid:faculty_of_law"
    },
    {
        "displayName": "Facultad de Farmacia",
        "name": "categories/gcid:faculty_of_pharmacy"
    },
    {
        "displayName": "Facultad de Ciencias",
        "name": "categories/gcid:faculty_of_science"
    },
    {
        "displayName": "Office of a fair trade organization",
        "name": "categories/gcid:fair_trade_organization"
    },
    {
        "displayName": "Parque de atracciones",
        "name": "categories/gcid:fairground"
    },
    {
        "displayName": "Restaurante de faláfel",
        "name": "categories/gcid:falafel_restaurant"
    },
    {
        "displayName": "Consejero familiar",
        "name": "categories/gcid:family_counselor"
    },
    {
        "displayName": "Servicio de guardería familiar",
        "name": "categories/gcid:family_day_care_service"
    },
    {
        "displayName": "Abogado de derecho de familia",
        "name": "categories/gcid:family_law_attorney"
    },
    {
        "displayName": "Centro de planificación familiar",
        "name": "categories/gcid:family_planning_center"
    },
    {
        "displayName": "Centro de planificación familiar",
        "name": "categories/gcid:family_planning_counselor"
    },
    {
        "displayName": "Médico general",
        "name": "categories/gcid:family_practice_physician"
    },
    {
        "displayName": "Restaurante familiar",
        "name": "categories/gcid:family_restaurant"
    },
    {
        "displayName": "Centro de servicios familiares",
        "name": "categories/gcid:family_service_center"
    },
    {
        "displayName": "Granja",
        "name": "categories/gcid:farm"
    },
    {
        "displayName": "Farm bureau",
        "name": "categories/gcid:farm_bureau"
    },
    {
        "displayName": "Servicio de reparación de máquinas agrícolas",
        "name": "categories/gcid:farm_equipment_repair_service"
    },
    {
        "displayName": "Proveedor de equipos agrícolas",
        "name": "categories/gcid:farm_equipment_supplier"
    },
    {
        "displayName": "Visita guiada a un hogar agrícola",
        "name": "categories/gcid:farm_household_tour"
    },
    {
        "displayName": "Escuela granja",
        "name": "categories/gcid:farm_school"
    },
    {
        "displayName": "Tienda agrícola",
        "name": "categories/gcid:farm_shop"
    },
    {
        "displayName": "Mercado de agricultores",
        "name": "categories/gcid:farmers_market"
    },
    {
        "displayName": "Turismo rural",
        "name": "categories/gcid:farmstay"
    },
    {
        "displayName": "Servicio de herraje",
        "name": "categories/gcid:farrier_service"
    },
    {
        "displayName": "Tienda de accesorios de moda",
        "name": "categories/gcid:fashion_accessories_store"
    },
    {
        "displayName": "Escuela de diseño de modas",
        "name": "categories/gcid:fashion_design_school"
    },
    {
        "displayName": "Diseñador de moda",
        "name": "categories/gcid:fashion_designer"
    },
    {
        "displayName": "Restaurante de comida rápida",
        "name": "categories/gcid:fast_food_restaurant"
    },
    {
        "displayName": "Proveedor de tornillería",
        "name": "categories/gcid:fastener_supplier"
    },
    {
        "displayName": "Favela",
        "name": "categories/gcid:favela"
    },
    {
        "displayName": "Servicio de fax",
        "name": "categories/gcid:fax_service"
    },
    {
        "displayName": "Agencia federal de protección civil",
        "name": "categories/gcid:federal_agency_for_technical_relief"
    },
    {
        "displayName": "Cooperativa de crédito federal",
        "name": "categories/gcid:federal_credit_union"
    },
    {
        "displayName": "Oficina administrativa federal",
        "name": "categories/gcid:federal_government_office"
    },
    {
        "displayName": "Policía federal",
        "name": "categories/gcid:federal_police"
    },
    {
        "displayName": "Federal reserve bank",
        "name": "categories/gcid:federal_reserve_bank"
    },
    {
        "displayName": "Fabricante de alimento para ganado",
        "name": "categories/gcid:feed_manufacturer"
    },
    {
        "displayName": "Tienda de alimentos para animales",
        "name": "categories/gcid:feed_store"
    },
    {
        "displayName": "Tienda de botas de fieltro",
        "name": "categories/gcid:felt_boots_store"
    },
    {
        "displayName": "Contratista de cercos",
        "name": "categories/gcid:fence_contractor"
    },
    {
        "displayName": "Tienda de suministros para cercas",
        "name": "categories/gcid:fence_supply_store"
    },
    {
        "displayName": "Salón de esgrima",
        "name": "categories/gcid:fencing_salon"
    },
    {
        "displayName": "Escuela de esgrima",
        "name": "categories/gcid:fencing_school"
    },
    {
        "displayName": "Asesor de feng shui",
        "name": "categories/gcid:feng_shui_consultant"
    },
    {
        "displayName": "Tienda de feng shui",
        "name": "categories/gcid:feng_shui_shop"
    },
    {
        "displayName": "Concesionario Ferrari",
        "name": "categories/gcid:ferrari_dealer"
    },
    {
        "displayName": "Rueda de la fortuna",
        "name": "categories/gcid:ferris_wheel"
    },
    {
        "displayName": "Servicio de transbordador",
        "name": "categories/gcid:ferry_service"
    },
    {
        "displayName": "Clínica de fertilidad",
        "name": "categories/gcid:fertility_clinic"
    },
    {
        "displayName": "Médico especialista en fertilidad",
        "name": "categories/gcid:fertility_physician"
    },
    {
        "displayName": "Proveedor de fertilizantes",
        "name": "categories/gcid:fertilizer_supplier"
    },
    {
        "displayName": "Festival",
        "name": "categories/gcid:festival"
    },
    {
        "displayName": "Festival hall",
        "name": "categories/gcid:festival_hall"
    },
    {
        "displayName": "Concesionario de Fiat",
        "name": "categories/gcid:fiat_dealer"
    },
    {
        "displayName": "Proveedor de productos de fibra óptica",
        "name": "categories/gcid:fiber_optic_products_supplier"
    },
    {
        "displayName": "Servicio de reparación de fibra de vidrio",
        "name": "categories/gcid:fiberglass_repair_service"
    },
    {
        "displayName": "Proveedor de fibra de vidrio",
        "name": "categories/gcid:fiberglass_supplier"
    },
    {
        "displayName": "Tienda de estatuillas",
        "name": "categories/gcid:figurine_shop"
    },
    {
        "displayName": "Supermercado filipino",
        "name": "categories/gcid:filipino_grocery_store"
    },
    {
        "displayName": "Restaurante filipino",
        "name": "categories/gcid:filipino_restaurant"
    },
    {
        "displayName": "Biblioteca de películas y fotografías",
        "name": "categories/gcid:film_and_photograph_library"
    },
    {
        "displayName": "Productora cinematográfica",
        "name": "categories/gcid:film_production_company"
    },
    {
        "displayName": "Planta purificadora",
        "name": "categories/gcid:filtration_plant"
    },
    {
        "displayName": "Corredor de finanzas",
        "name": "categories/gcid:finance_broker"
    },
    {
        "displayName": "Asesor financiero",
        "name": "categories/gcid:financial_advisor"
    },
    {
        "displayName": "Auditoría financiera",
        "name": "categories/gcid:financial_audit"
    },
    {
        "displayName": "Asesor financiero",
        "name": "categories/gcid:financial_consultant"
    },
    {
        "displayName": "Institución financiera",
        "name": "categories/gcid:financial_institution"
    },
    {
        "displayName": "Planificador financiero",
        "name": "categories/gcid:financial_planner"
    },
    {
        "displayName": "Restaurante elegante",
        "name": "categories/gcid:fine_dining_restaurant"
    },
    {
        "displayName": "Servicio de dactiloscopia",
        "name": "categories/gcid:fingerprinting_service"
    },
    {
        "displayName": "Proveedor de materiales para acabados",
        "name": "categories/gcid:finishing_materials_supplier"
    },
    {
        "displayName": "Restaurante finlandés",
        "name": "categories/gcid:finnish_restaurant"
    },
    {
        "displayName": "Proveedor de alarmas contra incendio",
        "name": "categories/gcid:fire_alarm_supplier"
    },
    {
        "displayName": "Servicio de restauración debido a daños por incendios",
        "name": "categories/gcid:fire_damage_restoration_service"
    },
    {
        "displayName": "Fire department equipment supplier",
        "name": "categories/gcid:fire_department_equipment_supplier"
    },
    {
        "displayName": "Academia de bomberos",
        "name": "categories/gcid:fire_fighters_academy"
    },
    {
        "displayName": "Consultor de protección contra incendios",
        "name": "categories/gcid:fire_protection_consultant"
    },
    {
        "displayName": "Servicio de seguridad contra incendios",
        "name": "categories/gcid:fire_protection_equipment_supplier"
    },
    {
        "displayName": "Servicio de protección contra incendios",
        "name": "categories/gcid:fire_protection_service"
    },
    {
        "displayName": "Proveedor de sistemas de protección contra incendios",
        "name": "categories/gcid:fire_protection_system_supplier"
    },
    {
        "displayName": "Cuartel de bomberos",
        "name": "categories/gcid:fire_station"
    },
    {
        "displayName": "Academia de armas de fuego",
        "name": "categories/gcid:firearms_academy"
    },
    {
        "displayName": "Fabricante de chimeneas",
        "name": "categories/gcid:fireplace_manufacturer"
    },
    {
        "displayName": "Tienda de hogar a leños",
        "name": "categories/gcid:fireplace_store"
    },
    {
        "displayName": "Proveedor de leña",
        "name": "categories/gcid:firewood_supplier"
    },
    {
        "displayName": "Tienda de fuegos artificiales",
        "name": "categories/gcid:fireworks_store"
    },
    {
        "displayName": "Proveedor de fuegos artificiales",
        "name": "categories/gcid:fireworks_supplier"
    },
    {
        "displayName": "Puesto de primeros auxilios",
        "name": "categories/gcid:first_aid"
    },
    {
        "displayName": "Restaurante de pescados y patatas fritas",
        "name": "categories/gcid:fish_and_chips_restaurant"
    },
    {
        "displayName": "Tienda de pescado y papas fritas para llevar",
        "name": "categories/gcid:fish_and_chips_takeaway"
    },
    {
        "displayName": "Estación piscícola",
        "name": "categories/gcid:fish_farm"
    },
    {
        "displayName": "Procesamiento del pescado",
        "name": "categories/gcid:fish_processing"
    },
    {
        "displayName": "Restaurante de pescados",
        "name": "categories/gcid:fish_restaurant"
    },
    {
        "displayName": "Spa de peces",
        "name": "categories/gcid:fish_spa"
    },
    {
        "displayName": "Tienda de peces",
        "name": "categories/gcid:fish_store"
    },
    {
        "displayName": "Campamento de pesca",
        "name": "categories/gcid:fishing_camp"
    },
    {
        "displayName": "Bote para pesqueros",
        "name": "categories/gcid:fishing_charter"
    },
    {
        "displayName": "Club de pesca",
        "name": "categories/gcid:fishing_club"
    },
    {
        "displayName": "Muelle de pesca",
        "name": "categories/gcid:fishing_pier"
    },
    {
        "displayName": "Estanque de pesca",
        "name": "categories/gcid:fishing_pond"
    },
    {
        "displayName": "Tienda de pesca",
        "name": "categories/gcid:fishing_store"
    },
    {
        "displayName": "Sala de fitness",
        "name": "categories/gcid:fitness_center"
    },
    {
        "displayName": "Tienda de equipos para ejercicio",
        "name": "categories/gcid:fitness_equipment_store"
    },
    {
        "displayName": "Mayorista de equipos para fitness",
        "name": "categories/gcid:fitness_equipment_wholesaler"
    },
    {
        "displayName": "Fitted furniture supplier",
        "name": "categories/gcid:fitted_furniture_supplier"
    },
    {
        "displayName": "Operador de base fija",
        "name": "categories/gcid:fixed_base_operator"
    },
    {
        "displayName": "Tienda de banderas",
        "name": "categories/gcid:flag_store"
    },
    {
        "displayName": "Tienda especializada en danza flamenca",
        "name": "categories/gcid:flamenco_dance_store"
    },
    {
        "displayName": "Escuela de flamenco",
        "name": "categories/gcid:flamenco_school"
    },
    {
        "displayName": "Teatro de flamenco",
        "name": "categories/gcid:flamenco_theater"
    },
    {
        "displayName": "Proveedor de fragancias, aromas y sabores",
        "name": "categories/gcid:flavours_fragrances_and_aroma_supplier"
    },
    {
        "displayName": "Mercado de pulgas",
        "name": "categories/gcid:flea_market"
    },
    {
        "displayName": "Escuela de aviación",
        "name": "categories/gcid:flight_school"
    },
    {
        "displayName": "Mercado flotante",
        "name": "categories/gcid:floating_market"
    },
    {
        "displayName": "Servicio de acabado de suelos",
        "name": "categories/gcid:floor_refinishing_service"
    },
    {
        "displayName": "Servicio de lijado y pulimiento de pisos",
        "name": "categories/gcid:floor_sanding_and_polishing_service"
    },
    {
        "displayName": "Contratista de materiales para pisos",
        "name": "categories/gcid:flooring_contractor"
    },
    {
        "displayName": "Tienda de pisos",
        "name": "categories/gcid:flooring_store"
    },
    {
        "displayName": "Restaurante de cocina de Florida (Estados Unidos)",
        "name": "categories/gcid:floridian_restaurant"
    },
    {
        "displayName": "Florería",
        "name": "categories/gcid:florist"
    },
    {
        "displayName": "Molino de harina",
        "name": "categories/gcid:flour_mill"
    },
    {
        "displayName": "Entrega de flores",
        "name": "categories/gcid:flower_delivery"
    },
    {
        "displayName": "Diseñador de flores",
        "name": "categories/gcid:flower_designer"
    },
    {
        "displayName": "Mercado de flores",
        "name": "categories/gcid:flower_market"
    },
    {
        "displayName": "Mayorista de bienes de consumo de alta rotación",
        "name": "categories/gcid:fmcg_goods_wholesaler"
    },
    {
        "displayName": "FMCG manufacturer",
        "name": "categories/gcid:fmcg_manufacturer"
    },
    {
        "displayName": "Productor de gomaespuma",
        "name": "categories/gcid:foam_rubber_producer"
    },
    {
        "displayName": "Proveedor de gomaespuma",
        "name": "categories/gcid:foam_rubber_supplier"
    },
    {
        "displayName": "Universidad popular",
        "name": "categories/gcid:folk_high_school"
    },
    {
        "displayName": "Restaurante de fondue",
        "name": "categories/gcid:fondue_restaurant"
    },
    {
        "displayName": "Food and beverage consultant",
        "name": "categories/gcid:food_and_beverage_consultant"
    },
    {
        "displayName": "Exportador de alimentos y bebidas",
        "name": "categories/gcid:food_and_beverage_exporter"
    },
    {
        "displayName": "Banco de alimentos",
        "name": "categories/gcid:food_bank"
    },
    {
        "displayName": "Agente de alimentos",
        "name": "categories/gcid:food_broker"
    },
    {
        "displayName": "Patio de comidas",
        "name": "categories/gcid:food_court"
    },
    {
        "displayName": "Proveedor de maquinaria de alimentos",
        "name": "categories/gcid:food_machinery_supplier"
    },
    {
        "displayName": "Fabricante de alimentos",
        "name": "categories/gcid:food_manufacturer"
    },
    {
        "displayName": "Insumos para la manufactura de alimentos",
        "name": "categories/gcid:food_manufacturing_supply"
    },
    {
        "displayName": "Empresa procesadora de alimentos",
        "name": "categories/gcid:food_processing_company"
    },
    {
        "displayName": "Equipos para el procesamiento de alimentos",
        "name": "categories/gcid:food_processing_equipment"
    },
    {
        "displayName": "Productor de alimentos",
        "name": "categories/gcid:food_producer"
    },
    {
        "displayName": "Proveedor de productos comestibles",
        "name": "categories/gcid:food_products_supplier"
    },
    {
        "displayName": "Fabricante de condimentos para alimentos",
        "name": "categories/gcid:food_seasoning_manufacturer"
    },
    {
        "displayName": "Baño de pies",
        "name": "categories/gcid:foot_bath"
    },
    {
        "displayName": "Centro para el cuidado de los pies",
        "name": "categories/gcid:foot_care"
    },
    {
        "displayName": "Salón de masaje para pies",
        "name": "categories/gcid:foot_massage_parlor"
    },
    {
        "displayName": "Club de fútbol australiano",
        "name": "categories/gcid:football_club"
    },
    {
        "displayName": "Cancha de fútbol americano",
        "name": "categories/gcid:football_field"
    },
    {
        "displayName": "Concesionario Ford",
        "name": "categories/gcid:ford_dealer"
    },
    {
        "displayName": "Servicio de ejecución de hipotecas",
        "name": "categories/gcid:foreclosure_service"
    },
    {
        "displayName": "Consulado extranjero",
        "name": "categories/gcid:foreign_consulate"
    },
    {
        "displayName": "Organización de estudiantes extranjeros de intercambio",
        "name": "categories/gcid:foreign_exchange_students_organization"
    },
    {
        "displayName": "Instituto de lenguas extranjeras",
        "name": "categories/gcid:foreign_languages_program_school"
    },
    {
        "displayName": "Consultor de comercio exterior",
        "name": "categories/gcid:foreign_trade_consultant"
    },
    {
        "displayName": "Asociación de jefes de obra",
        "name": "categories/gcid:foreman_builders_association"
    },
    {
        "displayName": "Consultor forense",
        "name": "categories/gcid:forensic_consultant"
    },
    {
        "displayName": "Servicio forestal",
        "name": "categories/gcid:forestry_service"
    },
    {
        "displayName": "Distribuidor de elevadores de horquilla",
        "name": "categories/gcid:forklift_dealer"
    },
    {
        "displayName": "Servicio de alquiler de montacargas",
        "name": "categories/gcid:forklift_rental_service"
    },
    {
        "displayName": "Tienda de ropa formal",
        "name": "categories/gcid:formal_clothing_store"
    },
    {
        "displayName": "Fortaleza",
        "name": "categories/gcid:fortress"
    },
    {
        "displayName": "Servicios de clarividencia",
        "name": "categories/gcid:fortune_telling_services"
    },
    {
        "displayName": "Servicio de acogimiento familiar",
        "name": "categories/gcid:foster_care_service"
    },
    {
        "displayName": "Base",
        "name": "categories/gcid:foundation"
    },
    {
        "displayName": "Fundidora",
        "name": "categories/gcid:foundry"
    },
    {
        "displayName": "Contratista de fuentes",
        "name": "categories/gcid:fountain_contractor"
    },
    {
        "displayName": "Iglesia del Evangelio Cuadrangular",
        "name": "categories/gcid:foursquare_church"
    },
    {
        "displayName": "Restaurante francón",
        "name": "categories/gcid:franconian_restaurant"
    },
    {
        "displayName": "Organización fraternal",
        "name": "categories/gcid:fraternal_organization"
    },
    {
        "displayName": "Clínica gratis",
        "name": "categories/gcid:free_clinic"
    },
    {
        "displayName": "Playa de estacionamiento gratuito",
        "name": "categories/gcid:free_parking_lot"
    },
    {
        "displayName": "Gimnasio de lucha libre",
        "name": "categories/gcid:freestyle_wrestling"
    },
    {
        "displayName": "Servicio de despacho de mercadería",
        "name": "categories/gcid:freight_forwarding_service"
    },
    {
        "displayName": "Escuela de francés",
        "name": "categories/gcid:french_language_school"
    },
    {
        "displayName": "Restaurante francés",
        "name": "categories/gcid:french_restaurant"
    },
    {
        "displayName": "Restaurante especializado en filete francés",
        "name": "categories/gcid:french_steakhouse_restaurant"
    },
    {
        "displayName": "Mercado de alimentos frescos",
        "name": "categories/gcid:fresh_food_market"
    },
    {
        "displayName": "Restaurante de pollo frito para llevar",
        "name": "categories/gcid:fried_chicken_takeaway"
    },
    {
        "displayName": "Iglesia de los Amigos",
        "name": "categories/gcid:friends_church"
    },
    {
        "displayName": "Fritería",
        "name": "categories/gcid:frituur"
    },
    {
        "displayName": "Proveedor de postres congelados",
        "name": "categories/gcid:frozen_dessert_supplier"
    },
    {
        "displayName": "Fabricante de comida congelada",
        "name": "categories/gcid:frozen_food_manufacturer"
    },
    {
        "displayName": "Tienda de congelados",
        "name": "categories/gcid:frozen_food_store"
    },
    {
        "displayName": "Tienda de yogur helado",
        "name": "categories/gcid:frozen_yogurt_shop"
    },
    {
        "displayName": "Procesamiento de frutas y vegetales",
        "name": "categories/gcid:fruit_and_vegetable_processing"
    },
    {
        "displayName": "Tienda de frutas y verduras",
        "name": "categories/gcid:fruit_and_vegetable_store"
    },
    {
        "displayName": "Mayorista de frutas y hortalizas",
        "name": "categories/gcid:fruit_and_vegetable_wholesaler"
    },
    {
        "displayName": "Salón de frutas",
        "name": "categories/gcid:fruit_parlor"
    },
    {
        "displayName": "Mayorista de frutas",
        "name": "categories/gcid:fruit_wholesaler"
    },
    {
        "displayName": "Distribuidor mayorista de frutas",
        "name": "categories/gcid:fruits_wholesaler"
    },
    {
        "displayName": "Restaurante fujianés",
        "name": "categories/gcid:fu_jian_restaurant"
    },
    {
        "displayName": "Proveedor de combustible",
        "name": "categories/gcid:fuel_supplier"
    },
    {
        "displayName": "Restaurante de fugu",
        "name": "categories/gcid:fugu_restaurant"
    },
    {
        "displayName": "Alquiler de vestuario",
        "name": "categories/gcid:full_dress_rental_service"
    },
    {
        "displayName": "Iglesia del Evangelio Completo",
        "name": "categories/gcid:full_gospel_church"
    },
    {
        "displayName": "Salón para eventos",
        "name": "categories/gcid:function_room_facility"
    },
    {
        "displayName": "Servicio funerario civil",
        "name": "categories/gcid:funeral_celebrant_service"
    },
    {
        "displayName": "Director funerario",
        "name": "categories/gcid:funeral_director"
    },
    {
        "displayName": "Funeraria",
        "name": "categories/gcid:funeral_home"
    },
    {
        "displayName": "Tienda de abrigos de piel",
        "name": "categories/gcid:fur_coat_shop"
    },
    {
        "displayName": "Fabricante de pelaje",
        "name": "categories/gcid:fur_manufacturer"
    },
    {
        "displayName": "Fur service",
        "name": "categories/gcid:fur_service"
    },
    {
        "displayName": "Proveedor de piezas para hornos",
        "name": "categories/gcid:furnace_parts_supplier"
    },
    {
        "displayName": "Servicio de reparación de incineradores",
        "name": "categories/gcid:furnace_repair_service"
    },
    {
        "displayName": "Tienda de incineradores",
        "name": "categories/gcid:furnace_store"
    },
    {
        "displayName": "Edificio de departamentos amueblados",
        "name": "categories/gcid:furnished_apartment_building"
    },
    {
        "displayName": "Accesorios para muebles",
        "name": "categories/gcid:furniture_accessories"
    },
    {
        "displayName": "Proveedor de accesorios para muebles",
        "name": "categories/gcid:furniture_accessories_supplier"
    },
    {
        "displayName": "Fabricante de muebles",
        "name": "categories/gcid:furniture_maker"
    },
    {
        "displayName": "Fábrica de muebles",
        "name": "categories/gcid:furniture_manufacturer"
    },
    {
        "displayName": "Servicio de alquiler de mobiliario",
        "name": "categories/gcid:furniture_rental_service"
    },
    {
        "displayName": "Taller de reparación de muebles",
        "name": "categories/gcid:furniture_repair_shop"
    },
    {
        "displayName": "Tienda de muebles",
        "name": "categories/gcid:furniture_store"
    },
    {
        "displayName": "Mayorista de mobiliario",
        "name": "categories/gcid:furniture_wholesaler"
    },
    {
        "displayName": "Restaurante de comida fusión",
        "name": "categories/gcid:fusion_restaurant"
    },
    {
        "displayName": "Tienda de futones",
        "name": "categories/gcid:futon_store"
    },
    {
        "displayName": "Cancha de fútbol de salón",
        "name": "categories/gcid:futsal_field"
    },
    {
        "displayName": "Restaurante gallego",
        "name": "categories/gcid:galician_restaurant"
    },
    {
        "displayName": "Casa de apuestas",
        "name": "categories/gcid:gambling_house"
    },
    {
        "displayName": "Instructor de juegos de azar y apuestas",
        "name": "categories/gcid:gambling_instructor"
    },
    {
        "displayName": "Tienda de juegos",
        "name": "categories/gcid:game_store"
    },
    {
        "displayName": "Constructora de garajes",
        "name": "categories/gcid:garage_builder"
    },
    {
        "displayName": "Proveedor de puertas de garaje",
        "name": "categories/gcid:garage_door_supplier"
    },
    {
        "displayName": "Servicio de recolección de residuos",
        "name": "categories/gcid:garbage_collection_service"
    },
    {
        "displayName": "Basural",
        "name": "categories/gcid:garbage_dump"
    },
    {
        "displayName": "Garbage dump service",
        "name": "categories/gcid:garbage_dump_service"
    },
    {
        "displayName": "Jardín",
        "name": "categories/gcid:garden"
    },
    {
        "displayName": "Proveedor de estructuras de jardín",
        "name": "categories/gcid:garden_building_retail"
    },
    {
        "displayName": "Vivero",
        "name": "categories/gcid:garden_center"
    },
    {
        "displayName": "Tienda de mobiliario para jardín",
        "name": "categories/gcid:garden_furniture_store"
    },
    {
        "displayName": "Distribuidor de equipos para jardinería",
        "name": "categories/gcid:garden_machinery"
    },
    {
        "displayName": "Jardinero",
        "name": "categories/gcid:gardener"
    },
    {
        "displayName": "Exportador de ropa",
        "name": "categories/gcid:garment_exporter"
    },
    {
        "displayName": "Empresa de gas",
        "name": "categories/gcid:gas_company"
    },
    {
        "displayName": "Proveedor de cilindros de gas",
        "name": "categories/gcid:gas_cylinders_supplier"
    },
    {
        "displayName": "Gas engineer",
        "name": "categories/gcid:gas_engineer"
    },
    {
        "displayName": "Servicio de instalación de gas",
        "name": "categories/gcid:gas_installation_service"
    },
    {
        "displayName": "Proveedor de leña",
        "name": "categories/gcid:gas_logs_supplier"
    },
    {
        "displayName": "Tienda de gas y artículos relacionados",
        "name": "categories/gcid:gas_shop"
    },
    {
        "displayName": "Gasolinera",
        "name": "categories/gcid:gas_station"
    },
    {
        "displayName": "Gasista",
        "name": "categories/gcid:gasfitter"
    },
    {
        "displayName": "Fabricante de empaquetadura",
        "name": "categories/gcid:gasket_manufacturer"
    },
    {
        "displayName": "Gastroenterólogo",
        "name": "categories/gcid:gastroenterologist"
    },
    {
        "displayName": "Cirujano gastrointestinal",
        "name": "categories/gcid:gastrointestinal_surgeon"
    },
    {
        "displayName": "Restobar",
        "name": "categories/gcid:gastropub"
    },
    {
        "displayName": "Comunidad cerrada",
        "name": "categories/gcid:gated_community"
    },
    {
        "displayName": "Organización de LGBT",
        "name": "categories/gcid:gay_and_lesbian_organization"
    },
    {
        "displayName": "Bar de homosexuales",
        "name": "categories/gcid:gay_bar"
    },
    {
        "displayName": "Club nocturno gay",
        "name": "categories/gcid:gay_night_club"
    },
    {
        "displayName": "Sauna gay",
        "name": "categories/gcid:gay_sauna"
    },
    {
        "displayName": "Constructor de glorietas",
        "name": "categories/gcid:gazebo_builder"
    },
    {
        "displayName": "Gemologist",
        "name": "categories/gcid:gemologist"
    },
    {
        "displayName": "Genealogista",
        "name": "categories/gcid:genealogist"
    },
    {
        "displayName": "Contratista general",
        "name": "categories/gcid:general_contractor"
    },
    {
        "displayName": "Escuela de enseñanza general",
        "name": "categories/gcid:general_education_school"
    },
    {
        "displayName": "Hospital general",
        "name": "categories/gcid:general_hospital"
    },
    {
        "displayName": "Abogado general",
        "name": "categories/gcid:general_practice_attorney"
    },
    {
        "displayName": "Médico de familia",
        "name": "categories/gcid:general_practitioner"
    },
    {
        "displayName": "General register office",
        "name": "categories/gcid:general_register_office"
    },
    {
        "displayName": "Almacén",
        "name": "categories/gcid:general_store"
    },
    {
        "displayName": "Tienda de generadores eléctricos",
        "name": "categories/gcid:generator_shop"
    },
    {
        "displayName": "Concesionario Genesis",
        "name": "categories/gcid:genesis_dealer"
    },
    {
        "displayName": "Facultad de Historia y Geografía",
        "name": "categories/gcid:geography_and_history_faculty"
    },
    {
        "displayName": "Compañía de investigación geológica",
        "name": "categories/gcid:geological_research_company"
    },
    {
        "displayName": "Servicio geológico",
        "name": "categories/gcid:geological_service"
    },
    {
        "displayName": "Restaurante de cocina de Georgia",
        "name": "categories/gcid:georgian_restaurant"
    },
    {
        "displayName": "Ingeniero geotécnico",
        "name": "categories/gcid:geotechnical_engineer"
    },
    {
        "displayName": "Geriatra",
        "name": "categories/gcid:geriatrician"
    },
    {
        "displayName": "Escuela de alemán",
        "name": "categories/gcid:german_language_school"
    },
    {
        "displayName": "Restaurante alemán",
        "name": "categories/gcid:german_restaurant"
    },
    {
        "displayName": "Terapeuta Gestalt",
        "name": "categories/gcid:gestalt_therapist"
    },
    {
        "displayName": "Pueblo fantasma",
        "name": "categories/gcid:ghost_town"
    },
    {
        "displayName": "Tienda de canastas de regalos",
        "name": "categories/gcid:gift_basket_store"
    },
    {
        "displayName": "Tienda de regalos",
        "name": "categories/gcid:gift_shop"
    },
    {
        "displayName": "Tienda de envoltorios para regalos",
        "name": "categories/gcid:gift_wrap_store"
    },
    {
        "displayName": "Bar atendido por chicas",
        "name": "categories/gcid:girl_bar"
    },
    {
        "displayName": "Secundaria para señoritas",
        "name": "categories/gcid:girls_secondary_school"
    },
    {
        "displayName": "Tienda de cristales y espejos",
        "name": "categories/gcid:glass_and_mirror_shop"
    },
    {
        "displayName": "Proveedor de ladrillos de cristal",
        "name": "categories/gcid:glass_block_supplier"
    },
    {
        "displayName": "Soplador de vidrio",
        "name": "categories/gcid:glass_blower"
    },
    {
        "displayName": "Servicio de corte de vidrio",
        "name": "categories/gcid:glass_cutting_service"
    },
    {
        "displayName": "Servicio de grabado en vidrio",
        "name": "categories/gcid:glass_engraving"
    },
    {
        "displayName": "Servicio de grabado en vidrio",
        "name": "categories/gcid:glass_etching_service"
    },
    {
        "displayName": "Glass industry",
        "name": "categories/gcid:glass_industry"
    },
    {
        "displayName": "Fabricante de vidrio",
        "name": "categories/gcid:glass_manufacturer"
    },
    {
        "displayName": "Glass merchant",
        "name": "categories/gcid:glass_merchant"
    },
    {
        "displayName": "Servicio de reparación de cristales y espejos",
        "name": "categories/gcid:glass_repair_service"
    },
    {
        "displayName": "Vidriería",
        "name": "categories/gcid:glass_shop"
    },
    {
        "displayName": "Servicio de reparación de gafas",
        "name": "categories/gcid:glasses_repair_service"
    },
    {
        "displayName": "Fabricante de cristalería",
        "name": "categories/gcid:glassware_manufacturer"
    },
    {
        "displayName": "Cristalería",
        "name": "categories/gcid:glassware_store"
    },
    {
        "displayName": "Mayorista de cristalería",
        "name": "categories/gcid:glassware_wholesaler"
    },
    {
        "displayName": "Vidriero",
        "name": "categories/gcid:glazier"
    },
    {
        "displayName": "Restaurante de comidas sin gluten",
        "name": "categories/gcid:gluten_free_restaurant"
    },
    {
        "displayName": "Concesionario GMC",
        "name": "categories/gcid:gmc_dealer"
    },
    {
        "displayName": "Club de Go",
        "name": "categories/gcid:go_club"
    },
    {
        "displayName": "Pista de karting",
        "name": "categories/gcid:go_kart_track"
    },
    {
        "displayName": "Restaurante de cocina de Goa",
        "name": "categories/gcid:goan_restaurant"
    },
    {
        "displayName": "Vendedor de oro",
        "name": "categories/gcid:gold_dealer"
    },
    {
        "displayName": "Compañía minera de oro",
        "name": "categories/gcid:gold_mining_company"
    },
    {
        "displayName": "Tienda de peces dorados",
        "name": "categories/gcid:goldfish_store"
    },
    {
        "displayName": "Orfebre",
        "name": "categories/gcid:goldsmith"
    },
    {
        "displayName": "Concesionario de carros de golf",
        "name": "categories/gcid:golf_cart_dealer"
    },
    {
        "displayName": "Club de golf",
        "name": "categories/gcid:golf_club"
    },
    {
        "displayName": "Cancha de golf",
        "name": "categories/gcid:golf_course"
    },
    {
        "displayName": "Constructor de campos de golf",
        "name": "categories/gcid:golf_course_builder"
    },
    {
        "displayName": "Campo de golf",
        "name": "categories/gcid:golf_driving_range"
    },
    {
        "displayName": "Instructor de golf",
        "name": "categories/gcid:golf_instructor"
    },
    {
        "displayName": "Tienda de golf",
        "name": "categories/gcid:golf_shop"
    },
    {
        "displayName": "Iglesia del Evangelio",
        "name": "categories/gcid:gospel_church"
    },
    {
        "displayName": "Tienda de alimentos gourmet",
        "name": "categories/gcid:gourmet_grocery_store"
    },
    {
        "displayName": "Universidad de gobierno",
        "name": "categories/gcid:government_college"
    },
    {
        "displayName": "Government economic program",
        "name": "categories/gcid:government_economic_program"
    },
    {
        "displayName": "Hospital público",
        "name": "categories/gcid:government_hospital"
    },
    {
        "displayName": "Oficina administrativa",
        "name": "categories/gcid:government_office"
    },
    {
        "displayName": "Tienda de abasto social",
        "name": "categories/gcid:government_ration_shop"
    },
    {
        "displayName": "Escuela pública",
        "name": "categories/gcid:government_school"
    },
    {
        "displayName": "Proveedor de GPS",
        "name": "categories/gcid:gps_supplier"
    },
    {
        "displayName": "Escuela de graduados",
        "name": "categories/gcid:graduate_school"
    },
    {
        "displayName": "Servicio de eliminación de grafitis",
        "name": "categories/gcid:graffiti_removal_service"
    },
    {
        "displayName": "Elevador de granos",
        "name": "categories/gcid:grain_elevator"
    },
    {
        "displayName": "Instituto de enseñanza secundaria",
        "name": "categories/gcid:grammar_school"
    },
    {
        "displayName": "Proveedor de granito",
        "name": "categories/gcid:granite_supplier"
    },
    {
        "displayName": "Diseñador gráfico",
        "name": "categories/gcid:graphic_designer"
    },
    {
        "displayName": "Banco de grava",
        "name": "categories/gcid:gravel_pit"
    },
    {
        "displayName": "Planta de grava",
        "name": "categories/gcid:gravel_plant"
    },
    {
        "displayName": "Iglesia Ortodoxa Griega",
        "name": "categories/gcid:greek_orthodox_church"
    },
    {
        "displayName": "Restaurante griego",
        "name": "categories/gcid:greek_restaurant"
    },
    {
        "displayName": "Proveedor de energía renovable",
        "name": "categories/gcid:green_energy_supplier"
    },
    {
        "displayName": "Verdulería",
        "name": "categories/gcid:green_grocers"
    },
    {
        "displayName": "Invernadero",
        "name": "categories/gcid:greenhouse"
    },
    {
        "displayName": "Tienda de tarjetas de felicitación",
        "name": "categories/gcid:greeting_card_shop"
    },
    {
        "displayName": "Estadio para carreras de galgos",
        "name": "categories/gcid:greyhound_stadium"
    },
    {
        "displayName": "Tienda de parrillas",
        "name": "categories/gcid:grill_store"
    },
    {
        "displayName": "Servicio de entrega de comestibles",
        "name": "categories/gcid:grocery_delivery_service"
    },
    {
        "displayName": "Supermercado",
        "name": "categories/gcid:grocery_store"
    },
    {
        "displayName": "Ground self defense force",
        "name": "categories/gcid:ground_self_defense_force"
    },
    {
        "displayName": "Hogar de grupo",
        "name": "categories/gcid:group_home"
    },
    {
        "displayName": "Tienda para cultivo de cannabis",
        "name": "categories/gcid:grow_shop"
    },
    {
        "displayName": "Guardia Civil",
        "name": "categories/gcid:guardia_civil"
    },
    {
        "displayName": "Policía Guardia Di Finanza",
        "name": "categories/gcid:guardia_di_finanza_police"
    },
    {
        "displayName": "Restaurante de cocina de Guatemala",
        "name": "categories/gcid:guatemalan_restaurant"
    },
    {
        "displayName": "Casa de huéspedes",
        "name": "categories/gcid:guest_house"
    },
    {
        "displayName": "Restaurante Guizhou",
        "name": "categories/gcid:gui_zhou_restaurant"
    },
    {
        "displayName": "Instructor de guitarra",
        "name": "categories/gcid:guitar_instructor"
    },
    {
        "displayName": "Tienda de guitarras",
        "name": "categories/gcid:guitar_store"
    },
    {
        "displayName": "Restaurante gujarati",
        "name": "categories/gcid:gujarati_restaurant"
    },
    {
        "displayName": "Club de armas",
        "name": "categories/gcid:gun_club"
    },
    {
        "displayName": "Tienda de armas",
        "name": "categories/gcid:gun_shop"
    },
    {
        "displayName": "Restaurante especializado en barbacoa de asaduras",
        "name": "categories/gcid:guts_barbecue_restaurant"
    },
    {
        "displayName": "Servicio de limpieza de canaletas",
        "name": "categories/gcid:gutter_cleaning_service"
    },
    {
        "displayName": "Servicio de canaletas",
        "name": "categories/gcid:gutter_service"
    },
    {
        "displayName": "Gimnasio",
        "name": "categories/gcid:gym"
    },
    {
        "displayName": "Escuela de gimnasia",
        "name": "categories/gcid:gymnasium_school"
    },
    {
        "displayName": "Centro de gimnasia",
        "name": "categories/gcid:gymnastics_center"
    },
    {
        "displayName": "Club de gimnasia",
        "name": "categories/gcid:gymnastics_club"
    },
    {
        "displayName": "Ginecólogo-obstetra",
        "name": "categories/gcid:gynecologist"
    },
    {
        "displayName": "Ginecólogo",
        "name": "categories/gcid:gynecologist_only"
    },
    {
        "displayName": "Distribuidor de productos de yeso",
        "name": "categories/gcid:gypsum_product_supplier"
    },
    {
        "displayName": "Restaurante de gyro",
        "name": "categories/gcid:gyro_restaurant"
    },
    {
        "displayName": "Mercería",
        "name": "categories/gcid:haberdashery"
    },
    {
        "displayName": "Técnico en extensión de cabello",
        "name": "categories/gcid:hair_extension_technician"
    },
    {
        "displayName": "Proveedor de extensiones de cabello",
        "name": "categories/gcid:hair_extensions_supplier"
    },
    {
        "displayName": "Servicio de depilación",
        "name": "categories/gcid:hair_removal_service"
    },
    {
        "displayName": "Servicio de recuperación ante la caída del cabello",
        "name": "categories/gcid:hair_replacement_service"
    },
    {
        "displayName": "Peluquería",
        "name": "categories/gcid:hair_salon"
    },
    {
        "displayName": "Clínica de trasplante de cabello",
        "name": "categories/gcid:hair_transplantation_clinic"
    },
    {
        "displayName": "Restaurante de cocina haitiana",
        "name": "categories/gcid:haitian_restaurant"
    },
    {
        "displayName": "Restaurante hakka",
        "name": "categories/gcid:hakka_restaurant"
    },
    {
        "displayName": "Restaurante Halal",
        "name": "categories/gcid:halal_restaurant"
    },
    {
        "displayName": "Restaurante de haleem",
        "name": "categories/gcid:haleem_restaurant"
    },
    {
        "displayName": "Centro de transición",
        "name": "categories/gcid:halfway_house"
    },
    {
        "displayName": "Tienda de jamones",
        "name": "categories/gcid:ham_shop"
    },
    {
        "displayName": "Hamburguesería",
        "name": "categories/gcid:hamburger_restaurant"
    },
    {
        "displayName": "Hammam",
        "name": "categories/gcid:hammam"
    },
    {
        "displayName": "Cirujano de manos",
        "name": "categories/gcid:hand_surgeon"
    },
    {
        "displayName": "Tienda de bolsos",
        "name": "categories/gcid:handbags_shop"
    },
    {
        "displayName": "Club de balonmano",
        "name": "categories/gcid:handball_club"
    },
    {
        "displayName": "Cancha de hándbol",
        "name": "categories/gcid:handball_court"
    },
    {
        "displayName": "Servicio de transporte para discapacitados",
        "name": "categories/gcid:handicapped_transportation_service"
    },
    {
        "displayName": "Artesanías",
        "name": "categories/gcid:handicraft"
    },
    {
        "displayName": "Exportador de artesanía",
        "name": "categories/gcid:handicraft_exporter"
    },
    {
        "displayName": "Feria de artesanías",
        "name": "categories/gcid:handicraft_fair"
    },
    {
        "displayName": "Museo de artesanías",
        "name": "categories/gcid:handicraft_museum"
    },
    {
        "displayName": "Escuela de manualidades",
        "name": "categories/gcid:handicraft_school"
    },
    {
        "displayName": "Mayorista de artesanías",
        "name": "categories/gcid:handicrafts_wholesaler"
    },
    {
        "displayName": "Reparador doméstico",
        "name": "categories/gcid:handyman"
    },
    {
        "displayName": "Centro de aladeltismo",
        "name": "categories/gcid:hang_gliding_center"
    },
    {
        "displayName": "Ferretería",
        "name": "categories/gcid:hardware_store"
    },
    {
        "displayName": "Concesionario Harley-Davidson",
        "name": "categories/gcid:harley_davidson_dealer"
    },
    {
        "displayName": "Tienda de sombreros",
        "name": "categories/gcid:hat_shop"
    },
    {
        "displayName": "Casa embrujada",
        "name": "categories/gcid:haunted_house"
    },
    {
        "displayName": "Escuela básica",
        "name": "categories/gcid:hauptschule"
    },
    {
        "displayName": "Boutique de alta costura",
        "name": "categories/gcid:haute_couture_fashion_house"
    },
    {
        "displayName": "Restaurante de alta cocina francesa",
        "name": "categories/gcid:haute_french_restaurant"
    },
    {
        "displayName": "Tienda de productos hawaianos",
        "name": "categories/gcid:hawaiian_goods_store"
    },
    {
        "displayName": "Restaurante hawaiano",
        "name": "categories/gcid:hawaiian_restaurant"
    },
    {
        "displayName": "Hawker centre",
        "name": "categories/gcid:hawker_centre"
    },
    {
        "displayName": "Puesto de venta ambulante de comida",
        "name": "categories/gcid:hawker_stall"
    },
    {
        "displayName": "Proveedor de heno",
        "name": "categories/gcid:hay_supplier"
    },
    {
        "displayName": "Centro de educación preescolar",
        "name": "categories/gcid:head_start_center"
    },
    {
        "displayName": "Tienda de salud y belleza",
        "name": "categories/gcid:health_and_beauty_shop"
    },
    {
        "displayName": "Consultor de salud",
        "name": "categories/gcid:health_consultant"
    },
    {
        "displayName": "Consultor médico",
        "name": "categories/gcid:health_counselor"
    },
    {
        "displayName": "Restaurante de alimentos naturales",
        "name": "categories/gcid:health_food_restaurant"
    },
    {
        "displayName": "Tienda de comida sana",
        "name": "categories/gcid:health_food_store"
    },
    {
        "displayName": "Agencia de seguros médicos",
        "name": "categories/gcid:health_insurance_agency"
    },
    {
        "displayName": "Health resort",
        "name": "categories/gcid:health_resort"
    },
    {
        "displayName": "Spa",
        "name": "categories/gcid:health_spa"
    },
    {
        "displayName": "Servicio técnico de audífonos",
        "name": "categories/gcid:hearing_aid_repair_service"
    },
    {
        "displayName": "Tienda de aparatos auditivos",
        "name": "categories/gcid:hearing_aid_store"
    },
    {
        "displayName": "Tienda de audífonos de ayuda auditiva",
        "name": "categories/gcid:hearing_assistance_earphone_store"
    },
    {
        "displayName": "Hospital coronario",
        "name": "categories/gcid:heart_hospital"
    },
    {
        "displayName": "Contratista de calefacción",
        "name": "categories/gcid:heating_contractor"
    },
    {
        "displayName": "Proveedor de equipos de calefacción",
        "name": "categories/gcid:heating_equipment_supplier"
    },
    {
        "displayName": "Proveedor de gasóleo de calefacción",
        "name": "categories/gcid:heating_oil_supplier"
    },
    {
        "displayName": "Trabajo en altura",
        "name": "categories/gcid:height_works"
    },
    {
        "displayName": "Alquiler de helicóptero",
        "name": "categories/gcid:helicopter_charter"
    },
    {
        "displayName": "Agencia de turismo en helicóptero",
        "name": "categories/gcid:helicopter_tour_agency"
    },
    {
        "displayName": "Helipuerto",
        "name": "categories/gcid:heliport"
    },
    {
        "displayName": "Proveedor de helio",
        "name": "categories/gcid:helium_gas_supplier"
    },
    {
        "displayName": "Helpline",
        "name": "categories/gcid:helpline"
    },
    {
        "displayName": "Hematólogo",
        "name": "categories/gcid:hematologist"
    },
    {
        "displayName": "Hepatólogo",
        "name": "categories/gcid:hepatologist"
    },
    {
        "displayName": "Tienda de hierbas",
        "name": "categories/gcid:herb_shop"
    },
    {
        "displayName": "Herboristería",
        "name": "categories/gcid:herbal_medicine_store"
    },
    {
        "displayName": "Herborista",
        "name": "categories/gcid:herbalist"
    },
    {
        "displayName": "Edificio patrimonial",
        "name": "categories/gcid:heritage_building"
    },
    {
        "displayName": "Museo patrimonial",
        "name": "categories/gcid:heritage_museum"
    },
    {
        "displayName": "Preservación del patrimonio",
        "name": "categories/gcid:heritage_preservation"
    },
    {
        "displayName": "Tren histórico",
        "name": "categories/gcid:heritage_railroad"
    },
    {
        "displayName": "Curso de cuerdas altas",
        "name": "categories/gcid:high_ropes_course"
    },
    {
        "displayName": "Escuela secundaria",
        "name": "categories/gcid:high_school"
    },
    {
        "displayName": "Escuela secundaria",
        "name": "categories/gcid:higher_secondary_school"
    },
    {
        "displayName": "Highway patrol",
        "name": "categories/gcid:highway_patrol"
    },
    {
        "displayName": "Área de excursionismo",
        "name": "categories/gcid:hiking_area"
    },
    {
        "displayName": "Guía de senderismo",
        "name": "categories/gcid:hiking_guide"
    },
    {
        "displayName": "Sacerdote hindú",
        "name": "categories/gcid:hindu_priest"
    },
    {
        "displayName": "Templo hindú",
        "name": "categories/gcid:hindu_temple"
    },
    {
        "displayName": "Clase de baile hip hop",
        "name": "categories/gcid:hip_hop_dance_class"
    },
    {
        "displayName": "Iglesia Hispana",
        "name": "categories/gcid:hispanic_church"
    },
    {
        "displayName": "Punto de referencia histórico",
        "name": "categories/gcid:historical_landmark"
    },
    {
        "displayName": "Museo Histórico",
        "name": "categories/gcid:historical_place_museum"
    },
    {
        "displayName": "Sociedad histórica",
        "name": "categories/gcid:historical_society"
    },
    {
        "displayName": "Museo de historia",
        "name": "categories/gcid:history_museum"
    },
    {
        "displayName": "Centro para examen de VIH",
        "name": "categories/gcid:hiv_testing_center"
    },
    {
        "displayName": "Restaurante de sándwiches submarino",
        "name": "categories/gcid:hoagie_restaurant"
    },
    {
        "displayName": "Tienda de aficiones",
        "name": "categories/gcid:hobby_store"
    },
    {
        "displayName": "Club de hockey",
        "name": "categories/gcid:hockey_club"
    },
    {
        "displayName": "Cancha de hockey",
        "name": "categories/gcid:hockey_field"
    },
    {
        "displayName": "Pista de hockey",
        "name": "categories/gcid:hockey_rink"
    },
    {
        "displayName": "Tienda de insumos para hockey",
        "name": "categories/gcid:hockey_supply_store"
    },
    {
        "displayName": "Sociedad de cartera",
        "name": "categories/gcid:holding_company"
    },
    {
        "displayName": "Alquiler de apartamento vacacional",
        "name": "categories/gcid:holiday_apartment_rental"
    },
    {
        "displayName": "Casa de vacaciones",
        "name": "categories/gcid:holiday_home"
    },
    {
        "displayName": "Médico especializado en medicina holística",
        "name": "categories/gcid:holistic_medicine_practitioner"
    },
    {
        "displayName": "Empresa de automatización residencial",
        "name": "categories/gcid:home_automation_company"
    },
    {
        "displayName": "Constructor de casas",
        "name": "categories/gcid:home_builder"
    },
    {
        "displayName": "Servicio de instalación de sistemas de cine en casa",
        "name": "categories/gcid:home_cinema_installation"
    },
    {
        "displayName": "Tienda de artículos para el hogar",
        "name": "categories/gcid:home_goods_store"
    },
    {
        "displayName": "Peluquero a domicilio",
        "name": "categories/gcid:home_hairdresser"
    },
    {
        "displayName": "Servicios de asistencia médica a domicilio",
        "name": "categories/gcid:home_health_care_service"
    },
    {
        "displayName": "Ayuda a domicilio",
        "name": "categories/gcid:home_help"
    },
    {
        "displayName": "Home help service agency",
        "name": "categories/gcid:home_help_service_agency"
    },
    {
        "displayName": "Tienda para la reforma del hogar",
        "name": "categories/gcid:home_improvement_store"
    },
    {
        "displayName": "Inspector de casas",
        "name": "categories/gcid:home_inspector"
    },
    {
        "displayName": "Agencia de seguros para el hogar",
        "name": "categories/gcid:home_insurance_agency"
    },
    {
        "displayName": "Servicio de preparación de vivienda",
        "name": "categories/gcid:home_staging_service"
    },
    {
        "displayName": "Tienda de equipos de teatro en el hogar",
        "name": "categories/gcid:home_theater_store"
    },
    {
        "displayName": "Matadero",
        "name": "categories/gcid:homekill_service"
    },
    {
        "displayName": "Servicio para gente sin hogar",
        "name": "categories/gcid:homeless_service"
    },
    {
        "displayName": "Refugio para personas sin hogar",
        "name": "categories/gcid:homeless_shelter"
    },
    {
        "displayName": "Homeopatía",
        "name": "categories/gcid:homeopath"
    },
    {
        "displayName": "Farmacia homeopática",
        "name": "categories/gcid:homeopathic_pharmacy"
    },
    {
        "displayName": "Asociación de propietarios",
        "name": "categories/gcid:homeowners_association"
    },
    {
        "displayName": "Concesionario Honda",
        "name": "categories/gcid:honda_dealer"
    },
    {
        "displayName": "Restaurante hondureño",
        "name": "categories/gcid:honduran_restaurant"
    },
    {
        "displayName": "Granja apícola",
        "name": "categories/gcid:honey_farm"
    },
    {
        "displayName": "Cha chaan teng",
        "name": "categories/gcid:hong_kong_style_cafe"
    },
    {
        "displayName": "Restaurante de comida rápida estilo hongkonés",
        "name": "categories/gcid:hong_kong_style_fast_food_restaurant"
    },
    {
        "displayName": "Bar de pipas turcas",
        "name": "categories/gcid:hookah_bar"
    },
    {
        "displayName": "Tienda de pipas de agua",
        "name": "categories/gcid:hookah_store"
    },
    {
        "displayName": "Establo para residencia de caballos",
        "name": "categories/gcid:horse_boarding_stable"
    },
    {
        "displayName": "Criadero de caballos",
        "name": "categories/gcid:horse_breeder"
    },
    {
        "displayName": "Servicio de alquiler de caballos",
        "name": "categories/gcid:horse_rental_service"
    },
    {
        "displayName": "Campo de equitación",
        "name": "categories/gcid:horse_riding_field"
    },
    {
        "displayName": "Escuela de equitación",
        "name": "categories/gcid:horse_riding_school"
    },
    {
        "displayName": "Distribuidor de remolques a caballo",
        "name": "categories/gcid:horse_trailer_dealer"
    },
    {
        "displayName": "Entrenador de caballos",
        "name": "categories/gcid:horse_trainer"
    },
    {
        "displayName": "Servicio de paseo a caballo",
        "name": "categories/gcid:horseback_riding_service"
    },
    {
        "displayName": "Horse transport supplier",
        "name": "categories/gcid:horsebox_specialist"
    },
    {
        "displayName": "Herrador",
        "name": "categories/gcid:horseshoe_smith"
    },
    {
        "displayName": "Criadero de caballos con establos",
        "name": "categories/gcid:horsestable_studfarm"
    },
    {
        "displayName": "Proveedor de mangueras",
        "name": "categories/gcid:hose_supplier"
    },
    {
        "displayName": "Hospicio",
        "name": "categories/gcid:hospice"
    },
    {
        "displayName": "Hospital",
        "name": "categories/gcid:hospital"
    },
    {
        "displayName": "Hospital equipment and supplies",
        "name": "categories/gcid:hospital_and_equipment_supplies"
    },
    {
        "displayName": "Unidad del hospital",
        "name": "categories/gcid:hospital_department"
    },
    {
        "displayName": "Escuela de hotelería y turismo",
        "name": "categories/gcid:hospitality_and_tourism_school"
    },
    {
        "displayName": "Escuela superior de hotelería y gastronomía",
        "name": "categories/gcid:hospitality_high_school"
    },
    {
        "displayName": "Club de acompañantes",
        "name": "categories/gcid:host_club"
    },
    {
        "displayName": "Albergue",
        "name": "categories/gcid:hostel"
    },
    {
        "displayName": "Spa de piedras calientes",
        "name": "categories/gcid:hot_bedstone_spa"
    },
    {
        "displayName": "Restaurante de perros calientes",
        "name": "categories/gcid:hot_dog_restaurant"
    },
    {
        "displayName": "Puesto de perros calientes",
        "name": "categories/gcid:hot_dog_stand"
    },
    {
        "displayName": "Restaurante \"Hot pot\"",
        "name": "categories/gcid:hot_pot_restaurant"
    },
    {
        "displayName": "Servicio de reparación de jacuzzis",
        "name": "categories/gcid:hot_tub_repair_service"
    },
    {
        "displayName": "Tienda de jacuzzi",
        "name": "categories/gcid:hot_tub_store"
    },
    {
        "displayName": "Proveedor de sistemas para agua caliente",
        "name": "categories/gcid:hot_water_system_supplier"
    },
    {
        "displayName": "Hotel",
        "name": "categories/gcid:hotel"
    },
    {
        "displayName": "Escuela de hostelería",
        "name": "categories/gcid:hotel_management_school"
    },
    {
        "displayName": "Tienda de artículos para hotel",
        "name": "categories/gcid:hotel_supply_store"
    },
    {
        "displayName": "Servicios de limpieza doméstica",
        "name": "categories/gcid:house_cleaning_service"
    },
    {
        "displayName": "Servicio de retirada de enseres",
        "name": "categories/gcid:house_clearance_service"
    },
    {
        "displayName": "Cuidador de casas",
        "name": "categories/gcid:house_sitter"
    },
    {
        "displayName": "Agencia de cuidado de casas",
        "name": "categories/gcid:house_sitter_agency"
    },
    {
        "displayName": "Servicio de alquiler de casas flotantes",
        "name": "categories/gcid:houseboat_rental_service"
    },
    {
        "displayName": "Proveedor de productos químicos de uso doméstico",
        "name": "categories/gcid:household_chemicals_supplier"
    },
    {
        "displayName": "Mayorista de artículos para el hogar",
        "name": "categories/gcid:household_goods_wholesaler"
    },
    {
        "displayName": "Asociación para la vivienda",
        "name": "categories/gcid:housing_association"
    },
    {
        "displayName": "Autoridad de viviendas",
        "name": "categories/gcid:housing_authority"
    },
    {
        "displayName": "Complejo de viviendas",
        "name": "categories/gcid:housing_complex"
    },
    {
        "displayName": "Cooperativa de viviendas",
        "name": "categories/gcid:housing_cooperative"
    },
    {
        "displayName": "Desarrollo Inmobiliario",
        "name": "categories/gcid:housing_development"
    },
    {
        "displayName": "Cooperativa de viviendas",
        "name": "categories/gcid:housing_society"
    },
    {
        "displayName": "Housing utility company",
        "name": "categories/gcid:housing_utility_company"
    },
    {
        "displayName": "Tienda de hua gong",
        "name": "categories/gcid:hua_gong_shop"
    },
    {
        "displayName": "Mercado Hua Niao",
        "name": "categories/gcid:hua_niao_market_place"
    },
    {
        "displayName": "Distribuidor de tapacubos",
        "name": "categories/gcid:hub_cap_supplier"
    },
    {
        "displayName": "Asesoría de recursos humanos",
        "name": "categories/gcid:human_ressource_consulting"
    },
    {
        "displayName": "Restaurante de cocina de Hunán",
        "name": "categories/gcid:hunan_style_restaurant"
    },
    {
        "displayName": "Restaurante húngaro",
        "name": "categories/gcid:hungarian_restaurant"
    },
    {
        "displayName": "Tienda de caza y pesca",
        "name": "categories/gcid:hunting_and_fishing_store"
    },
    {
        "displayName": "Área de caza",
        "name": "categories/gcid:hunting_area"
    },
    {
        "displayName": "Club de caza",
        "name": "categories/gcid:hunting_club"
    },
    {
        "displayName": "Hunting preserve",
        "name": "categories/gcid:hunting_preserve"
    },
    {
        "displayName": "Tienda de caza",
        "name": "categories/gcid:hunting_store"
    },
    {
        "displayName": "Contratista de sistemas de climatización",
        "name": "categories/gcid:hvac_contractor"
    },
    {
        "displayName": "Restaurante hyderabadí",
        "name": "categories/gcid:hyderabadi_restaurant"
    },
    {
        "displayName": "Taller de ingeniería hidráulica",
        "name": "categories/gcid:hydraulic_engineer"
    },
    {
        "displayName": "Proveedor de equipos hidráulicos",
        "name": "categories/gcid:hydraulic_equipment_supplier"
    },
    {
        "displayName": "Servicio de reparación hidráulica",
        "name": "categories/gcid:hydraulic_repair_service"
    },
    {
        "displayName": "Central hidroeléctrica",
        "name": "categories/gcid:hydroelectric_power_plant"
    },
    {
        "displayName": "Proveedor de equipos hidropónicos",
        "name": "categories/gcid:hydroponics_equipment_supplier"
    },
    {
        "displayName": "Mayorista de artículos de higiene",
        "name": "categories/gcid:hygiene_articles_wholesaler"
    },
    {
        "displayName": "Médico especialista en medicina hiperbárica",
        "name": "categories/gcid:hyperbaric_medicine_physician"
    },
    {
        "displayName": "Hipermercado",
        "name": "categories/gcid:hypermarket"
    },
    {
        "displayName": "Servicio de hipnoterapia",
        "name": "categories/gcid:hypnotherapy_service"
    },
    {
        "displayName": "Concesionario Hyundai",
        "name": "categories/gcid:hyundai_dealer"
    },
    {
        "displayName": "Proveedor de equipos para la fabricación de helados",
        "name": "categories/gcid:ice_cream_equipment_supplier"
    },
    {
        "displayName": "Heladería",
        "name": "categories/gcid:ice_cream_shop"
    },
    {
        "displayName": "Club de hockey sobre hielo",
        "name": "categories/gcid:ice_hockey_club"
    },
    {
        "displayName": "Club de patinaje sobre hielo",
        "name": "categories/gcid:ice_skating_club"
    },
    {
        "displayName": "Instructor de patinaje sobre hielo",
        "name": "categories/gcid:ice_skating_instructor"
    },
    {
        "displayName": "Pista de hielo",
        "name": "categories/gcid:ice_skating_rink"
    },
    {
        "displayName": "Proveedor de hielo",
        "name": "categories/gcid:ice_supplier"
    },
    {
        "displayName": "Restaurante islandés",
        "name": "categories/gcid:icelandic_restaurant"
    },
    {
        "displayName": "Escuela ICSE",
        "name": "categories/gcid:icse_school"
    },
    {
        "displayName": "Fabricante de objetos de culto",
        "name": "categories/gcid:idol_manufacturer"
    },
    {
        "displayName": "Restaurante especializado en ikan\\xa0bakar",
        "name": "categories/gcid:ikan_bakar_restaurant"
    },
    {
        "displayName": "Asesor de imagen",
        "name": "categories/gcid:image_consultant"
    },
    {
        "displayName": "Cine IMAX",
        "name": "categories/gcid:imax_theater"
    },
    {
        "displayName": "Servicio de inmigración y naturalización",
        "name": "categories/gcid:immigration_and_naturalization_service"
    },
    {
        "displayName": "Abogado especialista en inmigración",
        "name": "categories/gcid:immigration_attorney"
    },
    {
        "displayName": "Centro de detención de inmigrantes",
        "name": "categories/gcid:immigration_detention_center"
    },
    {
        "displayName": "Inmunólogo",
        "name": "categories/gcid:immunologist"
    },
    {
        "displayName": "Servicios de impermeabilización",
        "name": "categories/gcid:impermeabilization_service"
    },
    {
        "displayName": "Empresa de importación y exportación",
        "name": "categories/gcid:import_export_company"
    },
    {
        "displayName": "Importador",
        "name": "categories/gcid:importer"
    },
    {
        "displayName": "Proveedor de inciensos",
        "name": "categories/gcid:incense_supplier"
    },
    {
        "displayName": "Planta incineradora",
        "name": "categories/gcid:incineration_plant"
    },
    {
        "displayName": "Income protection insurance agency",
        "name": "categories/gcid:income_protection_insurance"
    },
    {
        "displayName": "Asociación de ayuda para el impuesto a los ingresos",
        "name": "categories/gcid:income_tax_help_association"
    },
    {
        "displayName": "Tienda de comestibles de la India",
        "name": "categories/gcid:indian_grocery_store"
    },
    {
        "displayName": "Concesionario de motos Indian",
        "name": "categories/gcid:indian_motorcycle_dealer"
    },
    {
        "displayName": "Restaurante indio musulmán",
        "name": "categories/gcid:indian_muslim_restaurant"
    },
    {
        "displayName": "Restaurante indio",
        "name": "categories/gcid:indian_restaurant"
    },
    {
        "displayName": "Restaurante de sizzler hindú",
        "name": "categories/gcid:indian_sizzler_restaurant"
    },
    {
        "displayName": "Tienda de dulces de la India",
        "name": "categories/gcid:indian_sweets_shop"
    },
    {
        "displayName": "Comida hindú para llevar",
        "name": "categories/gcid:indian_takeaway"
    },
    {
        "displayName": "Restaurante de cocina indonesia",
        "name": "categories/gcid:indonesian_restaurant"
    },
    {
        "displayName": "Ciclismo indoor",
        "name": "categories/gcid:indoor_cycling"
    },
    {
        "displayName": "Campo de golf cubierto",
        "name": "categories/gcid:indoor_golf_course"
    },
    {
        "displayName": "Patio de juegos cubierto",
        "name": "categories/gcid:indoor_playground"
    },
    {
        "displayName": "Pista de nieve cubierta",
        "name": "categories/gcid:indoor_snowcenter"
    },
    {
        "displayName": "Piscina cubierta",
        "name": "categories/gcid:indoor_swimming_pool"
    },
    {
        "displayName": "Distribuidor de productos químicos industriales",
        "name": "categories/gcid:industrial_chemicals_wholesaler"
    },
    {
        "displayName": "Consultor industrial",
        "name": "categories/gcid:industrial_consultant"
    },
    {
        "displayName": "Empresa de diseño industrial",
        "name": "categories/gcid:industrial_design_company"
    },
    {
        "displayName": "Industrial door supplier",
        "name": "categories/gcid:industrial_door_supplier"
    },
    {
        "displayName": "Ingeniero industrial",
        "name": "categories/gcid:industrial_engineer"
    },
    {
        "displayName": "Asociación de ingenieros industriales",
        "name": "categories/gcid:industrial_engineers_association"
    },
    {
        "displayName": "Proveedor de equipos industriales",
        "name": "categories/gcid:industrial_equipment_supplier"
    },
    {
        "displayName": "Industrial framework supplier",
        "name": "categories/gcid:industrial_framework_supplier"
    },
    {
        "displayName": "Proveedor de gas industrial",
        "name": "categories/gcid:industrial_gas_supplier"
    },
    {
        "displayName": "Agencia inmobiliaria industrial",
        "name": "categories/gcid:industrial_real_estate_agency"
    },
    {
        "displayName": "Supermercado industrial",
        "name": "categories/gcid:industrial_supermarket"
    },
    {
        "displayName": "Asociación de ingenieros técnicos industriales",
        "name": "categories/gcid:industrial_technical_engineers_association"
    },
    {
        "displayName": "Proveedor de equipos industriales para aspirar",
        "name": "categories/gcid:industrial_vacuum_equipment_supplier"
    },
    {
        "displayName": "Infectólogo",
        "name": "categories/gcid:infectious_disease_physician"
    },
    {
        "displayName": "Concesionario Infiniti",
        "name": "categories/gcid:infiniti_dealer"
    },
    {
        "displayName": "Servicios de información",
        "name": "categories/gcid:information_services"
    },
    {
        "displayName": "Posada",
        "name": "categories/gcid:inn"
    },
    {
        "displayName": "Servicio para casos de insolvencia",
        "name": "categories/gcid:insolvency_service"
    },
    {
        "displayName": "Instituto de geografía y estadísticas",
        "name": "categories/gcid:institute_of_geography_and_statistics"
    },
    {
        "displayName": "Ingeniero de instrumentación",
        "name": "categories/gcid:instrumentation_engineer"
    },
    {
        "displayName": "Contratista de aislamiento",
        "name": "categories/gcid:insulation_contractor"
    },
    {
        "displayName": "Tienda de materiales aislantes",
        "name": "categories/gcid:insulation_materials_store"
    },
    {
        "displayName": "Proveedor de aislantes eléctricos",
        "name": "categories/gcid:insulator_supplier"
    },
    {
        "displayName": "Agencia de seguros",
        "name": "categories/gcid:insurance_agency"
    },
    {
        "displayName": "Abogado de seguros",
        "name": "categories/gcid:insurance_attorney"
    },
    {
        "displayName": "Corredor de seguros",
        "name": "categories/gcid:insurance_broker"
    },
    {
        "displayName": "Aseguradora",
        "name": "categories/gcid:insurance_company"
    },
    {
        "displayName": "Registro de propiedad intelectual",
        "name": "categories/gcid:intellectual_property_registry"
    },
    {
        "displayName": "Intensivista",
        "name": "categories/gcid:intensivist"
    },
    {
        "displayName": "Oficina de arquitecto de interiores",
        "name": "categories/gcid:interior_architect_office"
    },
    {
        "displayName": "Contratista para construcción de interiores",
        "name": "categories/gcid:interior_construction_contractor"
    },
    {
        "displayName": "Decorador de interiores",
        "name": "categories/gcid:interior_decorator"
    },
    {
        "displayName": "Diseñador de interiores",
        "name": "categories/gcid:interior_designer"
    },
    {
        "displayName": "Interior door shop",
        "name": "categories/gcid:interior_door"
    },
    {
        "displayName": "Contratista de instalaciones interiores",
        "name": "categories/gcid:interior_fitting_contractor"
    },
    {
        "displayName": "Servicio de plantas de interior",
        "name": "categories/gcid:interior_plant_service"
    },
    {
        "displayName": "Unidad de medicina interna",
        "name": "categories/gcid:internal_medicine_ward"
    },
    {
        "displayName": "Aeropuerto internacional",
        "name": "categories/gcid:international_airport"
    },
    {
        "displayName": "Escuela Internacional",
        "name": "categories/gcid:international_school"
    },
    {
        "displayName": "Asesor en comercio internacional",
        "name": "categories/gcid:international_trade_consultant"
    },
    {
        "displayName": "Café internet",
        "name": "categories/gcid:internet_cafe"
    },
    {
        "displayName": "Servicio de marketing por Internet",
        "name": "categories/gcid:internet_marketing_service"
    },
    {
        "displayName": "Proveedor de servicios de internet",
        "name": "categories/gcid:internet_service_provider"
    },
    {
        "displayName": "Tienda de artículos relacionados con Internet",
        "name": "categories/gcid:internet_shop"
    },
    {
        "displayName": "Internista",
        "name": "categories/gcid:internist"
    },
    {
        "displayName": "Banco de inversiones",
        "name": "categories/gcid:investment_bank"
    },
    {
        "displayName": "Sociedad de inversión",
        "name": "categories/gcid:investment_company"
    },
    {
        "displayName": "Servicio de inversión",
        "name": "categories/gcid:investment_service"
    },
    {
        "displayName": "Servicio de impresión de invitaciones",
        "name": "categories/gcid:invitation_printing_service"
    },
    {
        "displayName": "Tienda de productos irlandeses",
        "name": "categories/gcid:irish_goods_store"
    },
    {
        "displayName": "Pub irlandés",
        "name": "categories/gcid:irish_pub"
    },
    {
        "displayName": "Restaurante irlandés",
        "name": "categories/gcid:irish_restaurant"
    },
    {
        "displayName": "Empresa de hierros y aceros",
        "name": "categories/gcid:iron_steel_contractor"
    },
    {
        "displayName": "Proveedor de artículos de hierro",
        "name": "categories/gcid:iron_ware_dealer"
    },
    {
        "displayName": "Siderúrgica",
        "name": "categories/gcid:iron_works"
    },
    {
        "displayName": "Proveedor de equipos de irrigación",
        "name": "categories/gcid:irrigation_equipment_supplier"
    },
    {
        "displayName": "Restaurante israelí",
        "name": "categories/gcid:israeli_restaurant"
    },
    {
        "displayName": "Concesionario Isuzu",
        "name": "categories/gcid:isuzu_dealer"
    },
    {
        "displayName": "Supermercado de productos italianos",
        "name": "categories/gcid:italian_grocery_store"
    },
    {
        "displayName": "Restaurante italiano",
        "name": "categories/gcid:italian_restaurant"
    },
    {
        "displayName": "Instituto tecnológico",
        "name": "categories/gcid:iut"
    },
    {
        "displayName": "Concesionario Jaguar",
        "name": "categories/gcid:jaguar_dealer"
    },
    {
        "displayName": "Templo jaina",
        "name": "categories/gcid:jain_temple"
    },
    {
        "displayName": "Restaurante de cocina jamaiquina",
        "name": "categories/gcid:jamaican_restaurant"
    },
    {
        "displayName": "Proveedor de equipos de limpieza",
        "name": "categories/gcid:janitorial_equipment_supplier"
    },
    {
        "displayName": "Servicio de limpieza",
        "name": "categories/gcid:janitorial_service"
    },
    {
        "displayName": "Restaurante japonés auténtico",
        "name": "categories/gcid:japanese_authentic_restaurant"
    },
    {
        "displayName": "Tienda de golosinas japonesas económicas",
        "name": "categories/gcid:japanese_cheap_sweets_shop"
    },
    {
        "displayName": "Tienda de confitería japonesa",
        "name": "categories/gcid:japanese_confectionery_shop"
    },
    {
        "displayName": "Restaurante de curry japonés",
        "name": "categories/gcid:japanese_curry_restaurant"
    },
    {
        "displayName": "Charcutería japonesa",
        "name": "categories/gcid:japanese_delicatessen"
    },
    {
        "displayName": "Tienda de productos comestibles japoneses",
        "name": "categories/gcid:japanese_grocery_store"
    },
    {
        "displayName": "Restaurante riotei",
        "name": "categories/gcid:japanese_high_quality_restaurant"
    },
    {
        "displayName": "Bar izakaya",
        "name": "categories/gcid:japanese_izakaya_restaurant"
    },
    {
        "displayName": "Profesor de japonés",
        "name": "categories/gcid:japanese_language_instructor"
    },
    {
        "displayName": "Restaurante regional japonés",
        "name": "categories/gcid:japanese_regional_restaurant"
    },
    {
        "displayName": "Restaurante japonés",
        "name": "categories/gcid:japanese_restaurant"
    },
    {
        "displayName": "Restaurante de carne japonesa",
        "name": "categories/gcid:japanese_steakhouse"
    },
    {
        "displayName": "Restaurante de dulces japoneses",
        "name": "categories/gcid:japanese_sweets_restaurant"
    },
    {
        "displayName": "Restaurante de comida occidental con estilo japonés",
        "name": "categories/gcid:japanized_western_food_restaurant"
    },
    {
        "displayName": "Restaurante javanés",
        "name": "categories/gcid:javanese_restaurant"
    },
    {
        "displayName": "Club de jazz",
        "name": "categories/gcid:jazz_club"
    },
    {
        "displayName": "Concesionario Jeep",
        "name": "categories/gcid:jeep_dealer"
    },
    {
        "displayName": "Salón del Reino de los Testigos de Jehová",
        "name": "categories/gcid:jehovahs_witness_church"
    },
    {
        "displayName": "Joyero",
        "name": "categories/gcid:jeweler"
    },
    {
        "displayName": "Tasador de joyas",
        "name": "categories/gcid:jewelry_appraiser"
    },
    {
        "displayName": "Comprador de joyas",
        "name": "categories/gcid:jewelry_buyer"
    },
    {
        "displayName": "Diseñador de joyas",
        "name": "categories/gcid:jewelry_designer"
    },
    {
        "displayName": "Grabador de joyería",
        "name": "categories/gcid:jewelry_engraver"
    },
    {
        "displayName": "Proveedor de equipos para joyería",
        "name": "categories/gcid:jewelry_equipment_supplier"
    },
    {
        "displayName": "Exportador de joyería",
        "name": "categories/gcid:jewelry_exporter"
    },
    {
        "displayName": "Jewelry manufacturer",
        "name": "categories/gcid:jewelry_manufacturer"
    },
    {
        "displayName": "Servicio de reparación de joyería",
        "name": "categories/gcid:jewelry_repair_service"
    },
    {
        "displayName": "Joyería",
        "name": "categories/gcid:jewelry_store"
    },
    {
        "displayName": "Restaurante de cocina judía",
        "name": "categories/gcid:jewish_restaurant"
    },
    {
        "displayName": "Restaurante de Jiang Su",
        "name": "categories/gcid:jiang_su_restaurant"
    },
    {
        "displayName": "Joiner",
        "name": "categories/gcid:joiner"
    },
    {
        "displayName": "Tienda judaica",
        "name": "categories/gcid:judaica_store"
    },
    {
        "displayName": "Remate judicial",
        "name": "categories/gcid:judicial_auction"
    },
    {
        "displayName": "Escribano judicial",
        "name": "categories/gcid:judicial_scrivener"
    },
    {
        "displayName": "Club de judo",
        "name": "categories/gcid:judo_club"
    },
    {
        "displayName": "Escuela de yudo",
        "name": "categories/gcid:judo_school"
    },
    {
        "displayName": "Puesto de jugos",
        "name": "categories/gcid:juice_shop"
    },
    {
        "displayName": "Escuela de jujitsu",
        "name": "categories/gcid:jujitsu_school"
    },
    {
        "displayName": "Colegio universitario",
        "name": "categories/gcid:junior_college"
    },
    {
        "displayName": "Comerciante de chatarra",
        "name": "categories/gcid:junk_dealer"
    },
    {
        "displayName": "Junk store",
        "name": "categories/gcid:junk_store"
    },
    {
        "displayName": "Depósito de chatarra",
        "name": "categories/gcid:junkyard"
    },
    {
        "displayName": "Departamento de Justicia",
        "name": "categories/gcid:justice_department"
    },
    {
        "displayName": "Jute exporter",
        "name": "categories/gcid:jute_exporter"
    },
    {
        "displayName": "Fábrica de yute",
        "name": "categories/gcid:jute_mill"
    },
    {
        "displayName": "Centro correccional juvenil",
        "name": "categories/gcid:juvenile_detention_center"
    },
    {
        "displayName": "Club de kabaddi",
        "name": "categories/gcid:kabaddi_club"
    },
    {
        "displayName": "Restaurante de kaiseki",
        "name": "categories/gcid:kaiseki_restaurant"
    },
    {
        "displayName": "Restaurante de kale pache",
        "name": "categories/gcid:kalle_pache_restaurant"
    },
    {
        "displayName": "Bar con karaoke",
        "name": "categories/gcid:karaoke_bar"
    },
    {
        "displayName": "Servicio de alquiler de equipos para karaoke",
        "name": "categories/gcid:karaoke_equipment_rental_service"
    },
    {
        "displayName": "Club de karate",
        "name": "categories/gcid:karate_club"
    },
    {
        "displayName": "Escuela de karate",
        "name": "categories/gcid:karate_school"
    },
    {
        "displayName": "Concesionario Karma",
        "name": "categories/gcid:karma_dealer"
    },
    {
        "displayName": "Restaurante Karnataka",
        "name": "categories/gcid:karnataka_restaurant"
    },
    {
        "displayName": "Restaurante especializado en comida cachemir",
        "name": "categories/gcid:kashmiri_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Kazajistán",
        "name": "categories/gcid:kazakhstani_restaurant"
    },
    {
        "displayName": "Tienda de pinchos",
        "name": "categories/gcid:kebab_shop"
    },
    {
        "displayName": "Perrera",
        "name": "categories/gcid:kennel"
    },
    {
        "displayName": "Restaurante keralite",
        "name": "categories/gcid:kerala_restaurant"
    },
    {
        "displayName": "Proveedor de queroseno",
        "name": "categories/gcid:kerosene_supplier"
    },
    {
        "displayName": "Servicio de duplicación de llaves",
        "name": "categories/gcid:key_duplication_service"
    },
    {
        "displayName": "Concesionario Kia",
        "name": "categories/gcid:kia_dealer"
    },
    {
        "displayName": "Escuela de kickboxing",
        "name": "categories/gcid:kickboxing_school"
    },
    {
        "displayName": "Venta y alquiler de faldas escocesas",
        "name": "categories/gcid:kilt_shop_and_hire"
    },
    {
        "displayName": "Tienda de kimonos",
        "name": "categories/gcid:kimono_store"
    },
    {
        "displayName": "Jardín de infantes",
        "name": "categories/gcid:kindergarten"
    },
    {
        "displayName": "Centro de kinesiología",
        "name": "categories/gcid:kinesiologist"
    },
    {
        "displayName": "Kinesioterapista",
        "name": "categories/gcid:kinesiotherapist"
    },
    {
        "displayName": "Quiosco",
        "name": "categories/gcid:kiosk"
    },
    {
        "displayName": "Tienda de mobiliario para cocina",
        "name": "categories/gcid:kitchen_furniture_store"
    },
    {
        "displayName": "Remodelador de cocinas",
        "name": "categories/gcid:kitchen_remodeler"
    },
    {
        "displayName": "Tienda de suministros de cocina",
        "name": "categories/gcid:kitchen_supply_store"
    },
    {
        "displayName": "Tienda de barriletes",
        "name": "categories/gcid:kite_shop"
    },
    {
        "displayName": "Fabricante de cuchillos",
        "name": "categories/gcid:knife_manufacturing"
    },
    {
        "displayName": "Cuchillería",
        "name": "categories/gcid:knife_store"
    },
    {
        "displayName": "Tienda de artículos para tejer",
        "name": "categories/gcid:knit_shop"
    },
    {
        "displayName": "Profesor de tricot",
        "name": "categories/gcid:knitting_instructor"
    },
    {
        "displayName": "Fabricante de prendas de punto",
        "name": "categories/gcid:knitwear_manufacturer"
    },
    {
        "displayName": "Restaurante de kofta",
        "name": "categories/gcid:kofta_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Konkani",
        "name": "categories/gcid:konkani_restaurant"
    },
    {
        "displayName": "Restaurante de barbacoa coreana",
        "name": "categories/gcid:korean_barbecue_restaurant"
    },
    {
        "displayName": "Restaurantes coreanos de carnes",
        "name": "categories/gcid:korean_beef_restaurant"
    },
    {
        "displayName": "Iglesia coreana",
        "name": "categories/gcid:korean_church"
    },
    {
        "displayName": "Supermercado de productos coreanos",
        "name": "categories/gcid:korean_grocery_store"
    },
    {
        "displayName": "Restaurante coreano",
        "name": "categories/gcid:korean_restaurant"
    },
    {
        "displayName": "Restaurante coreano de costillas",
        "name": "categories/gcid:korean_rib_restaurant"
    },
    {
        "displayName": "Restaurante de koshari",
        "name": "categories/gcid:koshari_restaurant"
    },
    {
        "displayName": "Supermercado de productos kosher",
        "name": "categories/gcid:kosher_grocery_store"
    },
    {
        "displayName": "Restaurante de cocina kosher",
        "name": "categories/gcid:kosher_restaurant"
    },
    {
        "displayName": "Escuela de Kung Fu",
        "name": "categories/gcid:kung_fu_school"
    },
    {
        "displayName": "Restaurante de kushiyaki",
        "name": "categories/gcid:kushiyaki_restaurant"
    },
    {
        "displayName": "Restaurante japonés con estilo de Kyoto",
        "name": "categories/gcid:kyoto_cuisine_restaurant"
    },
    {
        "displayName": "Abogado de relaciones laborales",
        "name": "categories/gcid:labor_relations_attorney"
    },
    {
        "displayName": "Sindicato",
        "name": "categories/gcid:labor_union"
    },
    {
        "displayName": "Laboratorio",
        "name": "categories/gcid:laboratory"
    },
    {
        "displayName": "Proveedor de equipos para laboratorio",
        "name": "categories/gcid:laboratory_equipment_supplier"
    },
    {
        "displayName": "Servicio de lactancia materna",
        "name": "categories/gcid:lactation_service"
    },
    {
        "displayName": "Proveedor de escaleras",
        "name": "categories/gcid:ladder_supplier"
    },
    {
        "displayName": "Concesionario Lamborghini",
        "name": "categories/gcid:lamborghini_dealer"
    },
    {
        "displayName": "Proveedor de equipo de laminado",
        "name": "categories/gcid:laminating_equipment_supplier"
    },
    {
        "displayName": "Servicio de laminado",
        "name": "categories/gcid:lamination_service"
    },
    {
        "displayName": "Servicio de reparación de artículos eléctricos",
        "name": "categories/gcid:lamp_repair_service"
    },
    {
        "displayName": "Proveedor de pantallas para lámparas",
        "name": "categories/gcid:lamp_shade_supplier"
    },
    {
        "displayName": "Concesionario Lancia",
        "name": "categories/gcid:lancia_dealer"
    },
    {
        "displayName": "Loteamiento de terrenos",
        "name": "categories/gcid:land_allotment"
    },
    {
        "displayName": "Autoridad de planificación urbana",
        "name": "categories/gcid:land_planning_authority"
    },
    {
        "displayName": "Instituto de reforma agraria",
        "name": "categories/gcid:land_reform_institute"
    },
    {
        "displayName": "Concesionario Land Rover",
        "name": "categories/gcid:land_rover_dealer"
    },
    {
        "displayName": "Oficina de agrimensura",
        "name": "categories/gcid:land_surveying_office"
    },
    {
        "displayName": "Agrimensor",
        "name": "categories/gcid:land_surveyor"
    },
    {
        "displayName": "Arquitecto paisajista",
        "name": "categories/gcid:landscape_architect"
    },
    {
        "displayName": "Diseñador paisajista",
        "name": "categories/gcid:landscape_designer"
    },
    {
        "displayName": "Diseñador de iluminación de paisajes",
        "name": "categories/gcid:landscape_lighting_designer"
    },
    {
        "displayName": "Paisajista",
        "name": "categories/gcid:landscaper"
    },
    {
        "displayName": "Tienda de insumos para paisajismo",
        "name": "categories/gcid:landscaping_supply_store"
    },
    {
        "displayName": "Escuela de idiomas",
        "name": "categories/gcid:language_school"
    },
    {
        "displayName": "Restaurante de cocina de Laos",
        "name": "categories/gcid:laotian_restaurant"
    },
    {
        "displayName": "Lapidaria",
        "name": "categories/gcid:lapidary"
    },
    {
        "displayName": "Servicio de corte láser",
        "name": "categories/gcid:laser_cutting_service"
    },
    {
        "displayName": "Proveedor de máquinas láser",
        "name": "categories/gcid:laser_equipment_supplier"
    },
    {
        "displayName": "Servicio de depilación por láser",
        "name": "categories/gcid:laser_hair_removal_service"
    },
    {
        "displayName": "Centro de juego con pistolas láser",
        "name": "categories/gcid:laser_tag_center"
    },
    {
        "displayName": "Cirujano especialista en LASIK",
        "name": "categories/gcid:lasik_surgeon"
    },
    {
        "displayName": "Restaurante de cocina latina",
        "name": "categories/gcid:latin_american_restaurant"
    },
    {
        "displayName": "Lavandería automática",
        "name": "categories/gcid:laundromat"
    },
    {
        "displayName": "Lavandería",
        "name": "categories/gcid:laundry"
    },
    {
        "displayName": "Servicio de lavandería",
        "name": "categories/gcid:laundry_service"
    },
    {
        "displayName": "Librería especializada en Derecho",
        "name": "categories/gcid:law_book_store"
    },
    {
        "displayName": "Estudio de abogados",
        "name": "categories/gcid:law_firm"
    },
    {
        "displayName": "Biblioteca de derecho",
        "name": "categories/gcid:law_library"
    },
    {
        "displayName": "Facultad de Derecho",
        "name": "categories/gcid:law_school"
    },
    {
        "displayName": "Club de bochas (lawnbowls)",
        "name": "categories/gcid:lawn_bowls_club"
    },
    {
        "displayName": "Servicio de jardinería",
        "name": "categories/gcid:lawn_care_service"
    },
    {
        "displayName": "Renta de equipos para jardinería",
        "name": "categories/gcid:lawn_equipment_rental_service"
    },
    {
        "displayName": "Proveedor de equipos para riego de césped",
        "name": "categories/gcid:lawn_irrigation_equipment_supplier"
    },
    {
        "displayName": "Servicio de reparación de podadoras de césped",
        "name": "categories/gcid:lawn_mower_repair_service"
    },
    {
        "displayName": "Tienda de podadoras de césped",
        "name": "categories/gcid:lawn_mower_store"
    },
    {
        "displayName": "Contratista de sistema de riego para jardín",
        "name": "categories/gcid:lawn_sprinkler_system_contractor"
    },
    {
        "displayName": "Abogado",
        "name": "categories/gcid:lawyer"
    },
    {
        "displayName": "Asociación de abogados",
        "name": "categories/gcid:lawyers_association"
    },
    {
        "displayName": "Leagues club",
        "name": "categories/gcid:leagues_club"
    },
    {
        "displayName": "Área de escuela de conductores",
        "name": "categories/gcid:learner_driver_training_area"
    },
    {
        "displayName": "Centro de aprendizaje",
        "name": "categories/gcid:learning_center"
    },
    {
        "displayName": "Leasing service",
        "name": "categories/gcid:leasing_service"
    },
    {
        "displayName": "Servicio de limpieza de cuero",
        "name": "categories/gcid:leather_cleaning_service"
    },
    {
        "displayName": "Tienda de abrigos de cuero",
        "name": "categories/gcid:leather_coats_store"
    },
    {
        "displayName": "Exportador de cuero",
        "name": "categories/gcid:leather_exporter"
    },
    {
        "displayName": "Fabricante de productos de cuero",
        "name": "categories/gcid:leather_goods_manufacturer"
    },
    {
        "displayName": "Tienda de productos de cuero",
        "name": "categories/gcid:leather_goods_store"
    },
    {
        "displayName": "Proveedor de artículos de cuero",
        "name": "categories/gcid:leather_goods_supplier"
    },
    {
        "displayName": "Mayorista de artículos de cuero",
        "name": "categories/gcid:leather_goods_wholesaler"
    },
    {
        "displayName": "Servicio de reparación de cuero",
        "name": "categories/gcid:leather_repair_service"
    },
    {
        "displayName": "Mayorista de cuero",
        "name": "categories/gcid:leather_wholesaler"
    },
    {
        "displayName": "Restaurante libanés",
        "name": "categories/gcid:lebanese_restaurant"
    },
    {
        "displayName": "Restaurante de lechón",
        "name": "categories/gcid:lechon_restaurant"
    },
    {
        "displayName": "Oficina de asuntos legales",
        "name": "categories/gcid:legal_affairs_bureau"
    },
    {
        "displayName": "Legal aid office",
        "name": "categories/gcid:legal_aid_office"
    },
    {
        "displayName": "Servicios legales",
        "name": "categories/gcid:legal_services"
    },
    {
        "displayName": "Leisure centre",
        "name": "categories/gcid:leisurecentre"
    },
    {
        "displayName": "Bar lésbico",
        "name": "categories/gcid:lesbian_bar"
    },
    {
        "displayName": "Concesionario Lexus",
        "name": "categories/gcid:lexus_dealer"
    },
    {
        "displayName": "Biblioteca",
        "name": "categories/gcid:library"
    },
    {
        "displayName": "Departamento de licencias",
        "name": "categories/gcid:license_bureau"
    },
    {
        "displayName": "Proveedor de marcos para matrículas",
        "name": "categories/gcid:license_plate_frames_supplier"
    },
    {
        "displayName": "Lido",
        "name": "categories/gcid:lido"
    },
    {
        "displayName": "Entrenador de vida",
        "name": "categories/gcid:life_coach"
    },
    {
        "displayName": "Agencia de seguros de vida",
        "name": "categories/gcid:life_insurance_agency"
    },
    {
        "displayName": "Proveedor de focos",
        "name": "categories/gcid:light_bulb_supplier"
    },
    {
        "displayName": "Consultora de iluminación",
        "name": "categories/gcid:lighting_consultant"
    },
    {
        "displayName": "Contratista de iluminación",
        "name": "categories/gcid:lighting_contractor"
    },
    {
        "displayName": "Fabricante de equipos de iluminación",
        "name": "categories/gcid:lighting_manufacturer"
    },
    {
        "displayName": "Tienda de iluminación",
        "name": "categories/gcid:lighting_store"
    },
    {
        "displayName": "Mayorista de iluminación",
        "name": "categories/gcid:lighting_wholesaler"
    },
    {
        "displayName": "Restaurante ligur",
        "name": "categories/gcid:ligurian_restaurant"
    },
    {
        "displayName": "Servicio de limusina",
        "name": "categories/gcid:limousine_service"
    },
    {
        "displayName": "Concesionario de Lincoln",
        "name": "categories/gcid:lincoln_mercury_dealer"
    },
    {
        "displayName": "Servicio de demarcación vial",
        "name": "categories/gcid:line_marking_service"
    },
    {
        "displayName": "Tienda de ropa de cama",
        "name": "categories/gcid:linens_store"
    },
    {
        "displayName": "Fabricante de lencería",
        "name": "categories/gcid:lingerie_manufacturer"
    },
    {
        "displayName": "Lencería",
        "name": "categories/gcid:lingerie_store"
    },
    {
        "displayName": "Distribuidor mayorista de lencería",
        "name": "categories/gcid:lingerie_wholesaler"
    },
    {
        "displayName": "Tienda de linóleo",
        "name": "categories/gcid:linoleum_store"
    },
    {
        "displayName": "Liquidador",
        "name": "categories/gcid:liquidator"
    },
    {
        "displayName": "Tienda de bebidas alcohólicas",
        "name": "categories/gcid:liquor_store"
    },
    {
        "displayName": "Programa de alfabetización",
        "name": "categories/gcid:literacy_program"
    },
    {
        "displayName": "Restaurante lituano",
        "name": "categories/gcid:lithuanian_restaurant"
    },
    {
        "displayName": "Club de beisbol de ligas infantiles",
        "name": "categories/gcid:little_league_club"
    },
    {
        "displayName": "Campo de las Ligas Pequeñas",
        "name": "categories/gcid:little_league_field"
    },
    {
        "displayName": "Bar con música en vivo",
        "name": "categories/gcid:live_music_bar"
    },
    {
        "displayName": "Auditorio para música en vivo",
        "name": "categories/gcid:live_music_venue"
    },
    {
        "displayName": "Gremio",
        "name": "categories/gcid:livery_company"
    },
    {
        "displayName": "Casa de subasta de ganado",
        "name": "categories/gcid:livestock_auction_house"
    },
    {
        "displayName": "Criador de ganado",
        "name": "categories/gcid:livestock_breeder"
    },
    {
        "displayName": "Comerciante de ganado",
        "name": "categories/gcid:livestock_dealer"
    },
    {
        "displayName": "Productor pecuario",
        "name": "categories/gcid:livestock_producer"
    },
    {
        "displayName": "Agencia de préstamos",
        "name": "categories/gcid:loan_agency"
    },
    {
        "displayName": "Oficina de gobierno local",
        "name": "categories/gcid:local_government_office"
    },
    {
        "displayName": "Museo de historia local",
        "name": "categories/gcid:local_history_museum"
    },
    {
        "displayName": "Servicios médicos locales",
        "name": "categories/gcid:local_medical_services"
    },
    {
        "displayName": "Cerrajería",
        "name": "categories/gcid:lock_store"
    },
    {
        "displayName": "Proveedor de cerraduras",
        "name": "categories/gcid:locks_supplier"
    },
    {
        "displayName": "Cerrajero",
        "name": "categories/gcid:locksmith"
    },
    {
        "displayName": "Servicio de dreadlocks",
        "name": "categories/gcid:loctician_service"
    },
    {
        "displayName": "Lodge",
        "name": "categories/gcid:lodge"
    },
    {
        "displayName": "Construcción, venta, y alquiler de cabañas en tronco",
        "name": "categories/gcid:log_cabins"
    },
    {
        "displayName": "Constructor de cabañas",
        "name": "categories/gcid:log_home_builder"
    },
    {
        "displayName": "Contratista maderero",
        "name": "categories/gcid:logging_contractor"
    },
    {
        "displayName": "Servicio de logística",
        "name": "categories/gcid:logistics_service"
    },
    {
        "displayName": "Restaurante lombardo",
        "name": "categories/gcid:lombardian_restaurant"
    },
    {
        "displayName": "Evaluador de perdidas",
        "name": "categories/gcid:loss_adjuster"
    },
    {
        "displayName": "Oficina de objetos extraviados",
        "name": "categories/gcid:lost_property_office"
    },
    {
        "displayName": "Vendedor de billetes de lotería",
        "name": "categories/gcid:lottery_retailer"
    },
    {
        "displayName": "Agencia de lotería",
        "name": "categories/gcid:lottery_shop"
    },
    {
        "displayName": "Salón",
        "name": "categories/gcid:lounge"
    },
    {
        "displayName": "Motel (hotel del amor)",
        "name": "categories/gcid:love_hotel"
    },
    {
        "displayName": "Programa de viviendas para personas de bajos ingresos",
        "name": "categories/gcid:low_income_housing_program"
    },
    {
        "displayName": "Servicio de conversión automotor a GLP",
        "name": "categories/gcid:lpg_conversion"
    },
    {
        "displayName": "Servicio de reparación de equipaje",
        "name": "categories/gcid:luggage_repair_service"
    },
    {
        "displayName": "Consigna de equipaje",
        "name": "categories/gcid:luggage_storage_facility"
    },
    {
        "displayName": "Tienda de maletas y equipaje",
        "name": "categories/gcid:luggage_store"
    },
    {
        "displayName": "Mayorista de equipaje",
        "name": "categories/gcid:luggage_wholesaler"
    },
    {
        "displayName": "Maderera",
        "name": "categories/gcid:lumber_store"
    },
    {
        "displayName": "Restaurante de almuerzo",
        "name": "categories/gcid:lunch_restaurant"
    },
    {
        "displayName": "Iglesia Luterana",
        "name": "categories/gcid:lutheran_church"
    },
    {
        "displayName": "Liceo",
        "name": "categories/gcid:lyceum"
    },
    {
        "displayName": "Terapeuta especializado en drenaje linfático",
        "name": "categories/gcid:lymph_drainage"
    },
    {
        "displayName": "Construcción de máquinas",
        "name": "categories/gcid:machine_construction"
    },
    {
        "displayName": "Machine knife supplier",
        "name": "categories/gcid:machine_knife_supplier"
    },
    {
        "displayName": "Servicio de mantenimiento de maquinaria",
        "name": "categories/gcid:machine_maintenance"
    },
    {
        "displayName": "Servicio de reparación de maquinaria",
        "name": "categories/gcid:machine_repair_service"
    },
    {
        "displayName": "Tienda de máquinas",
        "name": "categories/gcid:machine_shop"
    },
    {
        "displayName": "Taller de máquinas",
        "name": "categories/gcid:machine_workshop"
    },
    {
        "displayName": "Fabricante de partes para maquinarias",
        "name": "categories/gcid:machinery_parts_manufacturer"
    },
    {
        "displayName": "Fabricante de máquinas",
        "name": "categories/gcid:machining_manufacturer"
    },
    {
        "displayName": "Restaurante de cocina macrobiótica",
        "name": "categories/gcid:macrobiotic_restaurant"
    },
    {
        "displayName": "Restaurante de cocina madrileña",
        "name": "categories/gcid:madrilian_restaurant"
    },
    {
        "displayName": "Tienda de revistas",
        "name": "categories/gcid:magazine_store"
    },
    {
        "displayName": "Tienda de artículos de magia",
        "name": "categories/gcid:magic_store"
    },
    {
        "displayName": "Mago",
        "name": "categories/gcid:magician"
    },
    {
        "displayName": "Casa de mahjong",
        "name": "categories/gcid:mah_jong_house"
    },
    {
        "displayName": "Servicio de alquiler de buzones de correo",
        "name": "categories/gcid:mailbox_rental_service"
    },
    {
        "displayName": "Proveedor de buzones de correo",
        "name": "categories/gcid:mailbox_supplier"
    },
    {
        "displayName": "Proveedor de máquinas para la gestión de correo",
        "name": "categories/gcid:mailing_machine_supplier"
    },
    {
        "displayName": "Servicio de correo",
        "name": "categories/gcid:mailing_service"
    },
    {
        "displayName": "Oficina principal de aduana",
        "name": "categories/gcid:main_customs_office"
    },
    {
        "displayName": "Restaurante de cocina de Mallorca",
        "name": "categories/gcid:majorcan_restaurant"
    },
    {
        "displayName": "Makerspace",
        "name": "categories/gcid:makerspace"
    },
    {
        "displayName": "Maquillador/a",
        "name": "categories/gcid:makeup_artist"
    },
    {
        "displayName": "Restaurante malayo",
        "name": "categories/gcid:malaysian_restaurant"
    },
    {
        "displayName": "Restaurante de cocina maltesa",
        "name": "categories/gcid:maltese_restaurant"
    },
    {
        "displayName": "Servicio de mamografía",
        "name": "categories/gcid:mammography_service"
    },
    {
        "displayName": "Restaurante de cocina de Manado",
        "name": "categories/gcid:manado_restaurant"
    },
    {
        "displayName": "Escuela de administración de empresas",
        "name": "categories/gcid:management_school"
    },
    {
        "displayName": "Restaurante de cocina china (mandarina)",
        "name": "categories/gcid:mandarin_restaurant"
    },
    {
        "displayName": "Manor house",
        "name": "categories/gcid:manor_house"
    },
    {
        "displayName": "Transportador de viviendas prefabricadas",
        "name": "categories/gcid:manufactured_home_transporter"
    },
    {
        "displayName": "Fabricante",
        "name": "categories/gcid:manufacturer"
    },
    {
        "displayName": "Organización maorí",
        "name": "categories/gcid:maori_organization"
    },
    {
        "displayName": "Tienda de mapas",
        "name": "categories/gcid:map_store"
    },
    {
        "displayName": "Servicio de cartografía",
        "name": "categories/gcid:mapping_service"
    },
    {
        "displayName": "Marae",
        "name": "categories/gcid:marae"
    },
    {
        "displayName": "Restaurante maratí",
        "name": "categories/gcid:marathi_restaurant"
    },
    {
        "displayName": "Contratista de mármol",
        "name": "categories/gcid:marble_contractor"
    },
    {
        "displayName": "Marmolería",
        "name": "categories/gcid:marble_supplier"
    },
    {
        "displayName": "Restaurante de cocina de Las Marcas",
        "name": "categories/gcid:marche_restaurant"
    },
    {
        "displayName": "Puerto deportivo",
        "name": "categories/gcid:marina"
    },
    {
        "displayName": "Servicio de ingeniería naval",
        "name": "categories/gcid:marine_engineer"
    },
    {
        "displayName": "Fuerza marítima de autodefensa",
        "name": "categories/gcid:marine_self_defense_force"
    },
    {
        "displayName": "Tienda de insumos marítimos",
        "name": "categories/gcid:marine_supply_store"
    },
    {
        "displayName": "Perito naval",
        "name": "categories/gcid:marine_surveyor"
    },
    {
        "displayName": "Museo marítimo",
        "name": "categories/gcid:maritime_museum"
    },
    {
        "displayName": "Mercado",
        "name": "categories/gcid:market"
    },
    {
        "displayName": "Investigador de mercado",
        "name": "categories/gcid:market_researcher"
    },
    {
        "displayName": "Agencia de marketing",
        "name": "categories/gcid:marketing_agency"
    },
    {
        "displayName": "Asesor de marketing",
        "name": "categories/gcid:marketing_consultant"
    },
    {
        "displayName": "Club de tiro",
        "name": "categories/gcid:markmens_clubhouse"
    },
    {
        "displayName": "Servicio de alquiler de toldos",
        "name": "categories/gcid:marquee_hire"
    },
    {
        "displayName": "Oficiante de matrimonio",
        "name": "categories/gcid:marriage_celebrant"
    },
    {
        "displayName": "Consejero matrimonial",
        "name": "categories/gcid:marriage_counselor"
    },
    {
        "displayName": "Registro Civil",
        "name": "categories/gcid:marriage_license_bureau"
    },
    {
        "displayName": "Club de artes marciales",
        "name": "categories/gcid:martial_arts_club"
    },
    {
        "displayName": "Escuela de artes marciales",
        "name": "categories/gcid:martial_arts_school"
    },
    {
        "displayName": "Tienda de insumos para artes marciales",
        "name": "categories/gcid:martial_arts_supply_store"
    },
    {
        "displayName": "Concesionario Maserati",
        "name": "categories/gcid:maserati_dealer"
    },
    {
        "displayName": "Centro masónico",
        "name": "categories/gcid:masonic_building"
    },
    {
        "displayName": "Contratista de albañilería",
        "name": "categories/gcid:masonry_contractor"
    },
    {
        "displayName": "Tienda de insumos para albañilería",
        "name": "categories/gcid:masonry_supply_store"
    },
    {
        "displayName": "Escuela de masajes",
        "name": "categories/gcid:massage_school"
    },
    {
        "displayName": "Massage spa",
        "name": "categories/gcid:massage_spa"
    },
    {
        "displayName": "Tienda de insumos para masajes",
        "name": "categories/gcid:massage_supply_store"
    },
    {
        "displayName": "Masoterapéuta",
        "name": "categories/gcid:massage_therapist"
    },
    {
        "displayName": "Fabricante de cajas de fósforos",
        "name": "categories/gcid:match_box_manufacturer"
    },
    {
        "displayName": "Proveedor de equipos de manejo de materiales",
        "name": "categories/gcid:material_handling_equipment_supplier"
    },
    {
        "displayName": "Hospital de maternidad",
        "name": "categories/gcid:maternity_hospital"
    },
    {
        "displayName": "Tienda de maternidad",
        "name": "categories/gcid:maternity_store"
    },
    {
        "displayName": "Facultad de matemáticas",
        "name": "categories/gcid:mathematics_school"
    },
    {
        "displayName": "Tienda de colchones",
        "name": "categories/gcid:mattress_store"
    },
    {
        "displayName": "Constructor de mausoleos",
        "name": "categories/gcid:mausoleum_builder"
    },
    {
        "displayName": "Concesionario Maybach",
        "name": "categories/gcid:maybach_dealer"
    },
    {
        "displayName": "Concesionario Mazda",
        "name": "categories/gcid:mazda_dealer"
    },
    {
        "displayName": "Concesionario McLaren",
        "name": "categories/gcid:mclaren_dealer"
    },
    {
        "displayName": "Comida a domicilio",
        "name": "categories/gcid:meal_delivery"
    },
    {
        "displayName": "Restaurante de comidas para llevar",
        "name": "categories/gcid:meal_takeaway"
    },
    {
        "displayName": "Proveedor de instrumentos de medición",
        "name": "categories/gcid:measuring_instruments_supplier"
    },
    {
        "displayName": "Embalador de carnes",
        "name": "categories/gcid:meat_packer"
    },
    {
        "displayName": "Meat processor",
        "name": "categories/gcid:meat_processor"
    },
    {
        "displayName": "Tienda de productos cárnicos",
        "name": "categories/gcid:meat_products"
    },
    {
        "displayName": "Restaurante de platos con carne",
        "name": "categories/gcid:meat_restaurant"
    },
    {
        "displayName": "Mayorista de carne",
        "name": "categories/gcid:meat_wholesaler"
    },
    {
        "displayName": "Mecánico",
        "name": "categories/gcid:mechanic"
    },
    {
        "displayName": "Contratista mecánico",
        "name": "categories/gcid:mechanical_contractor"
    },
    {
        "displayName": "Ingeniero mecánico",
        "name": "categories/gcid:mechanical_engineer"
    },
    {
        "displayName": "Planta mecánica",
        "name": "categories/gcid:mechanical_plant"
    },
    {
        "displayName": "Facultad de Ciencias de la Información y Medios Audiovisuales",
        "name": "categories/gcid:media_and_information_sciences_faculty"
    },
    {
        "displayName": "Empresa de medios de comunicación",
        "name": "categories/gcid:media_company"
    },
    {
        "displayName": "Consultor(a) de comunicación",
        "name": "categories/gcid:media_consultant"
    },
    {
        "displayName": "Media house",
        "name": "categories/gcid:media_house"
    },
    {
        "displayName": "Servicio de meditación",
        "name": "categories/gcid:mediation_service"
    },
    {
        "displayName": "Servicio de facturación médica",
        "name": "categories/gcid:medical_billing_service"
    },
    {
        "displayName": "Librería especializada en Medicina",
        "name": "categories/gcid:medical_book_store"
    },
    {
        "displayName": "Centro médico",
        "name": "categories/gcid:medical_center"
    },
    {
        "displayName": "Emisor de certificados médicos",
        "name": "categories/gcid:medical_certificate_service"
    },
    {
        "displayName": "Clínica",
        "name": "categories/gcid:medical_clinic"
    },
    {
        "displayName": "Centro médico de diagnóstico por imágenes",
        "name": "categories/gcid:medical_diagnostic_imaging_center"
    },
    {
        "displayName": "Fabricante de equipos médicos",
        "name": "categories/gcid:medical_equipment_manufacturer"
    },
    {
        "displayName": "Proveedor de equipos médicos",
        "name": "categories/gcid:medical_equipment_supplier"
    },
    {
        "displayName": "Forense",
        "name": "categories/gcid:medical_examiner"
    },
    {
        "displayName": "Grupo de medicina",
        "name": "categories/gcid:medical_group"
    },
    {
        "displayName": "Laboratorio médico",
        "name": "categories/gcid:medical_lab"
    },
    {
        "displayName": "Abogado especializado en medicina legal",
        "name": "categories/gcid:medical_lawyer"
    },
    {
        "displayName": "Consultorio médico",
        "name": "categories/gcid:medical_office"
    },
    {
        "displayName": "Escuela de medicina",
        "name": "categories/gcid:medical_school"
    },
    {
        "displayName": "Spa médico",
        "name": "categories/gcid:medical_spa"
    },
    {
        "displayName": "Tienda de artículos médicos",
        "name": "categories/gcid:medical_supply_store"
    },
    {
        "displayName": "Fabricante de tecnología médica",
        "name": "categories/gcid:medical_technology_manufacturer"
    },
    {
        "displayName": "Servicio de transporte médico",
        "name": "categories/gcid:medical_transcription_service"
    },
    {
        "displayName": "Exportador de medicamentos",
        "name": "categories/gcid:medicine_exporter"
    },
    {
        "displayName": "Centro de meditación",
        "name": "categories/gcid:meditation_center"
    },
    {
        "displayName": "Instructor de meditación",
        "name": "categories/gcid:meditation_instructor"
    },
    {
        "displayName": "Restaurante de cocina mediterránea",
        "name": "categories/gcid:mediterranean_restaurant"
    },
    {
        "displayName": "Servicio de planificación de reuniones",
        "name": "categories/gcid:meeting_planning_service"
    },
    {
        "displayName": "Clase de mehandi",
        "name": "categories/gcid:mehandi_class"
    },
    {
        "displayName": "Artista mehndi",
        "name": "categories/gcid:mehndi_designer"
    },
    {
        "displayName": "Monumento conmemorativo",
        "name": "categories/gcid:memorial"
    },
    {
        "displayName": "Memorial estate",
        "name": "categories/gcid:memorial_estate"
    },
    {
        "displayName": "Parque conmemorativo",
        "name": "categories/gcid:memorial_park"
    },
    {
        "displayName": "Iglesia Menonita",
        "name": "categories/gcid:mennonite_church"
    },
    {
        "displayName": "Tienda de ropa para hombres",
        "name": "categories/gcid:mens_clothing_store"
    },
    {
        "displayName": "Andrólogo",
        "name": "categories/gcid:mens_health_physician"
    },
    {
        "displayName": "Sastre de ropa masculina",
        "name": "categories/gcid:mens_tailor"
    },
    {
        "displayName": "Clínica de salud mental",
        "name": "categories/gcid:mental_health_clinic"
    },
    {
        "displayName": "Centro de salud mental",
        "name": "categories/gcid:mental_health_service"
    },
    {
        "displayName": "Financiador para desarrollo empresarial",
        "name": "categories/gcid:mercantile_development"
    },
    {
        "displayName": "Distribuidor Mercedes-Benz",
        "name": "categories/gcid:mercedes_benz_dealer"
    },
    {
        "displayName": "Sinagoga mesiánica",
        "name": "categories/gcid:messianic_synagogue"
    },
    {
        "displayName": "Empresa de construcciones metálicas",
        "name": "categories/gcid:metal_construction_company"
    },
    {
        "displayName": "Proveedor de equipos detectores de metales",
        "name": "categories/gcid:metal_detecting_equipment_supplier"
    },
    {
        "displayName": "Fabricante de estructuras metálicas",
        "name": "categories/gcid:metal_fabricator"
    },
    {
        "displayName": "Pulidor de metales",
        "name": "categories/gcid:metal_finisher"
    },
    {
        "displayName": "Servicio de tratamiento térmico de metales",
        "name": "categories/gcid:metal_heat_treating_service"
    },
    {
        "displayName": "Proveedores del sector metalero",
        "name": "categories/gcid:metal_industry_suppliers"
    },
    {
        "displayName": "Proveedor de maquinarias de metal",
        "name": "categories/gcid:metal_machinery_supplier"
    },
    {
        "displayName": "Servicio de pulido de metales",
        "name": "categories/gcid:metal_polishing_service"
    },
    {
        "displayName": "Empresa de procesamiento de metales",
        "name": "categories/gcid:metal_processing_company"
    },
    {
        "displayName": "Servicio de estampado, moldeo, y troquelado de metales",
        "name": "categories/gcid:metal_stamping_service"
    },
    {
        "displayName": "Proveedor de metales",
        "name": "categories/gcid:metal_supplier"
    },
    {
        "displayName": "Taller de trabajo de metales",
        "name": "categories/gcid:metal_working_shop"
    },
    {
        "displayName": "Taller de metal",
        "name": "categories/gcid:metal_workshop"
    },
    {
        "displayName": "Metallurgy company",
        "name": "categories/gcid:metallurgy_company"
    },
    {
        "displayName": "Proveedor de productos de metal",
        "name": "categories/gcid:metalware_dealer"
    },
    {
        "displayName": "Productor de utensilios metálicos",
        "name": "categories/gcid:metalware_producer"
    },
    {
        "displayName": "Tienda de insumos de metafísica",
        "name": "categories/gcid:metaphysical_supply_store"
    },
    {
        "displayName": "Iglesia Metodista",
        "name": "categories/gcid:methodist_church"
    },
    {
        "displayName": "Empresa de trenes metropolitanos",
        "name": "categories/gcid:metropolitan_train_company"
    },
    {
        "displayName": "Tienda de artículos mexicanos",
        "name": "categories/gcid:mexican_goods_store"
    },
    {
        "displayName": "Tienda de comestibles mexicanos",
        "name": "categories/gcid:mexican_grocery_store"
    },
    {
        "displayName": "Restaurante mexicano",
        "name": "categories/gcid:mexican_restaurant"
    },
    {
        "displayName": "Tortería mexicana",
        "name": "categories/gcid:mexican_torta_restaurant"
    },
    {
        "displayName": "Meyhane",
        "name": "categories/gcid:meyhane"
    },
    {
        "displayName": "Concesionaria MG",
        "name": "categories/gcid:mg_dealer"
    },
    {
        "displayName": "Servicio de reparación de hornos microondas",
        "name": "categories/gcid:microwave_oven_repair_service"
    },
    {
        "displayName": "Restaurante de cocina del Atlántico Medio (Estados Unidos)",
        "name": "categories/gcid:mid_atlantic_us_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Oriente Medio",
        "name": "categories/gcid:middle_eastern_restaurant"
    },
    {
        "displayName": "Escuela secundaria",
        "name": "categories/gcid:middle_school"
    },
    {
        "displayName": "Obstetra",
        "name": "categories/gcid:midwife"
    },
    {
        "displayName": "Archivo militar",
        "name": "categories/gcid:militar_archive"
    },
    {
        "displayName": "Residencia militar",
        "name": "categories/gcid:militar_residence"
    },
    {
        "displayName": "Cuartel militar",
        "name": "categories/gcid:military_barrack"
    },
    {
        "displayName": "Base militar",
        "name": "categories/gcid:military_base"
    },
    {
        "displayName": "Centro militar",
        "name": "categories/gcid:military_board"
    },
    {
        "displayName": "Cementerio militar",
        "name": "categories/gcid:military_cemetery"
    },
    {
        "displayName": "Hospital militar",
        "name": "categories/gcid:military_hospital"
    },
    {
        "displayName": "Oficina de reclutamiento militar",
        "name": "categories/gcid:military_recruiting_office"
    },
    {
        "displayName": "Escuela militar",
        "name": "categories/gcid:military_school"
    },
    {
        "displayName": "Military town",
        "name": "categories/gcid:military_town"
    },
    {
        "displayName": "Servicio de distribución de leche",
        "name": "categories/gcid:milk_delivery_service"
    },
    {
        "displayName": "Molino",
        "name": "categories/gcid:mill"
    },
    {
        "displayName": "Tienda de carpintería prefabricada",
        "name": "categories/gcid:millwork_shop"
    },
    {
        "displayName": "Mina",
        "name": "categories/gcid:mine"
    },
    {
        "displayName": "Empresa de aguas minerales",
        "name": "categories/gcid:mineral_water_company"
    },
    {
        "displayName": "Distribuidor de agua mineral",
        "name": "categories/gcid:mineral_water_wholesale"
    },
    {
        "displayName": "Concesionario de MINI",
        "name": "categories/gcid:mini_dealer"
    },
    {
        "displayName": "Cancha de minigolf",
        "name": "categories/gcid:miniature_golf_course"
    },
    {
        "displayName": "Tienda de miniaturas",
        "name": "categories/gcid:miniatures_store"
    },
    {
        "displayName": "Servicio de taxi minibús",
        "name": "categories/gcid:minibus_taxi_service"
    },
    {
        "displayName": "Minera",
        "name": "categories/gcid:mining_company"
    },
    {
        "displayName": "Consultor de minería",
        "name": "categories/gcid:mining_consultant"
    },
    {
        "displayName": "Ingeniero de minería",
        "name": "categories/gcid:mining_engineer"
    },
    {
        "displayName": "Equipo de minería",
        "name": "categories/gcid:mining_equipment"
    },
    {
        "displayName": "Ministerio de Educación",
        "name": "categories/gcid:ministry_of_education"
    },
    {
        "displayName": "Tienda de espejos",
        "name": "categories/gcid:mirror_shop"
    },
    {
        "displayName": "Restaurante especializado en cerdo empanizado con pasta de miso",
        "name": "categories/gcid:miso_cutlet_restaurant"
    },
    {
        "displayName": "Organización para las personas desaparecidas",
        "name": "categories/gcid:missing_persons_organization"
    },
    {
        "displayName": "Misión religiosa",
        "name": "categories/gcid:mission"
    },
    {
        "displayName": "Concesionario Mitsubishi",
        "name": "categories/gcid:mitsubishi_dealer"
    },
    {
        "displayName": "Catering móvil",
        "name": "categories/gcid:mobile_catering"
    },
    {
        "displayName": "Mobile disco",
        "name": "categories/gcid:mobile_disco"
    },
    {
        "displayName": "Mobile hairdresser",
        "name": "categories/gcid:mobile_hairdresser"
    },
    {
        "displayName": "Distribuidor de casas rodantes",
        "name": "categories/gcid:mobile_home_dealer"
    },
    {
        "displayName": "Estacionamiento de casas rodantes",
        "name": "categories/gcid:mobile_home_park"
    },
    {
        "displayName": "Agencia de alquiler de casas rodantes",
        "name": "categories/gcid:mobile_home_rental_agency"
    },
    {
        "displayName": "Tienda de insumos de artículos para casas rodantes",
        "name": "categories/gcid:mobile_home_supply_store"
    },
    {
        "displayName": "Agente de Mobile Money",
        "name": "categories/gcid:mobile_money_agent"
    },
    {
        "displayName": "Operador de redes móviles",
        "name": "categories/gcid:mobile_network_operator"
    },
    {
        "displayName": "Tienda de reparación de teléfonos celulares",
        "name": "categories/gcid:mobile_phone_repair_shop"
    },
    {
        "displayName": "Proveedor de equipos de asistencia para la movilidad",
        "name": "categories/gcid:mobility_equipment_supplier"
    },
    {
        "displayName": "Área de pistas para automóviles a escala",
        "name": "categories/gcid:model_car_play_area"
    },
    {
        "displayName": "Empresa de diseño de modelos",
        "name": "categories/gcid:model_design_company"
    },
    {
        "displayName": "Estudio de books de modelos",
        "name": "categories/gcid:model_portfolio_studio"
    },
    {
        "displayName": "Tienda de trenes a escala",
        "name": "categories/gcid:model_train_store"
    },
    {
        "displayName": "Agencia de modelos",
        "name": "categories/gcid:modeling_agency"
    },
    {
        "displayName": "Escuela de modelaje",
        "name": "categories/gcid:modeling_school"
    },
    {
        "displayName": "Museo de arte moderno",
        "name": "categories/gcid:modern_art_museum"
    },
    {
        "displayName": "Restaurante de cocina británica moderna",
        "name": "categories/gcid:modern_british_restaurant"
    },
    {
        "displayName": "Restaurante de cocina europea moderna",
        "name": "categories/gcid:modern_european_restaurant"
    },
    {
        "displayName": "Restaurante de cocina francesa moderna",
        "name": "categories/gcid:modern_french_restaurant"
    },
    {
        "displayName": "Restaurante de comida hindú moderna",
        "name": "categories/gcid:modern_indian_restaurant"
    },
    {
        "displayName": "Constructor de casas modulares",
        "name": "categories/gcid:modular_home_builder"
    },
    {
        "displayName": "Constructora de casas prefabricadas",
        "name": "categories/gcid:modular_home_dealer"
    },
    {
        "displayName": "Mohel",
        "name": "categories/gcid:mohel"
    },
    {
        "displayName": "Moldista",
        "name": "categories/gcid:mold_maker"
    },
    {
        "displayName": "Proveedor de moldeado",
        "name": "categories/gcid:molding_supplier"
    },
    {
        "displayName": "Restaurante de momos",
        "name": "categories/gcid:momo_restaurant"
    },
    {
        "displayName": "Monasterio",
        "name": "categories/gcid:monastery"
    },
    {
        "displayName": "Servicio de giro bancario",
        "name": "categories/gcid:money_order_service"
    },
    {
        "displayName": "Servicio de transferencia de dinero",
        "name": "categories/gcid:money_transfer_service"
    },
    {
        "displayName": "Restaurante de barbacoa mongola",
        "name": "categories/gcid:mongolian_barbecue_restaurant"
    },
    {
        "displayName": "Restaurante especializado en monjayaki",
        "name": "categories/gcid:monja_restaurant"
    },
    {
        "displayName": "Servicios de monograma",
        "name": "categories/gcid:monogramming_service"
    },
    {
        "displayName": "Escuela Montessori",
        "name": "categories/gcid:montessori_school"
    },
    {
        "displayName": "Fabricante de monumentos",
        "name": "categories/gcid:monument_maker"
    },
    {
        "displayName": "Distribuidor de ciclomotores",
        "name": "categories/gcid:moped_dealer"
    },
    {
        "displayName": "Iglesia Morava",
        "name": "categories/gcid:moravian_church"
    },
    {
        "displayName": "Restaurante izakaya moderno",
        "name": "categories/gcid:mordern_izakaya_restaurants"
    },
    {
        "displayName": "Restaurante marroquí",
        "name": "categories/gcid:moroccan_restaurant"
    },
    {
        "displayName": "Corredor hipotecario",
        "name": "categories/gcid:mortgage_broker"
    },
    {
        "displayName": "Prestamista hipotecario",
        "name": "categories/gcid:mortgage_lender"
    },
    {
        "displayName": "Funeraria",
        "name": "categories/gcid:mortuary"
    },
    {
        "displayName": "Mezquita",
        "name": "categories/gcid:mosque"
    },
    {
        "displayName": "Motel (hotel para automovilistas)",
        "name": "categories/gcid:motel"
    },
    {
        "displayName": "Concesionario de motocicletas Scooter",
        "name": "categories/gcid:motor_scooter_dealer"
    },
    {
        "displayName": "Taller de reparación de scooters",
        "name": "categories/gcid:motor_scooter_repair_shop"
    },
    {
        "displayName": "Concesionario de vehículos de motor",
        "name": "categories/gcid:motor_vehicle_dealer"
    },
    {
        "displayName": "Concesionario de motocicletas",
        "name": "categories/gcid:motorcycle_dealer"
    },
    {
        "displayName": "Escuela de conducción de motocicletas",
        "name": "categories/gcid:motorcycle_driving_school"
    },
    {
        "displayName": "Agencia de seguros de motocicletas",
        "name": "categories/gcid:motorcycle_insurance_agency"
    },
    {
        "displayName": "Tienda de repuestos de motocicletas",
        "name": "categories/gcid:motorcycle_parts_store"
    },
    {
        "displayName": "Agencias de alquiler de motos",
        "name": "categories/gcid:motorcycle_rental_agency"
    },
    {
        "displayName": "Taller de reparación de motocicletas",
        "name": "categories/gcid:motorcycle_repair_shop"
    },
    {
        "displayName": "Tienda de motocicletas",
        "name": "categories/gcid:motorcycle_shop"
    },
    {
        "displayName": "Club de automovilismo",
        "name": "categories/gcid:motoring_club"
    },
    {
        "displayName": "Tienda de deportes de motor",
        "name": "categories/gcid:motorsports_store"
    },
    {
        "displayName": "Teleférico de montaña",
        "name": "categories/gcid:mountain_cable_car"
    },
    {
        "displayName": "Refugio de montaña",
        "name": "categories/gcid:mountain_hut"
    },
    {
        "displayName": "Clase de montañismo",
        "name": "categories/gcid:mountaineering_class"
    },
    {
        "displayName": "Tienda de alquiler de películas",
        "name": "categories/gcid:movie_rental_kiosk"
    },
    {
        "displayName": "Videoclub",
        "name": "categories/gcid:movie_rental_store"
    },
    {
        "displayName": "Estudio cinematográfico",
        "name": "categories/gcid:movie_studio"
    },
    {
        "displayName": "Cine",
        "name": "categories/gcid:movie_theater"
    },
    {
        "displayName": "Servicio de mudanza y depósito",
        "name": "categories/gcid:moving_and_storage_service"
    },
    {
        "displayName": "Empresa de mudanzas",
        "name": "categories/gcid:moving_company"
    },
    {
        "displayName": "Tienda de insumos para mudanzas",
        "name": "categories/gcid:moving_supply_store"
    },
    {
        "displayName": "Centro de RMN",
        "name": "categories/gcid:mri_center"
    },
    {
        "displayName": "Gimnasio de boxeo Muay Thai",
        "name": "categories/gcid:muay_thai_boxing_gym"
    },
    {
        "displayName": "Tienda de silenciadores",
        "name": "categories/gcid:muffler_shop"
    },
    {
        "displayName": "Restaurante mughlai",
        "name": "categories/gcid:mughlai_restaurant"
    },
    {
        "displayName": "Proveedor de mantillo",
        "name": "categories/gcid:mulch_supplier"
    },
    {
        "displayName": "Multimedia and electronic book publisher",
        "name": "categories/gcid:multimedia_and_electronic_book_publisher"
    },
    {
        "displayName": "Oficina de administración municipal",
        "name": "categories/gcid:municipal_administration_office"
    },
    {
        "displayName": "Municipal corporation",
        "name": "categories/gcid:municipal_corporation"
    },
    {
        "displayName": "Municipal Department Agricultural Development",
        "name": "categories/gcid:municipal_department_agricultural_development"
    },
    {
        "displayName": "Municipal department of agriculture food supply",
        "name": "categories/gcid:municipal_department_agriculture_food_supply"
    },
    {
        "displayName": "Municipal Department Civil Defense",
        "name": "categories/gcid:municipal_department_civil_defense"
    },
    {
        "displayName": "Municipal Department Communication",
        "name": "categories/gcid:municipal_department_communication"
    },
    {
        "displayName": "Municipal Department Finance",
        "name": "categories/gcid:municipal_department_finance"
    },
    {
        "displayName": "Municipal Department Housing and Urban Development",
        "name": "categories/gcid:municipal_department_housing_and_urban_development"
    },
    {
        "displayName": "Municipal Department of Culture",
        "name": "categories/gcid:municipal_department_of_culture"
    },
    {
        "displayName": "Municipal Department of Sports",
        "name": "categories/gcid:municipal_department_of_sports"
    },
    {
        "displayName": "Departamento Municipal de Turismo",
        "name": "categories/gcid:municipal_department_of_tourism"
    },
    {
        "displayName": "Municipal Department Science Technology",
        "name": "categories/gcid:municipal_department_science_technology"
    },
    {
        "displayName": "Municipal Department Social Defense",
        "name": "categories/gcid:municipal_department_social_defense"
    },
    {
        "displayName": "Municipal Guard",
        "name": "categories/gcid:municipal_guard"
    },
    {
        "displayName": "Municipal health department",
        "name": "categories/gcid:municipal_health_department"
    },
    {
        "displayName": "Municipal office education",
        "name": "categories/gcid:municipal_office_education"
    },
    {
        "displayName": "Municipal Social Development",
        "name": "categories/gcid:municipal_social_development"
    },
    {
        "displayName": "Restaurante especializado en murtabak",
        "name": "categories/gcid:murtabak_restaurant"
    },
    {
        "displayName": "Musalla",
        "name": "categories/gcid:musalla"
    },
    {
        "displayName": "Museo",
        "name": "categories/gcid:museum"
    },
    {
        "displayName": "Museo de historial espacial",
        "name": "categories/gcid:museum_of_space_history"
    },
    {
        "displayName": "Museo de zoología",
        "name": "categories/gcid:museum_of_zoology"
    },
    {
        "displayName": "Tienda de cajas musicales",
        "name": "categories/gcid:music_box_store"
    },
    {
        "displayName": "Universidad musical",
        "name": "categories/gcid:music_college"
    },
    {
        "displayName": "Music conservatory",
        "name": "categories/gcid:music_conservatory"
    },
    {
        "displayName": "Instructor de música",
        "name": "categories/gcid:music_instructor"
    },
    {
        "displayName": "Music management and promotion",
        "name": "categories/gcid:music_management_and_promotion"
    },
    {
        "displayName": "Productor discográfico",
        "name": "categories/gcid:music_producer"
    },
    {
        "displayName": "Compañía discográfica",
        "name": "categories/gcid:music_publisher"
    },
    {
        "displayName": "Escuela de música",
        "name": "categories/gcid:music_school"
    },
    {
        "displayName": "Tienda de música",
        "name": "categories/gcid:music_store"
    },
    {
        "displayName": "Club musical",
        "name": "categories/gcid:musical_club"
    },
    {
        "displayName": "Fabricante de instrumentos musicales",
        "name": "categories/gcid:musical_instrument_manufacturer"
    },
    {
        "displayName": "Servicio de alquiler de instrumentos musicales",
        "name": "categories/gcid:musical_instrument_rental_service"
    },
    {
        "displayName": "Taller de reparación de instrumentos musicales",
        "name": "categories/gcid:musical_instrument_repair_shop"
    },
    {
        "displayName": "Tienda de instrumentos musicales",
        "name": "categories/gcid:musical_instrument_store"
    },
    {
        "displayName": "Musician",
        "name": "categories/gcid:musician"
    },
    {
        "displayName": "Musician and composer",
        "name": "categories/gcid:musician_and_composer"
    },
    {
        "displayName": "Restaurante especializado en barbacoa de cordero",
        "name": "categories/gcid:mutton_barbecue_restaurant"
    },
    {
        "displayName": "Manicuría",
        "name": "categories/gcid:nail_salon"
    },
    {
        "displayName": "Servicio de ingeniería en nanotecnología",
        "name": "categories/gcid:nanotechnology_engineer"
    },
    {
        "displayName": "Restaurante especializado en nasi\\xa0goreng",
        "name": "categories/gcid:nasi_goreng_restaurant"
    },
    {
        "displayName": "Restaurante de nasi",
        "name": "categories/gcid:nasi_restaurant"
    },
    {
        "displayName": "Restaurante especializado en nasi\\xa0uduk",
        "name": "categories/gcid:nasi_uduk_restaurant"
    },
    {
        "displayName": "Bosque nacional",
        "name": "categories/gcid:national_forest"
    },
    {
        "displayName": "National health foundation",
        "name": "categories/gcid:national_health_foundation"
    },
    {
        "displayName": "Biblioteca Nacional",
        "name": "categories/gcid:national_library"
    },
    {
        "displayName": "Museo Nacional",
        "name": "categories/gcid:national_museum"
    },
    {
        "displayName": "Parque nacional",
        "name": "categories/gcid:national_park"
    },
    {
        "displayName": "Reserva nacional",
        "name": "categories/gcid:national_reserve"
    },
    {
        "displayName": "Tienda de productos de nativos americanos",
        "name": "categories/gcid:native_american_goods_store"
    },
    {
        "displayName": "Restaurante de cocina nativa americana",
        "name": "categories/gcid:native_american_restaurant"
    },
    {
        "displayName": "Tienda de alimentos naturales",
        "name": "categories/gcid:natural_foods_store"
    },
    {
        "displayName": "Museo de Historia Natural",
        "name": "categories/gcid:natural_history_museum"
    },
    {
        "displayName": "Zona de escalada en roca natural",
        "name": "categories/gcid:natural_rock_climbing_area"
    },
    {
        "displayName": "Exportador de piedra natural",
        "name": "categories/gcid:natural_stone_exporter"
    },
    {
        "displayName": "Distribuidor de piedras naturales",
        "name": "categories/gcid:natural_stone_supplier"
    },
    {
        "displayName": "Mayorista de piedra natural",
        "name": "categories/gcid:natural_stone_wholesaler"
    },
    {
        "displayName": "Reserva natural",
        "name": "categories/gcid:nature_preserve"
    },
    {
        "displayName": "Naturópata",
        "name": "categories/gcid:naturopathic_practitioner"
    },
    {
        "displayName": "Base naval",
        "name": "categories/gcid:naval_base"
    },
    {
        "displayName": "Restaurante de cocina navarra",
        "name": "categories/gcid:navarraise_restaurant"
    },
    {
        "displayName": "Restaurante de cocina napolitana",
        "name": "categories/gcid:neapolitan_restaurant"
    },
    {
        "displayName": "Taller de costura",
        "name": "categories/gcid:needlework_shop"
    },
    {
        "displayName": "Tienda de letreros de neón",
        "name": "categories/gcid:neon_sign_shop"
    },
    {
        "displayName": "Neonatólogo",
        "name": "categories/gcid:neonatal_physician"
    },
    {
        "displayName": "Restaurante nepalí",
        "name": "categories/gcid:nepalese_restaurant"
    },
    {
        "displayName": "Nefrólogo",
        "name": "categories/gcid:nephrologist"
    },
    {
        "displayName": "Club de netball",
        "name": "categories/gcid:netball_club"
    },
    {
        "displayName": "Neurólogo",
        "name": "categories/gcid:neurologist"
    },
    {
        "displayName": "Neurofisiólogo",
        "name": "categories/gcid:neurophysiologist"
    },
    {
        "displayName": "Neuropsicólogo",
        "name": "categories/gcid:neuropsychologist"
    },
    {
        "displayName": "Neurocirujano",
        "name": "categories/gcid:neurosurgeon"
    },
    {
        "displayName": "Iglesia de la Nueva Era",
        "name": "categories/gcid:new_age_church"
    },
    {
        "displayName": "Restaurante de cocina de Nueva Inglaterra",
        "name": "categories/gcid:new_england_restaurant"
    },
    {
        "displayName": "Restaurante de cocina americana moderna",
        "name": "categories/gcid:new_us_american_restaurant"
    },
    {
        "displayName": "Restaurante de cocina neozelandesa",
        "name": "categories/gcid:new_zealand_restaurant"
    },
    {
        "displayName": "Servicio de noticias",
        "name": "categories/gcid:news_service"
    },
    {
        "displayName": "Newspaper advertising department",
        "name": "categories/gcid:newspaper_advertising_department"
    },
    {
        "displayName": "Distribución de periódicos",
        "name": "categories/gcid:newspaper_distribution"
    },
    {
        "displayName": "Editor de diarios",
        "name": "categories/gcid:newspaper_publisher"
    },
    {
        "displayName": "Puesto de diarios y revistas",
        "name": "categories/gcid:newsstand"
    },
    {
        "displayName": "Restaurante nicaragüense",
        "name": "categories/gcid:nicaraguan_restaurant"
    },
    {
        "displayName": "Club nocturno",
        "name": "categories/gcid:night_club"
    },
    {
        "displayName": "Mercado nocturno",
        "name": "categories/gcid:night_market"
    },
    {
        "displayName": "Concesionario Nissan",
        "name": "categories/gcid:nissan_dealer"
    },
    {
        "displayName": "Iglesia no denominacional",
        "name": "categories/gcid:non_denominational_church"
    },
    {
        "displayName": "Organización no gubernamental",
        "name": "categories/gcid:non_governmental_organization"
    },
    {
        "displayName": "Organización sin fines de lucro",
        "name": "categories/gcid:non_profit_organization"
    },
    {
        "displayName": "Casa de vacaciones donde no se permite fumar",
        "name": "categories/gcid:non_smoking_holiday_home"
    },
    {
        "displayName": "Restaurante no vegetariano",
        "name": "categories/gcid:non_vegetarian_restaurant"
    },
    {
        "displayName": "Tienda de pastas",
        "name": "categories/gcid:noodle_shop"
    },
    {
        "displayName": "Restaurante de cocina de África del Norte",
        "name": "categories/gcid:north_african_restaurant"
    },
    {
        "displayName": "Restaurante de comida hindú del noreste",
        "name": "categories/gcid:north_eastern_indian_restaurant"
    },
    {
        "displayName": "North Indian restaurant",
        "name": "categories/gcid:north_indian_restaurant"
    },
    {
        "displayName": "Restaurante del norte de Italia",
        "name": "categories/gcid:northern_italian_restaurant"
    },
    {
        "displayName": "Restaurante noruego",
        "name": "categories/gcid:norwegian_restaurant"
    },
    {
        "displayName": "Asociación de escribanos",
        "name": "categories/gcid:notaries_association"
    },
    {
        "displayName": "Notaría",
        "name": "categories/gcid:notary_public"
    },
    {
        "displayName": "Tienda de nociones",
        "name": "categories/gcid:notions_store"
    },
    {
        "displayName": "Novelties wholesaler",
        "name": "categories/gcid:novelties_wholesaler"
    },
    {
        "displayName": "Tienda de baratijas",
        "name": "categories/gcid:novelty_store"
    },
    {
        "displayName": "Servicio de ingeniería nuclear",
        "name": "categories/gcid:nuclear_engineer"
    },
    {
        "displayName": "Compañía de energía nuclear",
        "name": "categories/gcid:nuclear_power_company"
    },
    {
        "displayName": "Central nuclear",
        "name": "categories/gcid:nuclear_power_plant"
    },
    {
        "displayName": "Club nudista",
        "name": "categories/gcid:nudist_club"
    },
    {
        "displayName": "Parque nudista",
        "name": "categories/gcid:nudist_park"
    },
    {
        "displayName": "Restaurante latino",
        "name": "categories/gcid:nuevo_latino_restaurant"
    },
    {
        "displayName": "Numerólogo",
        "name": "categories/gcid:numerologist"
    },
    {
        "displayName": "Convento",
        "name": "categories/gcid:nunnery"
    },
    {
        "displayName": "Enfermero calificado para ejercer la medicina",
        "name": "categories/gcid:nurse_practitioner"
    },
    {
        "displayName": "Jardín de infantes",
        "name": "categories/gcid:nursery_school"
    },
    {
        "displayName": "Centro geriátrico",
        "name": "categories/gcid:nursing_agency"
    },
    {
        "displayName": "Asociación de enfermeros",
        "name": "categories/gcid:nursing_association"
    },
    {
        "displayName": "Hogar de ancianos",
        "name": "categories/gcid:nursing_home"
    },
    {
        "displayName": "Escuela de enfermería",
        "name": "categories/gcid:nursing_school"
    },
    {
        "displayName": "Tienda de frutos secos",
        "name": "categories/gcid:nut_store"
    },
    {
        "displayName": "Nutricionista",
        "name": "categories/gcid:nutritionist"
    },
    {
        "displayName": "Restaurante de cocina peranakan",
        "name": "categories/gcid:nyonya_restaurant"
    },
    {
        "displayName": "Restaurante oaxaqueño",
        "name": "categories/gcid:oaxacan_restaurant"
    },
    {
        "displayName": "Restaurante de cocina obanzai",
        "name": "categories/gcid:obanzai_cuisine"
    },
    {
        "displayName": "Mirador",
        "name": "categories/gcid:observation_deck"
    },
    {
        "displayName": "Observatorio",
        "name": "categories/gcid:observatory"
    },
    {
        "displayName": "Clínica de Salud de la Mujer",
        "name": "categories/gcid:obstetrics_gynecology_clinic"
    },
    {
        "displayName": "Servicio de salud ocupacional",
        "name": "categories/gcid:occupational_health_service"
    },
    {
        "displayName": "Médico laboral",
        "name": "categories/gcid:occupational_medical_physician"
    },
    {
        "displayName": "Salud y seguridad ocupacional",
        "name": "categories/gcid:occupational_safety_and_health"
    },
    {
        "displayName": "Ergoterapeuta",
        "name": "categories/gcid:occupational_therapist"
    },
    {
        "displayName": "Restaurante de oden",
        "name": "categories/gcid:oden_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Odisha",
        "name": "categories/gcid:odia_restaurant"
    },
    {
        "displayName": "Pista de carreras todo terreno",
        "name": "categories/gcid:off_road_race_track"
    },
    {
        "displayName": "Área sin carreteras definidas",
        "name": "categories/gcid:off_roading_area"
    },
    {
        "displayName": "Tienda de apuestas fuera del hipódromo",
        "name": "categories/gcid:off_track_betting_shop"
    },
    {
        "displayName": "Restaurante especializado en cazuelas con asaduras",
        "name": "categories/gcid:offal_pot_cooking"
    },
    {
        "displayName": "Mayorista de accesorios de oficina",
        "name": "categories/gcid:office_accessories_wholesaler"
    },
    {
        "displayName": "Alquiler de equipos de oficina",
        "name": "categories/gcid:office_equipment_rental_service"
    },
    {
        "displayName": "Servicio de reparación de equipos de oficina",
        "name": "categories/gcid:office_equipment_repair_service"
    },
    {
        "displayName": "Proveedor de equipos para oficina",
        "name": "categories/gcid:office_equipment_supplier"
    },
    {
        "displayName": "Tienda de mobiliario para oficina",
        "name": "categories/gcid:office_furniture_store"
    },
    {
        "displayName": "Office of Vital Records",
        "name": "categories/gcid:office_of_vital_records"
    },
    {
        "displayName": "Servicio de remodelación de oficinas",
        "name": "categories/gcid:office_refurbishment_service"
    },
    {
        "displayName": "Agencia de alquiler de espacios para oficinas",
        "name": "categories/gcid:office_space_rental_agency"
    },
    {
        "displayName": "Tienda de suministros de oficina",
        "name": "categories/gcid:office_supply_store"
    },
    {
        "displayName": "Mayorista de suministros de oficina",
        "name": "categories/gcid:office_supply_wholesaler"
    },
    {
        "displayName": "Servicio de exploración de petróleo y gas",
        "name": "categories/gcid:oil_and_gas_exploration_service"
    },
    {
        "displayName": "Servicio de cambio de aceite",
        "name": "categories/gcid:oil_change_service"
    },
    {
        "displayName": "Empresa de petróleo y gas natural",
        "name": "categories/gcid:oil_company"
    },
    {
        "displayName": "Proveedor de equipos para yacimientos petrolíferos",
        "name": "categories/gcid:oil_field_equipment_supplier"
    },
    {
        "displayName": "Refinería de petróleo",
        "name": "categories/gcid:oil_refinery"
    },
    {
        "displayName": "Oil store",
        "name": "categories/gcid:oil_store"
    },
    {
        "displayName": "Oil wholesaler",
        "name": "categories/gcid:oil_wholesaler"
    },
    {
        "displayName": "Yacimiento petrolífero",
        "name": "categories/gcid:oilfield"
    },
    {
        "displayName": "Restaurante de okonomiyaki",
        "name": "categories/gcid:okonomiyaki_restaurant"
    },
    {
        "displayName": "Concesionario Oldsmobile",
        "name": "categories/gcid:oldsmobile_dealer"
    },
    {
        "displayName": "Empresa embotelladora de aceite de oliva",
        "name": "categories/gcid:olive_oil_bottling_company"
    },
    {
        "displayName": "Cooperativa de productores de aceite de oliva",
        "name": "categories/gcid:olive_oil_cooperative"
    },
    {
        "displayName": "Fabricante de aceite de oliva",
        "name": "categories/gcid:olive_oil_manufacturer"
    },
    {
        "displayName": "Oncólogo",
        "name": "categories/gcid:oncologist"
    },
    {
        "displayName": "Onsen",
        "name": "categories/gcid:onsen"
    },
    {
        "displayName": "Concesionario de Opel",
        "name": "categories/gcid:opel_dealer"
    },
    {
        "displayName": "Museo de artículos al aire libre",
        "name": "categories/gcid:open_air_museum"
    },
    {
        "displayName": "Universidad abierta",
        "name": "categories/gcid:open_university"
    },
    {
        "displayName": "Compañía de ópera",
        "name": "categories/gcid:opera_company"
    },
    {
        "displayName": "Teatro de ópera",
        "name": "categories/gcid:opera_house"
    },
    {
        "displayName": "Oftalmólogo",
        "name": "categories/gcid:ophthalmologist"
    },
    {
        "displayName": "Clínica oftalmológica",
        "name": "categories/gcid:ophthalmology_clinic"
    },
    {
        "displayName": "Taller de reparación de instrumentos ópticos",
        "name": "categories/gcid:optical_instrument_repair_service"
    },
    {
        "displayName": "Fabricante de productos ópticos",
        "name": "categories/gcid:optical_products_manufacturer"
    },
    {
        "displayName": "Mayorista óptico",
        "name": "categories/gcid:optical_wholesaler"
    },
    {
        "displayName": "Óptica",
        "name": "categories/gcid:optician"
    },
    {
        "displayName": "Optometrista",
        "name": "categories/gcid:optometrist"
    },
    {
        "displayName": "Cirujano oral y maxilofacial",
        "name": "categories/gcid:oral_maxillofacial_surgeon"
    },
    {
        "displayName": "Cirujano oral",
        "name": "categories/gcid:oral_surgeon"
    },
    {
        "displayName": "Huerto",
        "name": "categories/gcid:orchard"
    },
    {
        "displayName": "Orquesta",
        "name": "categories/gcid:orchestra"
    },
    {
        "displayName": "Cultivo de orquídeas",
        "name": "categories/gcid:orchid_farm"
    },
    {
        "displayName": "Orquicultor",
        "name": "categories/gcid:orchid_grower"
    },
    {
        "displayName": "Banco de órganos y tejidos",
        "name": "categories/gcid:organ_donation_and_tissue_bank"
    },
    {
        "displayName": "Tienda de medicamentos orgánicos",
        "name": "categories/gcid:organic_drug_store"
    },
    {
        "displayName": "Granja orgánica",
        "name": "categories/gcid:organic_farm"
    },
    {
        "displayName": "Tienda de comida orgánica",
        "name": "categories/gcid:organic_food_store"
    },
    {
        "displayName": "Restaurante orgánico",
        "name": "categories/gcid:organic_restaurant"
    },
    {
        "displayName": "Tienda orgánica",
        "name": "categories/gcid:organic_store"
    },
    {
        "displayName": "Tienda de productos orientales",
        "name": "categories/gcid:oriental_goods_store"
    },
    {
        "displayName": "Oriental medicine clinic",
        "name": "categories/gcid:oriental_medicine_clinic"
    },
    {
        "displayName": "Oriental medicine store",
        "name": "categories/gcid:oriental_medicine_store"
    },
    {
        "displayName": "Tienda de alfombras orientales",
        "name": "categories/gcid:oriental_rug_store"
    },
    {
        "displayName": "Orphan asylum",
        "name": "categories/gcid:orphan_asylum"
    },
    {
        "displayName": "Orfanato",
        "name": "categories/gcid:orphanage"
    },
    {
        "displayName": "Ortodoncista",
        "name": "categories/gcid:orthodontist"
    },
    {
        "displayName": "Iglesia ortodoxa",
        "name": "categories/gcid:orthodox_church"
    },
    {
        "displayName": "Sinagoga ortodoxa",
        "name": "categories/gcid:orthodox_synagogue"
    },
    {
        "displayName": "Clínica ortopédica",
        "name": "categories/gcid:orthopedic_clinic"
    },
    {
        "displayName": "Tienda de calzado ortopédico",
        "name": "categories/gcid:orthopedic_shoe_store"
    },
    {
        "displayName": "Tienda ortopédica",
        "name": "categories/gcid:orthopedic_supplies_store"
    },
    {
        "displayName": "Cirujano ortopédico",
        "name": "categories/gcid:orthopedic_surgeon"
    },
    {
        "displayName": "Ortoptista",
        "name": "categories/gcid:orthoptist"
    },
    {
        "displayName": "Laboratorio de órtesis y prótesis",
        "name": "categories/gcid:orthotics_and_prosthetics_service"
    },
    {
        "displayName": "Osteópata",
        "name": "categories/gcid:osteopath"
    },
    {
        "displayName": "Otorrinolaringólogo",
        "name": "categories/gcid:otolaryngologist"
    },
    {
        "displayName": "Clínica de otorrinolaringología",
        "name": "categories/gcid:otolaryngology_clinic"
    },
    {
        "displayName": "Tienda de motores fuera de borda",
        "name": "categories/gcid:outboard_motor_store"
    },
    {
        "displayName": "Organizador de actividades al aire libre",
        "name": "categories/gcid:outdoor_activity_organizer"
    },
    {
        "displayName": "Piscina de agua caliente al aire libre",
        "name": "categories/gcid:outdoor_bath"
    },
    {
        "displayName": "Tienda de ropa y equipos para actividades al aire libre",
        "name": "categories/gcid:outdoor_clothing_and_equipment_shop"
    },
    {
        "displayName": "Outdoor equestrian facility",
        "name": "categories/gcid:outdoor_equestrian_facility"
    },
    {
        "displayName": "Tienda de muebles para exterior",
        "name": "categories/gcid:outdoor_furniture_store"
    },
    {
        "displayName": "Cine al aire libre",
        "name": "categories/gcid:outdoor_movie_theatre"
    },
    {
        "displayName": "Tienda de artículos para deportes al aire libre",
        "name": "categories/gcid:outdoor_sports_store"
    },
    {
        "displayName": "Piscina al aire libre",
        "name": "categories/gcid:outdoor_swimming_pool"
    },
    {
        "displayName": "Tienda de ropa de exterior",
        "name": "categories/gcid:outerwear_store"
    },
    {
        "displayName": "Centro comercial outlet",
        "name": "categories/gcid:outlet_mall"
    },
    {
        "displayName": "Tienda outlet",
        "name": "categories/gcid:outlet_store"
    },
    {
        "displayName": "Oxygen cocktail spot",
        "name": "categories/gcid:oxygen_cocktail_spot"
    },
    {
        "displayName": "Proveedor de equipos de oxígeno",
        "name": "categories/gcid:oxygen_equipment_supplier"
    },
    {
        "displayName": "Bar y restaurante de ostras",
        "name": "categories/gcid:oyster_bar_restaurant"
    },
    {
        "displayName": "Proveedor de ostras",
        "name": "categories/gcid:oyster_supplier"
    },
    {
        "displayName": "Tienda de paan",
        "name": "categories/gcid:paan_shop"
    },
    {
        "displayName": "Salones de pachinko",
        "name": "categories/gcid:pachinko"
    },
    {
        "displayName": "Restaurante de cocina típica de la Cuenca del Pacífico",
        "name": "categories/gcid:pacific_rim_restaurant"
    },
    {
        "displayName": "Casillero para recibir paquetes",
        "name": "categories/gcid:package_locker"
    },
    {
        "displayName": "Empresa de envases",
        "name": "categories/gcid:packaging_company"
    },
    {
        "displayName": "Packaging machinery",
        "name": "categories/gcid:packaging_machinery"
    },
    {
        "displayName": "Tienda de insumos para embalaje",
        "name": "categories/gcid:packaging_supply_store"
    },
    {
        "displayName": "Restaurante de cocina de Padang",
        "name": "categories/gcid:padang_restaurant"
    },
    {
        "displayName": "Club de padel",
        "name": "categories/gcid:padel_club"
    },
    {
        "displayName": "Cancha de pádel",
        "name": "categories/gcid:padel_court"
    },
    {
        "displayName": "Pagoda",
        "name": "categories/gcid:pagoda"
    },
    {
        "displayName": "Clínica de control del dolor",
        "name": "categories/gcid:pain_control_clinic"
    },
    {
        "displayName": "Médico de manejo del dolor",
        "name": "categories/gcid:pain_management_physician"
    },
    {
        "displayName": "Fabricante de pinturas",
        "name": "categories/gcid:paint_manufacturer"
    },
    {
        "displayName": "Pinturería",
        "name": "categories/gcid:paint_store"
    },
    {
        "displayName": "Empresa de decapado",
        "name": "categories/gcid:paint_stripping_company"
    },
    {
        "displayName": "Centro de paintball",
        "name": "categories/gcid:paintball_center"
    },
    {
        "displayName": "Tienda de paintball",
        "name": "categories/gcid:paintball_store"
    },
    {
        "displayName": "Pintor",
        "name": "categories/gcid:painter"
    },
    {
        "displayName": "Pintura",
        "name": "categories/gcid:painting"
    },
    {
        "displayName": "Academia de pintura",
        "name": "categories/gcid:painting_lessons"
    },
    {
        "displayName": "Estudio de pintura",
        "name": "categories/gcid:painting_studio"
    },
    {
        "displayName": "Tienda artística",
        "name": "categories/gcid:paintings_store"
    },
    {
        "displayName": "Restaurante de cocina paisa",
        "name": "categories/gcid:paisa_restaurant"
    },
    {
        "displayName": "Restaurante pakistaní",
        "name": "categories/gcid:pakistani_restaurant"
    },
    {
        "displayName": "Restaurante palatino",
        "name": "categories/gcid:palatine_restaurant"
    },
    {
        "displayName": "Restaurante palestino",
        "name": "categories/gcid:palestinian_restaurant"
    },
    {
        "displayName": "Proveedor de pallets",
        "name": "categories/gcid:pallet_supplier"
    },
    {
        "displayName": "Restaurante asiático",
        "name": "categories/gcid:pan_asian_restaurant"
    },
    {
        "displayName": "Restaurante panlatino",
        "name": "categories/gcid:pan_latin_restaurant"
    },
    {
        "displayName": "Restaurante especializado en panqueques",
        "name": "categories/gcid:pancake_house"
    },
    {
        "displayName": "Tienda de panipuri",
        "name": "categories/gcid:panipuri_shop"
    },
    {
        "displayName": "Proveedor de bolsas de papel",
        "name": "categories/gcid:paper_bag_supplier"
    },
    {
        "displayName": "Distribuidor de papel",
        "name": "categories/gcid:paper_distributor"
    },
    {
        "displayName": "Exportador de papel",
        "name": "categories/gcid:paper_exporter"
    },
    {
        "displayName": "Papelera",
        "name": "categories/gcid:paper_mill"
    },
    {
        "displayName": "Paper shredding machine supplier",
        "name": "categories/gcid:paper_shredding_machine_supplier"
    },
    {
        "displayName": "Papelería",
        "name": "categories/gcid:paper_store"
    },
    {
        "displayName": "Restaurante paraguayo",
        "name": "categories/gcid:paraguayan_restaurant"
    },
    {
        "displayName": "Proveedor de servicios de asistencia jurídica",
        "name": "categories/gcid:paralegal_services_provider"
    },
    {
        "displayName": "Parafarmacia",
        "name": "categories/gcid:parapharmacy"
    },
    {
        "displayName": "Servicio de parasailing",
        "name": "categories/gcid:parasailing_ride_service"
    },
    {
        "displayName": "Parroquia",
        "name": "categories/gcid:parish"
    },
    {
        "displayName": "Parque",
        "name": "categories/gcid:park"
    },
    {
        "displayName": "Estacionamiento disuasorio",
        "name": "categories/gcid:park_and_ride"
    },
    {
        "displayName": "Garage de estacionamiento",
        "name": "categories/gcid:parking_garage"
    },
    {
        "displayName": "Estacionamiento",
        "name": "categories/gcid:parking_lot"
    },
    {
        "displayName": "Estacionamiento para bicicletas",
        "name": "categories/gcid:parking_lot_for_bicycles"
    },
    {
        "displayName": "Estacionamiento para motos",
        "name": "categories/gcid:parking_lot_for_motorcycle"
    },
    {
        "displayName": "Zona de parkour",
        "name": "categories/gcid:parkour_spot"
    },
    {
        "displayName": "Escuela parroquial",
        "name": "categories/gcid:parochial_school"
    },
    {
        "displayName": "Restaurante parsi",
        "name": "categories/gcid:parsi_restaurant"
    },
    {
        "displayName": "Templo parsi",
        "name": "categories/gcid:parsi_temple"
    },
    {
        "displayName": "Part time daycare",
        "name": "categories/gcid:part_time_daycare"
    },
    {
        "displayName": "Servicio de alquiler de equipos para fiestas",
        "name": "categories/gcid:party_equipment_rental_service"
    },
    {
        "displayName": "Organizador de eventos",
        "name": "categories/gcid:party_planner"
    },
    {
        "displayName": "Tienda de artículos para fiestas",
        "name": "categories/gcid:party_store"
    },
    {
        "displayName": "Agente de pasaportes",
        "name": "categories/gcid:passport_agent"
    },
    {
        "displayName": "Oficina de pasaportes",
        "name": "categories/gcid:passport_office"
    },
    {
        "displayName": "Procesador de fotos para pasaportes",
        "name": "categories/gcid:passport_photo_processor"
    },
    {
        "displayName": "Tienda de pastas",
        "name": "categories/gcid:pasta_shop"
    },
    {
        "displayName": "Pastelería",
        "name": "categories/gcid:pastry_shop"
    },
    {
        "displayName": "Abogado de patentes",
        "name": "categories/gcid:patent_attorney"
    },
    {
        "displayName": "Oficina de patentes",
        "name": "categories/gcid:patent_office"
    },
    {
        "displayName": "Servicio de pruebas de paternidad",
        "name": "categories/gcid:paternity_testing_service"
    },
    {
        "displayName": "Patólogo",
        "name": "categories/gcid:pathologist"
    },
    {
        "displayName": "Asociación de asistencia a pacientes",
        "name": "categories/gcid:patients_support_association"
    },
    {
        "displayName": "Proveedor de equipos para cerramiento de patios",
        "name": "categories/gcid:patio_enclosure_supplier"
    },
    {
        "displayName": "Pastelería francesa",
        "name": "categories/gcid:patisserie"
    },
    {
        "displayName": "Contratista de pavimentación",
        "name": "categories/gcid:paving_contractor"
    },
    {
        "displayName": "Proveedor de materiales para pavimento",
        "name": "categories/gcid:paving_materials_supplier"
    },
    {
        "displayName": "Casa de empeños",
        "name": "categories/gcid:pawn_shop"
    },
    {
        "displayName": "Restaurante de comida por peso",
        "name": "categories/gcid:pay_by_weight_restaurant"
    },
    {
        "displayName": "Terminal de pago",
        "name": "categories/gcid:payment_terminal"
    },
    {
        "displayName": "Servicios de nómina",
        "name": "categories/gcid:payroll_service"
    },
    {
        "displayName": "Restaurante especializado en pecel\\xa0lele",
        "name": "categories/gcid:pecel_lele_restaurant"
    },
    {
        "displayName": "Pedestrian zone",
        "name": "categories/gcid:pedestrian_zone"
    },
    {
        "displayName": "Cardiólogo pediatra",
        "name": "categories/gcid:pediatric_cardiologist"
    },
    {
        "displayName": "Centro pediátrico",
        "name": "categories/gcid:pediatric_clinic"
    },
    {
        "displayName": "Odontopediatra",
        "name": "categories/gcid:pediatric_dentist"
    },
    {
        "displayName": "Dermatólogo pediátrico",
        "name": "categories/gcid:pediatric_dermatologist"
    },
    {
        "displayName": "Endocrinólogo pediatra",
        "name": "categories/gcid:pediatric_endocrinologist"
    },
    {
        "displayName": "Gastroenterólogo pediátrico",
        "name": "categories/gcid:pediatric_gastroenterologist"
    },
    {
        "displayName": "Hematólogo pediátrico",
        "name": "categories/gcid:pediatric_hematologist"
    },
    {
        "displayName": "Nefrólogo pediatra",
        "name": "categories/gcid:pediatric_nephrologist"
    },
    {
        "displayName": "Neurólogo pediátrico",
        "name": "categories/gcid:pediatric_neurologist"
    },
    {
        "displayName": "Oncólogo pediatra",
        "name": "categories/gcid:pediatric_oncologist"
    },
    {
        "displayName": "Oftalmólogo pediátrico",
        "name": "categories/gcid:pediatric_ophthalmologist"
    },
    {
        "displayName": "Cirujano ortopeda pediátrico",
        "name": "categories/gcid:pediatric_orthopedic_surgeon"
    },
    {
        "displayName": "Neumólogo pediatra",
        "name": "categories/gcid:pediatric_pulmonologist"
    },
    {
        "displayName": "Pediatra reumatólogo",
        "name": "categories/gcid:pediatric_rheumatologist"
    },
    {
        "displayName": "Cirujano pediátrico",
        "name": "categories/gcid:pediatric_surgeon"
    },
    {
        "displayName": "Urólogo pediatra",
        "name": "categories/gcid:pediatric_urologist"
    },
    {
        "displayName": "Pediatra",
        "name": "categories/gcid:pediatrician"
    },
    {
        "displayName": "Podólogo",
        "name": "categories/gcid:pedorthist"
    },
    {
        "displayName": "Restaurante especializado en pempek",
        "name": "categories/gcid:pempek_restaurant"
    },
    {
        "displayName": "Tienda de bolígrafos",
        "name": "categories/gcid:pen_store"
    },
    {
        "displayName": "Restaurante de cocina menonita",
        "name": "categories/gcid:pennsylvania_dutch_restaurant"
    },
    {
        "displayName": "Oficina de pensiones",
        "name": "categories/gcid:pension_office"
    },
    {
        "displayName": "Iglesia Pentecostal",
        "name": "categories/gcid:pentecostal_church"
    },
    {
        "displayName": "Grupo de artes escénicas",
        "name": "categories/gcid:performing_arts_group"
    },
    {
        "displayName": "Teatro de artes escénicas",
        "name": "categories/gcid:performing_arts_theater"
    },
    {
        "displayName": "Perfumería",
        "name": "categories/gcid:perfume_store"
    },
    {
        "displayName": "Centro perinatal",
        "name": "categories/gcid:perinatal_center"
    },
    {
        "displayName": "Periodoncista",
        "name": "categories/gcid:periodontist"
    },
    {
        "displayName": "Clínica de maquillaje permanente",
        "name": "categories/gcid:permanent_make_up_clinic"
    },
    {
        "displayName": "Restaurante de cocina persa",
        "name": "categories/gcid:persian_restaurant"
    },
    {
        "displayName": "Servicio de cocinero personal",
        "name": "categories/gcid:personal_chef_service"
    },
    {
        "displayName": "Abogado de lesiones personales",
        "name": "categories/gcid:personal_injury_lawyer"
    },
    {
        "displayName": "Entrenador personal",
        "name": "categories/gcid:personal_trainer"
    },
    {
        "displayName": "Concesionaria de embarcaciones personales",
        "name": "categories/gcid:personal_watercraft_dealer"
    },
    {
        "displayName": "Restaurante peruano",
        "name": "categories/gcid:peruvian_restaurant"
    },
    {
        "displayName": "Empresa de fumigación y control de plagas",
        "name": "categories/gcid:pest_control_service"
    },
    {
        "displayName": "Servicio de adopción de mascotas",
        "name": "categories/gcid:pet_adoption_service"
    },
    {
        "displayName": "Servicio de embarque para mascotas",
        "name": "categories/gcid:pet_boarding_service"
    },
    {
        "displayName": "Cementerio de mascotas",
        "name": "categories/gcid:pet_cemetery"
    },
    {
        "displayName": "Servicio funerario para mascotas",
        "name": "categories/gcid:pet_funeral_services"
    },
    {
        "displayName": "Especialista en el aseo de mascotas",
        "name": "categories/gcid:pet_groomer"
    },
    {
        "displayName": "Servicio de traslado de mascotas",
        "name": "categories/gcid:pet_moving_service"
    },
    {
        "displayName": "Cuidador de mascotas",
        "name": "categories/gcid:pet_sitter"
    },
    {
        "displayName": "Tienda de animales",
        "name": "categories/gcid:pet_store"
    },
    {
        "displayName": "Tienda de suministros para mascotas",
        "name": "categories/gcid:pet_supply_store"
    },
    {
        "displayName": "Entrenador de mascotas",
        "name": "categories/gcid:pet_trainer"
    },
    {
        "displayName": "Servicio de ingeniería petroquímica",
        "name": "categories/gcid:petrochemical_engineer"
    },
    {
        "displayName": "Compañía de productos derivados del petróleo",
        "name": "categories/gcid:petroleum_products_company"
    },
    {
        "displayName": "Concesionario de Peugeot",
        "name": "categories/gcid:peugeot_dealer"
    },
    {
        "displayName": "Empresa farmacéutica",
        "name": "categories/gcid:pharmaceutical_company"
    },
    {
        "displayName": "Laboratorio farmacéutico",
        "name": "categories/gcid:pharmaceutical_lab"
    },
    {
        "displayName": "Mayorista de productos farmacéuticos",
        "name": "categories/gcid:pharmaceutical_products_wholesaler"
    },
    {
        "displayName": "Farmacia",
        "name": "categories/gcid:pharmacy"
    },
    {
        "displayName": "Sala de conciertos para orquestas filarmónicas",
        "name": "categories/gcid:philharmonic_hall"
    },
    {
        "displayName": "Restaurante de pho",
        "name": "categories/gcid:pho_restaurant"
    },
    {
        "displayName": "Servicio de reparación de teléfonos",
        "name": "categories/gcid:phone_repair_service"
    },
    {
        "displayName": "Agencia de fotografía",
        "name": "categories/gcid:photo_agency"
    },
    {
        "displayName": "Cabina de fotos",
        "name": "categories/gcid:photo_booth"
    },
    {
        "displayName": "Laboratorio fotográfico",
        "name": "categories/gcid:photo_lab"
    },
    {
        "displayName": "Servicio de restauración de fotos",
        "name": "categories/gcid:photo_restoration_service"
    },
    {
        "displayName": "Tienda de fotografía",
        "name": "categories/gcid:photo_shop"
    },
    {
        "displayName": "Distribuidor de máquinas fotocopiadoras",
        "name": "categories/gcid:photocopiers_supplier"
    },
    {
        "displayName": "Fotógrafo",
        "name": "categories/gcid:photographer"
    },
    {
        "displayName": "Academia de fotografía",
        "name": "categories/gcid:photography_class"
    },
    {
        "displayName": "Escuela de fotografía",
        "name": "categories/gcid:photography_school"
    },
    {
        "displayName": "Servicio de fotografía",
        "name": "categories/gcid:photography_service"
    },
    {
        "displayName": "Estudio fotográfico",
        "name": "categories/gcid:photography_studio"
    },
    {
        "displayName": "Fisioterapeuta",
        "name": "categories/gcid:physiatrist"
    },
    {
        "displayName": "Centro de exámenes físicos",
        "name": "categories/gcid:physical_examination_center"
    },
    {
        "displayName": "Programa de acondicionamiento físico",
        "name": "categories/gcid:physical_fitness_program"
    },
    {
        "displayName": "Asistente médico",
        "name": "categories/gcid:physician_assistant"
    },
    {
        "displayName": "Servicio de remisiones médicas",
        "name": "categories/gcid:physician_referral_service"
    },
    {
        "displayName": "Fisioterapeuta",
        "name": "categories/gcid:physiotherapist"
    },
    {
        "displayName": "Clínica de fisioterapia",
        "name": "categories/gcid:physiotherapy_center"
    },
    {
        "displayName": "Proveedor de equipos de fisioterapia",
        "name": "categories/gcid:physiotherapy_equip_supplier"
    },
    {
        "displayName": "Piadinería",
        "name": "categories/gcid:piadina_restaurant"
    },
    {
        "displayName": "Piano bar",
        "name": "categories/gcid:piano_bar"
    },
    {
        "displayName": "Instructor de piano",
        "name": "categories/gcid:piano_instructor"
    },
    {
        "displayName": "Fabricante de pianos",
        "name": "categories/gcid:piano_maker"
    },
    {
        "displayName": "Servicio de traslado de pianos",
        "name": "categories/gcid:piano_moving_service"
    },
    {
        "displayName": "Servicio de reparación de pianos",
        "name": "categories/gcid:piano_repair_service"
    },
    {
        "displayName": "Tienda de pianos",
        "name": "categories/gcid:piano_store"
    },
    {
        "displayName": "Servicio de afinación de pianos",
        "name": "categories/gcid:piano_tuning_service"
    },
    {
        "displayName": "Tienda de autoservicio de productos agrícolas",
        "name": "categories/gcid:pick_your_own_farm_produce"
    },
    {
        "displayName": "Cancha de pickleball",
        "name": "categories/gcid:pickleball_court"
    },
    {
        "displayName": "Zona para pícnic",
        "name": "categories/gcid:picnic_ground"
    },
    {
        "displayName": "Tienda de marcos",
        "name": "categories/gcid:picture_frame_shop"
    },
    {
        "displayName": "Puesto de pasteles",
        "name": "categories/gcid:pie_shop"
    },
    {
        "displayName": "Restaurante piamontés",
        "name": "categories/gcid:piedmontese_restaurant"
    },
    {
        "displayName": "Granja porcina",
        "name": "categories/gcid:pig_farm"
    },
    {
        "displayName": "Restaurante de pilaf",
        "name": "categories/gcid:pilaf_restaurant"
    },
    {
        "displayName": "Estudio de pilates",
        "name": "categories/gcid:pilates_studio"
    },
    {
        "displayName": "Hincador de pilotes",
        "name": "categories/gcid:pile_driver"
    },
    {
        "displayName": "Albergue de peregrinos",
        "name": "categories/gcid:pilgrim_hostel"
    },
    {
        "displayName": "Lugar de peregrinaje",
        "name": "categories/gcid:pilgrimages_place"
    },
    {
        "displayName": "Proveedor de piñatas",
        "name": "categories/gcid:pinatas_supplier"
    },
    {
        "displayName": "Proveedor de máquinas de pinball",
        "name": "categories/gcid:pinball_machine_supplier"
    },
    {
        "displayName": "Tienda de muebles de pino",
        "name": "categories/gcid:pine_furniture_shop"
    },
    {
        "displayName": "Proveedor de tuberías",
        "name": "categories/gcid:pipe_supplier"
    },
    {
        "displayName": "Pizza a domicilio",
        "name": "categories/gcid:pizza_delivery_service"
    },
    {
        "displayName": "Pizzería",
        "name": "categories/gcid:pizza_restaurant"
    },
    {
        "displayName": "Pizza para llevar",
        "name": "categories/gcid:pizzatakeaway"
    },
    {
        "displayName": "Sitio de devoción",
        "name": "categories/gcid:place_of_worship"
    },
    {
        "displayName": "Planetario",
        "name": "categories/gcid:planetarium"
    },
    {
        "displayName": "Alquiler de maquinaria y equipos industriales",
        "name": "categories/gcid:plant_and_machinery_hire"
    },
    {
        "displayName": "Vivero de plantas",
        "name": "categories/gcid:plant_nursery"
    },
    {
        "displayName": "Tienda de ventanas de PVC",
        "name": "categories/gcid:plast_window_store"
    },
    {
        "displayName": "Empresa de revoques",
        "name": "categories/gcid:plaster_contractor"
    },
    {
        "displayName": "Fabricante de bolsas plásticas",
        "name": "categories/gcid:plastic_bag_supplier"
    },
    {
        "displayName": "Mayorista de bolsas plásticas",
        "name": "categories/gcid:plastic_bags_wholesaler"
    },
    {
        "displayName": "Compañía de fabricación de plástico",
        "name": "categories/gcid:plastic_fabrication_company"
    },
    {
        "displayName": "Empresa de moldeo por inyección de plástico",
        "name": "categories/gcid:plastic_injection_molding_service"
    },
    {
        "displayName": "Proveedor de plástico",
        "name": "categories/gcid:plastic_products_supplier"
    },
    {
        "displayName": "Fabricante de resina plástica",
        "name": "categories/gcid:plastic_resin_manufacturer"
    },
    {
        "displayName": "Cirujano plástico",
        "name": "categories/gcid:plastic_surgeon"
    },
    {
        "displayName": "Clínica de cirugía plástica y reconstructiva",
        "name": "categories/gcid:plastic_surgery_clinic"
    },
    {
        "displayName": "Mayorista de plástico",
        "name": "categories/gcid:plastic_wholesaler"
    },
    {
        "displayName": "Servicio de cromado",
        "name": "categories/gcid:plating_service"
    },
    {
        "displayName": "Zona de recreación",
        "name": "categories/gcid:playground"
    },
    {
        "displayName": "Proveedor de parques infantiles",
        "name": "categories/gcid:playground_equipment_supplier"
    },
    {
        "displayName": "Grupo de juego",
        "name": "categories/gcid:playgroup"
    },
    {
        "displayName": "Plomero",
        "name": "categories/gcid:plumber"
    },
    {
        "displayName": "Tienda de insumos para plomería",
        "name": "categories/gcid:plumbing_supply_store"
    },
    {
        "displayName": "Tienda de ropa de talles grandes",
        "name": "categories/gcid:plus_size_clothing_store"
    },
    {
        "displayName": "Proveedor de madera contrachapada",
        "name": "categories/gcid:plywood_supplier"
    },
    {
        "displayName": "Proveedor de herramientas neumáticas",
        "name": "categories/gcid:pneumatic_tools_supplier"
    },
    {
        "displayName": "Restaurante de sándwiches Po' Boy",
        "name": "categories/gcid:po_boys_restaurant"
    },
    {
        "displayName": "Podólogo",
        "name": "categories/gcid:podiatrist"
    },
    {
        "displayName": "Restaurante de poke",
        "name": "categories/gcid:poke_bar"
    },
    {
        "displayName": "Academia policial",
        "name": "categories/gcid:police_academy"
    },
    {
        "displayName": "Viviendas fiscales para policías",
        "name": "categories/gcid:police_officers_housing"
    },
    {
        "displayName": "Tienda de insumos para policías",
        "name": "categories/gcid:police_supply_store"
    },
    {
        "displayName": "Restaurante polaco",
        "name": "categories/gcid:polish_restaurant"
    },
    {
        "displayName": "Sede de partido político",
        "name": "categories/gcid:political_party"
    },
    {
        "displayName": "Club de polo",
        "name": "categories/gcid:polo_club"
    },
    {
        "displayName": "Servicio de polígrafo",
        "name": "categories/gcid:polygraph_service"
    },
    {
        "displayName": "Proveedor de polímeros",
        "name": "categories/gcid:polymer_supplier"
    },
    {
        "displayName": "Restaurante de cocina polinesia",
        "name": "categories/gcid:polynesian_restaurant"
    },
    {
        "displayName": "Politécnico",
        "name": "categories/gcid:polytechnic_school"
    },
    {
        "displayName": "Proveedor de láminas de polietileno y plástico",
        "name": "categories/gcid:polythene_and_plastic_sheeting_supplier"
    },
    {
        "displayName": "Contratista de estanques",
        "name": "categories/gcid:pond_contractor"
    },
    {
        "displayName": "Proveedor de peces para estanques",
        "name": "categories/gcid:pond_fish_supplier"
    },
    {
        "displayName": "Tienda de artículos para estanques",
        "name": "categories/gcid:pond_supply_store"
    },
    {
        "displayName": "Concesionario Pontiac",
        "name": "categories/gcid:pontiac_dealer"
    },
    {
        "displayName": "Club de caballos pony",
        "name": "categories/gcid:pony_club"
    },
    {
        "displayName": "Servicio de paseos en poni",
        "name": "categories/gcid:pony_ride_service"
    },
    {
        "displayName": "Academia de billar",
        "name": "categories/gcid:pool_academy"
    },
    {
        "displayName": "Club de pool o billar",
        "name": "categories/gcid:pool_billard_club"
    },
    {
        "displayName": "Servicio de limpieza de piscinas",
        "name": "categories/gcid:pool_cleaning_service"
    },
    {
        "displayName": "Salón de pool",
        "name": "categories/gcid:pool_hall"
    },
    {
        "displayName": "Tienda de palomitas de maíz",
        "name": "categories/gcid:popcorn_store"
    },
    {
        "displayName": "Restaurante especializado en katsudon",
        "name": "categories/gcid:pork_cutlet_rice_bowl_restaurant"
    },
    {
        "displayName": "Restaurante especializado en crema de avena",
        "name": "categories/gcid:porridge_restaurant"
    },
    {
        "displayName": "Concesionario Porsche",
        "name": "categories/gcid:porsche_dealer"
    },
    {
        "displayName": "Autoridad portuaria",
        "name": "categories/gcid:port_authority"
    },
    {
        "displayName": "Empresa de operaciones portuarias",
        "name": "categories/gcid:port_operating_company"
    },
    {
        "displayName": "Fabricante de edificios portátiles",
        "name": "categories/gcid:portable_building_manufacturer"
    },
    {
        "displayName": "Proveedor de baños portátiles",
        "name": "categories/gcid:portable_toilet_supplier"
    },
    {
        "displayName": "Estudio de retratos",
        "name": "categories/gcid:portrait_studio"
    },
    {
        "displayName": "Restaurante portugués",
        "name": "categories/gcid:portuguese_restaurant"
    },
    {
        "displayName": "Oficina postal",
        "name": "categories/gcid:post_office"
    },
    {
        "displayName": "Tienda de pósters",
        "name": "categories/gcid:poster_store"
    },
    {
        "displayName": "Clases de cerámica",
        "name": "categories/gcid:pottery_classes"
    },
    {
        "displayName": "Fabricante de artículos de cerámica",
        "name": "categories/gcid:pottery_manufacturer"
    },
    {
        "displayName": "Tienda de cerámica",
        "name": "categories/gcid:pottery_store"
    },
    {
        "displayName": "Granja avícola",
        "name": "categories/gcid:poultry_farm"
    },
    {
        "displayName": "Pollería",
        "name": "categories/gcid:poultry_store"
    },
    {
        "displayName": "Servicio de recubrimiento en polvo",
        "name": "categories/gcid:powder_coating_service"
    },
    {
        "displayName": "Central eléctrica",
        "name": "categories/gcid:power_plant"
    },
    {
        "displayName": "Asesor de centrales eléctricas",
        "name": "categories/gcid:power_plant_consultant"
    },
    {
        "displayName": "Proveedor de equipos para centrales de generación eléctrica",
        "name": "categories/gcid:power_plant_equipment_supplier"
    },
    {
        "displayName": "Consecionaria de vehículos de powersports",
        "name": "categories/gcid:powersports_vehicle_dealer"
    },
    {
        "displayName": "Pozolería",
        "name": "categories/gcid:pozole_restaurant"
    },
    {
        "displayName": "Pesca de gambas",
        "name": "categories/gcid:prawn_fishing"
    },
    {
        "displayName": "Taller de ingeniería de precisión",
        "name": "categories/gcid:precision_engineer"
    },
    {
        "displayName": "Prefabricated house companies",
        "name": "categories/gcid:prefabricated_house_companies"
    },
    {
        "displayName": "Oficina gubernamental de la prefectura japonesa",
        "name": "categories/gcid:prefecture_government_office"
    },
    {
        "displayName": "Centro de asistencia para embarazadas",
        "name": "categories/gcid:pregnancy_care_center"
    },
    {
        "displayName": "Colegio secundario privado",
        "name": "categories/gcid:preparatory_school"
    },
    {
        "displayName": "Iglesia presbiteriana",
        "name": "categories/gcid:presbyterian_church"
    },
    {
        "displayName": "Educación preescolar",
        "name": "categories/gcid:preschool"
    },
    {
        "displayName": "Consultoría de prensa",
        "name": "categories/gcid:press_advisory"
    },
    {
        "displayName": "Servicio de lavado a presión",
        "name": "categories/gcid:pressure_washing_service"
    },
    {
        "displayName": "Tienda de pretzels",
        "name": "categories/gcid:pretzel_store"
    },
    {
        "displayName": "Sacerdote",
        "name": "categories/gcid:priest"
    },
    {
        "displayName": "Escuela primaria",
        "name": "categories/gcid:primary_school"
    },
    {
        "displayName": "Servicio de impresión",
        "name": "categories/gcid:print_shop"
    },
    {
        "displayName": "Editorial de música impresa",
        "name": "categories/gcid:printed_music_publisher"
    },
    {
        "displayName": "Tienda de recarga de cartuchos de tinta",
        "name": "categories/gcid:printer_ink_refill_store"
    },
    {
        "displayName": "Servicio de reparación de impresoras",
        "name": "categories/gcid:printer_repair_service"
    },
    {
        "displayName": "Printing equipment and supplies",
        "name": "categories/gcid:printing_equipment_and_supplies"
    },
    {
        "displayName": "Proveedor de equipos de impresión",
        "name": "categories/gcid:printing_equipment_supplier"
    },
    {
        "displayName": "Cárcel",
        "name": "categories/gcid:prison"
    },
    {
        "displayName": "Universidad privada",
        "name": "categories/gcid:private_college"
    },
    {
        "displayName": "Firma de capital privado",
        "name": "categories/gcid:private_equity_firm"
    },
    {
        "displayName": "Campo de golf privado",
        "name": "categories/gcid:private_golf_course"
    },
    {
        "displayName": "Hospital privado",
        "name": "categories/gcid:private_hospital"
    },
    {
        "displayName": "Investigador privado",
        "name": "categories/gcid:private_investigator"
    },
    {
        "displayName": "Escuela privada",
        "name": "categories/gcid:private_school"
    },
    {
        "displayName": "Banco privado",
        "name": "categories/gcid:private_sector_bank"
    },
    {
        "displayName": "Tutor privado",
        "name": "categories/gcid:private_tutor"
    },
    {
        "displayName": "Universidad privada",
        "name": "categories/gcid:private_university"
    },
    {
        "displayName": "Oficina de libertad condicional",
        "name": "categories/gcid:probation_office"
    },
    {
        "displayName": "Agente judicial",
        "name": "categories/gcid:process_server"
    },
    {
        "displayName": "Proctólogo",
        "name": "categories/gcid:proctologist"
    },
    {
        "displayName": "Mercado de productos agrícolas",
        "name": "categories/gcid:produce_market"
    },
    {
        "displayName": "Mayorista de productos agrícolas",
        "name": "categories/gcid:produce_wholesaler"
    },
    {
        "displayName": "Productor de foie gras",
        "name": "categories/gcid:producteur_de_foie_gras"
    },
    {
        "displayName": "Professional and hobby associations",
        "name": "categories/gcid:professional_and_hobby_associations"
    },
    {
        "displayName": "Asociación profesional",
        "name": "categories/gcid:professional_association"
    },
    {
        "displayName": "Organizador(a) profesional",
        "name": "categories/gcid:professional_organizer"
    },
    {
        "displayName": "Promenade",
        "name": "categories/gcid:promenade"
    },
    {
        "displayName": "Proveedor de productos promocionales",
        "name": "categories/gcid:promotional_products_supplier"
    },
    {
        "displayName": "Proveedor de gas propano",
        "name": "categories/gcid:propane_supplier"
    },
    {
        "displayName": "Tienda de propulsores",
        "name": "categories/gcid:propeller_shop"
    },
    {
        "displayName": "Administrador de la propiedad",
        "name": "categories/gcid:property_administrator"
    },
    {
        "displayName": "Compañía de inversión inmobiliaria",
        "name": "categories/gcid:property_investment"
    },
    {
        "displayName": "Mantenimiento de propiedades",
        "name": "categories/gcid:property_maintenance"
    },
    {
        "displayName": "Compañía de administración de propiedades",
        "name": "categories/gcid:property_management_company"
    },
    {
        "displayName": "Registro de la propiedad",
        "name": "categories/gcid:property_registry"
    },
    {
        "displayName": "Prosthetics",
        "name": "categories/gcid:prosthetics"
    },
    {
        "displayName": "Prostodoncista",
        "name": "categories/gcid:prosthodontist"
    },
    {
        "displayName": "Área protegida",
        "name": "categories/gcid:protected_area"
    },
    {
        "displayName": "Proveedor de ropa de seguridad",
        "name": "categories/gcid:protective_clothing_supplier"
    },
    {
        "displayName": "Iglesia protestante",
        "name": "categories/gcid:protestant_church"
    },
    {
        "displayName": "Restaurante de cocina de Provenza",
        "name": "categories/gcid:provence_restaurant"
    },
    {
        "displayName": "Diputación provincial",
        "name": "categories/gcid:provincial_council"
    },
    {
        "displayName": "Hospital psiquiátrico",
        "name": "categories/gcid:psychiatric_hospital"
    },
    {
        "displayName": "Psiquiatra",
        "name": "categories/gcid:psychiatrist"
    },
    {
        "displayName": "Vidente",
        "name": "categories/gcid:psychic"
    },
    {
        "displayName": "Clínica psicoanalítica",
        "name": "categories/gcid:psychoanalyst"
    },
    {
        "displayName": "Psicólogo",
        "name": "categories/gcid:psychologist"
    },
    {
        "displayName": "Terapeuta psicomotor",
        "name": "categories/gcid:psychomotor_therapist"
    },
    {
        "displayName": "Clínica neuropsicológica",
        "name": "categories/gcid:psychoneurological_specialized_clinic"
    },
    {
        "displayName": "Consultorio de psicopedagogía",
        "name": "categories/gcid:psychopedagogy_clinic"
    },
    {
        "displayName": "Médico psicosomático",
        "name": "categories/gcid:psychosomatic_medical_practitioner"
    },
    {
        "displayName": "Psicoterapeuta",
        "name": "categories/gcid:psychotherapist"
    },
    {
        "displayName": "Pub",
        "name": "categories/gcid:pub"
    },
    {
        "displayName": "Baño público",
        "name": "categories/gcid:public_bath"
    },
    {
        "displayName": "Baño público",
        "name": "categories/gcid:public_bathroom"
    },
    {
        "displayName": "Oficina del Defensor del Pueblo",
        "name": "categories/gcid:public_defenders_office"
    },
    {
        "displayName": "Baño público para mujeres",
        "name": "categories/gcid:public_female_bathroom"
    },
    {
        "displayName": "Campo de golf público",
        "name": "categories/gcid:public_golf_course"
    },
    {
        "displayName": "Departamento de Salud Pública",
        "name": "categories/gcid:public_health_department"
    },
    {
        "displayName": "Vivienda pública",
        "name": "categories/gcid:public_housing"
    },
    {
        "displayName": "Biblioteca pública",
        "name": "categories/gcid:public_library"
    },
    {
        "displayName": "Baño público para hombres",
        "name": "categories/gcid:public_male_bathroom"
    },
    {
        "displayName": "Centro médico público",
        "name": "categories/gcid:public_medical_center"
    },
    {
        "displayName": "Espacio de estacionamiento público",
        "name": "categories/gcid:public_parking_space"
    },
    {
        "displayName": "Oficina de abogados públicos",
        "name": "categories/gcid:public_prosecutors_office"
    },
    {
        "displayName": "Empresa de relaciones públicas",
        "name": "categories/gcid:public_relations_firm"
    },
    {
        "displayName": "Secretaría de Seguridad Pública",
        "name": "categories/gcid:public_safety_office"
    },
    {
        "displayName": "Sauna público",
        "name": "categories/gcid:public_sauna"
    },
    {
        "displayName": "Escuela pública",
        "name": "categories/gcid:public_school"
    },
    {
        "displayName": "Banco del sector público",
        "name": "categories/gcid:public_sector_bank"
    },
    {
        "displayName": "Piscina de natación pública",
        "name": "categories/gcid:public_swimming_pool"
    },
    {
        "displayName": "Universidad pública",
        "name": "categories/gcid:public_university"
    },
    {
        "displayName": "Baño público con acceso para sillas de ruedas",
        "name": "categories/gcid:public_wheelchair_accessible_bathroom"
    },
    {
        "displayName": "Departamento de Obras Públicas",
        "name": "categories/gcid:public_works_department"
    },
    {
        "displayName": "Editor",
        "name": "categories/gcid:publisher"
    },
    {
        "displayName": "Restaurante poblano",
        "name": "categories/gcid:pueblan_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Puerto Rico",
        "name": "categories/gcid:puerto_rican_restaurant"
    },
    {
        "displayName": "Neumólogo",
        "name": "categories/gcid:pulmonologist"
    },
    {
        "displayName": "Proveedor de bombas",
        "name": "categories/gcid:pump_supplier"
    },
    {
        "displayName": "Parche de calabaza",
        "name": "categories/gcid:pumpkin_patch"
    },
    {
        "displayName": "Restaurante punjabí",
        "name": "categories/gcid:punjabi_restaurant"
    },
    {
        "displayName": "Teatro de títeres",
        "name": "categories/gcid:puppet_theater"
    },
    {
        "displayName": "PVC industry",
        "name": "categories/gcid:pvc_industry"
    },
    {
        "displayName": "Proveedor de ventanas PVC",
        "name": "categories/gcid:pvc_windows_supplier"
    },
    {
        "displayName": "Pirotécnico",
        "name": "categories/gcid:pyrotechnician"
    },
    {
        "displayName": "Mercado de Qing Fang",
        "name": "categories/gcid:qing_fang_market_place"
    },
    {
        "displayName": "Iglesia cuáquera",
        "name": "categories/gcid:quaker_church"
    },
    {
        "displayName": "Calculista",
        "name": "categories/gcid:quantity_surveyor"
    },
    {
        "displayName": "Cantera",
        "name": "categories/gcid:quarry"
    },
    {
        "displayName": "Restaurante de cocina de Quebec",
        "name": "categories/gcid:quebecois_restaurant"
    },
    {
        "displayName": "Tienda de acolchados",
        "name": "categories/gcid:quilt_shop"
    },
    {
        "displayName": "Concesionario de automóviles de carrera",
        "name": "categories/gcid:race_car_dealer"
    },
    {
        "displayName": "Hipódromo",
        "name": "categories/gcid:race_course"
    },
    {
        "displayName": "Tienda de repuestos para automóviles de carrera",
        "name": "categories/gcid:racing_car_parts_store"
    },
    {
        "displayName": "Restaurante especializado en raclettes",
        "name": "categories/gcid:raclette_restaurant"
    },
    {
        "displayName": "Club de raquetbol",
        "name": "categories/gcid:racquetball_club"
    },
    {
        "displayName": "Servicio de reparación de radiadores",
        "name": "categories/gcid:radiator_repair_service"
    },
    {
        "displayName": "Tienda de radiadores",
        "name": "categories/gcid:radiator_shop"
    },
    {
        "displayName": "Radiodifusora",
        "name": "categories/gcid:radio_broadcaster"
    },
    {
        "displayName": "Radiólogo",
        "name": "categories/gcid:radiologist"
    },
    {
        "displayName": "Radioterapeuta",
        "name": "categories/gcid:radiotherapist"
    },
    {
        "displayName": "Distribuidor de equipos para excursiones en balsa",
        "name": "categories/gcid:raft_trip_outfitter"
    },
    {
        "displayName": "Rafting",
        "name": "categories/gcid:rafting"
    },
    {
        "displayName": "Museo del ferrocarril",
        "name": "categories/gcid:rail_museum"
    },
    {
        "displayName": "Contratista de barandas",
        "name": "categories/gcid:railing_contractor"
    },
    {
        "displayName": "Empresa ferroviaria",
        "name": "categories/gcid:railroad_company"
    },
    {
        "displayName": "Contratista de ferrocarril",
        "name": "categories/gcid:railroad_contractor"
    },
    {
        "displayName": "Proveedor de equipos de ferrocarril",
        "name": "categories/gcid:railroad_equipment_supplier"
    },
    {
        "displayName": "Railroad ties supplier",
        "name": "categories/gcid:railroad_ties_supplier"
    },
    {
        "displayName": "Servicios ferroviarios",
        "name": "categories/gcid:railway_services"
    },
    {
        "displayName": "Proveedor de tanques para agua de lluvia",
        "name": "categories/gcid:rainwater_tank_supplier"
    },
    {
        "displayName": "Restaurante rayastani",
        "name": "categories/gcid:rajasthani_restaurant"
    },
    {
        "displayName": "Concesionario Ram",
        "name": "categories/gcid:ram_dealer"
    },
    {
        "displayName": "Restaurante de ramen",
        "name": "categories/gcid:ramen_restaurant"
    },
    {
        "displayName": "Hacienda de ganadería",
        "name": "categories/gcid:ranch"
    },
    {
        "displayName": "Tienda de libros atípicos",
        "name": "categories/gcid:rare_book_store"
    },
    {
        "displayName": "Restaurante de comida cruda",
        "name": "categories/gcid:raw_food_restaurant"
    },
    {
        "displayName": "Proveedor de hormigón premezclado",
        "name": "categories/gcid:ready_mix_concrete_supplier"
    },
    {
        "displayName": "Agencia inmobiliaria",
        "name": "categories/gcid:real_estate_agency"
    },
    {
        "displayName": "Agente inmobiliario",
        "name": "categories/gcid:real_estate_agents"
    },
    {
        "displayName": "Valuador inmobiliario",
        "name": "categories/gcid:real_estate_appraiser"
    },
    {
        "displayName": "Abogado especializado en derecho inmobiliario",
        "name": "categories/gcid:real_estate_attorney"
    },
    {
        "displayName": "Rematador inmobiliario",
        "name": "categories/gcid:real_estate_auctioneer"
    },
    {
        "displayName": "Consultor inmobiliario",
        "name": "categories/gcid:real_estate_consultant"
    },
    {
        "displayName": "Promotor inmobiliario",
        "name": "categories/gcid:real_estate_developer"
    },
    {
        "displayName": "Agencia de alquiler de inmuebles",
        "name": "categories/gcid:real_estate_rental_agency"
    },
    {
        "displayName": "Escuela de bienes raíces",
        "name": "categories/gcid:real_estate_school"
    },
    {
        "displayName": "Agrimensor de bienes raíces",
        "name": "categories/gcid:real_estate_surveyor"
    },
    {
        "displayName": "Escuela práctica",
        "name": "categories/gcid:realschule"
    },
    {
        "displayName": "Reclamation centre",
        "name": "categories/gcid:reclamation_centre"
    },
    {
        "displayName": "Productora discográfica",
        "name": "categories/gcid:record_company"
    },
    {
        "displayName": "Instalación de almacenamiento de archivos",
        "name": "categories/gcid:record_storage_facility"
    },
    {
        "displayName": "Tienda de discos de vinilo",
        "name": "categories/gcid:record_store"
    },
    {
        "displayName": "Estudio de grabación",
        "name": "categories/gcid:recording_studio"
    },
    {
        "displayName": "Centro de recreación",
        "name": "categories/gcid:recreation_center"
    },
    {
        "displayName": "Reclutador",
        "name": "categories/gcid:recruiter"
    },
    {
        "displayName": "Rectoría",
        "name": "categories/gcid:rectory"
    },
    {
        "displayName": "Centro de reciclaje",
        "name": "categories/gcid:recycling_center"
    },
    {
        "displayName": "Punto de recolección de reciclables",
        "name": "categories/gcid:recycling_drop_off_location"
    },
    {
        "displayName": "Sitio de recreación histórica",
        "name": "categories/gcid:reenactment_site"
    },
    {
        "displayName": "Reflexólogo",
        "name": "categories/gcid:reflexologist"
    },
    {
        "displayName": "Sinagoga de la Reforma",
        "name": "categories/gcid:reform_synagogue"
    },
    {
        "displayName": "Iglesia Reformada",
        "name": "categories/gcid:reformed_church"
    },
    {
        "displayName": "Refrigerated transport service",
        "name": "categories/gcid:refrigerated_transport_service"
    },
    {
        "displayName": "Servicio de reparación de refrigeradores",
        "name": "categories/gcid:refrigerator_repair_service"
    },
    {
        "displayName": "Tienda de refrigeradores",
        "name": "categories/gcid:refrigerator_store"
    },
    {
        "displayName": "Campo de refugiados",
        "name": "categories/gcid:refugee_camp"
    },
    {
        "displayName": "Aeropuerto regional",
        "name": "categories/gcid:regional_airport"
    },
    {
        "displayName": "Consejo comarcal",
        "name": "categories/gcid:regional_council"
    },
    {
        "displayName": "Oficina gubernamental regional",
        "name": "categories/gcid:regional_government_office"
    },
    {
        "displayName": "Asistente técnico sanitario",
        "name": "categories/gcid:registered_general_nurse"
    },
    {
        "displayName": "Oficina de registro",
        "name": "categories/gcid:registration_office"
    },
    {
        "displayName": "Registro civil",
        "name": "categories/gcid:registry_office"
    },
    {
        "displayName": "Centro de rehabilitación",
        "name": "categories/gcid:rehabilitation_center"
    },
    {
        "displayName": "Sala de ensayos",
        "name": "categories/gcid:rehearsa_studio"
    },
    {
        "displayName": "Terapeuta de Reiki",
        "name": "categories/gcid:reiki_therapist"
    },
    {
        "displayName": "Tienda de libros religiosos",
        "name": "categories/gcid:religious_book_store"
    },
    {
        "displayName": "Destino religioso",
        "name": "categories/gcid:religious_destination"
    },
    {
        "displayName": "Tienda de artículos religiosos",
        "name": "categories/gcid:religious_goods_store"
    },
    {
        "displayName": "Institución religiosa",
        "name": "categories/gcid:religious_institution"
    },
    {
        "displayName": "Hospedaje religioso",
        "name": "categories/gcid:religious_lodging"
    },
    {
        "displayName": "Organización religiosa",
        "name": "categories/gcid:religious_organization"
    },
    {
        "displayName": "Escuela religiosa",
        "name": "categories/gcid:religious_school"
    },
    {
        "displayName": "Remodelador",
        "name": "categories/gcid:remodeler"
    },
    {
        "displayName": "Concesionario de Renault",
        "name": "categories/gcid:renault_dealer"
    },
    {
        "displayName": "Agencia de seguros de locatario",
        "name": "categories/gcid:renters_insurance_agency"
    },
    {
        "displayName": "Servicio de reparación",
        "name": "categories/gcid:repair_service"
    },
    {
        "displayName": "Clínica de salud reproductiva",
        "name": "categories/gcid:reproductive_health_clinic"
    },
    {
        "displayName": "Tienda de reptiles",
        "name": "categories/gcid:reptile_store"
    },
    {
        "displayName": "Investigación y desarrollo de productos",
        "name": "categories/gcid:research_and_product_development"
    },
    {
        "displayName": "Ingeniero de investigación",
        "name": "categories/gcid:research_engineer"
    },
    {
        "displayName": "Fundación de investigación",
        "name": "categories/gcid:research_foundation"
    },
    {
        "displayName": "Instituto de Investigaciones",
        "name": "categories/gcid:research_institute"
    },
    {
        "displayName": "Resident registration office",
        "name": "categories/gcid:resident_registration_office"
    },
    {
        "displayName": "Colegio universitario residencial",
        "name": "categories/gcid:residential_college"
    },
    {
        "displayName": "Asociación de vecinos",
        "name": "categories/gcid:residents_association"
    },
    {
        "displayName": "Complejo hotelero",
        "name": "categories/gcid:resort_hotel"
    },
    {
        "displayName": "Parada de descanso",
        "name": "categories/gcid:rest_stop"
    },
    {
        "displayName": "Restaurante",
        "name": "categories/gcid:restaurant"
    },
    {
        "displayName": "Brasserie",
        "name": "categories/gcid:restaurant_brasserie"
    },
    {
        "displayName": "Tienda de suministros para restaurantes",
        "name": "categories/gcid:restaurant_supply_store"
    },
    {
        "displayName": "Servicios de currículum vítae",
        "name": "categories/gcid:resume_service"
    },
    {
        "displayName": "Agencia de alquiler de espacios comerciales",
        "name": "categories/gcid:retail_space_rental_agency"
    },
    {
        "displayName": "Fabricante de muros de contención",
        "name": "categories/gcid:retaining_wall_supplier"
    },
    {
        "displayName": "Comunidad de retiro",
        "name": "categories/gcid:retirement_community"
    },
    {
        "displayName": "Casa de reposo",
        "name": "categories/gcid:retirement_home"
    },
    {
        "displayName": "Centro de retiro",
        "name": "categories/gcid:retreat_center"
    },
    {
        "displayName": "Reumatólogo",
        "name": "categories/gcid:rheumatologist"
    },
    {
        "displayName": "Tienda especializada en pasteles de arroz",
        "name": "categories/gcid:rice_cake_shop"
    },
    {
        "displayName": "Tienda de galletas de arroz",
        "name": "categories/gcid:rice_cracker_shop"
    },
    {
        "displayName": "Molino arrocero",
        "name": "categories/gcid:rice_mill"
    },
    {
        "displayName": "Restaurante de arroz",
        "name": "categories/gcid:rice_restaurant"
    },
    {
        "displayName": "Arrocería",
        "name": "categories/gcid:rice_shop"
    },
    {
        "displayName": "Mayorista de arroz",
        "name": "categories/gcid:rice_wholesaler"
    },
    {
        "displayName": "Puerto fluvial",
        "name": "categories/gcid:river_port"
    },
    {
        "displayName": "Empresa de construcción de rutas",
        "name": "categories/gcid:road_construction_company"
    },
    {
        "displayName": "Road construction machine repair service",
        "name": "categories/gcid:road_construction_machine_repair_service"
    },
    {
        "displayName": "Ciclismo de ruta",
        "name": "categories/gcid:road_cycling"
    },
    {
        "displayName": "Centro de seguridad vial",
        "name": "categories/gcid:road_safety_town"
    },
    {
        "displayName": "Asociación de ingenieros de rutas, puertos y canales",
        "name": "categories/gcid:roads_ports_and_canals_engineers_association"
    },
    {
        "displayName": "Escalada en roca",
        "name": "categories/gcid:rock_climbing"
    },
    {
        "displayName": "Gimnasio de escalada en roca",
        "name": "categories/gcid:rock_climbing_centre"
    },
    {
        "displayName": "Instructor de escalada en roca",
        "name": "categories/gcid:rock_climbing_instructor"
    },
    {
        "displayName": "Club de rock",
        "name": "categories/gcid:rock_music_club"
    },
    {
        "displayName": "Tienda de piedras preciosas",
        "name": "categories/gcid:rock_shop"
    },
    {
        "displayName": "Rodeo",
        "name": "categories/gcid:rodeo"
    },
    {
        "displayName": "Proveedor de productos de metales laminados",
        "name": "categories/gcid:rolled_metal_products_supplier"
    },
    {
        "displayName": "Montaña rusa",
        "name": "categories/gcid:roller_coaster"
    },
    {
        "displayName": "Club de patinaje",
        "name": "categories/gcid:roller_skating_club"
    },
    {
        "displayName": "Pista de patinaje",
        "name": "categories/gcid:roller_skating_rink"
    },
    {
        "displayName": "Concesionario Rolls-Royce",
        "name": "categories/gcid:rolls_royce_dealer"
    },
    {
        "displayName": "Restaurante de cocina romana",
        "name": "categories/gcid:roman_restaurant"
    },
    {
        "displayName": "Restaurante de cocina rumana",
        "name": "categories/gcid:romanian_restaurant"
    },
    {
        "displayName": "Contratista de tejados",
        "name": "categories/gcid:roofing_contractor"
    },
    {
        "displayName": "Tienda de insumos para techado",
        "name": "categories/gcid:roofing_supply_store"
    },
    {
        "displayName": "Servicio de referencia de compañero de cuarto",
        "name": "categories/gcid:roommate_referral_service"
    },
    {
        "displayName": "Área de regata",
        "name": "categories/gcid:rowing_area"
    },
    {
        "displayName": "Club de remo",
        "name": "categories/gcid:rowing_club"
    },
    {
        "displayName": "Club RSL",
        "name": "categories/gcid:rsl_club"
    },
    {
        "displayName": "Proveedor de productos de caucho",
        "name": "categories/gcid:rubber_products_supplier"
    },
    {
        "displayName": "Tienda de sellos de goma",
        "name": "categories/gcid:rubber_stamp_store"
    },
    {
        "displayName": "Tienda de alfombras",
        "name": "categories/gcid:rug_store"
    },
    {
        "displayName": "Rugby",
        "name": "categories/gcid:rugby"
    },
    {
        "displayName": "Club de rugby",
        "name": "categories/gcid:rugby_club"
    },
    {
        "displayName": "Campo de rugby",
        "name": "categories/gcid:rugby_field"
    },
    {
        "displayName": "Club de la Liga de Rugby",
        "name": "categories/gcid:rugby_league_club"
    },
    {
        "displayName": "Tienda de rugby",
        "name": "categories/gcid:rugby_store"
    },
    {
        "displayName": "Tienda para corredores",
        "name": "categories/gcid:running_store"
    },
    {
        "displayName": "Tienda de abarrotes rusa",
        "name": "categories/gcid:russian_grocery_store"
    },
    {
        "displayName": "Iglesia Ortodoxa Rusa",
        "name": "categories/gcid:russian_orthodox_church"
    },
    {
        "displayName": "Restaurante ruso",
        "name": "categories/gcid:russian_restaurant"
    },
    {
        "displayName": "Tienda de mobiliario rústico",
        "name": "categories/gcid:rustic_furniture_store"
    },
    {
        "displayName": "Concesionario de vehículos recreativos",
        "name": "categories/gcid:rv_dealer"
    },
    {
        "displayName": "Servicio de detallado de casas rodantes",
        "name": "categories/gcid:rv_detailing_service"
    },
    {
        "displayName": "Parque para casas rodantes",
        "name": "categories/gcid:rv_park"
    },
    {
        "displayName": "Agencia de alquiler de vehículos recreativos",
        "name": "categories/gcid:rv_rental_agency"
    },
    {
        "displayName": "Taller de reparación de vehículos recreativos",
        "name": "categories/gcid:rv_repair_shop"
    },
    {
        "displayName": "Instalación de almacenamiento de vehículos recreativos",
        "name": "categories/gcid:rv_storage_facility"
    },
    {
        "displayName": "Tienda de insumos para vehículos recreativos",
        "name": "categories/gcid:rv_supply_store"
    },
    {
        "displayName": "Concesionario Saab",
        "name": "categories/gcid:saab_dealer"
    },
    {
        "displayName": "SACEM",
        "name": "categories/gcid:sacem"
    },
    {
        "displayName": "Talabartería",
        "name": "categories/gcid:saddlery"
    },
    {
        "displayName": "Tienda de bóvedas y cajas de seguridad",
        "name": "categories/gcid:safe_and_vault_shop"
    },
    {
        "displayName": "Proveedor de equipos de seguridad",
        "name": "categories/gcid:safety_equipment_supplier"
    },
    {
        "displayName": "Club de navegación",
        "name": "categories/gcid:sailing_club"
    },
    {
        "displayName": "Área de eventos de navegación a vela",
        "name": "categories/gcid:sailing_event_area"
    },
    {
        "displayName": "Escuela de navegación",
        "name": "categories/gcid:sailing_school"
    },
    {
        "displayName": "Fabricante de velas para embarcaciones",
        "name": "categories/gcid:sailmaker"
    },
    {
        "displayName": "Fábrica de sake",
        "name": "categories/gcid:sake_brewery"
    },
    {
        "displayName": "Restaurante de ensaladas",
        "name": "categories/gcid:salad_shop"
    },
    {
        "displayName": "Bar donde se baila salsa",
        "name": "categories/gcid:salsa_bar"
    },
    {
        "displayName": "Clases de salsa",
        "name": "categories/gcid:salsa_classes"
    },
    {
        "displayName": "Restaurante salvadoreño",
        "name": "categories/gcid:salvadoran_restaurant"
    },
    {
        "displayName": "Negocio de autos de salvamento",
        "name": "categories/gcid:salvage_dealer"
    },
    {
        "displayName": "Feria de artículos usados",
        "name": "categories/gcid:salvage_yard"
    },
    {
        "displayName": "Escuela de samba",
        "name": "categories/gcid:samba_school"
    },
    {
        "displayName": "Escuela de sambo",
        "name": "categories/gcid:sambo_school"
    },
    {
        "displayName": "Sambódromo",
        "name": "categories/gcid:sambodrome"
    },
    {
        "displayName": "Proveedor de arena y gravilla",
        "name": "categories/gcid:sand_and_gravel_supplier"
    },
    {
        "displayName": "Planta de arena",
        "name": "categories/gcid:sand_plant"
    },
    {
        "displayName": "Servicio de limpieza por arenador",
        "name": "categories/gcid:sandblasting_service"
    },
    {
        "displayName": "Tienda de sándwiches",
        "name": "categories/gcid:sandwich_shop"
    },
    {
        "displayName": "Centro de inspección sanitaria",
        "name": "categories/gcid:sanitary_inspection"
    },
    {
        "displayName": "Servicio de saneamiento",
        "name": "categories/gcid:sanitation_service"
    },
    {
        "displayName": "Restaurante sardo",
        "name": "categories/gcid:sardinian_restaurant"
    },
    {
        "displayName": "Tienda de saris",
        "name": "categories/gcid:saree_shop"
    },
    {
        "displayName": "Restaurante especializado en satay",
        "name": "categories/gcid:satay_restaurant"
    },
    {
        "displayName": "Servicio de comunicación por satélite",
        "name": "categories/gcid:satellite_communication_service"
    },
    {
        "displayName": "Concesionario Saturn",
        "name": "categories/gcid:saturn_dealer"
    },
    {
        "displayName": "Sauna",
        "name": "categories/gcid:sauna"
    },
    {
        "displayName": "Sauna privado",
        "name": "categories/gcid:sauna_club"
    },
    {
        "displayName": "Tienda de saunas",
        "name": "categories/gcid:sauna_store"
    },
    {
        "displayName": "Banco de ahorros",
        "name": "categories/gcid:savings_bank"
    },
    {
        "displayName": "Aserradero",
        "name": "categories/gcid:saw_mill"
    },
    {
        "displayName": "Servicio de afilado de sierras",
        "name": "categories/gcid:saw_sharpening_service"
    },
    {
        "displayName": "Servicio de andamiaje",
        "name": "categories/gcid:scaffolder"
    },
    {
        "displayName": "Empresa de alquiler de andamios",
        "name": "categories/gcid:scaffolding_rental_service"
    },
    {
        "displayName": "Club de modelos a escala",
        "name": "categories/gcid:scale_model_club"
    },
    {
        "displayName": "Taller de maquetas",
        "name": "categories/gcid:scale_model_shop"
    },
    {
        "displayName": "Servicio de reparación de básculas",
        "name": "categories/gcid:scale_repair_service"
    },
    {
        "displayName": "Proveedor de básculas",
        "name": "categories/gcid:scale_supplier"
    },
    {
        "displayName": "Restaurante escandinavo",
        "name": "categories/gcid:scandinavian_restaurant"
    },
    {
        "displayName": "Lugar destacado",
        "name": "categories/gcid:scenic_spot"
    },
    {
        "displayName": "Compañía escenográfica",
        "name": "categories/gcid:scenography_company"
    },
    {
        "displayName": "Escuela",
        "name": "categories/gcid:school"
    },
    {
        "displayName": "Oficina de administración escolar",
        "name": "categories/gcid:school_administrator"
    },
    {
        "displayName": "Servicio de bus escolar",
        "name": "categories/gcid:school_bus_service"
    },
    {
        "displayName": "Centro educativo",
        "name": "categories/gcid:school_center"
    },
    {
        "displayName": "Oficina del distrito escolar",
        "name": "categories/gcid:school_district_office"
    },
    {
        "displayName": "Escuela para sordos",
        "name": "categories/gcid:school_for_the_deaf"
    },
    {
        "displayName": "Internado educativo",
        "name": "categories/gcid:school_house"
    },
    {
        "displayName": "Cantina escolar",
        "name": "categories/gcid:school_lunch_center"
    },
    {
        "displayName": "Tienda de insumos escolares",
        "name": "categories/gcid:school_supply_store"
    },
    {
        "displayName": "Museo de ciencias",
        "name": "categories/gcid:science_museum"
    },
    {
        "displayName": "Proveedor de equipos científicos",
        "name": "categories/gcid:scientific_equipment_supplier"
    },
    {
        "displayName": "Servicio de alquiler de scooters",
        "name": "categories/gcid:scooter_rental_service"
    },
    {
        "displayName": "Taller de reparación de scooters",
        "name": "categories/gcid:scooter_repair_shop"
    },
    {
        "displayName": "Restaurante escocés",
        "name": "categories/gcid:scottish_restaurant"
    },
    {
        "displayName": "Salón de niños exploradores",
        "name": "categories/gcid:scout_hall"
    },
    {
        "displayName": "Centro para niños exploradores",
        "name": "categories/gcid:scout_home"
    },
    {
        "displayName": "Scouting",
        "name": "categories/gcid:scouting"
    },
    {
        "displayName": "Distribuidor de chatarra",
        "name": "categories/gcid:scrap_metal_dealer"
    },
    {
        "displayName": "Tienda de scrapbooking",
        "name": "categories/gcid:scrapbooking_store"
    },
    {
        "displayName": "Impresora de pantallas",
        "name": "categories/gcid:screen_printer"
    },
    {
        "displayName": "Tienda de estampadoras serigráficas",
        "name": "categories/gcid:screen_printing_shop"
    },
    {
        "displayName": "Tienda de insumos para estampadoras serigráficas",
        "name": "categories/gcid:screen_printing_supply_store"
    },
    {
        "displayName": "Servicio de reparación de cristales",
        "name": "categories/gcid:screen_repair_service"
    },
    {
        "displayName": "Tienda de puertas mosquiteras",
        "name": "categories/gcid:screen_store"
    },
    {
        "displayName": "Proveedor de tornillería",
        "name": "categories/gcid:screw_supplier"
    },
    {
        "displayName": "Instructor de buceo",
        "name": "categories/gcid:scuba_instructor"
    },
    {
        "displayName": "Agencia de buceo",
        "name": "categories/gcid:scuba_tour_agency"
    },
    {
        "displayName": "Sculptor",
        "name": "categories/gcid:sculptor"
    },
    {
        "displayName": "Escultura",
        "name": "categories/gcid:sculpture"
    },
    {
        "displayName": "Museo de esculturas",
        "name": "categories/gcid:sculpture_museum"
    },
    {
        "displayName": "Restaurante especializado en mariscos donburi",
        "name": "categories/gcid:seafood_donburi"
    },
    {
        "displayName": "Granja de mariscos",
        "name": "categories/gcid:seafood_farm"
    },
    {
        "displayName": "Mercado de mariscos",
        "name": "categories/gcid:seafood_market"
    },
    {
        "displayName": "Restaurante de mariscos",
        "name": "categories/gcid:seafood_restaurant"
    },
    {
        "displayName": "Mayorista de mariscos",
        "name": "categories/gcid:seafood_wholesaler"
    },
    {
        "displayName": "Tienda de sellado",
        "name": "categories/gcid:seal_shop"
    },
    {
        "displayName": "Base de hidroaviones",
        "name": "categories/gcid:seaplane_base"
    },
    {
        "displayName": "Tienda de artículos estacionales",
        "name": "categories/gcid:seasonal_goods_store"
    },
    {
        "displayName": "Concesionario de Seat",
        "name": "categories/gcid:seat_dealer"
    },
    {
        "displayName": "Restaurante de seblak",
        "name": "categories/gcid:seblak_restaurant"
    },
    {
        "displayName": "Tienda de segunda mano",
        "name": "categories/gcid:second_hand_shop"
    },
    {
        "displayName": "Escuela secundaria",
        "name": "categories/gcid:secondary_school"
    },
    {
        "displayName": "Empresa de seguridad",
        "name": "categories/gcid:security_guard_service"
    },
    {
        "displayName": "Servicio de seguridad privada",
        "name": "categories/gcid:security_service"
    },
    {
        "displayName": "Instaladores de sistemas de seguridad",
        "name": "categories/gcid:security_system_installer"
    },
    {
        "displayName": "Proveedor de sistemas de seguridad",
        "name": "categories/gcid:security_system_supplier"
    },
    {
        "displayName": "Proveedor de semillas",
        "name": "categories/gcid:seed_supplier"
    },
    {
        "displayName": "Seitai",
        "name": "categories/gcid:seitai"
    },
    {
        "displayName": "Escuela de autodefensa",
        "name": "categories/gcid:self_defense_school"
    },
    {
        "displayName": "Autoservicio de lavado de autos",
        "name": "categories/gcid:self_service_car_wash"
    },
    {
        "displayName": "Estación de salud con autoservicio",
        "name": "categories/gcid:self_service_health_station"
    },
    {
        "displayName": "Self service restaurant",
        "name": "categories/gcid:self_service_restaurant"
    },
    {
        "displayName": "Instalación de autoalmacenamiento",
        "name": "categories/gcid:self_storage_facility"
    },
    {
        "displayName": "Proveedor de semiconductores",
        "name": "categories/gcid:semi_conductor_supplier"
    },
    {
        "displayName": "Seminario",
        "name": "categories/gcid:seminary"
    },
    {
        "displayName": "Centro de la tercera edad",
        "name": "categories/gcid:senior_citizen_center"
    },
    {
        "displayName": "Cuidado de personas mayores",
        "name": "categories/gcid:senior_citizens_care_service"
    },
    {
        "displayName": "Escuela secundaria superior",
        "name": "categories/gcid:senior_high_school"
    },
    {
        "displayName": "Servicio de sistemas sépticos",
        "name": "categories/gcid:septic_system_service"
    },
    {
        "displayName": "Restaurante de cocina serbia",
        "name": "categories/gcid:serbian_restaurant"
    },
    {
        "displayName": "Alojamiento con servicios hoteleros",
        "name": "categories/gcid:serviced_accommodation"
    },
    {
        "displayName": "Departamento con servicios incluidos",
        "name": "categories/gcid:serviced_apartment"
    },
    {
        "displayName": "Iglesia Adventista del Séptimo Día",
        "name": "categories/gcid:seventh_day_adventist_church"
    },
    {
        "displayName": "Servicio de desecho de aguas residuales",
        "name": "categories/gcid:sewage_disposal_service"
    },
    {
        "displayName": "Planta de tratamiento de aguas negras",
        "name": "categories/gcid:sewage_treatment_plant"
    },
    {
        "displayName": "Compañía de costura",
        "name": "categories/gcid:sewing_company"
    },
    {
        "displayName": "Servicio de reparación de máquinas de coser",
        "name": "categories/gcid:sewing_machine_repair_service"
    },
    {
        "displayName": "Tienda de máquinas de coser",
        "name": "categories/gcid:sewing_machine_store"
    },
    {
        "displayName": "Tienda de costura",
        "name": "categories/gcid:sewing_shop"
    },
    {
        "displayName": "Sexólogo",
        "name": "categories/gcid:sexologist"
    },
    {
        "displayName": "Restaurante de cocina de Seychelles",
        "name": "categories/gcid:seychelles_restaurant"
    },
    {
        "displayName": "Restaurante especializado en sfiha",
        "name": "categories/gcid:sfiha_restaurant"
    },
    {
        "displayName": "Restaurante de sukiyaki y shabu shabu",
        "name": "categories/gcid:shabu_shabu_and_sukiyaki_restaurant"
    },
    {
        "displayName": "Restaurante especializado en shabu-shabu",
        "name": "categories/gcid:shabu_shabu_restaurant"
    },
    {
        "displayName": "Restaurante Shandong",
        "name": "categories/gcid:shan_dong_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Shanghái",
        "name": "categories/gcid:shanghainese_restaurant"
    },
    {
        "displayName": "Cocina comercial de uso compartido",
        "name": "categories/gcid:shared_use_commercial_kitchen"
    },
    {
        "displayName": "Servicio de afilado",
        "name": "categories/gcid:sharpening_service"
    },
    {
        "displayName": "Restaurante de shawarma",
        "name": "categories/gcid:shawarma_restaurant"
    },
    {
        "displayName": "Constructor de cobertizos",
        "name": "categories/gcid:shed_builder"
    },
    {
        "displayName": "Esquilador de ovejas",
        "name": "categories/gcid:sheep_shearer"
    },
    {
        "displayName": "Proveedor de piel y lana de oveja",
        "name": "categories/gcid:sheepskin_and_wool_products_supplier"
    },
    {
        "displayName": "Sheepskin coat store",
        "name": "categories/gcid:sheepskin_coat_store"
    },
    {
        "displayName": "Contratista de planchas metálicas",
        "name": "categories/gcid:sheet_metal_contractor"
    },
    {
        "displayName": "Tienda de partituras",
        "name": "categories/gcid:sheet_music_store"
    },
    {
        "displayName": "Albergue",
        "name": "categories/gcid:shelter"
    },
    {
        "displayName": "Vivienda asistida",
        "name": "categories/gcid:sheltered_housing"
    },
    {
        "displayName": "Tienda de estanterías",
        "name": "categories/gcid:shelving_store"
    },
    {
        "displayName": "Oficina del alguacil",
        "name": "categories/gcid:sheriffs_department"
    },
    {
        "displayName": "Shinkin bank",
        "name": "categories/gcid:shinkin_bank"
    },
    {
        "displayName": "Santuario sintoísta",
        "name": "categories/gcid:shinto_shrine"
    },
    {
        "displayName": "Shipbuilding and repair company",
        "name": "categories/gcid:shipbuilding_and_repair_company"
    },
    {
        "displayName": "Servicio de mensajería y transporte",
        "name": "categories/gcid:shipping_and_mailing_service"
    },
    {
        "displayName": "Compañía naviera",
        "name": "categories/gcid:shipping_company"
    },
    {
        "displayName": "Sector de equipos para fletes",
        "name": "categories/gcid:shipping_equipment_industry"
    },
    {
        "displayName": "Servicio de envíos",
        "name": "categories/gcid:shipping_service"
    },
    {
        "displayName": "Astillero",
        "name": "categories/gcid:shipyard"
    },
    {
        "displayName": "Fábrica de shochu",
        "name": "categories/gcid:shochu_brewery"
    },
    {
        "displayName": "Fábrica de zapatos",
        "name": "categories/gcid:shoe_factory"
    },
    {
        "displayName": "Tienda de reparación de zapatos",
        "name": "categories/gcid:shoe_repair_shop"
    },
    {
        "displayName": "Servicio de lustrado de calzados",
        "name": "categories/gcid:shoe_shining_service"
    },
    {
        "displayName": "Zapatería",
        "name": "categories/gcid:shoe_store"
    },
    {
        "displayName": "Mayorista de zapatos",
        "name": "categories/gcid:shoe_wholesaler"
    },
    {
        "displayName": "Clases de shogi",
        "name": "categories/gcid:shogi_lesson"
    },
    {
        "displayName": "Área para eventos de tiro",
        "name": "categories/gcid:shooting_event_area"
    },
    {
        "displayName": "Campo de tiro",
        "name": "categories/gcid:shooting_range"
    },
    {
        "displayName": "Tienda de mobiliario para supermercados y comercios",
        "name": "categories/gcid:shop_supermarket_furniture_store"
    },
    {
        "displayName": "Montador de espacios comerciales",
        "name": "categories/gcid:shopfitter"
    },
    {
        "displayName": "Centro comercial",
        "name": "categories/gcid:shopping_center"
    },
    {
        "displayName": "Agencia de arriendo de departamentos a corto plazo",
        "name": "categories/gcid:short_term_apartment_rental_agency"
    },
    {
        "displayName": "Tienda de puertas para duchas",
        "name": "categories/gcid:shower_door_shop"
    },
    {
        "displayName": "Sala de exposición",
        "name": "categories/gcid:showroom"
    },
    {
        "displayName": "Servicio de triturado",
        "name": "categories/gcid:shredding_service"
    },
    {
        "displayName": "Granja de camarones",
        "name": "categories/gcid:shrimp_farm"
    },
    {
        "displayName": "Santuario",
        "name": "categories/gcid:shrine"
    },
    {
        "displayName": "Restaurante de comida de Sichuan",
        "name": "categories/gcid:sichuan_restaurant"
    },
    {
        "displayName": "Restaurante siciliano",
        "name": "categories/gcid:sicilian_restaurant"
    },
    {
        "displayName": "Contratista de revestimientos",
        "name": "categories/gcid:siding_contractor"
    },
    {
        "displayName": "Agencia de turismo",
        "name": "categories/gcid:sightseeing_tour_agency"
    },
    {
        "displayName": "Local de letreros",
        "name": "categories/gcid:sign_shop"
    },
    {
        "displayName": "Gurdwara",
        "name": "categories/gcid:sikh_temple"
    },
    {
        "displayName": "Tienda de plantas de seda",
        "name": "categories/gcid:silk_plant_shop"
    },
    {
        "displayName": "Tienda de seda",
        "name": "categories/gcid:silk_store"
    },
    {
        "displayName": "Platero",
        "name": "categories/gcid:silversmith"
    },
    {
        "displayName": "Restaurante de cocina de Singapur",
        "name": "categories/gcid:singaporean_restaurant"
    },
    {
        "displayName": "Servicio de telegramas cantados",
        "name": "categories/gcid:singing_telegram_service"
    },
    {
        "displayName": "Escuela secundaria diferenciada",
        "name": "categories/gcid:single_sex_secondary_school"
    },
    {
        "displayName": "Organización de personas solteras",
        "name": "categories/gcid:singles_organization"
    },
    {
        "displayName": "Colegio Sixth Form",
        "name": "categories/gcid:sixth_form_college"
    },
    {
        "displayName": "Servicio de afilado de patines de hielo",
        "name": "categories/gcid:skate_sharpening_service"
    },
    {
        "displayName": "Tienda de patinetas",
        "name": "categories/gcid:skate_shop"
    },
    {
        "displayName": "Parque de patinetas",
        "name": "categories/gcid:skateboard_park"
    },
    {
        "displayName": "Tienda de skateboarding",
        "name": "categories/gcid:skateboard_shop"
    },
    {
        "displayName": "Instructor de patinaje",
        "name": "categories/gcid:skating_instructor"
    },
    {
        "displayName": "Campo de tiro de skeet",
        "name": "categories/gcid:skeet_shooting_range"
    },
    {
        "displayName": "Restaurante especializado en kushiage y kushikatsu",
        "name": "categories/gcid:skewer_deep_frying"
    },
    {
        "displayName": "Club de esquí",
        "name": "categories/gcid:ski_club"
    },
    {
        "displayName": "Servicio de renta de equipo para esquiar",
        "name": "categories/gcid:ski_rental_service"
    },
    {
        "displayName": "Servicio de reparaciòn de esquis",
        "name": "categories/gcid:ski_repair_service"
    },
    {
        "displayName": "Centro de esquí",
        "name": "categories/gcid:ski_resort"
    },
    {
        "displayName": "Escuela de esquí",
        "name": "categories/gcid:ski_school"
    },
    {
        "displayName": "Tienda de esquís",
        "name": "categories/gcid:ski_store"
    },
    {
        "displayName": "Clínica del cuidado de la piel",
        "name": "categories/gcid:skin_care_clinic"
    },
    {
        "displayName": "Máquina expendedora de productos para el cuidado de la piel",
        "name": "categories/gcid:skin_care_products_vending_machine"
    },
    {
        "displayName": "Club de skittles",
        "name": "categories/gcid:skittle_club"
    },
    {
        "displayName": "Concesionario de Skoda",
        "name": "categories/gcid:skoda_dealer"
    },
    {
        "displayName": "Centro de paracaidismo de caída libre",
        "name": "categories/gcid:skydiving_center"
    },
    {
        "displayName": "Contratista de tragaluz",
        "name": "categories/gcid:skylight_contractor"
    },
    {
        "displayName": "Matadero",
        "name": "categories/gcid:slaughterhouse"
    },
    {
        "displayName": "Clínica del sueño",
        "name": "categories/gcid:sleep_clinic"
    },
    {
        "displayName": "Servicio de reparación de pequeños electrodomésticos",
        "name": "categories/gcid:small_appliance_repair_service"
    },
    {
        "displayName": "Servicio de asistencia de pequeños reclamos",
        "name": "categories/gcid:small_claims_assistance_service"
    },
    {
        "displayName": "Servicio de reparación de motores pequeños",
        "name": "categories/gcid:small_engine_repair_service"
    },
    {
        "displayName": "Restaurante de platos pequeños",
        "name": "categories/gcid:small_plates_restaurant"
    },
    {
        "displayName": "Consensionario de vehículos inteligentes",
        "name": "categories/gcid:smart_car_dealer"
    },
    {
        "displayName": "Concesionario de Smart",
        "name": "categories/gcid:smart_dealer"
    },
    {
        "displayName": "Tienda inteligente",
        "name": "categories/gcid:smart_shop"
    },
    {
        "displayName": "Estación de inspección de esmog",
        "name": "categories/gcid:smog_inspection_station"
    },
    {
        "displayName": "Tienda de humo",
        "name": "categories/gcid:smoke_shop"
    },
    {
        "displayName": "Bar cafetería",
        "name": "categories/gcid:snack_bar"
    },
    {
        "displayName": "Servicio de limpieza de nieve",
        "name": "categories/gcid:snow_removal_service"
    },
    {
        "displayName": "Servicio de alquiler de tablas de snowboard",
        "name": "categories/gcid:snowboard_rental_service"
    },
    {
        "displayName": "Tienda de snowboard",
        "name": "categories/gcid:snowboard_shop"
    },
    {
        "displayName": "Vendedor de motos de nieve",
        "name": "categories/gcid:snowmobile_dealer"
    },
    {
        "displayName": "Alquiler de motos de nieve",
        "name": "categories/gcid:snowmobile_rental_service"
    },
    {
        "displayName": "Soapland",
        "name": "categories/gcid:soapland"
    },
    {
        "displayName": "Tienda de fideos de soba",
        "name": "categories/gcid:soba_noodle_shop"
    },
    {
        "displayName": "Club de fútbol",
        "name": "categories/gcid:soccer_club"
    },
    {
        "displayName": "Cancha de fútbol",
        "name": "categories/gcid:soccer_field"
    },
    {
        "displayName": "Práctica de fútbol",
        "name": "categories/gcid:soccer_practice"
    },
    {
        "displayName": "Tienda de fútbol",
        "name": "categories/gcid:soccer_store"
    },
    {
        "displayName": "Club social",
        "name": "categories/gcid:social_club"
    },
    {
        "displayName": "Abogado especializado en seguridad social",
        "name": "categories/gcid:social_security_attorney"
    },
    {
        "displayName": "Departamento financiero de seguridad social",
        "name": "categories/gcid:social_security_financial_department"
    },
    {
        "displayName": "Oficina del Seguro Social",
        "name": "categories/gcid:social_security_office"
    },
    {
        "displayName": "Organización de servicios sociales",
        "name": "categories/gcid:social_services_organization"
    },
    {
        "displayName": "Centro de bienestar social",
        "name": "categories/gcid:social_welfare_center"
    },
    {
        "displayName": "Trabajador social",
        "name": "categories/gcid:social_worker"
    },
    {
        "displayName": "Imprenta",
        "name": "categories/gcid:societe_de_flocage"
    },
    {
        "displayName": "Proveedor de césped",
        "name": "categories/gcid:sod_supplier"
    },
    {
        "displayName": "Tienda de sofás",
        "name": "categories/gcid:sofa_store"
    },
    {
        "displayName": "Tienda de refrescos",
        "name": "categories/gcid:soft_drinks_shop"
    },
    {
        "displayName": "Restaurante especializado en tortuga suppon",
        "name": "categories/gcid:soft_shelled_turtle_dish_restaurant"
    },
    {
        "displayName": "Club de Softbol",
        "name": "categories/gcid:softball_club"
    },
    {
        "displayName": "Campo de softball",
        "name": "categories/gcid:softball_field"
    },
    {
        "displayName": "Compañía de software",
        "name": "categories/gcid:software_company"
    },
    {
        "displayName": "Instituto de capacitación en software",
        "name": "categories/gcid:software_training_institute"
    },
    {
        "displayName": "Servicio de análisis de suelos",
        "name": "categories/gcid:soil_testing_service"
    },
    {
        "displayName": "Sokol house",
        "name": "categories/gcid:sokol_house"
    },
    {
        "displayName": "Empresa de energía solar",
        "name": "categories/gcid:solar_energy_company"
    },
    {
        "displayName": "Contratista de energía solar",
        "name": "categories/gcid:solar_energy_contractor"
    },
    {
        "displayName": "Proveedor de equipos de generación de energía solar",
        "name": "categories/gcid:solar_energy_equipment_supplier"
    },
    {
        "displayName": "Proveedor de sistemas solares de agua caliente",
        "name": "categories/gcid:solar_hot_water_system_supplier"
    },
    {
        "displayName": "Servicio de mantenimiento de paneles solares",
        "name": "categories/gcid:solar_panel_maintenance_service"
    },
    {
        "displayName": "Planta de energía fotovoltaica solar",
        "name": "categories/gcid:solar_photovoltaic_power_plant"
    },
    {
        "displayName": "Solid fuel company",
        "name": "categories/gcid:solid_fuel_company"
    },
    {
        "displayName": "Ingeniero en desecho de residuos",
        "name": "categories/gcid:solid_waste_engineer"
    },
    {
        "displayName": "Restaurante de sundae",
        "name": "categories/gcid:soondae_restaurant"
    },
    {
        "displayName": "Restaurante especializado en soto\\xa0ayam",
        "name": "categories/gcid:soto_ayam_restaurant"
    },
    {
        "displayName": "Restaurante de soto",
        "name": "categories/gcid:soto_restaurant"
    },
    {
        "displayName": "Restaurante de cocina soul food",
        "name": "categories/gcid:soul_food_restaurant"
    },
    {
        "displayName": "Comedor de beneficencia",
        "name": "categories/gcid:soup_kitchen"
    },
    {
        "displayName": "Restaurante de sopas",
        "name": "categories/gcid:soup_restaurant"
    },
    {
        "displayName": "Casa de sopas",
        "name": "categories/gcid:soup_shop"
    },
    {
        "displayName": "Restaurante sudafricano",
        "name": "categories/gcid:south_african_restaurant"
    },
    {
        "displayName": "Restaurante sudamericano",
        "name": "categories/gcid:south_american_restaurant"
    },
    {
        "displayName": "Restaurante de cocina del sur asiático",
        "name": "categories/gcid:south_asia_restaurant"
    },
    {
        "displayName": "Restaurante de cocina del sudeste asiático",
        "name": "categories/gcid:south_east_asian_restaurant"
    },
    {
        "displayName": "South Indian restaurant",
        "name": "categories/gcid:south_indian_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Célebes\\xa0Meridional",
        "name": "categories/gcid:south_sulawesi_restaurant"
    },
    {
        "displayName": "Restaurante de cocina del sur de Italia",
        "name": "categories/gcid:southern_italian_restaurant"
    },
    {
        "displayName": "Restaurante de cocina sureña (Estados Unidos)",
        "name": "categories/gcid:southern_us_restaurant"
    },
    {
        "displayName": "Restaurante de cocina del suroeste de Francia",
        "name": "categories/gcid:southwest_french_restaurant"
    },
    {
        "displayName": "Restaurante de cocina del sudoeste (Estados Unidos)",
        "name": "categories/gcid:southwestern_us_restaurant"
    },
    {
        "displayName": "Fabricante de souvenirs",
        "name": "categories/gcid:souvenir_manufacturer"
    },
    {
        "displayName": "Tienda de recuerdos",
        "name": "categories/gcid:souvenir_store"
    },
    {
        "displayName": "Productor de salsa de soja",
        "name": "categories/gcid:soy_sauce_maker"
    },
    {
        "displayName": "Spa",
        "name": "categories/gcid:spa"
    },
    {
        "displayName": "Spa y centro de bienestar",
        "name": "categories/gcid:spa_and_health_club"
    },
    {
        "displayName": "Jardín spa",
        "name": "categories/gcid:spa_garden"
    },
    {
        "displayName": "Espacio de memoria",
        "name": "categories/gcid:space_of_remembrance"
    },
    {
        "displayName": "Restaurante español",
        "name": "categories/gcid:spanish_restaurant"
    },
    {
        "displayName": "Escuela de educación especial",
        "name": "categories/gcid:special_education_school"
    },
    {
        "displayName": "Educador especial",
        "name": "categories/gcid:special_educator"
    },
    {
        "displayName": "Clínica especializada",
        "name": "categories/gcid:specialized_clinic"
    },
    {
        "displayName": "Hospital especializado",
        "name": "categories/gcid:specialized_hospital"
    },
    {
        "displayName": "Patólogo del habla",
        "name": "categories/gcid:speech_pathologist"
    },
    {
        "displayName": "Banco de semen",
        "name": "categories/gcid:sperm_bank"
    },
    {
        "displayName": "Tienda de especias",
        "name": "categories/gcid:spice_store"
    },
    {
        "displayName": "Exportador de especias",
        "name": "categories/gcid:spices_exporter"
    },
    {
        "displayName": "Mayorista de especias",
        "name": "categories/gcid:spices_wholesalers"
    },
    {
        "displayName": "Centro espiritista",
        "name": "categories/gcid:spiritist_center"
    },
    {
        "displayName": "Agencia de turismo deportivo",
        "name": "categories/gcid:sport_tour_agency"
    },
    {
        "displayName": "Tienda de deportes",
        "name": "categories/gcid:sporting_goods_store"
    },
    {
        "displayName": "Mayorista de accesorios deportivos",
        "name": "categories/gcid:sports_accessories_wholesaler"
    },
    {
        "displayName": "Bar deportivo",
        "name": "categories/gcid:sports_bar"
    },
    {
        "displayName": "Tienda de tarjetas de deportes",
        "name": "categories/gcid:sports_card_store"
    },
    {
        "displayName": "Club deportivo",
        "name": "categories/gcid:sports_club"
    },
    {
        "displayName": "Complejo deportivo",
        "name": "categories/gcid:sports_complex"
    },
    {
        "displayName": "Servicio de alquiler de equipamiento deportivo",
        "name": "categories/gcid:sports_equipment_rental_service"
    },
    {
        "displayName": "Masajista deportivo",
        "name": "categories/gcid:sports_massage_therapist"
    },
    {
        "displayName": "Clínica de medicina deportiva",
        "name": "categories/gcid:sports_medicine_clinic"
    },
    {
        "displayName": "Especialista en medicina deportiva",
        "name": "categories/gcid:sports_medicine_physician"
    },
    {
        "displayName": "Tienda de artículos deportivos de colección",
        "name": "categories/gcid:sports_memorabilia_store"
    },
    {
        "displayName": "Tienda de nutrición deportiva",
        "name": "categories/gcid:sports_nutrition_store"
    },
    {
        "displayName": "Escuela de deportes",
        "name": "categories/gcid:sports_school"
    },
    {
        "displayName": "Tienda de vestimenta deportiva",
        "name": "categories/gcid:sportswear_store"
    },
    {
        "displayName": "Fabricante de ropa y elementos deportivos",
        "name": "categories/gcid:sportwear_manufacturer"
    },
    {
        "displayName": "Proveedor de resortes",
        "name": "categories/gcid:spring_supplier"
    },
    {
        "displayName": "Club de squash",
        "name": "categories/gcid:squash_club"
    },
    {
        "displayName": "Cancha de squash",
        "name": "categories/gcid:squash_court"
    },
    {
        "displayName": "Restaurante de cocina de Sri Lanka",
        "name": "categories/gcid:sri_lankan_restaurant"
    },
    {
        "displayName": "Establo",
        "name": "categories/gcid:stable"
    },
    {
        "displayName": "Estadio",
        "name": "categories/gcid:stadium"
    },
    {
        "displayName": "Escenario",
        "name": "categories/gcid:stage"
    },
    {
        "displayName": "Proveedor de equipos de iluminación de escenarios",
        "name": "categories/gcid:stage_lighting_equipment_supplier"
    },
    {
        "displayName": "Estudio de vitrales",
        "name": "categories/gcid:stained_glass_studio"
    },
    {
        "displayName": "Fábrica de acero inoxidable",
        "name": "categories/gcid:stainless_steel_plant"
    },
    {
        "displayName": "Contratista de escaleras",
        "name": "categories/gcid:stair_contractor"
    },
    {
        "displayName": "Servicio de instalación de estands",
        "name": "categories/gcid:stall_installation_service"
    },
    {
        "displayName": "Club de coleccionistas de sellos",
        "name": "categories/gcid:stamp_collectors_club"
    },
    {
        "displayName": "Tienda de estampillas",
        "name": "categories/gcid:stamp_shop"
    },
    {
        "displayName": "Bar al paso",
        "name": "categories/gcid:stand_bar"
    },
    {
        "displayName": "Paquete de alimento básico",
        "name": "categories/gcid:staple_food_package"
    },
    {
        "displayName": "Archivo estatal",
        "name": "categories/gcid:state_archive"
    },
    {
        "displayName": "State department of agricultural development",
        "name": "categories/gcid:state_department_agricultural_development"
    },
    {
        "displayName": "State Department Agriculture Food Supply",
        "name": "categories/gcid:state_department_agriculture_food_supply"
    },
    {
        "displayName": "State Department Communication",
        "name": "categories/gcid:state_department_communication"
    },
    {
        "displayName": "State Department Finance",
        "name": "categories/gcid:state_department_finance"
    },
    {
        "displayName": "State Department for Social Development",
        "name": "categories/gcid:state_department_for_social_development"
    },
    {
        "displayName": "State Department Housing and Urban Development",
        "name": "categories/gcid:state_department_housing_and_urban_development"
    },
    {
        "displayName": "State Department of Environment",
        "name": "categories/gcid:state_department_of_environment"
    },
    {
        "displayName": "State Department of Tourism",
        "name": "categories/gcid:state_department_of_tourism"
    },
    {
        "displayName": "State Department of Transportation",
        "name": "categories/gcid:state_department_of_transportation"
    },
    {
        "displayName": "State Department Science Technology",
        "name": "categories/gcid:state_department_science_technology"
    },
    {
        "displayName": "State department of social defense",
        "name": "categories/gcid:state_department_social_defense"
    },
    {
        "displayName": "State Dept of Culture",
        "name": "categories/gcid:state_dept_of_culture"
    },
    {
        "displayName": "State Dept of Sports",
        "name": "categories/gcid:state_dept_of_sports"
    },
    {
        "displayName": "Departamento de empleo estatal",
        "name": "categories/gcid:state_employment_department"
    },
    {
        "displayName": "Oficina gubernamental estatal",
        "name": "categories/gcid:state_government_office"
    },
    {
        "displayName": "Tienda de licores del estado",
        "name": "categories/gcid:state_liquor_store"
    },
    {
        "displayName": "Secretaría de Educación",
        "name": "categories/gcid:state_office_of_education"
    },
    {
        "displayName": "State owned farm",
        "name": "categories/gcid:state_owned_farm"
    },
    {
        "displayName": "Parque Estatal",
        "name": "categories/gcid:state_park"
    },
    {
        "displayName": "Parlamento estatal",
        "name": "categories/gcid:state_parliament"
    },
    {
        "displayName": "Policía estatal",
        "name": "categories/gcid:state_police"
    },
    {
        "displayName": "Fabricante de artículos de papelería",
        "name": "categories/gcid:stationery_manufacturer"
    },
    {
        "displayName": "Papelería",
        "name": "categories/gcid:stationery_store"
    },
    {
        "displayName": "Proveedor de papelería",
        "name": "categories/gcid:stationery_wholesaler"
    },
    {
        "displayName": "Fabricante de estatuas",
        "name": "categories/gcid:statuary"
    },
    {
        "displayName": "Clínica de infecciones de transmisión sexual",
        "name": "categories/gcid:std_clinic"
    },
    {
        "displayName": "Servicio de detección de infecciones de transmisión sexual",
        "name": "categories/gcid:std_testing_service"
    },
    {
        "displayName": "Restaurante especializado en filetes",
        "name": "categories/gcid:steak_house"
    },
    {
        "displayName": "Restaurante de barco a vapor",
        "name": "categories/gcid:steamboat_restaurant"
    },
    {
        "displayName": "Tienda de panes cocidos al vapor",
        "name": "categories/gcid:steamed_bun_shop"
    },
    {
        "displayName": "Steel construction company",
        "name": "categories/gcid:steel_construction_company"
    },
    {
        "displayName": "Distribuidor de acero",
        "name": "categories/gcid:steel_distributor"
    },
    {
        "displayName": "Steel drum supplier",
        "name": "categories/gcid:steel_drum_supplier"
    },
    {
        "displayName": "Erector de acero",
        "name": "categories/gcid:steel_erector"
    },
    {
        "displayName": "Fabricante de acero",
        "name": "categories/gcid:steel_fabricator"
    },
    {
        "displayName": "Contratista de estructuras de acero",
        "name": "categories/gcid:steel_framework_contractor"
    },
    {
        "displayName": "Steelwork design company",
        "name": "categories/gcid:steelwork_design_company"
    },
    {
        "displayName": "Tienda de alquiler de estéreos",
        "name": "categories/gcid:stereo_rental_store"
    },
    {
        "displayName": "Reparación de equipos de música",
        "name": "categories/gcid:stereo_repair_service"
    },
    {
        "displayName": "Tienda de audio para el hogar",
        "name": "categories/gcid:stereo_store"
    },
    {
        "displayName": "Fabricante de etiquetas adhesivas",
        "name": "categories/gcid:sticker_manufacturer"
    },
    {
        "displayName": "Clase de costura",
        "name": "categories/gcid:stitching_class"
    },
    {
        "displayName": "Corredor de bolsa",
        "name": "categories/gcid:stock_broker"
    },
    {
        "displayName": "Edificio de la Bolsa de Valores",
        "name": "categories/gcid:stock_exchange_building"
    },
    {
        "displayName": "Talla de piedra",
        "name": "categories/gcid:stone_carving"
    },
    {
        "displayName": "Cortador de piedra",
        "name": "categories/gcid:stone_cutter"
    },
    {
        "displayName": "Proveedor de piedras",
        "name": "categories/gcid:stone_supplier"
    },
    {
        "displayName": "Planta de almacenamiento",
        "name": "categories/gcid:storage_facility"
    },
    {
        "displayName": "Tienda",
        "name": "categories/gcid:store"
    },
    {
        "displayName": "Proveedor de equipos para tiendas",
        "name": "categories/gcid:store_equipment_supplier"
    },
    {
        "displayName": "Stove builder",
        "name": "categories/gcid:stove_builder"
    },
    {
        "displayName": "Lutier",
        "name": "categories/gcid:stringed_intrument_maker"
    },
    {
        "displayName": "Ingeniero estructural",
        "name": "categories/gcid:structural_engineer"
    },
    {
        "displayName": "Contratista de estucos",
        "name": "categories/gcid:stucco_contractor"
    },
    {
        "displayName": "Oficina de consejería académica",
        "name": "categories/gcid:student_career_counseling_office"
    },
    {
        "displayName": "Residencia para estudiantes",
        "name": "categories/gcid:student_dormitory"
    },
    {
        "displayName": "Centro de alojamiento de estudiantes",
        "name": "categories/gcid:student_housing_center"
    },
    {
        "displayName": "Centro de estudiantes",
        "name": "categories/gcid:student_union"
    },
    {
        "displayName": "Asociación de padres de alumnos",
        "name": "categories/gcid:students_parents_association"
    },
    {
        "displayName": "Asociación de asistencia a alumnos",
        "name": "categories/gcid:students_support_association"
    },
    {
        "displayName": "Escuela de educación en el hogar",
        "name": "categories/gcid:study_at_home_school"
    },
    {
        "displayName": "Centro de estudio",
        "name": "categories/gcid:studying_center"
    },
    {
        "displayName": "Estilista",
        "name": "categories/gcid:stylist"
    },
    {
        "displayName": "Concesionario Subaru",
        "name": "categories/gcid:subaru_dealer"
    },
    {
        "displayName": "Suburban train line",
        "name": "categories/gcid:suburban_train_line"
    },
    {
        "displayName": "Central azucarera",
        "name": "categories/gcid:sugar_factory"
    },
    {
        "displayName": "Cabaña de azúcar",
        "name": "categories/gcid:sugar_shack"
    },
    {
        "displayName": "Restaurante especializado en sukiyaki",
        "name": "categories/gcid:sukiyaki_restaurant"
    },
    {
        "displayName": "Campamento de verano",
        "name": "categories/gcid:summer_camp"
    },
    {
        "displayName": "Pista de trineo de verano",
        "name": "categories/gcid:summer_toboggan_run"
    },
    {
        "displayName": "Restaurante de sundae",
        "name": "categories/gcid:sundae_restaurant"
    },
    {
        "displayName": "Restaurante de cocina sundanesa",
        "name": "categories/gcid:sundanese_restaurant"
    },
    {
        "displayName": "Tienda de gafas de sol",
        "name": "categories/gcid:sunglasses_store"
    },
    {
        "displayName": "Contratista de solario",
        "name": "categories/gcid:sunroom_contractor"
    },
    {
        "displayName": "Súper baño público",
        "name": "categories/gcid:super_public_bath"
    },
    {
        "displayName": "Consultor sobre jubilación",
        "name": "categories/gcid:superannuation_consultant"
    },
    {
        "displayName": "Sitio del superfondo",
        "name": "categories/gcid:superfund_site"
    },
    {
        "displayName": "Supermercado",
        "name": "categories/gcid:supermarket"
    },
    {
        "displayName": "Grupo de apoyo",
        "name": "categories/gcid:support_group"
    },
    {
        "displayName": "Club de salvamento acuático deportivo",
        "name": "categories/gcid:surf_lifesaving_club"
    },
    {
        "displayName": "Escuela de surf",
        "name": "categories/gcid:surf_school"
    },
    {
        "displayName": "Tienda de surf",
        "name": "categories/gcid:surf_shop"
    },
    {
        "displayName": "Cirujano",
        "name": "categories/gcid:surgeon"
    },
    {
        "displayName": "Centros quirúrgicos ambulatorios",
        "name": "categories/gcid:surgical_center"
    },
    {
        "displayName": "Cirujano oncólogo",
        "name": "categories/gcid:surgical_oncologist"
    },
    {
        "displayName": "Mayorista de productos quirúrgicos",
        "name": "categories/gcid:surgical_products_wholesaler"
    },
    {
        "displayName": "Tienda de insumos para cirugía",
        "name": "categories/gcid:surgical_supply_store"
    },
    {
        "displayName": "Restaurante de cocina surinamesa",
        "name": "categories/gcid:surinamese_restaurant"
    },
    {
        "displayName": "Tienda de excedentes",
        "name": "categories/gcid:surplus_store"
    },
    {
        "displayName": "Topógrafo",
        "name": "categories/gcid:surveyor"
    },
    {
        "displayName": "Restaurante de sushi",
        "name": "categories/gcid:sushi_restaurant"
    },
    {
        "displayName": "Restaurante de sushi para llevar",
        "name": "categories/gcid:sushi_takeaway"
    },
    {
        "displayName": "Concesionario Suzuki",
        "name": "categories/gcid:suzuki_dealer"
    },
    {
        "displayName": "Restaurante suabo",
        "name": "categories/gcid:swabian_restaurant"
    },
    {
        "displayName": "Restaurante sueco",
        "name": "categories/gcid:swedish_restaurant"
    },
    {
        "displayName": "Club de natación",
        "name": "categories/gcid:swim_club"
    },
    {
        "displayName": "Piscina",
        "name": "categories/gcid:swimming_basin"
    },
    {
        "displayName": "Competencia de natación",
        "name": "categories/gcid:swimming_competition"
    },
    {
        "displayName": "Área habilitada para nadar",
        "name": "categories/gcid:swimming_facility"
    },
    {
        "displayName": "Instructor de natación",
        "name": "categories/gcid:swimming_instructor"
    },
    {
        "displayName": "Lago apto para nadar",
        "name": "categories/gcid:swimming_lake"
    },
    {
        "displayName": "Piscina",
        "name": "categories/gcid:swimming_pool"
    },
    {
        "displayName": "Contratista de piscinas",
        "name": "categories/gcid:swimming_pool_contractor"
    },
    {
        "displayName": "Servicio de reparación de piscinas",
        "name": "categories/gcid:swimming_pool_repair_service"
    },
    {
        "displayName": "Tienda de insumos para piscinas",
        "name": "categories/gcid:swimming_pool_supply_store"
    },
    {
        "displayName": "Escuela de natación",
        "name": "categories/gcid:swimming_school"
    },
    {
        "displayName": "Tienda de trajes de baño",
        "name": "categories/gcid:swimwear_store"
    },
    {
        "displayName": "Restaurante suizo",
        "name": "categories/gcid:swiss_restaurant"
    },
    {
        "displayName": "Sinagoga",
        "name": "categories/gcid:synagogue"
    },
    {
        "displayName": "Restaurante sirio",
        "name": "categories/gcid:syrian_restaurant"
    },
    {
        "displayName": "Tienda de camisetas",
        "name": "categories/gcid:t_shirt_store"
    },
    {
        "displayName": "Restaurante tabasqueño",
        "name": "categories/gcid:tabascan_restaurant"
    },
    {
        "displayName": "Club de tenis de mesa",
        "name": "categories/gcid:table_tennis_club"
    },
    {
        "displayName": "Instalaciones de tenis de mesa",
        "name": "categories/gcid:table_tennis_facility"
    },
    {
        "displayName": "Tienda de insumos para tenis de mesa",
        "name": "categories/gcid:table_tennis_supply_store"
    },
    {
        "displayName": "Restaurante de tacacá",
        "name": "categories/gcid:tacaca_restaurant"
    },
    {
        "displayName": "Tienda de arreos",
        "name": "categories/gcid:tack_shop"
    },
    {
        "displayName": "Restaurante de tacos",
        "name": "categories/gcid:taco_restaurant"
    },
    {
        "displayName": "Área de competición de Tae Kwon Do",
        "name": "categories/gcid:tae_kwon_do_comp_area"
    },
    {
        "displayName": "Escuela de taekwondo",
        "name": "categories/gcid:taekwondo_school"
    },
    {
        "displayName": "Escuela de tai chi",
        "name": "categories/gcid:tai_chi_school"
    },
    {
        "displayName": "Sastre",
        "name": "categories/gcid:tailor"
    },
    {
        "displayName": "Restaurante taiwanés",
        "name": "categories/gcid:taiwanese_restaurant"
    },
    {
        "displayName": "Restaurante especializado en takoyaki",
        "name": "categories/gcid:takoyaki_stand"
    },
    {
        "displayName": "Agencia de talentos",
        "name": "categories/gcid:talent_agency"
    },
    {
        "displayName": "Tienda de tamales",
        "name": "categories/gcid:tamale_shop"
    },
    {
        "displayName": "Curtiembre",
        "name": "categories/gcid:tannery"
    },
    {
        "displayName": "Salón de bronceado",
        "name": "categories/gcid:tanning_studio"
    },
    {
        "displayName": "Templo taoísta",
        "name": "categories/gcid:taoist_temple"
    },
    {
        "displayName": "Bar de tapas",
        "name": "categories/gcid:tapas_bar"
    },
    {
        "displayName": "Restaurante de tapas",
        "name": "categories/gcid:tapas_restaurant"
    },
    {
        "displayName": "Concesionario de Tata Motors",
        "name": "categories/gcid:tata_motors_dealer"
    },
    {
        "displayName": "Tienda de tatami",
        "name": "categories/gcid:tatami_store"
    },
    {
        "displayName": "Tienda de tatuajes y piercing",
        "name": "categories/gcid:tattoo_and_piercing_shop"
    },
    {
        "displayName": "Tatuador",
        "name": "categories/gcid:tattoo_artist"
    },
    {
        "displayName": "Servicio de eliminación de tatuajes",
        "name": "categories/gcid:tattoo_removal_service"
    },
    {
        "displayName": "Tienda de tatuajes",
        "name": "categories/gcid:tattoo_shop"
    },
    {
        "displayName": "Asesor de impuestos",
        "name": "categories/gcid:tax_assessor"
    },
    {
        "displayName": "Abogado especializado en derecho fiscal",
        "name": "categories/gcid:tax_attorney"
    },
    {
        "displayName": "Oficina del recaudador fiscal",
        "name": "categories/gcid:tax_collectors_office"
    },
    {
        "displayName": "Asesor fiscal",
        "name": "categories/gcid:tax_consultant"
    },
    {
        "displayName": "Agencia tributaria",
        "name": "categories/gcid:tax_department"
    },
    {
        "displayName": "Gestoría",
        "name": "categories/gcid:tax_preparation"
    },
    {
        "displayName": "Servicio de declaración de impuestos",
        "name": "categories/gcid:tax_preparation_service"
    },
    {
        "displayName": "Servicio de taxis",
        "name": "categories/gcid:taxi_service"
    },
    {
        "displayName": "Parada de taxi",
        "name": "categories/gcid:taxi_stand"
    },
    {
        "displayName": "Taxidermista",
        "name": "categories/gcid:taxidermist"
    },
    {
        "displayName": "Clínica para tratar la tuberculosis",
        "name": "categories/gcid:tb_clinic"
    },
    {
        "displayName": "Exportador de té",
        "name": "categories/gcid:tea_exporter"
    },
    {
        "displayName": "Salón de té",
        "name": "categories/gcid:tea_house"
    },
    {
        "displayName": "Fabricante de té",
        "name": "categories/gcid:tea_manufacturer"
    },
    {
        "displayName": "Mercado de té",
        "name": "categories/gcid:tea_market_place"
    },
    {
        "displayName": "Tienda de té",
        "name": "categories/gcid:tea_store"
    },
    {
        "displayName": "Distribuidor mayorista de té",
        "name": "categories/gcid:tea_wholesaler"
    },
    {
        "displayName": "Facultad de Educación",
        "name": "categories/gcid:teacher_college"
    },
    {
        "displayName": "Vivienda para docentes",
        "name": "categories/gcid:teachers_housing"
    },
    {
        "displayName": "Escuela técnica",
        "name": "categories/gcid:technical_school"
    },
    {
        "displayName": "Universidad técnica",
        "name": "categories/gcid:technical_university"
    },
    {
        "displayName": "Museo de tecnología",
        "name": "categories/gcid:technology_museum"
    },
    {
        "displayName": "Parque tecnológico",
        "name": "categories/gcid:technology_park"
    },
    {
        "displayName": "Servicio de blanqueamiento dental",
        "name": "categories/gcid:teeth_whitening_service"
    },
    {
        "displayName": "Restaurante de cocina de Tegal",
        "name": "categories/gcid:tegal_restaurant"
    },
    {
        "displayName": "Escuela de telecomunicaciones",
        "name": "categories/gcid:telecommunication_school"
    },
    {
        "displayName": "Contratista de telecomunicaciones",
        "name": "categories/gcid:telecommunications_contractor"
    },
    {
        "displayName": "Ingeniero de telecomunicaciones",
        "name": "categories/gcid:telecommunications_engineer"
    },
    {
        "displayName": "Proveedor de equipos de telecomunicaciones",
        "name": "categories/gcid:telecommunications_equipment_supplier"
    },
    {
        "displayName": "Proveedor de servicios de telecomunicaciones",
        "name": "categories/gcid:telecommunications_service_provider"
    },
    {
        "displayName": "Servicio de telemarketing",
        "name": "categories/gcid:telemarketing_service"
    },
    {
        "displayName": "Servicio de contestadora",
        "name": "categories/gcid:telephone_answering_service"
    },
    {
        "displayName": "Compañía telefónica",
        "name": "categories/gcid:telephone_company"
    },
    {
        "displayName": "Central telefónica",
        "name": "categories/gcid:telephone_exchange"
    },
    {
        "displayName": "Tienda de telescopios",
        "name": "categories/gcid:telescope_store"
    },
    {
        "displayName": "Servicio de reparación de televisores",
        "name": "categories/gcid:television_repair_service"
    },
    {
        "displayName": "Canal de televisión",
        "name": "categories/gcid:television_station"
    },
    {
        "displayName": "Restaurante de temaki",
        "name": "categories/gcid:temaki_restaurant"
    },
    {
        "displayName": "Agencia de empleo temporal",
        "name": "categories/gcid:temp_agency"
    },
    {
        "displayName": "Restaurante especializado en tempura donburi",
        "name": "categories/gcid:tempura_bowl_restaurants"
    },
    {
        "displayName": "Restaurante de tempura",
        "name": "categories/gcid:tempura_dish_restaurant"
    },
    {
        "displayName": "Inquilino-propietario",
        "name": "categories/gcid:tenant_ownership"
    },
    {
        "displayName": "Asociación de inquilinos",
        "name": "categories/gcid:tenants_union"
    },
    {
        "displayName": "Club de tenis",
        "name": "categories/gcid:tennis_club"
    },
    {
        "displayName": "Cancha de tenis",
        "name": "categories/gcid:tennis_court"
    },
    {
        "displayName": "Compañía de construcción de canchas de tenis",
        "name": "categories/gcid:tennis_court_construction_company"
    },
    {
        "displayName": "Instructor de tenis",
        "name": "categories/gcid:tennis_instructor"
    },
    {
        "displayName": "Tienda de insumos para tenis",
        "name": "categories/gcid:tennis_store"
    },
    {
        "displayName": "Servicio de alquiler de tiendas",
        "name": "categories/gcid:tent_rental_service"
    },
    {
        "displayName": "Restaurante de teppanyaki",
        "name": "categories/gcid:teppan_grill_restaurant"
    },
    {
        "displayName": "Tienda Tesla",
        "name": "categories/gcid:tesla_showroom"
    },
    {
        "displayName": "Restaurante de cocina tex-mex",
        "name": "categories/gcid:tex_mex_restaurant"
    },
    {
        "displayName": "Ingeniero textil",
        "name": "categories/gcid:textile_engineer"
    },
    {
        "displayName": "Exportador de textiles",
        "name": "categories/gcid:textile_exporter"
    },
    {
        "displayName": "Fábrica textil",
        "name": "categories/gcid:textile_mill"
    },
    {
        "displayName": "Terapeuta de masajes thai",
        "name": "categories/gcid:thai_massage_therapist"
    },
    {
        "displayName": "Restaurante tailandés",
        "name": "categories/gcid:thai_restaurant"
    },
    {
        "displayName": "Compañía teatral",
        "name": "categories/gcid:theater_company"
    },
    {
        "displayName": "Productora de teatro",
        "name": "categories/gcid:theater_production"
    },
    {
        "displayName": "Tienda de artículos para teatro",
        "name": "categories/gcid:theater_supply_store"
    },
    {
        "displayName": "Proveedor de vestuario teatral",
        "name": "categories/gcid:theatrical_costume_supplier"
    },
    {
        "displayName": "Parque temático",
        "name": "categories/gcid:theme_park"
    },
    {
        "displayName": "Piscinas termales",
        "name": "categories/gcid:thermal_baths"
    },
    {
        "displayName": "Empresa de energía térmica",
        "name": "categories/gcid:thermal_energy_company"
    },
    {
        "displayName": "Proveedor de hilos",
        "name": "categories/gcid:thread_supplier"
    },
    {
        "displayName": "Mayorista de hilos y ovillos",
        "name": "categories/gcid:threads_and_yarns_wholesaler"
    },
    {
        "displayName": "Servicio de impresión 3D",
        "name": "categories/gcid:three_d_printing_service"
    },
    {
        "displayName": "Tienda de descuentos",
        "name": "categories/gcid:thrift_store"
    },
    {
        "displayName": "Restaurante turingio",
        "name": "categories/gcid:thuringian_restaurant"
    },
    {
        "displayName": "Restaurante tibetano",
        "name": "categories/gcid:tibetan_restaurant"
    },
    {
        "displayName": "Restaurante de tiffin",
        "name": "categories/gcid:tiffin_center"
    },
    {
        "displayName": "Bar tiki",
        "name": "categories/gcid:tiki_bar"
    },
    {
        "displayName": "Servicio de limpieza de baldosas",
        "name": "categories/gcid:tile_cleaning_service"
    },
    {
        "displayName": "Contratista de azulejos y mosaicos",
        "name": "categories/gcid:tile_contractor"
    },
    {
        "displayName": "Fabricante de azulejos",
        "name": "categories/gcid:tile_manufacturer"
    },
    {
        "displayName": "Tienda de azulejos y mosaicos",
        "name": "categories/gcid:tile_store"
    },
    {
        "displayName": "Agencia de tiempo compartido",
        "name": "categories/gcid:timeshare_agency"
    },
    {
        "displayName": "Fabricante de neumáticos",
        "name": "categories/gcid:tire_manufacturer"
    },
    {
        "displayName": "Llantera",
        "name": "categories/gcid:tire_repair_shop"
    },
    {
        "displayName": "Tienda de neumáticos",
        "name": "categories/gcid:tire_shop"
    },
    {
        "displayName": "Compañía de títulos de propiedad",
        "name": "categories/gcid:title_company"
    },
    {
        "displayName": "Restaurante de pan tostado",
        "name": "categories/gcid:toast_restaurant"
    },
    {
        "displayName": "Tobacco exporter",
        "name": "categories/gcid:tobacco_exporter"
    },
    {
        "displayName": "Tabaquería",
        "name": "categories/gcid:tobacco_shop"
    },
    {
        "displayName": "Proveedor de tabaco",
        "name": "categories/gcid:tobacco_supplier"
    },
    {
        "displayName": "Restaurante especializado en tofu",
        "name": "categories/gcid:tofu_restaurant"
    },
    {
        "displayName": "Tienda de tofu",
        "name": "categories/gcid:tofu_shop"
    },
    {
        "displayName": "Tienda de artículos para cuidado personal",
        "name": "categories/gcid:toiletries_store"
    },
    {
        "displayName": "Área de descanso en ruta con peaje",
        "name": "categories/gcid:toll_road_rest_stop"
    },
    {
        "displayName": "Caseta de peaje",
        "name": "categories/gcid:toll_station"
    },
    {
        "displayName": "Proveedor de cartuchos de tóner",
        "name": "categories/gcid:toner_cartridge_supplier"
    },
    {
        "displayName": "Restaurante de cocina japonesa (especializado en lengua)",
        "name": "categories/gcid:tongue_restaurant"
    },
    {
        "displayName": "Restaurante de tonkatsu",
        "name": "categories/gcid:tonkatsu_restaurant"
    },
    {
        "displayName": "Tienda de herramientas y troqueles",
        "name": "categories/gcid:tool_and_die_shop"
    },
    {
        "displayName": "Tool grinding service",
        "name": "categories/gcid:tool_grinding_service"
    },
    {
        "displayName": "Fabricante de herramientas",
        "name": "categories/gcid:tool_manufacturer"
    },
    {
        "displayName": "Servicio de alquiler de herramientas",
        "name": "categories/gcid:tool_rental_service"
    },
    {
        "displayName": "Taller de reparación de herramientas",
        "name": "categories/gcid:tool_repair_shop"
    },
    {
        "displayName": "Ferretería",
        "name": "categories/gcid:tool_store"
    },
    {
        "displayName": "Mayorista de herramientas",
        "name": "categories/gcid:tool_wholesaler"
    },
    {
        "displayName": "Empresa de herramientas",
        "name": "categories/gcid:toolroom"
    },
    {
        "displayName": "Empresa de topografía",
        "name": "categories/gcid:topography_company"
    },
    {
        "displayName": "Proveedor de capa superficial del suelo",
        "name": "categories/gcid:topsoil_supplier"
    },
    {
        "displayName": "Tortillería",
        "name": "categories/gcid:tortilla_shop"
    },
    {
        "displayName": "Excursiones",
        "name": "categories/gcid:tour_agency"
    },
    {
        "displayName": "Operador de excursiones",
        "name": "categories/gcid:tour_operator"
    },
    {
        "displayName": "Atracción turística",
        "name": "categories/gcid:tourist_attraction"
    },
    {
        "displayName": "Centro de información turística",
        "name": "categories/gcid:tourist_information_center"
    },
    {
        "displayName": "Torre de telecomunicaciones",
        "name": "categories/gcid:tower_communication_service"
    },
    {
        "displayName": "Proveedor de equipos de remolque",
        "name": "categories/gcid:towing_equipment_provider"
    },
    {
        "displayName": "Servicio de remolque terrestre",
        "name": "categories/gcid:towing_service"
    },
    {
        "displayName": "Complejo de viviendas en serie",
        "name": "categories/gcid:townhouse_complex"
    },
    {
        "displayName": "Toy and game manufacturer",
        "name": "categories/gcid:toy_and_game_manufacturer"
    },
    {
        "displayName": "Biblioteca de juguetes",
        "name": "categories/gcid:toy_library"
    },
    {
        "displayName": "Fábrica de juguetes",
        "name": "categories/gcid:toy_manufacturer"
    },
    {
        "displayName": "Museo de juguetes",
        "name": "categories/gcid:toy_museum"
    },
    {
        "displayName": "Juguetería",
        "name": "categories/gcid:toy_store"
    },
    {
        "displayName": "Concesionario Toyota",
        "name": "categories/gcid:toyota_dealer"
    },
    {
        "displayName": "Concesionario de tractores",
        "name": "categories/gcid:tractor_dealer"
    },
    {
        "displayName": "Taller de reparación de tractores",
        "name": "categories/gcid:tractor_repair_shop"
    },
    {
        "displayName": "Empresa de construcción de ferias comerciales",
        "name": "categories/gcid:trade_fair_construction_company"
    },
    {
        "displayName": "Facultad de Comercio",
        "name": "categories/gcid:trade_school"
    },
    {
        "displayName": "Tienda de intercambio de cartas coleccionables",
        "name": "categories/gcid:trading_card_store"
    },
    {
        "displayName": "Club de trajes tradicionales",
        "name": "categories/gcid:traditional_costume_club"
    },
    {
        "displayName": "Tienda de trajes típicos",
        "name": "categories/gcid:traditional_kostume_store"
    },
    {
        "displayName": "Mercado tradicional",
        "name": "categories/gcid:traditional_market"
    },
    {
        "displayName": "Mesón",
        "name": "categories/gcid:traditional_restaurant"
    },
    {
        "displayName": "Salón de té tradicional",
        "name": "categories/gcid:traditional_teahouse"
    },
    {
        "displayName": "Restaurante de cocina americana tradicional",
        "name": "categories/gcid:traditional_us_american_restaurant"
    },
    {
        "displayName": "Agente de tráfico",
        "name": "categories/gcid:traffic_officer"
    },
    {
        "displayName": "Concesionario de remolques terrestres",
        "name": "categories/gcid:trailer_dealer"
    },
    {
        "displayName": "Fabricante de trailers",
        "name": "categories/gcid:trailer_manufacturer"
    },
    {
        "displayName": "Servicio de alquiler de remolques",
        "name": "categories/gcid:trailer_rental_service"
    },
    {
        "displayName": "Taller de reparación de remolques",
        "name": "categories/gcid:trailer_repair_shop"
    },
    {
        "displayName": "Tienda de insumos para remolques",
        "name": "categories/gcid:trailer_supply_store"
    },
    {
        "displayName": "Depósito de trenes",
        "name": "categories/gcid:train_depot"
    },
    {
        "displayName": "Centro de reparación de trenes",
        "name": "categories/gcid:train_repairing_center"
    },
    {
        "displayName": "Agencia de venta de boletos de tren",
        "name": "categories/gcid:train_ticket_agency"
    },
    {
        "displayName": "Boletería de pasajes de tren",
        "name": "categories/gcid:train_ticket_counter"
    },
    {
        "displayName": "Parque ferroviario",
        "name": "categories/gcid:train_yard"
    },
    {
        "displayName": "Centro de formación",
        "name": "categories/gcid:training_center"
    },
    {
        "displayName": "Servicio de transcripción",
        "name": "categories/gcid:transcription_service"
    },
    {
        "displayName": "Almacén de tránsito",
        "name": "categories/gcid:transit_depot"
    },
    {
        "displayName": "Traductor",
        "name": "categories/gcid:translator"
    },
    {
        "displayName": "Taller de transmisiones",
        "name": "categories/gcid:transmission_shop"
    },
    {
        "displayName": "Cirujano de trasplante",
        "name": "categories/gcid:transplant_surgeon"
    },
    {
        "displayName": "Servicio de transporte con acompañante",
        "name": "categories/gcid:transportation_escort_service"
    },
    {
        "displayName": "Servicio de transporte",
        "name": "categories/gcid:transportation_service"
    },
    {
        "displayName": "Agencia de viajes",
        "name": "categories/gcid:travel_agency"
    },
    {
        "displayName": "Clínica para viajeros",
        "name": "categories/gcid:travel_clinic"
    },
    {
        "displayName": "Sala de espera VIP",
        "name": "categories/gcid:travel_lounge"
    },
    {
        "displayName": "Granja de árboles",
        "name": "categories/gcid:tree_farm"
    },
    {
        "displayName": "Servicio de árboles",
        "name": "categories/gcid:tree_service"
    },
    {
        "displayName": "Abogado especializado en derecho procesal",
        "name": "categories/gcid:trial_attorney"
    },
    {
        "displayName": "Centro de operaciones tribal",
        "name": "categories/gcid:tribal_headquarters"
    },
    {
        "displayName": "Tienda de trofeos",
        "name": "categories/gcid:trophy_shop"
    },
    {
        "displayName": "Tienda de peces tropicales",
        "name": "categories/gcid:tropical_fish_store"
    },
    {
        "displayName": "Tienda de accesorios para camiones",
        "name": "categories/gcid:truck_accessories_store"
    },
    {
        "displayName": "Concesionario de camiones",
        "name": "categories/gcid:truck_dealer"
    },
    {
        "displayName": "Truck farmer",
        "name": "categories/gcid:truck_farmer"
    },
    {
        "displayName": "Proveedor de repuestos para camiones",
        "name": "categories/gcid:truck_parts_supplier"
    },
    {
        "displayName": "Agencia de alquiler de camiones",
        "name": "categories/gcid:truck_rental_agency"
    },
    {
        "displayName": "Servicio de reparación de camiones",
        "name": "categories/gcid:truck_repair_shop"
    },
    {
        "displayName": "Paradero de camiones",
        "name": "categories/gcid:truck_stop"
    },
    {
        "displayName": "Proveedor de cúpulas para camionetas",
        "name": "categories/gcid:truck_topper_supplier"
    },
    {
        "displayName": "Compañía de transporte de carga",
        "name": "categories/gcid:trucking_company"
    },
    {
        "displayName": "Escuela de conducción de camiones",
        "name": "categories/gcid:trucking_school"
    },
    {
        "displayName": "Fabricante de vigas",
        "name": "categories/gcid:truss_manufacturer"
    },
    {
        "displayName": "Banco de depósitos",
        "name": "categories/gcid:trust_bank"
    },
    {
        "displayName": "Estacionamiento Tsukigime",
        "name": "categories/gcid:tsukigime_parking_lot"
    },
    {
        "displayName": "Proveedor de accesorios para afinación automotriz",
        "name": "categories/gcid:tune_up_supplier"
    },
    {
        "displayName": "Tuning automobile",
        "name": "categories/gcid:tuning_automobile"
    },
    {
        "displayName": "Restaurante tunecino",
        "name": "categories/gcid:tunisian_restaurant"
    },
    {
        "displayName": "Proveedor de tepe",
        "name": "categories/gcid:turf_supplier"
    },
    {
        "displayName": "Restaurante turco",
        "name": "categories/gcid:turkish_restaurant"
    },
    {
        "displayName": "Restaurante turcomano",
        "name": "categories/gcid:turkmen_restaurant"
    },
    {
        "displayName": "Tornería",
        "name": "categories/gcid:turnery"
    },
    {
        "displayName": "Restaurante toscano",
        "name": "categories/gcid:tuscan_restaurant"
    },
    {
        "displayName": "Servicio de clases de apoyo",
        "name": "categories/gcid:tutoring_service"
    },
    {
        "displayName": "Tienda de trajes formales",
        "name": "categories/gcid:tuxedo_shop"
    },
    {
        "displayName": "Servicio de reparación de máquinas de escribir",
        "name": "categories/gcid:typewriter_repair_service"
    },
    {
        "displayName": "Proveedor de máquinas de escribir",
        "name": "categories/gcid:typewriter_supplier"
    },
    {
        "displayName": "Servicio de mecanografía",
        "name": "categories/gcid:typing_service"
    },
    {
        "displayName": "Restaurante especializado en fideos udon",
        "name": "categories/gcid:udon_noodle_shop"
    },
    {
        "displayName": "Restaurante ucraniano",
        "name": "categories/gcid:ukrainian_restaurant"
    },
    {
        "displayName": "Restaurante de unagi",
        "name": "categories/gcid:unagi_restaurant"
    },
    {
        "displayName": "Tienda de ropa interior",
        "name": "categories/gcid:underwear_store"
    },
    {
        "displayName": "Oficina de desempleo",
        "name": "categories/gcid:unemployment_office"
    },
    {
        "displayName": "Tienda de muebles en crudo",
        "name": "categories/gcid:unfinished_furniture_store"
    },
    {
        "displayName": "Tienda de uniformes",
        "name": "categories/gcid:uniform_store"
    },
    {
        "displayName": "Peluquería",
        "name": "categories/gcid:unisex_hairdresser"
    },
    {
        "displayName": "Iglesia Unitaria Universalista",
        "name": "categories/gcid:unitarian_universalist_church"
    },
    {
        "displayName": "Iglesia Unida de Canadá",
        "name": "categories/gcid:united_church_of_canada"
    },
    {
        "displayName": "Iglesia Unida de Cristo",
        "name": "categories/gcid:united_church_of_christ"
    },
    {
        "displayName": "Iglesia Metodista Unida",
        "name": "categories/gcid:united_methodist_church"
    },
    {
        "displayName": "Base de las Fuerzas Armadas de los Estados\\xa0Unidos",
        "name": "categories/gcid:united_states_armed_forces_base"
    },
    {
        "displayName": "Iglesia Unida",
        "name": "categories/gcid:unity_church"
    },
    {
        "displayName": "Universidad",
        "name": "categories/gcid:university"
    },
    {
        "displayName": "Departamento académico",
        "name": "categories/gcid:university_department"
    },
    {
        "displayName": "Hospital universitario",
        "name": "categories/gcid:university_hospital"
    },
    {
        "displayName": "Biblioteca universitaria",
        "name": "categories/gcid:university_library"
    },
    {
        "displayName": "Servicio de limpieza de tapicería",
        "name": "categories/gcid:upholstery_cleaning_service"
    },
    {
        "displayName": "Tapicería",
        "name": "categories/gcid:upholstery_shop"
    },
    {
        "displayName": "Departamento de planificación urbana",
        "name": "categories/gcid:urban_planning_department"
    },
    {
        "displayName": "Centro de urgencias",
        "name": "categories/gcid:urgent_care_center"
    },
    {
        "displayName": "Urólogo",
        "name": "categories/gcid:urologist"
    },
    {
        "displayName": "Clínica urológica",
        "name": "categories/gcid:urology_clinic"
    },
    {
        "displayName": "Restaurante uruguayo",
        "name": "categories/gcid:uruguayan_restaurant"
    },
    {
        "displayName": "Restaurante de cocina del Noroeste del Pacífico (Estados Unidos)",
        "name": "categories/gcid:us_pacific_northwest_restaurant"
    },
    {
        "displayName": "Tienda de electrodomésticos usados",
        "name": "categories/gcid:used_appliance_store"
    },
    {
        "displayName": "Tienda de autopartes usadas",
        "name": "categories/gcid:used_auto_parts_store"
    },
    {
        "displayName": "Tienda de bicicletas usadas",
        "name": "categories/gcid:used_bicycle_shop"
    },
    {
        "displayName": "Tienda de libros usados",
        "name": "categories/gcid:used_book_store"
    },
    {
        "displayName": "Concesionario de automóviles usados",
        "name": "categories/gcid:used_car_dealer"
    },
    {
        "displayName": "Tienda de CD usados",
        "name": "categories/gcid:used_cd_store"
    },
    {
        "displayName": "Tienda de ropa usada",
        "name": "categories/gcid:used_clothing_store"
    },
    {
        "displayName": "Tienda de computadoras usadas",
        "name": "categories/gcid:used_computer_store"
    },
    {
        "displayName": "Tienda de mobiliario usado",
        "name": "categories/gcid:used_furniture_store"
    },
    {
        "displayName": "Tienda de juegos usados",
        "name": "categories/gcid:used_game_store"
    },
    {
        "displayName": "Concesionario de motocicletas usadas",
        "name": "categories/gcid:used_motorcycle_dealer"
    },
    {
        "displayName": "Tienda de instrumentos musicales usados",
        "name": "categories/gcid:used_musical_instrument_store"
    },
    {
        "displayName": "Tienda de mobiliario de oficina usado",
        "name": "categories/gcid:used_office_furniture_store"
    },
    {
        "displayName": "Used store fixture supplier",
        "name": "categories/gcid:used_store_fixture_supplier"
    },
    {
        "displayName": "Tienda de neumáticos usados",
        "name": "categories/gcid:used_tire_shop"
    },
    {
        "displayName": "Concesionario de camiones usados",
        "name": "categories/gcid:used_truck_dealer"
    },
    {
        "displayName": "Contratista de utilitarios",
        "name": "categories/gcid:utility_contractor"
    },
    {
        "displayName": "Concesionario de remolques utilitarios",
        "name": "categories/gcid:utility_trailer_dealer"
    },
    {
        "displayName": "Restaurante uigur",
        "name": "categories/gcid:uyghur_cuisine_restaurant"
    },
    {
        "displayName": "Restaurante de cocina de Uzbekistán",
        "name": "categories/gcid:uzbek_restaurant"
    },
    {
        "displayName": "Apartamento de vacaciones",
        "name": "categories/gcid:vacation_appartment"
    },
    {
        "displayName": "Agencia de alquiler de casas para vacaciones",
        "name": "categories/gcid:vacation_home_rental_agency"
    },
    {
        "displayName": "Taller de reparación de aspiradoras",
        "name": "categories/gcid:vacuum_cleaner_repair_shop"
    },
    {
        "displayName": "Tienda de aspiradoras",
        "name": "categories/gcid:vacuum_cleaner_store"
    },
    {
        "displayName": "Proveedor de sistemas de limpieza por aspiración",
        "name": "categories/gcid:vacuum_cleaning_system_supplier"
    },
    {
        "displayName": "Restaurante valenciano",
        "name": "categories/gcid:valencian_restaurant"
    },
    {
        "displayName": "Servicio de estacionamiento valet",
        "name": "categories/gcid:valet_parking_service"
    },
    {
        "displayName": "Agencia de alquiler de camionetas",
        "name": "categories/gcid:van_rental_agency"
    },
    {
        "displayName": "Tienda de vaporizadores",
        "name": "categories/gcid:vaporizer_store"
    },
    {
        "displayName": "Tienda de saldos",
        "name": "categories/gcid:variety_store"
    },
    {
        "displayName": "Cirujano vascular",
        "name": "categories/gcid:vascular_surgeon"
    },
    {
        "displayName": "Consultor especializado en vastu",
        "name": "categories/gcid:vastu_consultant"
    },
    {
        "displayName": "Servicio de reparación de VCR",
        "name": "categories/gcid:vcr_repair_service"
    },
    {
        "displayName": "Restaurante vegano",
        "name": "categories/gcid:vegan_restaurant"
    },
    {
        "displayName": "Mercado mayorista de vegetales",
        "name": "categories/gcid:vegetable_wholesale_market"
    },
    {
        "displayName": "Mayorista de vegetales",
        "name": "categories/gcid:vegetable_wholesaler"
    },
    {
        "displayName": "Vegetarian cafe and deli",
        "name": "categories/gcid:vegetarian_cafe_and_deli"
    },
    {
        "displayName": "Restaurante vegetariano",
        "name": "categories/gcid:vegetarian_restaurant"
    },
    {
        "displayName": "Exportador de vehículos",
        "name": "categories/gcid:vehicle_exporter"
    },
    {
        "displayName": "Servicio de inspección de vehículos",
        "name": "categories/gcid:vehicle_inspection"
    },
    {
        "displayName": "Agencia de transporte de vehículos",
        "name": "categories/gcid:vehicle_shipping_agent"
    },
    {
        "displayName": "Servicio de rotulación de vehículos",
        "name": "categories/gcid:vehicle_wrapping_service"
    },
    {
        "displayName": "Velódromo",
        "name": "categories/gcid:velodrome"
    },
    {
        "displayName": "Proveedor de máquinas expendedoras",
        "name": "categories/gcid:vending_machine_supplier"
    },
    {
        "displayName": "Venereólogo",
        "name": "categories/gcid:venereologist"
    },
    {
        "displayName": "Restaurante veneciano",
        "name": "categories/gcid:venetian_restaurant"
    },
    {
        "displayName": "Restaurante venezolano",
        "name": "categories/gcid:venezuelan_restaurant"
    },
    {
        "displayName": "Fabricante de equipos de ventilación",
        "name": "categories/gcid:ventilating_equipment_manufacturer"
    },
    {
        "displayName": "Compañía de capital de riesgo",
        "name": "categories/gcid:venture_capital_company"
    },
    {
        "displayName": "Departamento de Asuntos de Veteranos",
        "name": "categories/gcid:veterans_affairs_department"
    },
    {
        "displayName": "Centro de veteranos",
        "name": "categories/gcid:veterans_center"
    },
    {
        "displayName": "Hospital para veteranos",
        "name": "categories/gcid:veterans_hospital"
    },
    {
        "displayName": "Organización de veteranos",
        "name": "categories/gcid:veterans_organization"
    },
    {
        "displayName": "Veterinario",
        "name": "categories/gcid:veterinarian"
    },
    {
        "displayName": "Farmacia veterinaria",
        "name": "categories/gcid:veterinary_pharmacy"
    },
    {
        "displayName": "Sala recreativa de videojuegos",
        "name": "categories/gcid:video_arcade"
    },
    {
        "displayName": "Servicio de reparación de videocámaras",
        "name": "categories/gcid:video_camera_repair_service"
    },
    {
        "displayName": "Proveedor de equipos para videollamada",
        "name": "categories/gcid:video_conferencing_equipment_supplier"
    },
    {
        "displayName": "Servicio de videoconferencia",
        "name": "categories/gcid:video_conferencing_service"
    },
    {
        "displayName": "Servicio de duplicación de video",
        "name": "categories/gcid:video_duplication_service"
    },
    {
        "displayName": "Servicio de edición de video",
        "name": "categories/gcid:video_editing_service"
    },
    {
        "displayName": "Servicio de reparación de equipos de video",
        "name": "categories/gcid:video_equipment_repair_service"
    },
    {
        "displayName": "Puesto de alquiler de videojuegos",
        "name": "categories/gcid:video_game_rental_kiosk"
    },
    {
        "displayName": "Video game rental service",
        "name": "categories/gcid:video_game_rental_service"
    },
    {
        "displayName": "Tienda de alquiler de videojuegos",
        "name": "categories/gcid:video_game_rental_store"
    },
    {
        "displayName": "Tienda de videojuegos",
        "name": "categories/gcid:video_game_store"
    },
    {
        "displayName": "Karaoke con video",
        "name": "categories/gcid:video_karaoke"
    },
    {
        "displayName": "Servicio de producción de videos",
        "name": "categories/gcid:video_production_service"
    },
    {
        "displayName": "Tienda de videos",
        "name": "categories/gcid:video_store"
    },
    {
        "displayName": "Restaurante vietnamita",
        "name": "categories/gcid:vietnamese_restaurant"
    },
    {
        "displayName": "Villa",
        "name": "categories/gcid:villa"
    },
    {
        "displayName": "Ayuntamiento",
        "name": "categories/gcid:village_hall"
    },
    {
        "displayName": "Viñedo",
        "name": "categories/gcid:vineyard"
    },
    {
        "displayName": "Iglesia de la Viña",
        "name": "categories/gcid:vineyard_church"
    },
    {
        "displayName": "Tienda de vestimenta clásica",
        "name": "categories/gcid:vintage_clothing_store"
    },
    {
        "displayName": "Tienda de letreros de vinilo",
        "name": "categories/gcid:vinyl_sign_shop"
    },
    {
        "displayName": "Tienda de violines",
        "name": "categories/gcid:violin_shop"
    },
    {
        "displayName": "Alquiler de oficina virtual",
        "name": "categories/gcid:virtual_office_rental"
    },
    {
        "displayName": "Oficina de visas y pasaportes",
        "name": "categories/gcid:visa_and_passport_office"
    },
    {
        "displayName": "Consultor de visados",
        "name": "categories/gcid:visa_consultant"
    },
    {
        "displayName": "Centro de visitantes",
        "name": "categories/gcid:visitor_center"
    },
    {
        "displayName": "Tienda de vitaminas y suplementos",
        "name": "categories/gcid:vitamin_and_supplements_store"
    },
    {
        "displayName": "Entrenador vocal",
        "name": "categories/gcid:vocal_instructor"
    },
    {
        "displayName": "Escuela de formación profesional especializada",
        "name": "categories/gcid:vocational_gymnasium_school"
    },
    {
        "displayName": "Escuela secundaria de formación profesional",
        "name": "categories/gcid:vocational_secondary_school"
    },
    {
        "displayName": "Escuela vocacional",
        "name": "categories/gcid:vocational_training_school"
    },
    {
        "displayName": "Concesionario Volkswagen",
        "name": "categories/gcid:volkswagen_dealer"
    },
    {
        "displayName": "Club de voleibol",
        "name": "categories/gcid:volleyball_club"
    },
    {
        "displayName": "Cancha de voleibol",
        "name": "categories/gcid:volleyball_court"
    },
    {
        "displayName": "Instructor de voleibol",
        "name": "categories/gcid:volleyball_instructor"
    },
    {
        "displayName": "Organización de voluntarios",
        "name": "categories/gcid:volunteer_organization"
    },
    {
        "displayName": "Concesionario Volvo",
        "name": "categories/gcid:volvo_dealer"
    },
    {
        "displayName": "Oficina de Empadronamiento de Votantes",
        "name": "categories/gcid:voter_registration_office"
    },
    {
        "displayName": "Jardín de infantes Waldorf",
        "name": "categories/gcid:waldorf_kindergarten"
    },
    {
        "displayName": "Escuela Waldorf",
        "name": "categories/gcid:waldorf_school"
    },
    {
        "displayName": "Clínica de atención sin turno previo",
        "name": "categories/gcid:walk_in_clinic"
    },
    {
        "displayName": "Wallpaper installer",
        "name": "categories/gcid:wallpaper_installer"
    },
    {
        "displayName": "Tienda de papel tapiz",
        "name": "categories/gcid:wallpaper_store"
    },
    {
        "displayName": "Museo de la guerra",
        "name": "categories/gcid:war_museum"
    },
    {
        "displayName": "Depósito",
        "name": "categories/gcid:warehouse"
    },
    {
        "displayName": "Club de grandes almacenes",
        "name": "categories/gcid:warehouse_club"
    },
    {
        "displayName": "Tienda de grandes almacenes",
        "name": "categories/gcid:warehouse_store"
    },
    {
        "displayName": "Servicio de reparación de lavadoras y secadoras",
        "name": "categories/gcid:washer_and_dryer_repair_service"
    },
    {
        "displayName": "Tienda de lavado y secado",
        "name": "categories/gcid:washer_and_dryer_store"
    },
    {
        "displayName": "Servicio de tratamiento de desechos",
        "name": "categories/gcid:waste_management_service"
    },
    {
        "displayName": "Estación de transferencia de residuos sólidos",
        "name": "categories/gcid:waste_transfer_station"
    },
    {
        "displayName": "Fabricante de relojes",
        "name": "categories/gcid:watch_manufacturer"
    },
    {
        "displayName": "Servicio de reparación de relojes",
        "name": "categories/gcid:watch_repair_service"
    },
    {
        "displayName": "Relojería",
        "name": "categories/gcid:watch_store"
    },
    {
        "displayName": "Proveedor de enfriadores de agua",
        "name": "categories/gcid:water_cooler_supplier"
    },
    {
        "displayName": "Servicio de restauración de daños producidos por inundaciones",
        "name": "categories/gcid:water_damage_restoration_service"
    },
    {
        "displayName": "Proveedor de filtros para agua",
        "name": "categories/gcid:water_filter_supplier"
    },
    {
        "displayName": "Servicio de corte por chorro de agua",
        "name": "categories/gcid:water_jet_cutting_service"
    },
    {
        "displayName": "Molino de agua",
        "name": "categories/gcid:water_mill"
    },
    {
        "displayName": "Parque acuático",
        "name": "categories/gcid:water_park"
    },
    {
        "displayName": "Piscina de water polo",
        "name": "categories/gcid:water_polo_pool"
    },
    {
        "displayName": "Proveedor de bombas de agua",
        "name": "categories/gcid:water_pump_supplier"
    },
    {
        "displayName": "Empresa de purificación de agua",
        "name": "categories/gcid:water_purification_company"
    },
    {
        "displayName": "Tienda de insumos para esquí sobre agua",
        "name": "categories/gcid:water_ski_shop"
    },
    {
        "displayName": "Club de esquí acuático",
        "name": "categories/gcid:water_skiing_club"
    },
    {
        "displayName": "Instructor de esquí acuático",
        "name": "categories/gcid:water_skiing_instructor"
    },
    {
        "displayName": "Servicio de esquí acuático",
        "name": "categories/gcid:water_skiing_service"
    },
    {
        "displayName": "Proveedor de equipos para ablandamiento de agua",
        "name": "categories/gcid:water_softening_equipment_supplier"
    },
    {
        "displayName": "Servicio de alquiler de equipos para deportes acuáticos",
        "name": "categories/gcid:water_sports_equipment_rental_service"
    },
    {
        "displayName": "Servicio de limpieza de tanques de agua",
        "name": "categories/gcid:water_tank_cleaning_service"
    },
    {
        "displayName": "Servicio de análisis de agua",
        "name": "categories/gcid:water_testing_service"
    },
    {
        "displayName": "Planta de tratamiento de agua",
        "name": "categories/gcid:water_treatment_plant"
    },
    {
        "displayName": "Empresa de tratamiento del agua",
        "name": "categories/gcid:water_treatment_supplier"
    },
    {
        "displayName": "Compañía de servicios públicos de agua",
        "name": "categories/gcid:water_utility_company"
    },
    {
        "displayName": "Obras sanitarias",
        "name": "categories/gcid:water_works"
    },
    {
        "displayName": "Water works equipment supplier",
        "name": "categories/gcid:water_works_equipment_supplier"
    },
    {
        "displayName": "Servicio de reparación de camas de agua",
        "name": "categories/gcid:waterbed_repair_service"
    },
    {
        "displayName": "Tienda de camas de agua",
        "name": "categories/gcid:waterbed_store"
    },
    {
        "displayName": "Empresa de impermeabilización",
        "name": "categories/gcid:waterproofing_company"
    },
    {
        "displayName": "Museo de cera",
        "name": "categories/gcid:wax_museum"
    },
    {
        "displayName": "Proveedor de cera",
        "name": "categories/gcid:wax_supplier"
    },
    {
        "displayName": "Servicio de depilación con cera",
        "name": "categories/gcid:waxing_hair_removal_service"
    },
    {
        "displayName": "Servicio meteorológico",
        "name": "categories/gcid:weather_forecast_service"
    },
    {
        "displayName": "Fábrica de tejidos",
        "name": "categories/gcid:weaving_mill"
    },
    {
        "displayName": "Servicio de alojamiento web",
        "name": "categories/gcid:web_hosting_service"
    },
    {
        "displayName": "Diseñador web",
        "name": "categories/gcid:website_designer"
    },
    {
        "displayName": "Panadería para bodas",
        "name": "categories/gcid:wedding_bakery"
    },
    {
        "displayName": "Buffet para bodas",
        "name": "categories/gcid:wedding_buffet"
    },
    {
        "displayName": "Capilla para bodas",
        "name": "categories/gcid:wedding_chapel"
    },
    {
        "displayName": "Servicio de alquiler de vestidos de novia",
        "name": "categories/gcid:wedding_dress_rental_service"
    },
    {
        "displayName": "Fotógrafo de bodas",
        "name": "categories/gcid:wedding_photographer"
    },
    {
        "displayName": "Planificador de bodas",
        "name": "categories/gcid:wedding_planner"
    },
    {
        "displayName": "Servicio de bodas",
        "name": "categories/gcid:wedding_service"
    },
    {
        "displayName": "Tienda de recuerdos para bodas",
        "name": "categories/gcid:wedding_souvenir_shop"
    },
    {
        "displayName": "Tienda de bodas",
        "name": "categories/gcid:wedding_store"
    },
    {
        "displayName": "Salón de bodas",
        "name": "categories/gcid:wedding_venue"
    },
    {
        "displayName": "Estación de pesaje",
        "name": "categories/gcid:weigh_station"
    },
    {
        "displayName": "Servicio de adelgazamiento",
        "name": "categories/gcid:weight_loss_service"
    },
    {
        "displayName": "Área de levantamiento de pesas",
        "name": "categories/gcid:weightlifting_area"
    },
    {
        "displayName": "Presa",
        "name": "categories/gcid:weir"
    },
    {
        "displayName": "Soldador",
        "name": "categories/gcid:welder"
    },
    {
        "displayName": "Proveedor de gas para soldadura",
        "name": "categories/gcid:welding_gas_supplier"
    },
    {
        "displayName": "Tienda de insumos para soldadura",
        "name": "categories/gcid:welding_supply_store"
    },
    {
        "displayName": "Contratista de perforación de pozos",
        "name": "categories/gcid:well_drilling_contractor"
    },
    {
        "displayName": "Centro de bienestar",
        "name": "categories/gcid:wellness_center"
    },
    {
        "displayName": "Hotel balneario",
        "name": "categories/gcid:wellness_hotel"
    },
    {
        "displayName": "Programa de bienestar",
        "name": "categories/gcid:wellness_program"
    },
    {
        "displayName": "Restaurante galés",
        "name": "categories/gcid:welsh_restaurant"
    },
    {
        "displayName": "Iglesia wesleyana",
        "name": "categories/gcid:wesleyan_church"
    },
    {
        "displayName": "Restaurante de cocina de África Occidental",
        "name": "categories/gcid:west_african_restaurant"
    },
    {
        "displayName": "Tienda de indumentaria occidental",
        "name": "categories/gcid:western_apparel_store"
    },
    {
        "displayName": "Restaurante de comida occidental",
        "name": "categories/gcid:western_restaurant"
    },
    {
        "displayName": "Agencia de turismo de avistaje de ballenas",
        "name": "categories/gcid:whale_watching_tour_agency"
    },
    {
        "displayName": "Servicio de alineación de ruedas",
        "name": "categories/gcid:wheel_alignment_service"
    },
    {
        "displayName": "Tienda de rines",
        "name": "categories/gcid:wheel_store"
    },
    {
        "displayName": "Servicio de alquiler de sillas de ruedas",
        "name": "categories/gcid:wheelchair_rental_service"
    },
    {
        "displayName": "Servicio de reparación de sillas de ruedas",
        "name": "categories/gcid:wheelchair_repair_service"
    },
    {
        "displayName": "Tienda de sillas de ruedas",
        "name": "categories/gcid:wheelchair_store"
    },
    {
        "displayName": "Panadería mayorista",
        "name": "categories/gcid:wholesale_bakery"
    },
    {
        "displayName": "Distribuidor al por mayor de medicamentos",
        "name": "categories/gcid:wholesale_drugstore"
    },
    {
        "displayName": "Florería mayorista",
        "name": "categories/gcid:wholesale_florist"
    },
    {
        "displayName": "Tienda de alimentos al por mayor",
        "name": "categories/gcid:wholesale_food_store"
    },
    {
        "displayName": "Mayorista de alimentos y artículos para el hogar",
        "name": "categories/gcid:wholesale_grocer"
    },
    {
        "displayName": "Joyería mayorista",
        "name": "categories/gcid:wholesale_jeweler"
    },
    {
        "displayName": "Mercado mayorista",
        "name": "categories/gcid:wholesale_market"
    },
    {
        "displayName": "Vivero mayorista",
        "name": "categories/gcid:wholesale_plant_nursery"
    },
    {
        "displayName": "Mayorista",
        "name": "categories/gcid:wholesaler"
    },
    {
        "displayName": "Mayorista de electrodomésticos",
        "name": "categories/gcid:wholesaler_household_appliances"
    },
    {
        "displayName": "Zona de Wi-Fi",
        "name": "categories/gcid:wi_fi_spot"
    },
    {
        "displayName": "Mimbrería",
        "name": "categories/gcid:wicker_store"
    },
    {
        "displayName": "Tienda de pelucas",
        "name": "categories/gcid:wig_shop"
    },
    {
        "displayName": "Parque de vida silvestre y safari",
        "name": "categories/gcid:wildlife_and_safari_park"
    },
    {
        "displayName": "Parque de vida silvestre",
        "name": "categories/gcid:wildlife_park"
    },
    {
        "displayName": "Reserva de vida silvestre",
        "name": "categories/gcid:wildlife_refuge"
    },
    {
        "displayName": "Servicio de preservación de la vida silvestre",
        "name": "categories/gcid:wildlife_rescue_service"
    },
    {
        "displayName": "Fabricante de cestas de mimbre",
        "name": "categories/gcid:willow_basket_manufacturer"
    },
    {
        "displayName": "Central eólica",
        "name": "categories/gcid:wind_farm"
    },
    {
        "displayName": "Fabricante de turbinas eólicas",
        "name": "categories/gcid:wind_turbine_builder"
    },
    {
        "displayName": "Servicio de limpieza de ventanas",
        "name": "categories/gcid:window_cleaning_service"
    },
    {
        "displayName": "Servicio de instalación de ventanas",
        "name": "categories/gcid:window_installation_service"
    },
    {
        "displayName": "Proveedor de ventanas",
        "name": "categories/gcid:window_supplier"
    },
    {
        "displayName": "Servicio de polarizado de ventanas",
        "name": "categories/gcid:window_tinting_service"
    },
    {
        "displayName": "Tienda de tratamiento de ventanas",
        "name": "categories/gcid:window_treatment_store"
    },
    {
        "displayName": "Tienda de windsurf",
        "name": "categories/gcid:windsurfing_store"
    },
    {
        "displayName": "Bar especializado en vinos",
        "name": "categories/gcid:wine_bar"
    },
    {
        "displayName": "Wine cellar",
        "name": "categories/gcid:wine_cellar"
    },
    {
        "displayName": "Club de vino",
        "name": "categories/gcid:wine_club"
    },
    {
        "displayName": "Servicio de almacenamiento de vinos",
        "name": "categories/gcid:wine_storage_facility"
    },
    {
        "displayName": "Tienda de vinos",
        "name": "categories/gcid:wine_store"
    },
    {
        "displayName": "Mayorista e importador de vinos",
        "name": "categories/gcid:wine_wholesaler"
    },
    {
        "displayName": "Tienda de insumos para la elaboración de vinos",
        "name": "categories/gcid:winemaking_supply_store"
    },
    {
        "displayName": "Bodega",
        "name": "categories/gcid:winery"
    },
    {
        "displayName": "Escuela de wing chun",
        "name": "categories/gcid:wing_chun_school"
    },
    {
        "displayName": "Wok restaurant",
        "name": "categories/gcid:wok_restaurant"
    },
    {
        "displayName": "Tienda de ropa para mujeres",
        "name": "categories/gcid:womens_clothing_store"
    },
    {
        "displayName": "Universidad femenina",
        "name": "categories/gcid:womens_college"
    },
    {
        "displayName": "Organización de mujeres",
        "name": "categories/gcid:womens_organization"
    },
    {
        "displayName": "Entrenador personal para mujeres",
        "name": "categories/gcid:womens_personal_trainer"
    },
    {
        "displayName": "Servicio de protección para mujeres",
        "name": "categories/gcid:womens_protection_service"
    },
    {
        "displayName": "Refugio para mujeres",
        "name": "categories/gcid:womens_shelter"
    },
    {
        "displayName": "Comercializadora de pisos de madera y laminados",
        "name": "categories/gcid:wood_and_laminate_flooring_supplier"
    },
    {
        "displayName": "Servicio de instalación de pisos de madera",
        "name": "categories/gcid:wood_floor_installation_service"
    },
    {
        "displayName": "Wood floor refinishing service",
        "name": "categories/gcid:wood_floor_refinishing_service"
    },
    {
        "displayName": "Distribuidor de marcos de madera",
        "name": "categories/gcid:wood_frame_supplier"
    },
    {
        "displayName": "Tienda de estufas a leña",
        "name": "categories/gcid:wood_stove_shop"
    },
    {
        "displayName": "Proveedor de madera",
        "name": "categories/gcid:wood_supplier"
    },
    {
        "displayName": "Escuela de carpintería",
        "name": "categories/gcid:wood_working_class"
    },
    {
        "displayName": "Carpintero",
        "name": "categories/gcid:woodworker"
    },
    {
        "displayName": "Tienda de insumos para artesanías en madera",
        "name": "categories/gcid:woodworking_supply_store"
    },
    {
        "displayName": "Tienda de lana",
        "name": "categories/gcid:wool_store"
    },
    {
        "displayName": "Tienda de vestimenta de trabajo",
        "name": "categories/gcid:work_clothes_store"
    },
    {
        "displayName": "Club de trabajadores",
        "name": "categories/gcid:workers_club"
    },
    {
        "displayName": "Hostal para mujeres trabajadoras",
        "name": "categories/gcid:working_womens_hostel"
    },
    {
        "displayName": "Escuela de lucha",
        "name": "categories/gcid:wrestling_school"
    },
    {
        "displayName": "Proveedor de equipos de radiografía",
        "name": "categories/gcid:x_ray_equipment_supplier"
    },
    {
        "displayName": "Laboratorio de radiografía",
        "name": "categories/gcid:x_ray_lab"
    },
    {
        "displayName": "Corredor de yates",
        "name": "categories/gcid:yacht_broker"
    },
    {
        "displayName": "Club de yates",
        "name": "categories/gcid:yacht_club"
    },
    {
        "displayName": "Yakatabune",
        "name": "categories/gcid:yakatabune"
    },
    {
        "displayName": "Restaurante de yakiniku",
        "name": "categories/gcid:yakiniku_restaurant"
    },
    {
        "displayName": "Restaurante de yakisoba",
        "name": "categories/gcid:yakisoba_restaurant"
    },
    {
        "displayName": "Restaurante de yakitori",
        "name": "categories/gcid:yakitori_restaurant"
    },
    {
        "displayName": "Tienda de lanas",
        "name": "categories/gcid:yarn_store"
    },
    {
        "displayName": "Restaurante yemení",
        "name": "categories/gcid:yemenite_restaurant"
    },
    {
        "displayName": "Yeshivá",
        "name": "categories/gcid:yeshiva"
    },
    {
        "displayName": "Instructor de yoga",
        "name": "categories/gcid:yoga_instructor"
    },
    {
        "displayName": "Centro de retiros de yoga",
        "name": "categories/gcid:yoga_retreat_center"
    },
    {
        "displayName": "Estudio de yoga",
        "name": "categories/gcid:yoga_studio"
    },
    {
        "displayName": "Centro de asistencia juvenil",
        "name": "categories/gcid:youth_care"
    },
    {
        "displayName": "Centro juvenil",
        "name": "categories/gcid:youth_center"
    },
    {
        "displayName": "Tienda de ropa juvenil",
        "name": "categories/gcid:youth_clothing_store"
    },
    {
        "displayName": "Club de jóvenes",
        "name": "categories/gcid:youth_club"
    },
    {
        "displayName": "Youth group",
        "name": "categories/gcid:youth_groups"
    },
    {
        "displayName": "Hostal juvenil",
        "name": "categories/gcid:youth_hostel"
    },
    {
        "displayName": "Organización juvenil",
        "name": "categories/gcid:youth_organization"
    },
    {
        "displayName": "Organización de servicios sociales para la juventud",
        "name": "categories/gcid:youth_social_services_organization"
    },
    {
        "displayName": "Restaurante yucateco",
        "name": "categories/gcid:yucatan_restaurant"
    },
    {
        "displayName": "Restaurante de comida chekianesa",
        "name": "categories/gcid:zhe_jiang_restaurant"
    },
    {
        "displayName": "Zoológico",
        "name": "categories/gcid:zoo"
    }
]