import React from 'react';
import { Provider } from 'react-redux';

import { store } from './store/store';
import { AppRouter } from './routers/AppRouter';
import { APIProvider } from '@vis.gl/react-google-maps';

export const App = () => {
  return (
    <Provider store={store}>
      <APIProvider apiKey={'AIzaSyCJ8i9yk_zAV-6qjm856a1eYFCvtAOBeyk'}>
        <AppRouter />
      </APIProvider>
    </Provider>
  );
}

export default App;
