import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { types } from '../types/types';
import { startLoading, finishLoading } from './ui';
import { makePOST, makeLogin } from '../helpers/makeRequest';
import { eraseCookie, getCookie, setCookie } from '../helpers/cookies';
export const tokenSubject = new BehaviorSubject((getCookie('token')));

export const token = tokenSubject.asObservable()

export const startLoginEmailPassword = (email, password) => {
    return (dispatch) => {
        dispatch(startLoading());
        return makeLogin("auth/login", { email, password })
            .then((response) => {
                const data = response.data
                dispatch(login(data.token, email));
                setCookie('email', email, 1)
                setCookie('token', data.token, 1)
                setCookie('user', JSON.stringify(data.usuario))
                tokenSubject.next(data.token)
                dispatch(finishLoading());
            })
            .catch(e => {
                console.log(e)
                dispatch(finishLoading());
                Swal.fire('Error', 'Por favor ingrese una contraseña o usuario válidos', 'error');
            })
    }
}

export const login = (uid, displayName, token) => ({
    type: types.login,
    payload: {
        uid,
        displayName,
        token
    }
});

export const startLogout = () => {
    return async (dispatch) => {
        eraseCookie('email')
        eraseCookie('token')
        eraseCookie('user')
        tokenSubject.next(null)
        dispatch(logout());
    }
}


export const logout = () => ({
    type: types.logout
})


